import { Box, Center, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import MeteogramChart from "../../../../components/MeteogramChart/MeteogramChart";
import noData from "../../../../assets/animations/no-data.json";
import emptyStateMeteogramImage from "../../../../assets/emptystate-meteogram-analysis.svg";
import Lottie from "lottie-react";
import { IOccurrenceDescription } from "../../../../types/IOccurrence";
import { meteogramFormatTooltip } from "../../../../util/meteogramCharts/meteogramFormatTooltip/meteogramFormatTooltip";
import { useStores } from "../../../../stores/setup/use-store";

interface MeteogramProps {
  description: IOccurrenceDescription | undefined;
  chart: any;
  timezone?: any;
  chartXAxis: any[];
  chartYAxis: any[];
}

const Meteogram: React.FC<MeteogramProps> = ({
  description,
  chart,
  timezone,
  chartXAxis,
  chartYAxis,
}) => {
  const {
    dataStores: { forecastChartMeteogramStore },
  } = useStores();

  const { t } = useTranslation("translation", {
    keyPrefix: "eventAnalysis",
  });

  return (
    <>
      <Text color="newBlue.500" mt={8}>
        {t("timeSeries")}
      </Text>
      <Text color="gray.500" fontSize={"sm"} mb={2}>
        {t("theChartBelowShows")}
      </Text>
      {description ? (
        chart && Object.keys(chart as object).length ? (
          <MeteogramChart
            timezoneOffset={timezone || 0}
            options={{
              time: {
                useUTC: false,
              },
              chart: {
                zoomType: "xy",
                height: 250,
                backgroundColor: "#f0f1f2",
              },
              title: {
                text: "",
              },
              subtitle: {
                text: "",
              },
              xAxis: chartXAxis,
              yAxis: chartYAxis,
              tooltip: {
                shared: true,
                useHTML: true,
                formatter: function () {
                  const { x, y, points } = this;
                  return meteogramFormatTooltip(
                    x,
                    y,
                    points,
                    forecastChartMeteogramStore.renderInsightTitleOnMeteogram(
                      25
                    )
                  );
                },
              },
              legend: {
                x: -10,
                y: 10,
                floating: true,
                verticalAlign: "top",
                itemStyle: {
                  fontSize: "14px",
                  fontFamily: "'Hind Vadodara', 'Helvetica Neue', sans-serif",
                  fontWeight: "normal",
                  color: "#00000",
                },
              },
              series: chart,
            }}
          />
        ) : (
          <>
            <Center>
              <Box width={"140px"}>
                <Lottie animationData={noData} />
              </Box>
            </Center>
            <Center>
              <Text color={"newBlue.500"} fontSize="md">
                {t("noi4castDataFound")}
              </Text>
            </Center>
          </>
        )
      ) : (
        <Center mt={6} mb={4}>
          <img
            src={emptyStateMeteogramImage}
            style={{ width: "350px" }}
            alt="empty state"
          />
        </Center>
      )}
    </>
  );
};

export default Meteogram;
