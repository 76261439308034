import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  Table,
  TableProps,
  Tbody,
  Thead,
  Tr,
  Text,
} from "@chakra-ui/react";
import Loading from "../Loading/Loading";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { t } from "i18next";
import { IStationsFilter } from "../../stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";

interface CustomTableProps<T> {
  data: T[];
  isLoading: boolean;
  renderHeader: () => React.ReactNode;
  renderCell: (item: T) => React.ReactNode;
  pageSize: number;
  totalPages: number;
  getItems: (stationRequest: IStationsFilter) => void;
  manualPagination: boolean;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const TableComponent = <T extends { [key: string]: any }>(
  props: CustomTableProps<T> & TableProps
) => {
  const {
    data,
    isLoading,
    renderHeader,
    renderCell,
    pageSize,
    totalPages,
    getItems,
    manualPagination,
    currentPage,
    setCurrentPage,
    ...tableProps
  } = props;

  const [totalItems, setTotalItems] = useState(0);
  const [list, setList] = useState<T[]>(data);

  const startIndex = currentPage * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalItems);

  useEffect(() => {
    setTotalItems(data.length);
    if (manualPagination) {
      setList(data.slice(startIndex, endIndex));
    } else {
      setList(data);
    }

    if (currentPage > totalPages - 1) {
      setCurrentPage(0);
    }
  }, [
    currentPage,
    data,
    data.length,
    endIndex,
    manualPagination,
    setCurrentPage,
    startIndex,
    totalItems,
    totalPages,
  ]);

  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      if (!manualPagination) {
        const newOffSet = (currentPage + 1) * 10;
        getItems({ limit: pageSize, offset: newOffSet });
      }

      setCurrentPage((prev) => prev + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 0) {
      if (!manualPagination) {
        const newOffSet = (currentPage - 1) * 10;
        getItems({ limit: pageSize, offset: newOffSet });
      }

      setCurrentPage((prev) => prev - 1);
    }
  };

  const gotoFirstPage = () => {
    if (!manualPagination) {
      getItems({ limit: pageSize, offset: 0 });
    }
    setCurrentPage(0);
  };

  const gotoLastPage = () => {
    if (!manualPagination) {
      const newOffSet = (totalPages - 1) * 10;

      getItems({ limit: pageSize, offset: newOffSet });
    }
    setCurrentPage(totalPages - 1);
  };

  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage === totalPages - 1;

  return (
    <Box>
      <Table variant="striped" {...tableProps}>
        {isLoading && <Loading />}
        <Thead bg="newBlue.500">{renderHeader()}</Thead>
        <Tbody color="gray.600">
          {list.map((item, index) => (
            <Tr key={`row-${index}`}>{renderCell(item)}</Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justifyContent="flex-end" mt={4} mb={4} gap={2}>
        <Flex>
          <IconButton
            onClick={gotoFirstPage}
            isDisabled={isFirstPage}
            transform={"rotate(180deg)"}
            color="black"
            icon={<DoubleArrowIcon />}
            mr={4}
            aria-label={""}
          />
          <IconButton
            onClick={previousPage}
            isDisabled={isFirstPage}
            color="black"
            icon={<ChevronLeftIcon />}
            aria-label={""}
          />
        </Flex>

        <Flex alignItems="center" color="black">
          <Text mr={8}>
            {t("page")}{" "}
            <Text fontWeight="bold" as="span">
              {currentPage + 1}
            </Text>{" "}
            {t("of")}{" "}
            <Text fontWeight="bold" as="span">
              {totalPages}
            </Text>
          </Text>
        </Flex>

        <Flex>
          <IconButton
            onClick={nextPage}
            isDisabled={isLastPage}
            color="black"
            icon={<ChevronRightIcon />}
            aria-label={""}
          />
          <IconButton
            onClick={gotoLastPage}
            isDisabled={isLastPage}
            color="black"
            icon={<DoubleArrowIcon />}
            ml={4}
            aria-label={""}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default TableComponent;
