import { JoyrideStorage } from "enum/JoyrideStorage";
import { getSensorJoySteps } from "../steps/SensorJoySteps";
import GuidedTutorial from "./GuidedTutorial";

export default function SensorGuidedTutorial({ shouldRunJoyride = false }) {
  return (
    <GuidedTutorial
      stepsProps={getSensorJoySteps()}
      storage={[JoyrideStorage.SENSOR_RIDE_ON_LOCAL_STORAGE]}
      shouldRunJoyride={shouldRunJoyride}
    />
  );
}
