export const transformGeo = (dataValue: number) => {
  if (
    (dataValue >= 348.75 && dataValue <= 360) ||
    (dataValue >= 0 && dataValue < 11.25)
  ) {
    return "N";
  } else if (dataValue >= 11.25 && dataValue < 33.75) {
    return "NNE";
  } else if (dataValue >= 33.75 && dataValue < 56.25) {
    return "NE";
  } else if (dataValue >= 56.25 && dataValue < 78.75) {
    return "ENE";
  } else if (dataValue >= 78.75 && dataValue < 101.25) {
    return "E";
  } else if (dataValue >= 101.25 && dataValue < 123.75) {
    return "ESE";
  } else if (dataValue >= 123.75 && dataValue < 146.25) {
    return "SE";
  } else if (dataValue >= 146.25 && dataValue < 168.75) {
    return "SSE";
  } else if (dataValue >= 168.75 && dataValue < 191.25) {
    return "S";
  } else if (dataValue >= 191.25 && dataValue < 213.75) {
    return "SSW";
  } else if (dataValue >= 213.75 && dataValue < 236.25) {
    return "SW";
  } else if (dataValue >= 236.25 && dataValue < 258.75) {
    return "WSW";
  } else if (dataValue >= 258.75 && dataValue < 281.25) {
    return "W";
  } else if (dataValue >= 281.25 && dataValue < 303.75) {
    return "WNW";
  } else if (dataValue >= 303.75 && dataValue < 326.25) {
    return "NW";
  } else if (dataValue >= 326.25 && dataValue < 348.75) {
    return "NNW";
  }
};
