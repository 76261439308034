import api from "./api";
import { IUser } from "../types/IUser";
import { IInvitationUser } from "../types/IInvitationUser";

export default class AdminPanelConfigService {
  async getUsersData(): Promise<IUser[]> {
    const result = await api.get("/v1/adminPanel/get-users");
    return result.data;
  }

  async updateUserGroup(userId: number, groupName: string): Promise<boolean> {
    const result = await api.put(`/v1/adminPanel/update-user-group/${userId}`, {
      group_name: groupName,
    });
    return result.data;
  }

  async updateUserActive(userId: number, active: boolean): Promise<boolean> {
    const result = await api.put(
      `/v1/adminPanel/update-user-active/${userId}`,
      {
        active,
      }
    );
    return result.data;
  }

  async inviteNewUser(email: string, group: string) {
    const result = await api.post("/v1/adminpanel/invite-new-user", {
      email,
      group,
    });
    return result.data;
  }

  async verifyEmailInUse(email: string) {
    const result = await api.post("/v1/adminPanel/verify-email-in-use", {
      email,
    });
    return result.data;
  }

  async loadInvitation(invitationToken: string): Promise<IInvitationUser> {
    const result = await api.get(
      `/v1/adminPanel/load-invitation/${invitationToken}`
    );
    return result.data;
  }
}
