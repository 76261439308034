import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Text,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

interface AlertSuccessImportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AlertSuccessImportModal = ({
  isOpen,
  onClose,
}: AlertSuccessImportModalProps) => {
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation("translation", {
    keyPrefix: "occurrence",
  });
  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={() => {}}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent color="newBlue.500">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t("successfullyUploaded")}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text fontSize="md" fontWeight="light">
              {t("yourFileWithOccurrenceWasSent")}
            </Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              layerStyle="ocean"
              _hover={{ layerStyle: "ocean" }}
              onClick={onClose}
              ml={3}
            >
              {t("iGotIt")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
