import { useTheme } from "@chakra-ui/react";
import ReactJoyride, { Props } from "react-joyride";
import { ChakraTheme } from "../../styles/theme";

type JoyrideProps = {
  run?: boolean;
} & Omit<Props, "run">;

export default function Joyride({ run = true, ...rest }: JoyrideProps) {
  const theme: ChakraTheme = useTheme();
  return (
    <ReactJoyride
      styles={{
        tooltipContent: {
          padding: 0,
        },

        buttonClose: {
          display: "none",
        },
        buttonNext: {
          color: theme.colors.white[100],
        },

        options: {
          backgroundColor: theme.colors.white[300],
          overlayColor: "rgba(0,0,0,0.6)",
          primaryColor: theme.colors.newBlue[500],
          textColor: theme.colors.newBlue[500],
          zIndex: 1000,
        },
      }}
      run={run}
      {...rest}
    />
  );
}
