import { Step } from "react-joyride";

import JoyrideContent from "../../../../components/JoyrideContent/JoyrideContent";
import guideProbabilistic from "../../../../assets/guide-probabilistic.svg";
import i18n from "../../../../i18n";

export const probabilisticJoySteps: Step[] = [
  {
    target: ".joyride-forecast-first-step",
    placement: "center",
    disableBeacon: true,
    content: (
      <JoyrideContent
        image={{
          src: guideProbabilistic,
          alt: "Random image",
        }}
        text={
          i18n.t("forecastChart.forecastChartPage.probabilityMode") as string
        }
        summary={
          i18n.t("forecastChart.forecastChartPage.ifTheViewedRegion") as string
        }
      />
    ),
  },
  {
    target: ".joyride-probabilistic",
    content: (
      <JoyrideContent
        text={
          i18n.t(
            "forecastChart.forecastChartPage.forEachSelectedEnvironmental"
          ) as string
        }
      />
    ),
  },
];
