import React from "react";
import { Formik } from "formik";
import { Button, Text, useToast } from "@chakra-ui/react";
import { IStationRegion } from "../../../types/IStationRegion";
import { EnvironmentalType } from "../../../enum/EnvironmentalType";
import { useTranslation } from "react-i18next";
import { Modal as ModalAntd } from "antd";
import { useStores } from "stores/setup/use-store";
import { observer } from "mobx-react-lite";
import { useIsLoading } from "./hooks/useIsLoading";
import { VariableStationControl } from "./components/VariableStationControl";
import { LoadingState } from "./components/LoadingState";
import { useMetOceanReportDataRequest } from "./hooks/useMetOceanReportDataRequest";

export interface MetOceanReportRequest {
  environmentalType: EnvironmentalType;
  regionId: string;
  stationId: string;
}

export interface FormValues {
  weather: boolean;
  wave: boolean;
  waterFlow: boolean;
  weatherLocation: string | null;
  waveLocation: string | null;
  waterFlowLocation: string | null;
}

interface MetOceanReportModalProps {
  isOpen: boolean;
  onClose: () => void;
  stations: IStationRegion[];
  selectedStationId: number;
  selectedRegionId: number;
}

const MetOceanReportModal: React.FC<MetOceanReportModalProps> = ({
  isOpen,
  onClose,
  stations,
  selectedStationId,
  selectedRegionId,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.metOceanReport",
  });

  const {
    dataStores: { forecastChartPageStore },
  } = useStores();

  const toast = useToast();

  const isLoading = useIsLoading();
  const getMetOceanReportDataRequest = useMetOceanReportDataRequest();
  const selectedStationRegionId =
    selectedStationId > 0 && selectedRegionId > 0
      ? `${selectedStationId}-${selectedRegionId}`
      : null;

  const initialValues: FormValues = {
    weather: true,
    wave: true,
    waterFlow: true,
    weatherLocation: selectedStationRegionId,
    waveLocation: selectedStationRegionId,
    waterFlowLocation: selectedStationRegionId,
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string; children: any }
  ) => {
    const children = option?.children;
    const childrenArray = Array.isArray(children) ? children : [children];

    return childrenArray.join().toLowerCase().includes(input.toLowerCase());
  };

  const handleSendData = async (values: FormValues) => {
    const metOceanReportData = getMetOceanReportDataRequest(values);

    if (!metOceanReportData.length) {
      return;
    }

    const url = await forecastChartPageStore.getPDFReport(metOceanReportData);

    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    } else {
      toast({
        title: t("weWereUnableToOpen"),
        status: "info",
        position: "top",
        isClosable: true,
      });
    }

    onClose();
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values) => {
        handleSendData(values);
      }}
    >
      {({ values, getFieldProps, submitForm, setFieldValue }) => (
        <ModalAntd
          title={
            <Text fontWeight={"bold"} color="newBlue.500">
              {t("metOceanForecastReport")}
            </Text>
          }
          open={isOpen}
          onCancel={onClose}
          width="800px"
          footer={[
            <Button
              key="back"
              variant="ghost"
              color="newBlue.500"
              onClick={onClose}
              mr={3}
              disabled={isLoading}
            >
              {t("cancel")}
            </Button>,
            <Button
              key="submit"
              layerStyle="ocean"
              _hover={{ layerStyle: "ocean" }}
              onClick={submitForm}
              type="submit"
              disabled={isLoading}
            >
              {t("generate")}
            </Button>,
          ]}
        >
          {isLoading ? (
            <LoadingState />
          ) : (
            <VariableStationControl
              values={values}
              filterOption={filterOption}
              getFieldProps={getFieldProps}
              selectedRegionId={selectedRegionId}
              selectedStationId={selectedStationId}
              setFieldValue={setFieldValue}
              stations={stations}
            />
          )}
        </ModalAntd>
      )}
    </Formik>
  );
};

export default observer(MetOceanReportModal);
