export enum EnvironmentalVariables {
  //WEATHER
  WIND_VEL = "wind_vel",
  WIND_VEL_80M = "wind_vel_80m",
  WIND_VEL_100M = "wind_vel_100m",
  GUST = "gust",
  GUST_80M = "gust_80m",
  GUST_100M = "gust_100m",
  WIND_DIR = "wind_dir",
  WIND_DIR_80M = "wind_dir_80m",
  WIND_DIR_100M = "wind_dir_100m",

  //WAVE
  WAVE_HEIGHT = "wave_height",
  WAVE_HEIGHT_SEA = "wave_height_sea",
  WAVE_HEIGHT_SWELL = "wave_height_swell",
  WAVE_HEIGHT_MAX = "wave_height_max",
  PEAK_PERIOD = "peak_period",
  PEAK_PERIOD_SEA = "peak_period_sea",
  PEAK_PERIOD_SWELL = "peak_period_swell",
  MEAN_PERIOD = "mean_period",
  MEAN_PERIOD_SEA = "mean_period_sea",
  MEAN_PERIOD_SWELL = "mean_period_swell",
  WAVE_DIR = "wave_dir",
  WAVE_DIR_SEA = "wave_dir_sea",
  WAVE_DIR_SWELL = "wave_dir_swell",
  WAVE_POWER = "wave_power",

  //HIDRO
  WATER_LEVEL = "water_level",
  CURRENT_VEL = "current_vel",
  CURRENT_DIR = "current_dir",

  //TEMPERATURE
  TEMP_AIR = "temp_air",
  DEW_POINT = "dew_point",
  REL_HUMIDITY = "rel_humidity",

  //PRECIPTATION
  PRECIP_PROBABILITY = "precip_probability",
  PRECIP = "precip",
  PRECIP_MIN_20KM = "precip_min_20km",

  // VISIBILITY
  VISIBILITY = "visibility",
  VISIBILITY_MINIMUM_20KM = "visibility_minimum_20km",
  VISIBILITY_MAXIMUM_20KM = "visibility_maximum_20km",
  VISIBILITY_PROBABILITY_2000M = "visibility_probability_2000m",
  VISIBILITY_PROBABILITY_1500M = "visibility_probability_1500m",
  VISIBILITY_PROBABILITY_1000M = "visibility_probability_1000m",

  //RIVER WATER LEVEL
  RIVER_EXTREME_LOW = "river_extreme_low",
  RIVER_VERY_LOW = "river_very_low",
  RIVER_LOW = "river_low",
  RIVER_MOST_LIKELY = "river_most_likely",
  RIVER_HIGH = "river_high",
  RIVER_VERY_HIGH = "river_very_high",
  RIVER_EXTREME_HIGH = "river_extreme_high",
  RIVER_SENSOR = "river_sensor",
}
