import * as turf from "@turf/turf";

export const validatePointInsidePolygon = (
  point: number[],
  polygon: number[][][]
): boolean => {
  const pt = turf.point(point);
  const poly = turf.polygon(polygon);
  return turf.booleanPointInPolygon(pt, poly);
};
