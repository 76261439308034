import { Divider, Flex, Text } from "@chakra-ui/react";
import Drawer from "components/Drawer/Drawer";
import { VisualizationType } from "enum/VisualizationType";
import { observer } from "mobx-react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../stores/setup/use-store";

interface ForecastUpdatePopoverProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const ForecastUpdatePopover = ({
  isOpen,
  onClose,
  children,
}: ForecastUpdatePopoverProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartPanelMeteogram",
  });

  const {
    dataStores: { forecastChartMeteogramStore },
  } = useStores();
  const isForecast =
    forecastChartMeteogramStore.visualizationType ===
    VisualizationType.FORECAST;
  const isSensor =
    forecastChartMeteogramStore.visualizationType === VisualizationType.SENSOR;

  const isProbability =
    forecastChartMeteogramStore.visualizationType ===
    VisualizationType.PROBABILISTIC;

  const header = () => {
    if (isSensor) return t("aboutSensor");

    if (isProbability) return t("aboutProbabilistic");

    return t("aboutForecast");
  };

  const lastUpdate = () => (
    <Flex gap={1} mb={"4px"} direction="column">
      <Text fontSize="sm" color={"blackAlpha.700"} fontWeight="bold">
        {t("lastUpdatedAt")}:
      </Text>
      <Text fontSize="smaller" color={"blackAlpha.700"}>
        {forecastChartMeteogramStore.lastUpdateTime &&
        forecastChartMeteogramStore.showUpdateTime
          ? forecastChartMeteogramStore.lastUpdateTime
          : t("unavailable")}
      </Text>
    </Flex>
  );

  const nextUpdate = () => (
    <Flex gap={1} direction="column">
      <Text fontSize="sm" color={"blackAlpha.700"} fontWeight="bold">
        {t("nextUpdateEstimatedAt")}:
      </Text>
      <Text fontSize="smaller" color={"blackAlpha.700"}>
        {forecastChartMeteogramStore.nextUpdateTime &&
        forecastChartMeteogramStore.showUpdateTime
          ? forecastChartMeteogramStore.nextUpdateTime
          : t("unavailable")}
      </Text>
    </Flex>
  );

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} title={header()}>
        {isForecast && (
          <>
            {lastUpdate()}
            {nextUpdate()}
            <Divider my={3} />
          </>
        )}
        {children}
      </Drawer>
    </>
  );
};

export default observer(ForecastUpdatePopover);
