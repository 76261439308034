import { CustomToast } from "../ToastAlert/ToastAlert";
import React from "react";
interface I4castAlertProps {
  visible: boolean;
  message: string;
  type: "error" | "success" | "info" | "warning" | undefined;
  style?: React.CSSProperties;
}

const I4castAlert: React.FC<I4castAlertProps> = ({
  message,
  visible,
  type,
}) => {
  const { addToast } = CustomToast();

  return <>{visible && message ? addToast({ message, type }) : ""}</>;
};

export default I4castAlert;
