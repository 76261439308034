import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { MeteogramVariables } from "../../../enum/EnvironmentalType";
import { useStores } from "../../../stores/setup/use-store";
import { StoreState } from "../../../enum/StoreState";
import { useTranslation } from "react-i18next";

const UnitMeasurement: React.FC = () => {
  const {
    dataStores: { unitMeasurementStore },
  } = useStores();

  const [windUnit, setWindUnit] = useState("");
  const [currentUnit, setCurrentUnit] = useState("");
  const [waveUnit, setWaveUnit] = useState("");
  const [waterLevelUnit, setWaterLevelUnit] = useState("");
  const [precipitationUnit, setPrecipitationUnit] = useState("");
  const [visibilityUnit, setVisibilityUnit] = useState("");
  const toast = useToast();

  const { t } = useTranslation("translation", {
    keyPrefix: "settings",
  });

  useEffect(() => {
    unitMeasurementStore.getUserUnitMeasurementApi().then(() => {
      let _windUnit = unitMeasurementStore.getUnitMeasurementConfig(
        MeteogramVariables.WIND_VEL
      );
      let _currentUnit = unitMeasurementStore.getUnitMeasurementConfig(
        MeteogramVariables.CURRENT_VEL
      );
      let _waveUnit = unitMeasurementStore.getUnitMeasurementConfig(
        MeteogramVariables.WAVE_HEIGHT
      );
      let _waterLevelUnit = unitMeasurementStore.getUnitMeasurementConfig(
        MeteogramVariables.WATER_LEVEL
      );
      let _prepUnit = unitMeasurementStore.getUnitMeasurementConfig(
        MeteogramVariables.PRECIP
      );
      let _visibilityUnit = unitMeasurementStore.getUnitMeasurementConfig(
        MeteogramVariables.VISIBILITY
      );

      _windUnit = _windUnit === "kt" ? "knots" : _windUnit;
      _currentUnit = _currentUnit === "kt" ? "knots" : _currentUnit;

      setWindUnit(_windUnit);
      setCurrentUnit(_currentUnit);
      setWaveUnit(_waveUnit);
      setWaterLevelUnit(_waterLevelUnit);
      setPrecipitationUnit(_prepUnit);
      setVisibilityUnit(_visibilityUnit);
    });
  }, [unitMeasurementStore]);

  useEffect(() => {
    if (unitMeasurementStore.state === StoreState.SUCCESS) {
      toast({
        title: t("success"),
        description: t("yourChangesHaveSaved"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitMeasurementStore.state]);

  const handleChangeWind = (value: string) => {
    setWindUnit(value);
    unitMeasurementStore.addUserUnitMeasurementApi(
      [
        {
          environmentalVariable: MeteogramVariables.WIND_VEL,
          unitVariable: value,
        },
        {
          environmentalVariable: MeteogramVariables.WIND_VEL_80M,
          unitVariable: value,
        },
        {
          environmentalVariable: MeteogramVariables.WIND_VEL_100M,
          unitVariable: value,
        },
        {
          environmentalVariable: MeteogramVariables.GUST,
          unitVariable: value,
        },
        {
          environmentalVariable: MeteogramVariables.GUST_80M,
          unitVariable: value,
        },
        {
          environmentalVariable: MeteogramVariables.GUST_100M,
          unitVariable: value,
        },
      ],
      value
    );
  };

  const handleChangeWave = (value: string) => {
    setWaveUnit(value);
    unitMeasurementStore.addUserUnitMeasurementApi(
      [
        {
          environmentalVariable: MeteogramVariables.WAVE_HEIGHT,
          unitVariable: value,
        },
        {
          environmentalVariable: MeteogramVariables.WAVE_HEIGHT_SEA,
          unitVariable: value,
        },
        {
          environmentalVariable: MeteogramVariables.WAVE_HEIGHT_SWELL,
          unitVariable: value,
        },
        {
          environmentalVariable: MeteogramVariables.WAVE_HEIGHT_MAX,
          unitVariable: value,
        },
      ],
      value
    );
  };

  const handleChangeWaterLevel = (value: string) => {
    setWaterLevelUnit(value);
    unitMeasurementStore.addUserUnitMeasurementApi(
      [
        {
          environmentalVariable: MeteogramVariables.WATER_LEVEL,
          unitVariable: value,
        },
      ],
      value
    );
  };

  const handleChangePrecipitation = (value: string) => {
    setPrecipitationUnit(value);
    unitMeasurementStore.addUserUnitMeasurementApi(
      [
        {
          environmentalVariable: MeteogramVariables.PRECIP,
          unitVariable: value,
        },
        {
          environmentalVariable: MeteogramVariables.PRECIP_MIN_20KM,
          unitVariable: value,
        },
      ],
      value
    );
  };

  const handleChangeCurrent = (value: string) => {
    setCurrentUnit(value);
    unitMeasurementStore.addUserUnitMeasurementApi(
      [
        {
          environmentalVariable: MeteogramVariables.CURRENT_VEL,
          unitVariable: value,
        },
      ],
      value
    );
  };

  const handleChangeVisibility = (value: string) => {
    setVisibilityUnit(value);
    unitMeasurementStore.addUserUnitMeasurementApi(
      [
        {
          environmentalVariable: MeteogramVariables.VISIBILITY,
          unitVariable: value,
        },
        {
          environmentalVariable: MeteogramVariables.VISIBILITY_MINIMUM_20KM,
          unitVariable: value,
        },
        {
          environmentalVariable: MeteogramVariables.VISIBILITY_MAXIMUM_20KM,
          unitVariable: value,
        },
      ],
      value
    );
  };

  return (
    <Box>
      <Alert status="warning" mb={4}>
        <AlertIcon />
        {t("changingUnitStillNotAppliedOperationInsights")}
      </Alert>
      <Text mb={2}>{t("selectHowYouWantToSee")}</Text>
      {unitMeasurementStore.getUnitMeasurementState === StoreState.PEDDING && (
        <Center>
          <Spinner />
        </Center>
      )}
      {unitMeasurementStore.getUnitMeasurementState === StoreState.DONE && (
        <Wrap>
          <WrapItem>
            <Box mr={12} p={2} width="280px">
              <Text mb={2} fontSize="md" color="blue.100" borderBottom={"1px"}>
                {t("wind")}
              </Text>
              <RadioGroup
                onChange={handleChangeWind}
                value={windUnit}
                key={`windUnits-${windUnit}`}
              >
                <Stack direction="row">
                  <Radio value="knots" borderColor="gray.400">
                    {t("knots")}
                  </Radio>
                  <Radio value="m/s" borderColor="gray.400">
                    {t("ms")}
                  </Radio>
                  <Radio value="mph" borderColor="gray.400">
                    {t("mph")}
                  </Radio>
                  <Radio value="km/h" borderColor="gray.400">
                    {t("kmh")}
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>
          </WrapItem>

          <WrapItem>
            <Box mr={12} p={2} width="280px">
              <Text mb={2} fontSize="md" color="blue.100" borderBottom={"1px"}>
                {t("current")}
              </Text>
              <RadioGroup onChange={handleChangeCurrent} value={currentUnit}>
                <Stack direction="row">
                  <Radio value="knots" borderColor="gray.400">
                    {t("knots")}
                  </Radio>
                  <Radio value="m/s" borderColor="gray.400">
                    {t("ms")}
                  </Radio>
                  <Radio value="mph" borderColor="gray.400">
                    {t("mph")}
                  </Radio>
                  <Radio value="km/h" borderColor="gray.400">
                    {t("kmh")}
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>
          </WrapItem>

          <WrapItem>
            <Box mr={12} p={2} width="280px">
              <Text mb={2} fontSize="md" color="blue.100" borderBottom={"1px"}>
                {t("wave")}
              </Text>
              <RadioGroup onChange={handleChangeWave} value={waveUnit}>
                <Stack direction="row">
                  <Radio value="m" borderColor="gray.400">
                    {t("m")}
                  </Radio>
                  <Radio value="ft" borderColor="gray.400">
                    {t("ft")}
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>
          </WrapItem>

          <WrapItem>
            <Box mr={12} p={2} width="280px">
              <Text mb={2} fontSize="md" color="blue.100" borderBottom={"1px"}>
                {t("waterLevel")}
              </Text>
              <RadioGroup
                onChange={handleChangeWaterLevel}
                value={waterLevelUnit}
              >
                <Stack direction="row">
                  <Radio value="m" borderColor="gray.400">
                    {t("m")}
                  </Radio>
                  <Radio value="ft" borderColor="gray.400">
                    {t("ft")}
                  </Radio>
                  <Radio value="cm" borderColor="gray.400">
                    {t("cm")}
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>
          </WrapItem>

          <WrapItem>
            <Box mr={12} p={2} width="280px">
              <Text mb={2} fontSize="md" color="blue.100" borderBottom={"1px"}>
                {t("precipitation")}
              </Text>
              <RadioGroup
                onChange={handleChangePrecipitation}
                value={precipitationUnit}
              >
                <Stack direction="row">
                  <Radio value="mm" borderColor="gray.400">
                    {t("mm")}
                  </Radio>
                  <Radio value="in" borderColor="gray.400">
                    {t("inches")}
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>
          </WrapItem>

          <WrapItem>
            <Box mr={12} p={2} width="280px">
              <Text mb={2} fontSize="md" color="blue.100" borderBottom={"1px"}>
                {t("visibility")}
              </Text>
              <RadioGroup
                onChange={handleChangeVisibility}
                value={visibilityUnit}
              >
                <Stack direction="row">
                  <Radio value="km" borderColor="gray.400">
                    {t("km")}
                  </Radio>
                  <Radio value="m" borderColor="gray.400">
                    {t("m")}
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>
          </WrapItem>
        </Wrap>
      )}
    </Box>
  );
};

export default observer(UnitMeasurement);
