import { Box, Flex, Text } from "@chakra-ui/react";

import markerIcon from "../../assets/marker-icon.svg";
import { transformGeo } from "../../util/meteogramCharts/transformGeo/transformGeo";
import { getTimeDifference } from "../../util/getTimeDifference/getTimeDifference";
import { useTranslation } from "react-i18next";
import useConvertValueToUserUnit from "../../hooks/useConvertValueToUserUnit";
import { EnvironmentalVariables } from "enum/EnvironmentalVariables";
import { reverseDirection } from "util/meteogramCharts/meteogramFormatTooltip/meteogramFormatTooltip";
import { Range } from "types/Range";

type ZoomLevel = Range<19>;

export interface TooltipComponentProps {
  zoomLevel: number;
  title: string;
  degreePosition?: number;
  value?: number;
  defaultUnit?: string;
  lastUpdate?: Date;
  environmentalVariable?: string;
  showFooterText?: boolean;
}

const fontSizeMapper = {
  0: { title: "0.3rem", text: "0.2rem", unit: "0.2rem", degree: "0.2rem" },
  1: { title: "0.4rem", text: "0.3rem", unit: "0.3rem", degree: "0.3rem" },
  2: { title: "0.5rem", text: "0.4rem", unit: "0.4rem", degree: "0.4rem" },
  3: { title: "0.6rem", text: "0.5rem", unit: "0.5rem", degree: "0.5rem" },
  4: { title: "0.7rem", text: "0.6rem", unit: "0.6rem", degree: "0.6rem" },
  5: { title: "0.8rem", text: "0.6rem", unit: "0.6rem", degree: "0.6rem" },
  6: { title: "0.9rem", text: "0.7rem", unit: "0.7rem", degree: "0.7rem" },
  7: { title: "0.9rem", text: "0.8rem", unit: "0.8rem", degree: "0.8rem" },
  8: { title: "0.9rem", text: "0.8rem", unit: "0.8rem", degree: "0.8rem" },
  9: { title: "0.9rem", text: "0.8rem", unit: "0.8rem", degree: "0.8rem" },
  10: { title: "0.9rem", text: "0.8rem", unit: "0.8rem", degree: "0.8rem" },
  11: { title: "0.9rem", text: "0.8rem", unit: "0.8rem", degree: "0.8rem" },
  12: { title: "0.9rem", text: "0.8rem", unit: "0.8rem", degree: "0.8rem" },
  13: { title: "0.9rem", text: "0.8rem", unit: "0.8rem", degree: "0.8rem" },
  14: { title: "0.9rem", text: "0.8rem", unit: "0.8rem", degree: "0.8rem" },
  15: { title: "0.9rem", text: "0.8rem", unit: "0.8rem", degree: "0.8rem" },
  16: { title: "0.9rem", text: "0.8rem", unit: "0.8rem", degree: "0.8rem" },
  17: { title: "0.9rem", text: "0.8rem", unit: "0.8rem", degree: "0.8rem" },
  18: { title: "0.9rem", text: "0.8rem", unit: "0.8rem", degree: "0.8rem" },
};

export const TooltipContent = ({
  zoomLevel,
  title,
  degreePosition,
  value,
  defaultUnit,
  lastUpdate,
  environmentalVariable,
  showFooterText,
}: TooltipComponentProps) => {
  const showMoreInfo =
    degreePosition !== undefined ||
    value !== undefined ||
    lastUpdate ||
    environmentalVariable;
  const { i18n } = useTranslation("translation");

  const { unit, unitsUser } = useConvertValueToUserUnit({
    value,
    defaultUnit,
    environmentalVariable,
  });

  const isCurrent =
    environmentalVariable === EnvironmentalVariables.CURRENT_VEL;

  const fontSize = fontSizeMapper[zoomLevel as ZoomLevel] || fontSizeMapper[5];

  const paddingXOnBox = zoomLevel < 5 ? 0.5 : 2;

  return (
    <Box
      px={paddingXOnBox}
      py={0.5}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap="4px"
    >
      <Flex alignItems="center" gap="4px">
        <Text fontWeight="bold" fontSize={fontSize.title} color="white">
          {title}
        </Text>

        {lastUpdate && (
          <>
            <Text fontSize={fontSize.text} color="white">
              •
            </Text>
            <Text fontSize={fontSize.unit} color="white">
              {getTimeDifference(lastUpdate)}
            </Text>
          </>
        )}
      </Flex>
      {showMoreInfo && (
        <Box display="flex" flexDirection="column" width="full">
          {!!environmentalVariable && (
            <Text fontWeight="semibold" fontSize={fontSize.text} color="white">
              {i18n.t(`environmentalVariableEnum.${environmentalVariable}`)}
            </Text>
          )}
          {(value !== undefined || degreePosition !== undefined) && (
            <Box display="flex" alignItems="center" gap="8px">
              {value !== undefined && unit !== null && (
                <Text fontSize={fontSize.unit} color="white">
                  {unit.toFixed(2)} {unitsUser}
                </Text>
              )}
              {value !== undefined && degreePosition && (
                <Text fontSize={fontSize.unit} color="white">
                  |
                </Text>
              )}
              {degreePosition !== undefined && (
                <Flex gap="4px">
                  <img
                    src={markerIcon}
                    alt="marker icon"
                    style={{
                      minWidth: 8,
                      minHeight: 8,
                      transform: isCurrent
                        ? `rotate(${reverseDirection(degreePosition ?? 0)}deg)`
                        : `rotate(${degreePosition}deg)`,
                    }}
                  />
                  {degreePosition !== undefined && (
                    <Text fontSize={fontSize.degree} color="white">
                      {transformGeo(degreePosition)}
                    </Text>
                  )}
                </Flex>
              )}
            </Box>
          )}
        </Box>
      )}
      {showFooterText && (
        <Text fontSize={12} color="yellow.500">
          {i18n.t("forecastChart.forecastChartPanelMap.sensorDataIsDelayed")}
        </Text>
      )}
    </Box>
  );
};
