import React, { useCallback, useEffect, useState } from "react";
import { message } from "antd";
import { DateTime } from "luxon";
import GanttChart from "../../components/GanttChart";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/setup/use-store";
import { StoreState } from "../../enum/StoreState";
import DatePicker from "react-datepicker";
import {
  AlertIcon,
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Select,
  Spinner,
  Stack,
  Text,
  Alert,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import pt from "date-fns/locale/pt-BR";
import en from "date-fns/locale/en-GB";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import TodayIcon from "@material-ui/icons/Today";
import SettingsIcon from "@material-ui/icons/Settings";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import axios from "axios";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import noDashboards from "../../assets/noDashboards.svg";
import dashboardHasNoLocation from "../../assets/dashboardHasNoLocation.svg";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import EmptyState from "../../components/EmptyState/EmptyState";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import useWindowObserver from "../../hooks/useWindowObserver";
import { SCREEN_HORIZONTAL_SPACING } from "../../constants";

const OceanWeatherIntelligence: React.FC = () => {
  const {
    dataStores: {
      operationRuleStore,
      oceanWeatherIntelligenceStore,
      operationRuleLocationInsightStore,
      operationRuleLocationStore,
      authStore,
    },
  } = useStores();
  let history = useHistory();
  const [clickedDate, setClickedDate] = useState(false);
  const [startDate, setStartDate] = useState(
    oceanWeatherIntelligenceStore.processDateCurrent(DateTime.local())
  );

  const windowSize = useWindowObserver(".main-area");

  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "oceanWeatherIntelligence.oceanWeatherIntelligence",
  });

  let today = DateTime.local()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .setLocale("pt-BR")
      .setZone("America/Bahia")
      .toMillis(),
    day = 1000 * 60 * 60 * 24;

  useEffect(() => {
    if (operationRuleStore.operationRules.length === 0) {
      operationRuleStore.getOperationRulesApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const operationRuleId = operationRuleStore.operationRuleMainId;

    if (operationRuleId <= 0) {
      return;
    }

    if (!oceanWeatherIntelligenceStore.needUpdateGanttChart()) {
      return;
    }

    if (startDate && clickedDate) {
      oceanWeatherIntelligenceStore.setSelectForecastDateAvailable(
        DateTime.fromISO(startDate.toISOString())
      );
    }

    oceanWeatherIntelligenceStore.getGanttChartDataApi({
      operationRuleId: operationRuleId,
      cancelToken: source.token,
      create_at: startDate ? startDate.toISOString() : undefined,
    });

    oceanWeatherIntelligenceStore.setChangeForUpdate({
      update: false,
      expiration: DateTime.local().plus({ minutes: 10 }).toMillis(),
    });

    return () => {
      source.cancel("Request cancelled");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    operationRuleStore.operationRuleMainId,
    oceanWeatherIntelligenceStore.actionCallGanttChart,
  ]);

  const handleSelectDashboardChange = useCallback(
    (dashboardId: number) => {
      oceanWeatherIntelligenceStore.setChangeForUpdate({
        update: true,
        expiration: 0,
      });
      operationRuleLocationInsightStore.setOperationRuleLocationItens([]);
      operationRuleLocationStore.setOperationRuleLocations([]);
      operationRuleStore.setOperationRuleMain(dashboardId);
      operationRuleLocationStore.setHasLocations(true);
      operationRuleLocationInsightStore.setHasInsights(true);
    },
    [
      oceanWeatherIntelligenceStore,
      operationRuleLocationInsightStore,
      operationRuleLocationStore,
      operationRuleStore,
    ]
  );

  const handleGoBackCurrentInsight = useCallback(() => {
    oceanWeatherIntelligenceStore.clearSelectForecastDateAvailable();
    oceanWeatherIntelligenceStore.setActionCallGanttChart();
    setClickedDate(false);
    setStartDate(
      oceanWeatherIntelligenceStore.processDateCurrent(DateTime.local())
    );
    oceanWeatherIntelligenceStore.setChangeForUpdate({
      update: true,
      expiration: 0,
    });
  }, [oceanWeatherIntelligenceStore]);

  //@ts-ignore
  const CustomDatePickerButton = React.forwardRef(({ value, onClick }, ref) => (
    <Button
      //@ts-ignore
      ref={ref}
      className="CustomDatePickerButton"
      onClick={onClick}
      size="small"
      p={1}
      pr={2}
      layerStyle="lightOcean"
      _hover={{ layerStyle: "lightOcean" }}
      leftIcon={<TodayIcon style={{ marginLeft: "6px" }} />}
    >
      {clickedDate ? value : t("clickGoBackPastInsights")}
    </Button>
  ));

  const handleSelectForecastDateAvailable = useCallback(
    (date: Date) => {
      setStartDate(date);
      setClickedDate(true);
      const selectedDashboard = operationRuleStore.operationRuleMainId;

      if (!selectedDashboard || selectedDashboard <= 0) {
        message.info(t("needSelectDashboardPastInsight"));
      } else {
        oceanWeatherIntelligenceStore.setActionCallGanttChart();
        oceanWeatherIntelligenceStore.setChangeForUpdate({
          update: true,
          expiration: 0,
        });
      }
    },
    [oceanWeatherIntelligenceStore, operationRuleStore.operationRuleMainId, t]
  );

  const handleChangeTab = useCallback(
    (id: number) => {
      oceanWeatherIntelligenceStore.setActiveTab(id);
      history.push("/insight-manager");
    },
    [history, oceanWeatherIntelligenceStore]
  );

  const isInsightEmpty =
    oceanWeatherIntelligenceStore.ganttChartData?.length > 0 &&
    oceanWeatherIntelligenceStore.ganttChartData[0].data?.length > 0 &&
    oceanWeatherIntelligenceStore.ganttChartData[0].end === 0 &&
    oceanWeatherIntelligenceStore.state === StoreState.DONE;

  return (
    <Box mt={8} mb={8} mx={SCREEN_HORIZONTAL_SPACING}>
      <Stack direction="row" spacing={4}>
        <PageTitle text={t("operationInsights")} />
        <Button
          leftIcon={<DashboardIcon />}
          layerStyle="lightOcean"
          _hover={{ layerStyle: "lightOcean" }}
          size="md"
          mt={"3px"}
          onClick={() => handleChangeTab(0)}
        >
          {t("dashboards")}
        </Button>
        {operationRuleStore.operationRules?.length > 0 && (
          <>
            <Button
              leftIcon={<LocationOnIcon />}
              layerStyle="lightOcean"
              _hover={{ layerStyle: "lightOcean" }}
              size="md"
              mt={"3px"}
              onClick={() => handleChangeTab(1)}
            >
              {t("locations")}
            </Button>
            <Button
              leftIcon={<SettingsIcon />}
              layerStyle="lightOcean"
              _hover={{ layerStyle: "lightOcean" }}
              size="md"
              mt={"3px"}
              onClick={() => handleChangeTab(2)}
            >
              {t("insights")}
            </Button>
          </>
        )}
      </Stack>

      {operationRuleStore.state === StoreState.ERROR && (
        <Alert status="error" mb={4} color="black">
          <AlertIcon />
          {oceanWeatherIntelligenceStore.messageError}
        </Alert>
      )}

      {!operationRuleStore.operationRules?.length &&
        !operationRuleStore.hasDashboards && (
          <EmptyState
            description={t("weStillDontHaveAnyDashboard")}
            image={noDashboards}
            imageSize="25vw"
            buttonAction={() => handleChangeTab(0)}
            buttonIcon={<PlayArrowIcon />}
            buttonText={
              !authStore.readOnlyUser ? (t("letsGo") as string) : undefined
            }
            marginTop="10vh"
          />
        )}

      {operationRuleStore.operationRules?.length > 0 && (
        <>
          <Text color="blue.200" p="1">
            {t("selectDashboardInsight")}
          </Text>
          <Grid templateColumns="repeat(4, 1fr)" gap={2}>
            <GridItem colStart={1} colEnd={2}>
              <Select
                borderRadius={8}
                color={"blue.100"}
                backgroundColor="#ffffff"
                size="md"
                key={`selectDashboard-${operationRuleStore.operationRuleMainId}`}
                onChange={(e) =>
                  handleSelectDashboardChange(parseInt(e.target.value))
                }
                value={
                  operationRuleStore.operationRuleMainId > 0
                    ? operationRuleStore.operationRuleMainId
                    : undefined
                }
                _hover={{ borderColor: "blue.100" }}
                _focus={{ borderColor: "none" }}
              >
                {operationRuleStore.operationRules.map((value: any) => (
                  <option
                    style={{ color: "black" }}
                    value={value.id}
                    key={value.id}
                  >
                    {value.name}
                  </option>
                ))}
              </Select>
            </GridItem>
            {clickedDate && (
              <GridItem colStart={5} colEnd={6} p={2}>
                <Button
                  size="small"
                  p={1}
                  pr={2}
                  layerStyle="lightOcean"
                  _hover={{ layerStyle: "lightOcean" }}
                  leftIcon={<ArrowBackIcon />}
                  onClick={handleGoBackCurrentInsight}
                >
                  {t("currentInsight")}
                </Button>
              </GridItem>
            )}

            <GridItem colStart={6} p={2}>
              <DatePicker
                selected={startDate}
                onChange={handleSelectForecastDateAvailable}
                maxDate={DateTime.local().toJSDate()}
                timeIntervals={15}
                popperPlacement={"left-start"}
                customInput={<CustomDatePickerButton />}
                dateFormat="MMMM d, yyyy H:mm"
                timeInputLabel={t("time") as string}
                showTimeInput
                locale={i18n.language === "en" ? en : pt}
              />
            </GridItem>
          </Grid>

          {isInsightEmpty && !authStore.readOnlyUser && (
            <Alert color="gray.600" status="warning">
              <AlertIcon />
              <Text mr={1}>{t("almostThereCreateInsightVisualizeChart")}</Text>
              <Text
                cursor="pointer"
                color="#dd6b20"
                _hover={{ color: "rgba(221,107,32,0.7)" }}
                onClick={() => handleChangeTab(2)}
              >
                {t("clickHere")}
              </Text>
            </Alert>
          )}

          {oceanWeatherIntelligenceStore.state === StoreState.PEDDING && (
            <Center h={"50vh"}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Center>
          )}

          {oceanWeatherIntelligenceStore.ganttChartData?.length > 0 &&
            !oceanWeatherIntelligenceStore.ganttChartData[0].data.length &&
            oceanWeatherIntelligenceStore.state === StoreState.DONE && (
              <EmptyState
                key={`dash-${operationRuleStore.operationRuleMainId}`}
                description={t("addLocationInsightToYourDashboard")}
                image={dashboardHasNoLocation}
                imageSize="20vw"
                buttonAction={() => handleChangeTab(1)}
                buttonIcon={<AddLocationIcon />}
                buttonText={
                  !authStore.readOnlyUser
                    ? (t("addLocation") as string)
                    : undefined
                }
                marginTop="10vh"
              />
            )}

          {oceanWeatherIntelligenceStore.ganttChartData?.length > 0 &&
            oceanWeatherIntelligenceStore.ganttChartData[0].data.length &&
            oceanWeatherIntelligenceStore.state === StoreState.DONE &&
            operationRuleStore.operationRules?.length > 0 && (
              <GanttChart
                style={{ maxWidth: windowSize.width }}
                timezoneOffset={
                  oceanWeatherIntelligenceStore.ganttChartData[0].timezoneOffset
                }
                options={{
                  chart: {
                    backgroundColor: "rgb(240, 241, 242)",
                  },
                  time: {
                    useUTC: false,
                  },
                  title: {
                    text: "",
                  },
                  xAxis: {
                    min:
                      oceanWeatherIntelligenceStore.ganttChartData[0].start ||
                      today,
                    max:
                      oceanWeatherIntelligenceStore.ganttChartData[0].end ||
                      today + 5 * day,
                    currentDateIndicator: true,
                    tickInterval: 3600 * 1000 * 6, //every 6h
                  },
                  tooltip: {
                    useHTML: true,
                    backgroundColor: "#ffff",
                    borderColor: "#1890ff",
                    borderRadius: 8,
                    borderWidth: 1,
                    formatter: function (tooltip: any) {
                      if (this.point.isNull) {
                        return "Null";
                      }
                      return oceanWeatherIntelligenceStore.ganttChartFormatTooltip(
                        this
                      );
                    },
                  },
                  yAxis: {
                    uniqueNames: true,
                  },
                  series: oceanWeatherIntelligenceStore.ganttChartData,
                }}
              />
            )}
        </>
      )}
    </Box>
  );
};

export default observer(OceanWeatherIntelligence);
