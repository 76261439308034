import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Switch,
  Text,
  FormControl,
  FormLabel,
  HStack,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import Loading from "../../../../components/Loading/Loading";
import { IStation } from "../../../../stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";

type EditStationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  station: IStation | undefined;
  handleSaveEditStation: (station: IStation) => void;
  isLoading: boolean;
};

interface FormValues {
  dataHistory?: boolean;
  weather: boolean;
  wave: boolean;
  water_flow: boolean;
  wave_spectra: boolean;
}

export const EditStationModal: React.FC<EditStationModalProps> = ({
  isOpen,
  onClose,
  station,
  handleSaveEditStation,
  isLoading,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "manageStation",
  });

  const initialValues: FormValues = {
    dataHistory: station?.data_history,
    weather: !!station?.environmentalTypes?.find((x) => x === "weather"),
    wave: !!station?.environmentalTypes?.find((x) => x === "wave"),
    water_flow: !!station?.environmentalTypes?.find((x) => x === "water_flow"),
    wave_spectra: !!station?.environmentalTypes?.find(
      (x) => x === "wave_spectra"
    ),
  };

  const handleConfirm = (values: FormValues) => {
    if (values && station) {
      let stationData = station;
      stationData.data_history = values.dataHistory;
      stationData.environmentalTypes = [];

      if (
        values.weather &&
        !station?.environmentalTypes?.find((x) => x === "weather")
      ) {
        stationData.environmentalTypes?.push("weather");
      }

      if (
        values.wave &&
        !station?.environmentalTypes?.find((x) => x === "wave")
      ) {
        stationData.environmentalTypes?.push("wave");
      }

      if (
        values.water_flow &&
        !station?.environmentalTypes?.find((x) => x === "water_flow")
      ) {
        stationData.environmentalTypes?.push("water_flow");
      }

      if (
        values.wave_spectra &&
        !station?.environmentalTypes?.find((x) => x === "wave_spectra")
      ) {
        stationData.environmentalTypes?.push("wave_spectra");
      }

      handleSaveEditStation(stationData);
    }
  };

  const validateForm = (values: FormValues) => {
    let errors = {} as FormValues;
    const { dataHistory, weather, wave, wave_spectra, water_flow } = values;

    if (dataHistory) {
      if (!weather && !wave && !wave_spectra && !water_flow) {
        errors.weather = true;
      }
    }

    return errors;
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal size={"2xl"} isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent>
        <ModalHeader color="newBlue.500">{station?.station_name}</ModalHeader>
        <ModalCloseButton color={"black"} />
        <Formik<FormValues>
          initialValues={initialValues}
          validate={validateForm}
          onSubmit={(values) => {
            handleConfirm(values);
          }}
        >
          {({ values, getFieldProps, submitForm, errors }) => (
            <>
              <ModalBody color="gray.500">
                {isLoading && <Loading styleSpinner={{ marginLeft: 0 }} />}
                <FormControl color="newBlue.500">
                  <FormLabel>
                    <Text>{t("dataHistory")}</Text>
                  </FormLabel>
                  <Switch
                    {...getFieldProps("dataHistory")}
                    isChecked={values.dataHistory}
                  />
                </FormControl>

                <FormLabel color="newBlue.500" mt={4}>
                  <Text>{t("environmentalType")}</Text>
                </FormLabel>
                <HStack mt={2} spacing={2}>
                  <FormControl>
                    <FormLabel>
                      <Text>{t("weather")}</Text>
                    </FormLabel>
                    <Switch
                      colorScheme="green"
                      {...getFieldProps("weather")}
                      isChecked={values.weather}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>
                      <Text>{t("wave")}</Text>
                    </FormLabel>
                    <Switch
                      colorScheme="purple"
                      {...getFieldProps("wave")}
                      isChecked={values.wave}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>
                      <Text>{t("waveSpectra")}</Text>
                    </FormLabel>
                    <Switch
                      colorScheme="cyan"
                      {...getFieldProps("wave_spectra")}
                      isChecked={values.wave_spectra}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>
                      <Text>{t("waterFlow")}</Text>
                    </FormLabel>
                    <Switch
                      colorScheme="messenger"
                      {...getFieldProps("water_flow")}
                      isChecked={values.water_flow}
                    />
                  </FormControl>
                </HStack>
                <FormControl isInvalid={!!errors.weather}>
                  <FormErrorMessage mt={4}>
                    {t("selectAtLeastOneEnvironmentalType")}
                  </FormErrorMessage>
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button
                  layerStyle="ocean"
                  _hover={{ layerStyle: "ocean" }}
                  onClick={submitForm}
                  type="submit"
                  ml={2}
                >
                  {t("save")}
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
