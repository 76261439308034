import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import {
  ContainerLogin,
  FormLogin,
  InputLogin,
  ButtonLogin,
  TitleText,
  InputLabelLogin,
  ContainerLoginTitle,
  InputLoginLabelCheckbox,
  InputLoginCheckbox,
  DivErrorLogin,
  DivErrorLoginText,
  WrapperLoginForm,
  LinkArea,
  ShowHideButton,
} from "../styles";
import { useTranslation } from "react-i18next";
import { Alert, AlertIcon, Box } from "@chakra-ui/react";
import eye from "assets/eye.svg";
import eyeOff from "assets/eye-off.svg";
import { useStores } from "stores/setup/use-store";
import { observer } from "mobx-react-lite";

export interface LoginFormProps {
  username: string;
  password: string;
  title: string;
  rememberMe: boolean;
  error: boolean;
  errorMessage?: string;
  onSubmit: (e: any) => void;
  onChange: (e: any) => void;
  onClickForgotPassword: (e: any) => void;
  onClickForgotUsername: (e: any) => void;
  isLoading: boolean;
}

export function LoginForm({
  title,
  username,
  password,
  rememberMe,
  error,
  errorMessage,
  onChange,
  onSubmit,
  onClickForgotUsername,
  onClickForgotPassword,
  isLoading,
}: LoginFormProps) {
  const [showPassword, setShowPassword] = useState(false);
  const {
    uiStores: { navigatorAndWindowStore },
  } = useStores();
  const { t } = useTranslation("translation", { keyPrefix: "login.login" });

  const isDisabled = error
    ? true
    : username.length === 0 || password.length === 0;

  const icon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#ffffff" }} spin />
  );

  const DefaultError = () => (
    <>
      {t("userPasswordIncorrect")}
      <a
        style={{
          color: "rgba(0, 0, 0, 0.64)",
          textDecoration: "underline",
        }}
        href="#"
        onClick={onClickForgotPassword}
      >
        {t("password")}
      </a>
      .
    </>
  );

  const showHideButtonAriaLabel = showPassword
    ? t("hidePassword")
    : t("showPassword");

  return (
    <>
      <WrapperLoginForm>
        <ContainerLoginTitle>
          <TitleText>{title}</TitleText>
        </ContainerLoginTitle>
        <ContainerLogin>
          <FormLogin onSubmit={onSubmit}>
            <InputLabelLogin>
              {t("emailUsername")}
              <InputLogin
                placeholder={t("emailUsername") as string}
                name="username"
                value={username}
                onChange={onChange}
              />
            </InputLabelLogin>
            <InputLabelLogin>
              {t("password")}
              <Box position="relative" margin={0}>
                <InputLogin
                  placeholder={t("password") as string}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={onChange}
                />
                <ShowHideButton
                  type="button"
                  onClick={() => setShowPassword((prev) => !prev)}
                  aria-label={showHideButtonAriaLabel}
                >
                  {showPassword ? (
                    <img src={eyeOff} alt={t("eyesClosed") as string} />
                  ) : (
                    <img src={eye} alt={t("eyesOpen") as string} />
                  )}
                </ShowHideButton>
              </Box>
            </InputLabelLogin>
            <InputLoginLabelCheckbox>
              <InputLoginCheckbox
                type="checkbox"
                name="rememberMe"
                checked={rememberMe}
                onChange={onChange}
              />
              {t("keepLogged")}
            </InputLoginLabelCheckbox>
            <ButtonLogin disabled={isDisabled}>
              {!isLoading ? (
                t("logIn")
              ) : (
                <Spin
                  indicator={icon}
                  spinning={true}
                  style={{ color: "#ffffff" }}
                ></Spin>
              )}
            </ButtonLogin>
          </FormLogin>
          {!navigatorAndWindowStore.isOnline && (
            <Alert
              rounded="base"
              bg="#bee3f8"
              status="info"
              mb={4}
              color="gray.700"
            >
              <AlertIcon />
              {t("itAppearsYouAreDisconnected")}
            </Alert>
          )}
          {error && navigatorAndWindowStore.isOnline && (
            <DivErrorLogin>
              <DivErrorLoginText>
                {errorMessage ? errorMessage : <DefaultError />}
              </DivErrorLoginText>
            </DivErrorLogin>
          )}
        </ContainerLogin>
        <LinkArea>
          {false && (
            <a
              style={{ color: "rgba(0, 0, 0, 0.64)" }}
              href="#"
              onClick={onClickForgotUsername}
            >
              {t("forgotUsername")}
            </a>
          )}
          <a
            style={{ color: "rgba(0, 0, 0, 0.64)" }}
            href="#"
            onClick={onClickForgotPassword}
          >
            {t("forgotPassword")}
          </a>
        </LinkArea>
      </WrapperLoginForm>
    </>
  );
}

export default observer(LoginForm);
