import React from "react";
import { Box, Button, Center, Text } from "@chakra-ui/react";

interface EmptyStateProps {
  description: string;
  image?: string;
  imageSize?: string;
  buttonText?: string;
  buttonAction?: () => void;
  buttonIcon?: any;
  marginTop?: string;
}

const EmptyState: React.FC<EmptyStateProps> = ({
  description,
  image,
  imageSize,
  buttonText,
  buttonAction,
  buttonIcon,
  marginTop,
}) => {
  return (
    <Center mt={marginTop}>
      <Box>
        <Center>
          <img
            src={image}
            alt=""
            style={{
              width: imageSize,
            }}
          />
        </Center>
        <Center>
          <Text textColor={"blue.200"} p={4}>
            {description}
          </Text>
        </Center>
        <Center>
          {buttonText && (
            <Button
              p={4}
              leftIcon={buttonIcon}
              layerStyle="ocean"
              _hover={{ layerStyle: "ocean" }}
              onClick={buttonAction}
            >
              {buttonText}
            </Button>
          )}
        </Center>
      </Box>
    </Center>
  );
};

export default EmptyState;
