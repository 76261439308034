import { makeAutoObservable } from "mobx";
import { getEnv } from "mobx-easy";
import { StoreState } from "../../../enum/StoreState";
import { RootEnv } from "../../setup/create-store";
import { IOperationRule } from "./OperationRuleModel";
import i18n from "../../../i18n";
export default class OperationRuleStore {
  operationRule!: IOperationRule;

  operationRules: IOperationRule[] = [];

  operationRuleMainId: number = 0;

  state: string = StoreState.DONE;

  messageError: string = "";

  hasDashboards: boolean = true;

  filteredDashboards: IOperationRule[] = [];

  searchValue: string = "";

  constructor() {
    this.reset();
    makeAutoObservable(this);
  }

  setOperationRule(value: IOperationRule) {
    this.operationRule = value;
  }

  setOperationRuleMain(value: number) {
    this.operationRuleMainId = value;
  }

  setOperationRules(values: IOperationRule[]) {
    this.operationRules = values;
    if (values.length > 0) {
      this.setOperationRuleMain(values[0].id);
    }
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setMessageError(value: string) {
    this.messageError = value;
  }

  setHasDashboards(value: boolean) {
    this.hasDashboards = value;
  }

  setFilteredDashboards(values: IOperationRule[]) {
    this.filteredDashboards = values;
  }

  setSearchValue(value: string) {
    this.searchValue = value;
  }

  getOperationRule(id: number) {
    return this.operationRules.find(
      (operationRule) => operationRule.id === id
    ) as IOperationRule;
  }

  reset() {
    this.setState(StoreState.DONE);
    this.setOperationRuleMain(0);
    this.setOperationRules([]);
  }

  async getOperationRulesApi() {
    const { operationRuleService } = getEnv<RootEnv>();
    try {
      this.setOperationRuleMain(0);
      this.setOperationRules([]);
      this.setState(StoreState.PEDDING);
      const operationRules: IOperationRule[] =
        await operationRuleService.getOperationRule();
      if (operationRules.length > 0) {
        this.setOperationRuleMain(operationRules[0].id);
        this.setOperationRules(operationRules);
        this.setFilteredDashboards(this.getFilteredDashboard());

        this.setState(StoreState.DONE);
        this.setHasDashboards(true);
        return operationRules;
      } else {
        this.setHasDashboards(false);
      }
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t("oceanWeatherIntelligence.operationRule.errorToGetData")
      );
    }
  }

  async getOperationRuleById(id: number) {
    const { operationRuleService } = getEnv<RootEnv>();
    try {
      const operationRule = await operationRuleService.getOperationRule(id);
      if (operationRule) {
        this.setOperationRuleMain(id);
        this.setOperationRule(operationRule);
        this.setState(StoreState.DONE);
      } else {
        this.setState(StoreState.ERROR);
        this.setMessageError(
          i18n.t("oceanWeatherIntelligence.operationRule.errorToGetData")
        );
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t("oceanWeatherIntelligence.operationRule.errorToGetData")
      );
    }
  }

  async addOperationRule(name: string, initialsName: string) {
    const { operationRuleService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);
    try {
      const operationRuleInserted =
        await operationRuleService.createOperationRule(name, initialsName);
      if (operationRuleInserted) {
        this.setOperationRules(
          [operationRuleInserted].concat(this.operationRules)
        );
        this.setState(StoreState.DONE);
        this.setOperationRuleMain(operationRuleInserted.id);

        this.setFilteredDashboards(this.getFilteredDashboard());
      } else {
        this.setMessageError(
          i18n.t("oceanWeatherIntelligence.operationRule.errorToAddData")
        );

        this.setState(StoreState.ERROR);
      }
    } catch (error) {
      this.setMessageError(
        i18n.t("oceanWeatherIntelligence.operationRule.errorToAddData")
      );
      this.setState(StoreState.ERROR);
    }
  }

  async updateOperationRuleDashboard(
    id: number,
    name: string,
    initialsName: string
  ) {
    if (!id) {
      throw new Error(
        i18n.t(
          "oceanWeatherIntelligence.operationRule.needToInformIdForUpdateData"
        ) as string
      );
    }
    const { operationRuleService } = getEnv<RootEnv>();
    try {
      this.setState(StoreState.PEDDING);
      await operationRuleService.updateOperationRule(id, name, initialsName);
      this.setState(StoreState.DONE);
      const operationRuleIndex = this.operationRules.indexOf(
        this.getOperationRule(id)
      );

      if (operationRuleIndex !== -1) {
        const value = this.operationRules[operationRuleIndex];
        this.operationRules[operationRuleIndex] = {
          id: value.id,
          name: name,
          initialName: initialsName,
          companyId: value.companyId,
        };
      } else {
        const operationRule = await operationRuleService.getOperationRule(id);
        this.operationRules.push(operationRule);
      }
      this.setFilteredDashboards(this.getFilteredDashboard());
    } catch (error: any) {
      this.setState(StoreState.ERROR);
      if (error.response?.status === 403) {
        this.setMessageError(i18n.t("youCanOnlyChangeItems"));
      } else {
        this.setMessageError(
          i18n.t("oceanWeatherIntelligence.operationRule.errorToUpdateData")
        );
      }
    }
  }

  async deleteOperationRuleDashboard(id: number): Promise<Boolean> {
    if (!id) {
      throw new Error(
        i18n.t(
          "oceanWeatherIntelligence.operationRule.needInformIdForDeleteData"
        ) as string
      );
    }
    const operationRuleToRemove = this.getOperationRule(id);

    if (operationRuleToRemove) {
      const { operationRuleService } = getEnv<RootEnv>();
      try {
        this.setState(StoreState.PEDDING);
        const result = await operationRuleService.deleteOperationRule(id);
        if (result) {
          this.setState(StoreState.DONE);
          const operationRuleToRemoveIndex = this.operationRules.indexOf(
            operationRuleToRemove
          );
          if (
            this.operationRules[operationRuleToRemoveIndex].id ===
            this.operationRuleMainId
          ) {
            this.operationRules.splice(operationRuleToRemoveIndex, 1);
            if (this.operationRules.length > 0) {
              this.setOperationRuleMain(this.operationRules[0].id);
            } else {
              this.setOperationRuleMain(0);
            }
          } else {
            this.operationRules.splice(operationRuleToRemoveIndex, 1);
          }
          this.setFilteredDashboards(this.getFilteredDashboard());
          return true;
        }
      } catch (error: any) {
        this.setState(StoreState.ERROR);
        if (error.response?.status === 403) {
          this.setMessageError(i18n.t("youCanOnlyChangeItems"));
        } else {
          this.setMessageError(
            i18n.t("oceanWeatherIntelligence.operationRule.errorToDeleteData")
          );
        }
      }
    } else {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t("oceanWeatherIntelligence.operationRule.errorToDeleteData")
      );
    }
    return false;
  }

  getFilteredDashboard() {
    if (!this.searchValue) {
      return this.operationRules;
    }

    return this.operationRules.filter(
      (dashboard) =>
        dashboard.name.toLowerCase().includes(this.searchValue.toLowerCase()) ||
        dashboard.initialName
          .toLowerCase()
          .includes(this.searchValue.toLowerCase())
    );
  }
}
