import { makeAutoObservable } from "mobx";
import { EnvironmentalVariables } from "../../../enum/EnvironmentalVariables";
import i18n from "../../../i18n";

export default class EnvironmentalVariableStore {
  constructor() {
    makeAutoObservable(this);
  }

  getFullNameOfEnvironmentalVariable(environmentalVariable: string): string {
    switch (environmentalVariable) {
      case EnvironmentalVariables.WIND_VEL:
        return i18n.t("environmentalVariableStore.windSpeed");
      case EnvironmentalVariables.WIND_DIR:
        return i18n.t("environmentalVariableStore.windDirection");
      case EnvironmentalVariables.GUST:
        return i18n.t("environmentalVariableStore.windGusts");
      case EnvironmentalVariables.WATER_LEVEL:
        return i18n.t("environmentalVariableStore.waterLevel");
      case EnvironmentalVariables.CURRENT_VEL:
        return i18n.t("environmentalVariableStore.currentVelocity");
      case EnvironmentalVariables.CURRENT_DIR:
        return i18n.t("environmentalVariableStore.currentDirection");
      case EnvironmentalVariables.WAVE_HEIGHT:
        return i18n.t("environmentalVariableStore.waveHeight");
      case EnvironmentalVariables.WAVE_DIR:
        return i18n.t("environmentalVariableStore.waveDirection");
      case EnvironmentalVariables.PEAK_PERIOD:
        return i18n.t("environmentalVariableStore.peakPeriod");
      case EnvironmentalVariables.MEAN_PERIOD:
        return i18n.t("environmentalVariableStore.meanPeriod");
      case EnvironmentalVariables.WAVE_HEIGHT_SEA:
        return i18n.t("environmentalVariableStore.seaWaveHeight");
      case EnvironmentalVariables.WAVE_DIR_SEA:
        return i18n.t("environmentalVariableStore.seaWaveDirection");
      case EnvironmentalVariables.PEAK_PERIOD_SEA:
        return i18n.t("environmentalVariableStore.seaPeakPeriod");
      case EnvironmentalVariables.WAVE_HEIGHT_SWELL:
        return i18n.t("environmentalVariableStore.swellWaveHeight");
      case EnvironmentalVariables.WAVE_DIR_SWELL:
        return i18n.t("environmentalVariableStore.swellWaveDirection");
      case EnvironmentalVariables.PEAK_PERIOD_SWELL:
        return i18n.t("environmentalVariableStore.swellPeakPeriod");
      case EnvironmentalVariables.PRECIP:
        return i18n.t("environmentalVariableStore.precipitation");
      case EnvironmentalVariables.WIND_VEL_80M:
        return i18n.t("environmentalVariableStore.windSpeedAt80m");
      case EnvironmentalVariables.WIND_DIR_80M:
        return i18n.t("environmentalVariableStore.windDirectionAt80m");
      case EnvironmentalVariables.GUST_80M:
        return i18n.t("environmentalVariableStore.windGustsAt80m");
      case EnvironmentalVariables.WIND_VEL_100M:
        return i18n.t("environmentalVariableStore.windSpeedAt100m");
      case EnvironmentalVariables.WIND_DIR_100M:
        return i18n.t("environmentalVariableStore.windDirectionAt100m");
      case EnvironmentalVariables.GUST_100M:
        return i18n.t("environmentalVariableStore.windGustsAt100m");
      case EnvironmentalVariables.WAVE_HEIGHT_MAX:
        return i18n.t("environmentalVariableStore.maximumWaveHeight");
      case EnvironmentalVariables.VISIBILITY:
        return i18n.t("environmentalVariableStore.visibility");
      case EnvironmentalVariables.TEMP_AIR:
        return i18n.t("environmentalVariableStore.airTemperature");
      case EnvironmentalVariables.DEW_POINT:
        return i18n.t("environmentalVariableStore.dewPoint");
      default:
        return "";
    }
  }
}
