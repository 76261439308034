import { EnvironmentalVariables } from "../../../../enum/EnvironmentalVariables";
import { VisualizationType } from "../../../../enum/VisualizationType";
import i18n from "../../../../i18n";
import { ISerieConfigMap, YAxisConfig } from "./types/ISerieConfigMap";

export const getWaveConfigMap = (
  type: VisualizationType
): Record<string, ISerieConfigMap> => {
  const forecastColors: Partial<Record<EnvironmentalVariables, string>> = {
    [EnvironmentalVariables.WAVE_HEIGHT]: "#48AFE8",
    [EnvironmentalVariables.WAVE_HEIGHT_MAX]: "#D44C4C",
    [EnvironmentalVariables.WAVE_HEIGHT_SEA]: "#2A6CED",
    [EnvironmentalVariables.WAVE_HEIGHT_SWELL]: "#452AED",
    [EnvironmentalVariables.PEAK_PERIOD]: "#6F7CD0",
    [EnvironmentalVariables.PEAK_PERIOD_SEA]: "#2E6DB6",
    [EnvironmentalVariables.PEAK_PERIOD_SWELL]: "rgba(0,128,0, 0.45)",
    [EnvironmentalVariables.MEAN_PERIOD]: "#E8A848",
    [EnvironmentalVariables.MEAN_PERIOD_SEA]: "#ED872A",
    [EnvironmentalVariables.MEAN_PERIOD_SWELL]: "#ED582A",
    [EnvironmentalVariables.WAVE_POWER]: "#7B34D5",
    [EnvironmentalVariables.WAVE_DIR]: "#48AFE8",
    [EnvironmentalVariables.WAVE_DIR_SEA]: "#2A6CED",
    [EnvironmentalVariables.WAVE_DIR_SWELL]: "#452AED",
  };

  const measurementColors: Partial<Record<EnvironmentalVariables, string>> = {
    [EnvironmentalVariables.WAVE_HEIGHT]: "#54D3C0",
    [EnvironmentalVariables.WAVE_HEIGHT_MAX]: "#D44C4C",
    [EnvironmentalVariables.WAVE_HEIGHT_SEA]: "#2A6CED",
    [EnvironmentalVariables.WAVE_HEIGHT_SWELL]: "#452AED",
    [EnvironmentalVariables.PEAK_PERIOD]: "#367993",
    [EnvironmentalVariables.PEAK_PERIOD_SEA]: "#2E6DB6",
    [EnvironmentalVariables.PEAK_PERIOD_SWELL]: "rgba(0,128,0, 0.45)",
    [EnvironmentalVariables.MEAN_PERIOD]: "#E8A848",
    [EnvironmentalVariables.MEAN_PERIOD_SEA]: "#ED872A",
    [EnvironmentalVariables.MEAN_PERIOD_SWELL]: "#ED582A",
    [EnvironmentalVariables.WAVE_POWER]: "#7B34D5",
    [EnvironmentalVariables.WAVE_DIR]: "#54D3C0",
    [EnvironmentalVariables.WAVE_DIR_SEA]: "#2A6CED",
    [EnvironmentalVariables.WAVE_DIR_SWELL]: "#452AED",
  };

  const getColor = (
    variable: EnvironmentalVariables,
    type: VisualizationType
  ) => {
    let colorMap: Record<string, string>;
    switch (type) {
      case VisualizationType.FORECAST:
        colorMap = forecastColors;
        break;
      case VisualizationType.SENSOR:
        colorMap = measurementColors;
        break;
      default:
        colorMap = {};
        break;
    }
    return colorMap[variable.toLowerCase()] || "#347ED5";
  };

  return {
    [EnvironmentalVariables.WAVE_HEIGHT]: {
      serieConfig: {
        color: getColor(EnvironmentalVariables.WAVE_HEIGHT, type),
        keys: ["x", "y", "direction"],
        zIndex: 1,
        updateYAxis: true,
        yAxis: 0,
        updateBiggestMax: true,
        tooltip: {
          formatDirection: true,
          reverseDirection: false,
        },
      },
      yAxisConfig: yAxisWaveHeightConfig,
    },
    [EnvironmentalVariables.WAVE_HEIGHT_MAX]: {
      serieConfig: {
        color: getColor(EnvironmentalVariables.WAVE_HEIGHT_MAX, type),
        zIndex: 1,
        updateYAxis: true,
        yAxis: 0,
        updateBiggestMax: true,
      },
      yAxisConfig: yAxisWaveHeightConfig,
    },
    [EnvironmentalVariables.WAVE_HEIGHT_SEA]: {
      serieConfig: {
        color: getColor(EnvironmentalVariables.WAVE_HEIGHT_SEA, type),
        keys: ["x", "y", "direction"],
        zIndex: 1,
        updateYAxis: true,
        yAxis: 0,
        updateBiggestMax: true,
        tooltip: {
          formatDirection: true,
          reverseDirection: false,
        },
      },
      yAxisConfig: yAxisWaveHeightConfig,
    },
    [EnvironmentalVariables.WAVE_HEIGHT_SWELL]: {
      serieConfig: {
        color: getColor(EnvironmentalVariables.WAVE_HEIGHT_SWELL, type),
        keys: ["x", "y", "direction"],
        zIndex: 1,
        updateYAxis: true,
        yAxis: 0,
        updateBiggestMax: true,
        tooltip: {
          formatDirection: true,
          reverseDirection: false,
        },
      },
      yAxisConfig: yAxisWaveHeightConfig,
    },
    [EnvironmentalVariables.PEAK_PERIOD]: {
      serieConfig: {
        type: "column",
        color: getColor(EnvironmentalVariables.PEAK_PERIOD, type),
        yAxis: 1,
        updateYAxis: true,
        updateBiggestMax: true,
      },
      yAxisConfig: yAxisPeakPeriodConfig,
    },
    [EnvironmentalVariables.PEAK_PERIOD_SEA]: {
      serieConfig: {
        type: "column",
        color: getColor(EnvironmentalVariables.PEAK_PERIOD_SEA, type),
        yAxis: 1,
        updateYAxis: true,
        updateBiggestMax: true,
      },
      yAxisConfig: yAxisPeakPeriodConfig,
    },
    [EnvironmentalVariables.PEAK_PERIOD_SWELL]: {
      serieConfig: {
        type: "column",
        color: getColor(EnvironmentalVariables.PEAK_PERIOD_SWELL, type),
        yAxis: 1,
        updateYAxis: true,
        updateBiggestMax: true,
      },
      yAxisConfig: yAxisPeakPeriodConfig,
    },
    [EnvironmentalVariables.MEAN_PERIOD]: {
      serieConfig: {
        type: "column",
        color: getColor(EnvironmentalVariables.MEAN_PERIOD, type),
        yAxis: 1,
      },
    },
    [EnvironmentalVariables.MEAN_PERIOD_SEA]: {
      serieConfig: {
        type: "column",
        color: getColor(EnvironmentalVariables.MEAN_PERIOD_SEA, type),
        yAxis: 1,
      },
    },
    [EnvironmentalVariables.MEAN_PERIOD_SWELL]: {
      serieConfig: {
        type: "column",
        color: getColor(EnvironmentalVariables.MEAN_PERIOD_SWELL, type),
        yAxis: 1,
      },
    },
    [EnvironmentalVariables.WAVE_POWER]: {
      serieConfig: {
        color: getColor(EnvironmentalVariables.WAVE_POWER, type),
        zIndex: 1,
        updateYAxis: true,
        yAxis: 2,
      },
      yAxisConfig: {
        title: {
          text: i18n.t("environmentalVariableEnum.wave_power"),
          style: {
            color: "#000000",
          },
        },
        min: 0,
        max: 5,
        labels: { format: "{value}", style: { color: "#000000" } },
        gridLineWidth: 1,
        opposite: true,
      },
    },
    [EnvironmentalVariables.WAVE_DIR]: {
      serieConfig: {
        type: "windbarb",
        enableMouseTracking: true,
        color: getColor(EnvironmentalVariables.WAVE_DIR, type),
        tooltip: {
          formatDirection: false,
        },
      },
    },
    [EnvironmentalVariables.WAVE_DIR_SEA]: {
      serieConfig: {
        type: "windbarb",
        enableMouseTracking: true,
        color: getColor(EnvironmentalVariables.WAVE_DIR_SEA, type),
        tooltip: {
          formatDirection: false,
        },
      },
    },
    [EnvironmentalVariables.WAVE_DIR_SWELL]: {
      serieConfig: {
        type: "windbarb",
        enableMouseTracking: true,
        color: getColor(EnvironmentalVariables.WAVE_DIR_SWELL, type),
        tooltip: {
          formatDirection: false,
        },
      },
    },
  };
};

const yAxisPeakPeriodConfig: YAxisConfig = {
  title: {
    text: i18n.t("forecastChart.forecastChartPanelMeteogram.peakPeriod"),
    style: {
      color: "#000000",
    },
  },
  min: 0,
  max: 25,
  labels: { format: "{value}", style: { color: "#000000" } },
  gridLineWidth: 1,
  opposite: true,
};

const yAxisWaveHeightConfig: YAxisConfig = {
  title: {
    text: i18n.t("forecastChart.forecastChartPanelMeteogram.waveHeights"),
    style: {
      color: "#000000",
    },
  },
  min: 0,
  max: 1,
  labels: { format: "{value}", style: { color: "#000000" } },
  gridLineWidth: 1,
};
