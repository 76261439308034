import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsWindbarb from "highcharts/modules/windbarb";
import highchartsMore from "highcharts/highcharts-more";

highchartsMore(Highcharts);
highchartsWindbarb(Highcharts);

interface MeteogramChartProps {
  timezoneOffset: number;
  options: HighchartsReact.Props;
  style?: React.CSSProperties;
}

const MeteogramChart: React.FC<MeteogramChartProps> = (props) => {
  Highcharts.setOptions({
    time: {
      timezoneOffset: props.timezoneOffset,
    },
  });
  return (
    <div style={props.style}>
      <HighchartsReact
        highcharts={Highcharts}
        allowChartUpdate={true}
        immutable={true}
        options={props.options}
      />
    </div>
  );
};

export default MeteogramChart;
