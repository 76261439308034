import { observer } from "mobx-react-lite";
import React from "react";
import { useStores } from "../../../../stores/setup/use-store";
import MeteogramChart from "../../../../components/MeteogramChart/MeteogramChart";
import { meteogramFormatTooltip } from "util/meteogramCharts/meteogramFormatTooltip/meteogramFormatTooltip";

import useWindowObserver from "../../../../hooks/useWindowObserver";

const RainChartMock: React.FC = () => {
  const windowSize = useWindowObserver(".main-area");

  const {
    dataStores: { forecastChartMeteogramStore },
  } = useStores();

  return (
    <MeteogramChart
      style={{ maxWidth: windowSize.width }}
      timezoneOffset={180}
      options={{
        plotOptions: {
          series: {
            label: {
              enabled: false,
            },
            marker: {
              radius: 3,
            },
          },
        },
        chart: {
          zoomType: "xy",
          height: "290px",
          backgroundColor: "#ffffff",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: [
          {
            title: {
              text: "Precipitation",
            },
            labels: {
              format: "{value} mm",
            },
          },
          {
            labels: {
              format: "{value}%",
            },
            title: {
              text: "Probability",
            },
            opposite: true,
          },
        ],
        tooltip: {
          shared: true,
          useHTML: true,
          formatter: function () {
            const { x, y, points } = this;
            return meteogramFormatTooltip(
              x,
              y,
              points,
              forecastChartMeteogramStore.renderInsightTitleOnMeteogram(25)
            );
          },
        },
        legend: {
          itemStyle: {
            fontSize: "1em",
            fontFamily: "'Hind Vadodara', 'Helvetica Neue', sans-serif",
            fontWeight: "normal",
            color: "#00000",
          },
        },
        series: [
          {
            name: "Probabilidade de chuva",
            type: "spline",
            data: [
              [1724392800000, 12, null],
              [1724396400000, 60, null],
              [1724400000000, 8, null],
              [1724403600000, 0, null],
              [1724407200000, 0, null],
              [1724410800000, 0, null],
              [1724414400000, 56, null],
              [1724418000000, 0, null],
              [1724421600000, 0, null],
              [1724425200000, 20, null],
              [1724428800000, 80, null],
              [1724432400000, 60, null],
              [1724436000000, 0, null],
              [1724439600000, 0, null],
              [1724443200000, 0, null],
              [1724446800000, 0, null],
              [1724450400000, 0, null],
              [1724454000000, 0, null],
              [1724457600000, 0, null],
              [1724461200000, 0, null],
              [1724464800000, 0, null],
              [1724468400000, 0, null],
              [1724472000000, 0, null],
              [1724475600000, 0, null],
              [1724479200000, 0, null],
              [1724482800000, 0, null],
              [1724486400000, 0, null],
              [1724490000000, 96, null],
              [1724493600000, 80, null],
              [1724497200000, 100, null],
              [1724500800000, 64, null],
            ],
            environmentalVariable: "precip_probability",
            visualizationType: "FORECAST",
            events: {},
            color: "rgba(30, 82, 151,0.4)",
            tooltip: { valueDecimals: 2, valueSuffix: "%" },
            yAxis: 1,
            dataLabels: { enabled: false },
            lineWidth: 0.85,
            style: { fontSize: "12px" },
            updateYAxis: true,
            updateBiggestMax: false,
            yAxisConfig: {
              title: { text: "Probabilidade", style: { color: "#000000" } },
              min: 0,
              max: 100,
              labels: { format: "{value}%", style: { color: "#000000" } },
              opposite: true,
            },
            visible: true,
            unitSymbol: "%",
            defaultMaxConverted: 100,
          },
          {
            name: "Chuva",
            type: "column",
            data: [
              [1724392800000, 0, null],
              [1724396400000, 0.15625, null],
              [1724400000000, 0, null],
              [1724403600000, 0, null],
              [1724407200000, 0, null],
              [1724410800000, 0, null],
              [1724414400000, 0.1640625, null],
              [1724418000000, 0, null],
              [1724421600000, 0, null],
              [1724425200000, 0, null],
              [1724428800000, 0.3515625, null],
              [1724432400000, 0.1171875, null],
              [1724436000000, 0, null],
              [1724439600000, 0, null],
              [1724443200000, 0, null],
              [1724446800000, 0, null],
              [1724450400000, 0, null],
              [1724454000000, 0, null],
              [1724457600000, 0, null],
              [1724461200000, 0, null],
              [1724464800000, 0, null],
              [1724468400000, 0, null],
              [1724472000000, 0, null],
              [1724475600000, 0, null],
              [1724479200000, 0, null],
              [1724482800000, 0, null],
              [1724486400000, 0, null],
              [1724490000000, 2.25, null],
              [1724493600000, 0.59375, null],
              [1724497200000, 1.625, null],
              [1724500800000, 0.109375, null],
            ],
            environmentalVariable: "precip",
            visualizationType: "FORECAST",
            events: {},
            color: "rgba(0,128,0, 0.45)",
            tooltip: { valueDecimals: 2, valueSuffix: "mm" },
            yAxis: 0,
            groupPadding: 0,
            pointPadding: 0,
            borderWidth: 0,
            shadow: false,
            dataLabels: { enabled: false },
            style: { fontSize: "12px" },
            yAxisConfig: {
              title: { text: "Chuva", style: { color: "#000000" } },
              min: 0,
              max: 1,
              labels: { format: "{value}", style: { color: "#000000" } },
              gridLineWidth: 0,
              tickLength: 0,
            },
            visible: true,
            unitSymbol: "mm",
          },
          {
            name: "Chuva mínima e máxima",
            type: "errorbar",
            data: [
              [1724392800000, 0, 0.140625],
              [1724396400000, 0, 0.5],
              [1724400000000, 0, 0.1796875],
              [1724403600000, 0, 0],
              [1724407200000, 0, 0],
              [1724410800000, 0, 0],
              [1724414400000, 0, 0.3046875],
              [1724418000000, 0, 0],
              [1724421600000, 0, 0],
              [1724425200000, 0, 0.171875],
              [1724428800000, 0, 0.6640625],
              [1724432400000, 0, 0.46875],
              [1724436000000, 0, 0],
              [1724439600000, 0, 0],
              [1724443200000, 0, 0],
              [1724446800000, 0, 0],
              [1724450400000, 0, 0],
              [1724454000000, 0, 0],
              [1724457600000, 0, 0],
              [1724461200000, 0, 0],
              [1724464800000, 0, 0],
              [1724468400000, 0, 0],
              [1724472000000, 0, 0],
              [1724475600000, 0, 0],
              [1724479200000, 0, 0],
              [1724482800000, 0, 0],
              [1724486400000, 0, 0],
              [1724490000000, 0, 4.1171875],
              [1724493600000, 0, 2.2265625],
              [1724497200000, 0.890625, 3.7109375],
              [1724500800000, 0, 1.6640625],
            ],
            environmentalVariable: "precip_min_20km",
            visualizationType: "FORECAST",
            events: {},
            color: "rgba(30, 82, 151,1)",
            tooltip: { valueDecimals: 2, valueSuffix: "mm" },
            yAxis: 0,
            lineWidth: 2,
            showInLegend: true,
            dataLabels: { enabled: false },
            style: { fontSize: "12px" },
            updateYAxis: true,
            updateBiggestMax: true,
            yAxisConfig: {
              title: { text: "Chuva", style: { color: "#000000" } },
              min: 0,
              max: 1,
              labels: { format: "{value}", style: { color: "#000000" } },
              gridLineWidth: 0,
              tickLength: 0,
            },
            visible: true,
            unitSymbol: "mm",
            defaultMaxConverted: 1,
          },
        ],
      }}
    />
  );
};

export default observer(RainChartMock);
