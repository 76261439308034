import { makeAutoObservable } from "mobx";
import { StoreState } from "../../../../enum/StoreState";
import {
  IProbabilisticForecastChart,
  ProbabilisticForecastParams,
} from "../../../../services/ProbabilisticForecastService";
import axios from "axios";
import { getEnv, getRoot } from "mobx-easy";
import { RootEnv } from "../../../setup/create-store";
import i18n from "../../../../i18n";
import RootStore from "../../../root-store";
import { Units } from "../../unitMeasurement/UnitMeasurementStore";
import { EnvironmentalVariables } from "../../../../enum/EnvironmentalVariables";
import { EnvironmentalType } from "../../../../enum/EnvironmentalType";
import RequestCancellationStore from "stores/data/requestCancellationStore/RequestCancellationStore";
import { VisualizationType } from "enum/VisualizationType";

export default class ForecastChartProbabilisticStore {
  state: string = StoreState.DONE;

  messageError: string = "";

  isChartDataEmpty: boolean = false;

  probabilisticForecastData: IProbabilisticForecastChart | null = null;

  unitMeasurementUserConfig: string | null = null;

  deterministic: number[][];

  median: number[][];

  confidence50: (number | number[])[][];

  confidence80: (number | number[])[][];

  showProbabilisticChart: boolean = false;

  showNoProbabilisticDataMessage: boolean = false;

  requestCancellationStore: RequestCancellationStore;

  constructor(requestCancellationStore: RequestCancellationStore) {
    makeAutoObservable(this);
    this.requestCancellationStore = requestCancellationStore;
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setMessageError(value: string) {
    this.messageError = value;
  }

  setIsChartDataEmpty(value: boolean) {
    this.isChartDataEmpty = value;
  }

  setProbabilisticForecastData(value: IProbabilisticForecastChart | null) {
    this.probabilisticForecastData = value;
  }

  setDeterministic(value: number[][]) {
    this.deterministic = value;
  }

  setConfidence50(value: (number | number[])[][]) {
    this.confidence50 = value;
  }

  setConfidence80(value: (number | number[])[][]) {
    this.confidence80 = value;
  }

  setMedian(value: number[][]) {
    this.median = value;
  }

  setUnitMeasurementUserConfig(value: string | null) {
    this.unitMeasurementUserConfig = value;
  }

  setShowProbabilisticChart(value: boolean) {
    this.showProbabilisticChart = value;
  }

  setShowNoProbabilisticDataMessage(value: boolean) {
    this.showNoProbabilisticDataMessage = value;
  }

  reset() {
    this.setMessageError("");
    this.setIsChartDataEmpty(false);
    this.setProbabilisticForecastData(null);
    this.setDeterministic([]);
    this.setState(StoreState.DONE);
    this.setConfidence50([]);
    this.setConfidence80([]);
    this.setMedian([]);
    this.setUnitMeasurementUserConfig(null);
    this.setShowProbabilisticChart(false);
    this.setShowNoProbabilisticDataMessage(false);
  }

  async getProbabilisticForecastDataApi(
    {
      latitude,
      longitude,
      environmentalVariable,
      macroregionOrigin,
      dataInterval,
      timestampStart,
      timestampEnd,
    }: ProbabilisticForecastParams,
    controller?: AbortController
  ) {
    const {
      dataStores: { unitMeasurementStore },
    } = getRoot<RootStore>();
    const { probabilisticForecastService } = getEnv<RootEnv>();

    this.setState(StoreState.PEDDING);
    this.setProbabilisticForecastData(null);
    this.setShowNoProbabilisticDataMessage(false);

    if (
      environmentalVariable === EnvironmentalVariables.WATER_LEVEL ||
      environmentalVariable === EnvironmentalVariables.WAVE_HEIGHT_SEA ||
      environmentalVariable === EnvironmentalVariables.WAVE_HEIGHT_SWELL ||
      environmentalVariable === EnvironmentalVariables.TEMP_AIR
    ) {
      this.setIsChartDataEmpty(true);
      this.setShowNoProbabilisticDataMessage(true);
      this.setState(StoreState.DONE);
      return;
    }
    if (controller) {
      this.requestCancellationStore.addController(controller);
    }

    try {
      let chartData: IProbabilisticForecastChart =
        await probabilisticForecastService.getProbabilisticForecastData(
          {
            latitude,
            longitude,
            environmentalVariable,
            macroregionOrigin,
            dataInterval,
            timestampStart,
            timestampEnd,
          },
          undefined, // todo: remove cancelToken forever
          controller?.signal
        );

      this.setProbabilisticForecastData(chartData);

      const unitsUser = unitMeasurementStore.getUnitMeasurementConfig(
        this.getEnvironmentalVariableSimilar(chartData.environmentalVariable)
      );
      this.setUnitMeasurementUserConfig(unitsUser);

      let { deterministic, confidence50, confidence80, median } = chartData;
      let defaultMin = 0;
      let defaultMax =
        this.getYMaxDefaultByEnvVariable(
          chartData.environmentalVariable as EnvironmentalVariables
        ) || chartData.max;

      const unitFrom = chartData.units_symbol.toLowerCase();
      const unitTo = this.unitMeasurementUserConfig;

      if (unitTo && unitTo !== unitFrom) {
        deterministic = (await this.convertUnitMeasurementProbabilisticSerie(
          chartData.deterministic
        )) as number[][];
        confidence50 = (await this.convertUnitMeasurementProbabilisticSerie(
          chartData.confidence50
        )) as number[][];
        confidence80 = (await this.convertUnitMeasurementProbabilisticSerie(
          chartData.confidence80
        )) as number[][];

        if (median) {
          median = (await this.convertUnitMeasurementProbabilisticSerie(
            median
          )) as number[][];
        }

        const { min, max } = await this.convertUnitMeasurementForYMinYMax(
          environmentalVariable as EnvironmentalVariables,
          chartData.min,
          chartData.max,
          unitFrom,
          unitTo
        );
        defaultMin = min;
        defaultMax = max;
      } else {
        defaultMin = chartData.min < 0 ? chartData.min * 1.2 : 0;
        defaultMax =
          chartData.max * 1.2 > defaultMax ? chartData.max * 1.2 : defaultMax;
      }

      this.setProbabilisticForecastData({
        ...chartData,
        min: defaultMin,
        max: defaultMax,
      });

      this.setDeterministic(deterministic);
      this.setConfidence50(confidence50);
      this.setConfidence80(confidence80);
      if (median) {
        this.setMedian(median);
      }

      this.setState(StoreState.DONE);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("axios request cancelled");
      } else {
        this.setState(StoreState.ERROR);
        this.setIsChartDataEmpty(true);

        this.setMessageError(
          i18n.t(
            "forecastChart.forecastChartProbabilisticStore.errorTryGetData"
          )
        );
      }
    } finally {
      if (controller)
        this.requestCancellationStore.removeController(controller);
      setTimeout(() => {
        this.setMessageError("");
        this.setState(StoreState.DONE);
      }, 2000);
      this.setIsChartDataEmpty(false);
    }
  }

  async convertUnitMeasurementForYMinYMax(
    envVariable: EnvironmentalVariables,
    valueMin: number,
    valueMax: number,
    unitFrom: string,
    unitTo: string
  ) {
    const {
      dataStores: { unitMeasurementStore },
    } = getRoot<RootStore>();

    let defaultMaxValue = this.getYMaxDefaultByEnvVariable(envVariable);

    let convertedDefaultMax = valueMax;

    if (defaultMaxValue) {
      convertedDefaultMax = unitMeasurementStore.convertUnit(
        defaultMaxValue,
        unitFrom as Units,
        unitTo as Units
      );
    }

    const convertedValueMax = unitMeasurementStore.convertUnit(
      valueMax,
      unitFrom as Units,
      unitTo as Units
    );

    const currentMax =
      convertedValueMax * 1.2 > convertedDefaultMax
        ? convertedValueMax * 1.2
        : convertedDefaultMax;

    if (valueMin < 0) {
      const convertedValueMin = unitMeasurementStore.convertUnit(
        valueMin,
        unitFrom as Units,
        unitTo as Units
      );
      return {
        min: convertedValueMin * 1.2,
        max: currentMax,
      };
    }
    return {
      min: 0,
      max: currentMax,
    };
  }

  async convertUnitMeasurementProbabilisticSerie(
    data: number[][] | (number | number[])[][]
  ) {
    const {
      dataStores: { unitMeasurementStore },
    } = getRoot<RootStore>();

    let convertedData: (number | number[])[][] = [];

    if (Array.isArray(data[0])) {
      convertedData = (data as (number | number[])[][]).map((serie) => {
        return serie.map((value, index) => {
          if (index === 0) {
            return value;
          }
          if (Array.isArray(value)) {
            return (value as number[]).map((val: number) =>
              unitMeasurementStore.convertUnit(
                val,
                this.probabilisticForecastData?.units_symbol.toLowerCase() as Units,
                this.unitMeasurementUserConfig as Units
              )
            );
          } else {
            return unitMeasurementStore.convertUnit(
              value as number,
              this.probabilisticForecastData?.units_symbol.toLowerCase() as Units,
              this.unitMeasurementUserConfig as Units
            );
          }
        });
      });
    }

    return convertedData;
  }

  getYMaxDefaultByEnvVariable(
    envVariable: EnvironmentalVariables
  ): number | null {
    switch (envVariable) {
      case EnvironmentalVariables.WIND_VEL:
      case EnvironmentalVariables.WIND_VEL_80M:
      case EnvironmentalVariables.WIND_VEL_100M:
      case EnvironmentalVariables.GUST:
      case EnvironmentalVariables.GUST_80M:
      case EnvironmentalVariables.GUST_100M:
        return 60; //knots
      case EnvironmentalVariables.WAVE_HEIGHT:
      case EnvironmentalVariables.WAVE_HEIGHT_MAX:
        return 3; //meters
      case EnvironmentalVariables.PEAK_PERIOD:
      case EnvironmentalVariables.MEAN_PERIOD:
        return 20; //seconds
      default:
        return null;
    }
  }

  getEnvironmentalVariableSimilar(envVariable: string): string {
    switch (envVariable) {
      case EnvironmentalVariables.WIND_VEL:
      case EnvironmentalVariables.WIND_VEL_80M:
      case EnvironmentalVariables.WIND_VEL_100M:
      case EnvironmentalVariables.GUST:
      case EnvironmentalVariables.GUST_80M:
      case EnvironmentalVariables.GUST_100M:
        return EnvironmentalVariables.WIND_VEL;
      case EnvironmentalVariables.WAVE_HEIGHT:
      case EnvironmentalVariables.WAVE_HEIGHT_MAX:
      case EnvironmentalVariables.WAVE_HEIGHT_SEA:
      case EnvironmentalVariables.WAVE_HEIGHT_SWELL:
        return EnvironmentalVariables.WAVE_HEIGHT;
      default:
        return envVariable;
    }
  }
  getSerieByEnvironmentalVariable() {
    const {
      dataStores: { forecastChartPageStore },
    } = getRoot<RootStore>();
    const seriesConfigs = [];
    switch (forecastChartPageStore.tabStateActivty) {
      case EnvironmentalType.WEATHER:
        seriesConfigs.push(
          this.createSeriesConfig(EnvironmentalVariables.WIND_VEL)
        );
        seriesConfigs.push(
          this.createSeriesConfig(EnvironmentalVariables.GUST)
        );
        seriesConfigs.push(
          this.createSeriesConfig(EnvironmentalVariables.WIND_VEL_80M)
        );
        seriesConfigs.push(
          this.createSeriesConfig(EnvironmentalVariables.GUST_80M)
        );
        seriesConfigs.push(
          this.createSeriesConfig(EnvironmentalVariables.WIND_VEL_100M)
        );
        seriesConfigs.push(
          this.createSeriesConfig(EnvironmentalVariables.GUST_100M)
        );

        break;
      case EnvironmentalType.WAVE:
        seriesConfigs.push(
          this.createSeriesConfig(EnvironmentalVariables.WAVE_HEIGHT)
        );
        seriesConfigs.push(
          this.createSeriesConfig(EnvironmentalVariables.PEAK_PERIOD)
        );
        seriesConfigs.push(
          this.createSeriesConfig(EnvironmentalVariables.MEAN_PERIOD)
        );

        break;

      default:
        break;
    }
    return seriesConfigs;
  }
  createSeriesConfig(environmentalVariable: EnvironmentalVariables) {
    const {
      dataStores: { forecastChartPageStore, forecastChartProbabilisticStore },
    } = getRoot<RootStore>();
    return {
      visible:
        forecastChartPageStore.probabilisticActivityVariable ===
        environmentalVariable,
      name: i18n.t(`environmentalVariableEnum.${environmentalVariable}`),
      data:
        forecastChartPageStore.probabilisticActivityVariable ===
        environmentalVariable
          ? this.deterministic
          : [],
      environmentalVariable: environmentalVariable,
      events: {
        legendItemClick: function () {
          setTimeout(() => {
            const latLng = forecastChartPageStore.clickMapLatLng;
            const { chart } = this as any;

            const serie = chart.series.find(
              (x: any) =>
                x.userOptions.environmentalVariable === environmentalVariable
            );

            const isVisible = serie.visible;

            if (!isVisible) {
              if (forecastChartPageStore.macroRegionOrigin) {
                forecastChartPageStore.setProbabilisticActivityVariable(
                  environmentalVariable
                );
                forecastChartProbabilisticStore.getProbabilisticForecastDataApi(
                  {
                    latitude: latLng.lat,
                    longitude: latLng.lng,
                    environmentalVariable: environmentalVariable,
                    macroregionOrigin: forecastChartPageStore.macroRegionOrigin,
                  }
                );
              }
            } else {
              serie.hide();
            }
          }, 0);

          return false;
        },
      },
      zIndex: 3,
      label: {
        enabled: false,
      },
      marker: {
        enabled: false,
      },
      color: "#495154",
      lineWidth: 3,
      gridLineWidth: 1,
      tooltip: {
        valueDecimals: 2,
        valueSuffix: forecastChartProbabilisticStore.unitMeasurementUserConfig,
      },
    };
  }

  handleChangeToProbabilisticTab() {
    const {
      dataStores: {
        forecastChartPageStore,
        forecastChartMeteogramStore,
        analyticsChartStore,
      },
    } = getRoot<RootStore>();
    forecastChartMeteogramStore.setVisualizationType(
      VisualizationType.PROBABILISTIC
    );

    forecastChartPageStore.handleOnChangeTabMeteogram(
      forecastChartPageStore.tabStateActivty
    );
    forecastChartPageStore.setProbabilisticVariableByActivityTab();
    forecastChartPageStore.setSensorMarkerData([]);
    analyticsChartStore.setIsChartDataEmpty(false);
  }
}
