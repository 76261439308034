import React from "react";
import { Grid, GridItem, Box, Text, Flex, IconButton } from "@chakra-ui/react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

interface IList {
  id?: number | string;
  name?: string;
  initialName?: string;
  description?: string;
  resume?: string;
}

interface ListProps<T> {
  list: T[];
  onEdit: (item: T) => void;
  onDelete: (item: T) => void;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  gridStyle?: React.CSSProperties;
}

const List = <T extends IList>({
  list,
  onEdit,
  onDelete,
  showEditButton = true,
  showDeleteButton = true,
  gridStyle,
}: ListProps<T>) => {
  const limitText = (text: string | undefined, limit: number) => {
    if (text && text.length > limit) {
      return text.slice(0, limit) + "...";
    }
    return text;
  };

  return (
    <Grid templateRows="repeat(5, 1fr)" mt={4}>
      {list?.map((item) => (
        <GridItem
          mb={2}
          key={item.id}
          h={gridStyle?.height || "14"}
          color={gridStyle?.color || "newBlue.500"}
          borderRadius={8}
          borderColor={gridStyle?.borderColor || "rgba(30, 82, 151, 0.12)"}
          bg={gridStyle?.backgroundColor || "rgba(119, 176, 213, 0.1)"}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            pl={4}
            pt={2}
            pr={4}
          >
            <Box>
              <Text fontWeight={"bold"}>
                {item.initialName ? `${item.initialName}-` : ""} {item.name}
              </Text>
              <Text fontSize="sm">
                {limitText(
                  item.description ? item.description : item.resume,
                  150
                )}
              </Text>
            </Box>

            <Flex>
              {showEditButton && (
                <IconButton
                  icon={<EditIcon />}
                  layerStyle="yellowOcean"
                  _hover={{ layerStyle: "yellowOcean" }}
                  size="sm"
                  mr={4}
                  aria-label={""}
                  onClick={() => onEdit(item)}
                />
              )}
              {showDeleteButton && (
                <IconButton
                  icon={<DeleteIcon />}
                  layerStyle="redOcean"
                  _hover={{ layerStyle: "redOcean" }}
                  size="sm"
                  aria-label={""}
                  onClick={() => onDelete(item)}
                />
              )}
            </Flex>
          </Flex>
        </GridItem>
      ))}
    </Grid>
  );
};

export default List;
