import React, { useState } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

interface InternationalPhoneInputProps {
  phoneData?: { phone?: string; code?: string };
  setPhoneData: React.Dispatch<
    React.SetStateAction<{
      phone?: string | undefined;
      code?: string | undefined;
    }>
  >;
  style?: React.CSSProperties;
}

export const InternationalPhoneInput = ({
  phoneData,
  setPhoneData,
  style,
}: InternationalPhoneInputProps) => {
  const [phoneInput, setPhoneInput] = useState("");

  const onChange = (phone: string, code: string | undefined) => {
    setPhoneInput(phone);
    if (code && phone?.length) {
      const codeFormatted = `+${code}`;
      if (phone.startsWith(codeFormatted)) {
        const phoneFormatted = phone.slice(codeFormatted.length);
        setPhoneData({
          ...phoneData,
          phone: phoneFormatted,
          code: code,
        });
      }
    }
  };
  return (
    <PhoneInput
      defaultCountry={"br"}
      inputStyle={{ ...style }}
      forceDialCode
      value={phoneInput}
      onChange={(phone, meta) => onChange(phone, meta.country.dialCode)}
    />
  );
};
