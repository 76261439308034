import i18n from "../../i18n";

export const getTimeDifference = (date: Date): string => {
  const now = new Date();
  const timeDifference = now.getTime() - date.getTime();
  const minutes = Math.floor(timeDifference / (1000 * 60));
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (minutes < 1) {
    return i18n.t("utils.now");
  } else if (minutes < 60) {
    return i18n.t("utils.minAgo", {
      minutes: minutes,
    });
  } else if (hours < 24) {
    return hours === 1
      ? i18n.t("utils.oneHourAgo")
      : i18n.t("utils.hoursAgo", {
          hours: hours,
        });
  } else if (days <= 30) {
    return days === 1
      ? i18n.t("utils.oneDayAgo")
      : i18n.t("utils.daysAgo", {
          days: days,
        });
  } else {
    return i18n.t("utils.longTimeAgo");
  }
};
