import { makeAutoObservable } from "mobx";
import axios, { AxiosInstance } from "axios";
import { history } from "routes";

export interface TokenSet {
  token?: string;
  refreshToken?: string;
}

export const TOKEN_KEY = "kcs_tcd_dkt";

export class SecurityStore {
  tokenSet: TokenSet | null = null;
  private loading: boolean = false;

  constructor(apis: AxiosInstance[] = []) {
    makeAutoObservable(this);

    apis.forEach((api) => {
      // Authenticate all the requests automatically
      // when an access token is available
      api.interceptors.request.use(async (config) => {
        if (config.headers && this.tokenSet?.token) {
          //@ts-ignore
          config.headers.Authorization = `Bearer ${this.tokenSet.token}`;
        }
        return config;
      });

      api.interceptors.response.use(undefined, async (error) => {
        const originalRequest = error.config;

        if (error?.response?.status === 500 || error?.code === "ECONNABORTED") {
          history.push("/500");
          return Promise.reject(error);
        }

        if (error.message === "Request cancelled") {
          return Promise.reject(error);
        }

        if (
          error.response?.status === 401 &&
          error.response?.data?.name === "REFRESH_TOKEN_ERROR"
        ) {
          this.clearTokenSet();
          window.location.href = "/login";
        } else if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          if (!this.tokenSet?.refreshToken) {
            console.log("No refresh token available");
            this.clearTokenSet();
          }

          const response = await api.post("/v1/auth/refreshToken", {
            refreshToken: this.tokenSet?.refreshToken,
          });
          const { accessToken } = response.data;

          this.setTokenSet({
            token: accessToken,
            refreshToken: this.tokenSet?.refreshToken,
          });
          axios.defaults.headers.common.Authorization = "Bearer " + accessToken;
          return api(originalRequest);
        }

        return Promise.reject(error);
      });
    });
  }

  private setLoading(value: boolean) {
    this.loading = value;
  }

  public isLoading(): boolean {
    return this.loading;
  }

  clearTokenSet() {
    this.tokenSet = null;
    localStorage.removeItem(TOKEN_KEY);
  }

  setTokenSet(tokenSet: TokenSet) {
    this.tokenSet = tokenSet;
    localStorage.setItem(TOKEN_KEY, JSON.stringify(tokenSet));
  }

  isAuthorized() {
    return localStorage.getItem(TOKEN_KEY) !== null;
  }

  async getTokenSet(): Promise<void> {
    this.setLoading(true);
    try {
      const tokenSet = localStorage.getItem(TOKEN_KEY);

      this.tokenSet = tokenSet && JSON.parse(tokenSet);
    } catch (e) {
      throw e;
    } finally {
      this.setLoading(false);
    }
  }
}
