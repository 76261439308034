import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";
import React, { useRef } from "react";

interface DeleteDashboardAlertProps {
  isOpen: boolean;
  onClose: () => void;
  deleteButtonLabel: string;
  titleLabel: string;
  bodyText: string;
  cancelButtonLabel: string;
  handleDelete: () => void;
}

export const AlertDelete = ({
  isOpen,
  onClose,
  deleteButtonLabel,
  titleLabel,
  bodyText,
  cancelButtonLabel,
  handleDelete,
}: DeleteDashboardAlertProps) => {
  const cancelRef = useRef<HTMLButtonElement | null>(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent color="newBlue.500">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {titleLabel}
          </AlertDialogHeader>

          <AlertDialogBody>{bodyText}</AlertDialogBody>

          <AlertDialogFooter>
            <Button variant="ghost" ref={cancelRef} onClick={onClose}>
              {cancelButtonLabel}
            </Button>
            <Button colorScheme="red" onClick={handleDelete} ml={3}>
              {deleteButtonLabel}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
