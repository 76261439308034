import {
  Button,
  Checkbox,
  Form,
  Input,
  Popconfirm,
  Select,
  SelectProps,
  Table,
} from "antd";
import { ColumnFilterItem } from "antd/lib/table/interface";
import React, { useCallback, useEffect, useState } from "react";
import { IUser } from "../../types/IUser";
import { PermissionGroup } from "../../permissions";

import "./Style.css";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { t } from "@chakra-ui/styled-system/dist/declarations/src/utils";

export interface UserEditableTableRowProps {
  key: React.Key;
  name: string;
  login?: string;
  group?: string;
  active?: boolean;
  area?: string;
  position?: string;
  email?: string;
  status?: string;
}

export interface UserEditableTableProps {
  users: IUser[];
  onPaginationChange: () => void;
  onRemoveUser: (key: React.Key, active: boolean) => void;
  onChangeGroup: (key: React.Key, value: string) => void;
  pageSize?: number;
  isLoading: boolean;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "boolean" | "text" | "number";
  record: UserEditableTableRowProps;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "boolean" ? <Checkbox /> : <Input />;
  const { t } = useTranslation("translation", {
    keyPrefix: "admin.userManager",
  });

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          valuePropName={inputType === "boolean" ? "checked" : undefined}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `${t("pleaseInput")} ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const UserEditableTable: React.FC<UserEditableTableProps> = ({
  users,
  onPaginationChange,
  onRemoveUser,
  onChangeGroup,
  pageSize,
  isLoading,
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState<UserEditableTableRowProps[]>([]);
  const { t } = useTranslation("translation", {
    keyPrefix: "admin.userManager",
  });

  const { Option } = Select;

  useEffect(() => {
    const userEditableTableRowProps: UserEditableTableRowProps[] = users.map(
      (user) => {
        return {
          key: user.id,
          name: user.name,
          active: !!user.active,
          group: user.groups?.length ? user.groups[0] : PermissionGroup.USER,
          login: user.login || "",
          area: user.area,
          position: user.position,
          email: user.email || "",
          status: user.active
            ? (t("granted") as string)
            : (t("denied") as string),
        };
      }
    );
    setData(userEditableTableRowProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const getColumnFilter = useCallback(
    (item: keyof UserEditableTableRowProps) => {
      const filterAux: ColumnFilterItem[] = [];
      for (const row of data) {
        const columnFilterItem: ColumnFilterItem = {
          text: String(row[item]),
          value: row[item] as string,
        };
        if (
          !filterAux.find((filter) => filter.value === columnFilterItem.value)
        ) {
          filterAux.push(columnFilterItem);
        }
      }

      return filterAux;
    },
    [data]
  );

  const options: SelectProps["options"] = [
    {
      label: t("admin"),
      value: PermissionGroup.USER_ADMIN,
      desc: t("hasPermissionManageInviteUsers"),
    },
    {
      label: t("basic"),
      value: PermissionGroup.USER,
      desc: t("canAccessEditAllFeatures"),
    },
    {
      label: t("readOnly"),
      value: PermissionGroup.USER_READ_ONLY,
      desc: t("allowVisualizeData"),
    },
  ];

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      editable: false,
      type: "text",
      filters: getColumnFilter("name"),
      onFilter: (value: any, record: UserEditableTableRowProps) =>
        record.name.startsWith(value),
      filterSearch: true,
      filterIcon: <SearchOutlined style={{ fontSize: 20 }} />,
      render: (text: string, record: UserEditableTableRowProps) => (
        <h4 style={!record.active ? { color: "grey" } : {}}>{text}</h4>
      ),
    },
    {
      title: t("username"),
      dataIndex: "login",
      editable: false,
      type: "text",
      filters: getColumnFilter("login"),
      onFilter: (value: any, record: UserEditableTableRowProps) =>
        record.login !== undefined && record.login.startsWith(value),
      filterSearch: true,
      filterIcon: <SearchOutlined style={{ fontSize: 20 }} />,
      render: (text: string, record: UserEditableTableRowProps) => (
        <h4 style={!record.active ? { color: "grey" } : {}}>{text}</h4>
      ),
    },
    {
      title: t("email"),
      dataIndex: "email",
      editable: false,
      type: "text",
      filters: getColumnFilter("email"),
      onFilter: (value: any, record: UserEditableTableRowProps) =>
        record.email !== undefined && record.email.startsWith(value),
      filterSearch: true,
      filterIcon: (
        <SearchOutlined style={{ fontSize: 20, fontWeight: "bold" }} />
      ),
      render: (text: string, record: UserEditableTableRowProps) => (
        <h4 style={!record.active ? { color: "grey" } : {}}>{text}</h4>
      ),
    },
    {
      title: t("permissionGroup"),
      dataIndex: "admin",
      editable: true,
      type: "boolean",
      render: (_: any, record: UserEditableTableRowProps) => (
        <Select
          defaultValue={record.group}
          onChange={(value) => onChangeGroup(record.key, value)}
          style={{ width: 100 }}
          disabled={!record.active}
          dropdownMatchSelectWidth={false}
          options={options}
          optionRender={(option) => (
            <>
              <h3>{option.data.label}</h3>
              <p style={{ color: "grey" }}>{option.data.desc}</p>
            </>
          )}
        />
      ),
      width: "12.5%",
    },
    {
      title: t("accessToI4cast"),
      dataIndex: "status",
      filters: getColumnFilter("status"),
      onFilter: (value: any, record: UserEditableTableRowProps) =>
        record.status !== undefined && record.status.startsWith(value),
      filterSearch: true,
      filterIcon: (
        <SearchOutlined style={{ fontSize: 20, fontWeight: "bold" }} />
      ),
      render: (text: string, record: UserEditableTableRowProps) => (
        <h4 style={!record.active ? { color: "grey" } : {}}>{record.status}</h4>
      ),
      width: "12.5%",
    },
    {
      title: "",
      dataIndex: "active",
      editable: false,
      type: "boolean",
      render: (_: any, record: UserEditableTableRowProps) => (
        <Popconfirm
          title={
            record.active
              ? t("areYouSureWantDeactivateUser")
              : t("areYouSureWantreactivateUser")
          }
          onConfirm={() => {
            onRemoveUser(record.key, !!record.active);
          }}
          okText={t("yes")}
          cancelText={t("no")}
        >
          {record.active ? (
            <Button danger style={{ width: 120 }}>
              {t("deactivate")}
            </Button>
          ) : (
            <Button
              type="primary"
              ghost
              style={{ width: 120, color: "green", borderColor: "green" }}
            >
              {t("reactivate")}
            </Button>
          )}
        </Popconfirm>
      ),
      width: "12.5%",
    },
  ];

  return (
    <Form form={form} component={false}>
      <Table
        locale={{
          filterConfirm: t("ok"),
          filterReset: t("reset"),
          filterSearchPlaceholder: "",
        }}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={columns}
        rowClassName="editable-row"
        pagination={{
          pageSize: pageSize || 10,
          onChange: onPaginationChange,
        }}
        loading={isLoading}
      />
    </Form>
  );
};

export default UserEditableTable;
