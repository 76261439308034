import { JoyrideStorage } from "enum/JoyrideStorage";
import { geospatialJoySteps as geospatialJoyStepsValues } from "../steps/GeospatialJoySteps";
import GuidedTutorial from "./GuidedTutorial";

function GeoSpatialGuidedTutorial({ shouldRunJoyride = false }) {
  return (
    <GuidedTutorial
      stepsProps={geospatialJoyStepsValues}
      storage={[JoyrideStorage.GEOSPATIAL_ON_LOCAL_STORAGE]}
      shouldRunJoyride={shouldRunJoyride}
    />
  );
}

export default GeoSpatialGuidedTutorial;
