import { EnvironmentalType } from "../../../enum/EnvironmentalType";
import { ChartMenuData } from "./Components/ChartMenu";
import iconCloud from "../../../assets/cloud.svg";
import iconTemperature from "../../../assets/temperature.svg";
import iconWave from "../../../assets/wave.svg";
import iconWaterLevel from "../../../assets/water_level.svg";
import iconRain from "../../../assets/cloud-drizzle.svg";
import visibilityIcon from "../../../assets/visibility.svg";

import i18n from "../../../i18n";

export const loadChartMenuData = (): ChartMenuData[] => [
  {
    id: EnvironmentalType.WEATHER,
    text: i18n.t(
      "forecastChart.forecastChartPanelEnvironmentalButtons.weather"
    ),
    icon: iconCloud,
    iconStyle: { width: 28, padding: 4 },
    meteogram: true,
    children: [
      {
        id: EnvironmentalType.WEATHER_TEMPERATURE,
        text: i18n.t(
          "forecastChart.forecastChartPanelEnvironmentalButtons.temperature"
        ),
        icon: iconTemperature,
        iconStyle: { width: 16, marginLeft: 5, padding: 4, zIndex: 9999999 },
        meteogram: true,
      },
      {
        id: EnvironmentalType.PRECIPITATION,
        text: i18n.t(
          "forecastChart.forecastChartPanelEnvironmentalButtons.rain"
        ),
        icon: iconRain,
        iconStyle: { width: 26, padding: 4, zIndex: 9999999 },
        meteogram: true,
      },
      {
        id: EnvironmentalType.VISIBILITY,
        text: i18n.t(
          "forecastChart.forecastChartPanelEnvironmentalButtons.visibility"
        ),
        icon: visibilityIcon,
        iconStyle: { width: 26, padding: 4, zIndex: 9999999 },
        meteogram: true,
      },
    ],
  },
  {
    id: EnvironmentalType.WAVE,
    text: i18n.t("forecastChart.forecastChartPanelEnvironmentalButtons.wave"),
    icon: iconWave,
    iconStyle: { width: 28, padding: 4 },
    meteogram: true,
  },
  {
    id: EnvironmentalType.WATER_FLOW,
    text: i18n.t(
      "forecastChart.forecastChartPanelEnvironmentalButtons.waterFlow"
    ),
    icon: iconWaterLevel,
    iconStyle: { width: 24, padding: 4 },
    meteogram: true,
  },
];

export const findChartMenuParentId = (
  data: ChartMenuData[],
  childId: string
): string | null => {
  for (const item of data) {
    if (item.children) {
      for (const child of item.children) {
        if (child.id === childId) {
          return item.id;
        }
      }
    }
  }
  return null;
};

export const findMenuItemById = (data: ChartMenuData[], id: string) => {
  for (const item of data) {
    if (item.id === id) {
      return item;
    }
    if (item.children) {
      const childItem = item.children.find((child) => child.id === id);
      if (childItem) {
        return childItem;
      }
    }
  }
  return null;
};
