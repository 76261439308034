import React, { FC } from "react";
import { Steps } from "antd";
import { NotificationOutlined, UserOutlined } from "@ant-design/icons";

export interface NotificationWizzardProps {
  currentStep: number;
}

export const NotificationWizzard: FC<NotificationWizzardProps> = ({
  currentStep,
}) => {
  return (
    <Steps direction="horizontal" current={currentStep}>
      <Steps.Step title="Select recipients" icon={<UserOutlined />} />
      <Steps.Step
        title="Prepare and send message"
        icon={<NotificationOutlined />}
      />
    </Steps>
  );
};
