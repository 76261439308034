export const calculateAdjustedValue = (
  defaultValue: number,
  value?: number,
  isMax: boolean = true
): number => {
  const adjustedValue = (value ?? 0) * 1.2;

  return isMax
    ? Math.max(adjustedValue, defaultValue)
    : Math.min(adjustedValue, defaultValue);
};
