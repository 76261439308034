import * as React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import highchartsSeriesLabel from "highcharts/modules/series-label";

highchartsMore(Highcharts);
highchartsSeriesLabel(Highcharts);

interface LineChartProps {
  timezoneOffset?: number;
  options: HighchartsReact.Props;
  style?: React.CSSProperties;
}
const LineChart: React.FC<LineChartProps> = (props) => {
  Highcharts.setOptions({
    time: {
      timezoneOffset: props.timezoneOffset,
    },
  });

  return (
    <div style={props.style}>
      <HighchartsReact
        highcharts={Highcharts}
        allowChartUpdate={true}
        immutable={true}
        options={props.options}
      />
    </div>
  );
};

export default LineChart;
