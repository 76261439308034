export const translation_EN = {
  diveEditDashboard: "Dive into the edit dashboard.",
  passwordNotMatch: "Password not match",
  successChangedPassword: "Success ! You changed your password",
  currentPassword: "Current password",
  newPassword: "New password",
  confirmNewPassword: "Confirm new password",
  updatePassword: "Update Password",
  pleaseInformPhoneNumber: "Please, inform your phone number.",
  dataEditedSuccess: "Data edited with success",
  myData: "My Data",
  readingModePleaseContact:
    "Read-Only User. Contact your company's admin user to access editing features",
  youCanOnlyChangeItems: "You can only change items that you created yourself.",
  navBar: {
    operationInsight: "Operation Insights",
    forecastCharts: "Forecast Charts",
    sensorCharts: "Sensor Charts",
    dynamicUkcCharts: "Dynamic UKC Charts",
    alerts: "Alerts",
    mooringLines: "Mooring lines",
    morphologyMap: "Morphology map",
    admin: "Admin",
    myData: "My Data",
    changePassword: "Change Password",
    userManager: "User Manager",
    notificationManager: "Notification Manager",
    forecastChecker: "Forecast checker",
    settings: "Settings",
    errorWhileGetMooringAuthentication:
      "Oops! Error while get mooring authentication! Please contact us.",
    logout: "Logout",
    portuguese: "Portuguese",
    english: "English",
    shareForecast: "Share forecast",
    new: "New",
    shareForecastWithOutLeaving: "Share the forecast without leaving i4cast!",
    reportOccurrence: "Report occurrence",
    occurrences: "Occurrences",
    importOccurrence: "Import occurrence",
    youAreGoingToBeRedirect: "You will be redirected to another page",
    errorWhileGetUKCAuthentication:
      "Oops! Error while get dynamic ukc authentication! Please contact us.",
    manageStation: "Station Manager",
    performance: "Performance",
    eventHistory: "Event history",
    eventAnalysis: "Event Analysis",
    watchTutorial: "Watch Tutorial",
    learnHowToUse: "Learn How to Use i4cast",
    riverForecast: "River Forecasts",
  },
  login: {
    usernameNoLongerValid:
      "Sorry but this username has been deactivated and is no longer valid.",
    passwordNotBeenChanged:
      "The password has not been changed. We ask you to repeat the operation or contact contato@i4sea.com.",
    errorValidatingPassword: "Error in validating your current password.",
    resetPassword: "Reset password",
    logIn: "Log in",
    verificationCodeSent: "Verification code sent!",
    successWeveSentYouEmailPasswordReset:
      "Success! We’ve sent you an email with password reset instructions.",
    doneNewPasswordSaved: "Done! New password saved",
    successGoBackToLogin: "Success! Go back to login and welcome back.",
    login: {
      usernameNoLongerValid:
        "Sorry but this username has been deactivated and is no longer valid.",
      updateI4castData: "Update your data to maximize the use of i4cast",
      resetPassword: "Reset password",
      gotIt: "Got it!",
      skip: "skip",
      emailUsername: "Email or username",
      password: "Password",
      keepLogged: "Keep me logged in",
      userPasswordIncorrect:
        "Sorry, user or password isn’t right. We can help you recover your",
      logInTitle: "Log in",
      logIn: "Log in",
      forgotUsername: "Forgot username?",
      forgotPassword: "Forgot password?",
      alert: "Alert",
      verificationCodeSent: "Verification code sent!",
      successWeveSentYouEmailPasswordReset:
        "Success! We’ve sent you an email with password reset instructions.",
      doneNewPasswordSaved: "Done! New password saved",
      successGoBackToLogin: "Success! Go back to login and welcome back.",
      itAppearsYouAreDisconnected:
        "It appears you are disconnected. Please check your internet connection.",
      sorryWeSeemToHaveEncountered:
        "Sorry, we seem to have encountered a problem with our server. Please try again later.",
      hidePassword: "Hide password",
      showPassword: "Show password",
      eyesClosed: "Eyes closed",
      eyesOpen: "Eyes open",
    },
    forgotPassword: {
      recoverPassword: "Recover password",
      next: "Next",
      cancel: "Cancel",
      thereWasProblem: "There was a problem",
      pleaseInputEmail: "Please input your email!",
      updatePassword: "Update password",
      newPassword: "New password",
      pleaseCheckEmail: "Please check your email box",
      enterCodeVerification: "Enter your verification code",
      pleaseInputVerificationCode: "Please input your verification code",
      pleaseInputYourPassword: "Please input your password",
      pleaseInputYourNewPassword: "Please input your new password",
      pleaseInputYourNewPasswordAgain: "Please input your new password again",
      confirmNewPassword: "Confirm new password",
    },
    changePassword: {
      changePassword: "Change password",
      updatePassword: "Update password",
      cancel: "Cancel",
      dataEditedSuccess: "Data edited with success",
      currentPassword: "Current password",
      pleaseInputCurrentPassword: "Please input your current password",
      newPassword: "New password",
      passwordNotMatch: "Password not match",
      pleaseInputYourNewPassword: "Please input your new password",
      pleaseInputYourNewPasswordAgain: "Please input your new password again",
      confirmNewPassword: "Confirm new password",
      success: "Success",
      error: "Error",
    },
    newPasswordForm: {
      verificationCode: "Verification code",
      newPassword: "New password",
      resetPassword: "Reset password",
      returnToLogin: "Return to login",
      verificationCodePasswordIncorrect:
        "Verification code or password incorrect.",
    },
    recoverPasswordForm: {
      typeYourEmail: "Type your email",
      sendInstructionsByEmail: "Send instructions by email",
      emailIncorrect: "Email incorrect.",
      returnToLogin: "Return to login",
    },
    signIn: {
      alert: "Alert",
      updateI4castData: "Update your data to maximize the use of i4cast",
      gotIt: "Got it!",
      skip: "skip",
      thereWasAProblemWithLogin:
        "There was a problem with the login, check your credentials.",
      user: "User",
      password: "Password",
      logIn: "Log in",
      forgotPassword: "Forgot password?",
    },
  },
  notificationManager: {
    pleaseEnterMessageTitle: "Please enter a message title.",
    pleaseEnterMessageContent: "Please enter a message content.",
    confirm: "Confirm",
    confirmPushNotificationSent:
      "Confirm push notification sent to {{totalUsers}} target users?",
    send: "Send",
    cancel: "Cancel",
    selectCompany: "Select a company",
    next: "Next",
    previous: "Previous",
    sendPushNotification: "Send push notification",
    pushNotificationSentSuccessfully: "Push notification sent successfully",
    submissionFailed: "Submission Failed",
    checkModifingInformationResubmiting:
      "Please check and modify the following information before resubmitting.",
    tryAgain: "Try Again",
    allClearYouDontHaveNewMessages:
      "All clear ! You don’t have any new messages.",
    unreadNotification: "Unread notification",
    markAllAsRead: "Mark all as read.",
    notifications: "Notifications",
    sendNewPushes: "Send new pushes",
  },
  oceanWeatherIntelligence: {
    errorGeneratingInsight:
      "Ops! There is an issue when generating insights for this specific location and some of them have become inaccessible. Please, contact us for more information.",
    errorGetChartData: "Error to try get data for chart. Please contact us.",
    environmentalVariablesNotFound:
      "Ooops! Environmental variables not found for this location!",
    insightIsCausedBy: "Insight is caused by:",
    end: "End",
    start: "Start",
    summary: "Summary",
    oceanWeatherIntelligence: {
      clickGoBackPastInsights: "Insight History",
      needSelectDashboardPastInsight:
        "Oops! You need to select a dashboard to go back to past forecast",
      diveOperationInsights: "Dive into the Operation Insights.",
      editDashboard: "Edit Dashboard",
      selectDashboard: "Select a Dashboard",
      needSelectDashboardAddLocation:
        "Oops! You need to select a dashboard to add a location",
      editLocation: "Edit Location",
      editInsight: "Edit Insight",
      currentInsight: "Current Insight",
      videoTutorials: "Video tutorials",
      time: "Time:",
      operationInsights: "Operation Insights",
      selectDashboardInsight: "Select a dashboard to view its insights",
      dashboards: "Dashboards",
      locations: "Locations",
      insights: "Insights",
      weStillDontHaveAnyDashboard:
        "We still don't have any dashboard registered.",
      letsGo: "Let's go?",
      addLocation: "Add Location",
      addLocationInsightToYourDashboard:
        "To view the Graph add a location. Click on 'Add Location' to begin. 😉",
      almostThereCreateInsightVisualizeChart:
        "Almost there! To create an Insight and visualize the chart",
      clickHere: "click here",
    },
    insightAlert: {
      alertRemovalFailed: "Alert removal failed. Contact us",
      selectDashboardFilterLocation:
        "Please select a dashboard to filter location",
      alert: "alert",
      insight: "Insight",
      location: "Location",
      dashboard: "Dashboard",
      filterDashboard: "Filter a dashboard",
      filterLocation: "Filter a location",
      createNewAlert: "Create New Alert",
      delete: "Delete",
      selectRecipient: "Select a recipient",
      users: "Users",
      userName: "userName",
      addAlert: "Add Alert",
      editAlert: "Edit Alert",
      add: "Add",
      edit: "Edit",
      cancel: "Cancel",
      alertName: "Alert Name",
      pleaseInputAlertName: "Please input the alert's name!",
      alertNameExceedCharactersLimit: "Alert Name exceed 100 characters limit!",
      status: "Status",
      selectDashboardFromList: "Please select a dashboard from the list!",
      selectLocationFromList: "Please select a location from the list!",
      selectInsightFromList: "Please select a insight from the list!",
      upTo: "Time in advance",
      selectTimeBeforeEvent: "Please select a up to time before event!",
      minutes: "minutes",
      hour: "hour",
      hours: "hours",
      day: "day",
      days: "days",
      notificationName: "Notification Name",
      notificationNameExceedCharactersLimit:
        "Notification Name exceed 100 characters limit!",
      description: "Description",
      inputLocationSummary: "Please input the location's summary",
      minimunEventDuration: "Minimum event duration",
      eventTimeRange: "Event time range",
      insightLocationTimezone: "*Insight location timezone",
      otherConfigurationOptions: "Other configuration options",
      configurationCommingSoon: "This configuration coming soon",
      eventStart: "Event start",
      eventFinish: "Event finish",
      recipient: "Recipient",
      errorGetUserAlert: "Error to get user for alert. Please contact us.",
      errorGetDashboardAlert:
        "Error to get dashboard for alert. Please contact us.",
      errorGetLocationAlert:
        "Error to get location for alert. Please contact us.",
      errorGetInsightAlert:
        "Error to get insight for alert. Please contact us.",
      errorGetAlertsData: "Error to get alerts data. Please contact us.",
      errorDeleteAlerts: "Error to delete alerts. Please contact us.",
      errorAddAlert: "Error to add alert. Please contact us.",
      errorUpdateAlert: "Error to update alert. Please contact us.",
      insightAlerts: "Insight Alerts",
      beforeEventsStart: "Before event start",
      notification: "Notification",
      areYouSureWantDeleteThisAlert:
        "Are you sure you want to delete this alert?",
      allUsers: "All Users",
      yes: "Yes",
      no: "No",
    },
    operationRule: {
      createDashboard: "Create a new Dashboard",
      youNeed4AlphaNumericToTheKey:
        "Oops! You need to use 4 alphanumeric characters to the key. ex: ABCD, ABC1, A1BC",
      informNameAndKeyForAdd: "Inform the name and key for add new dashboard.",
      informNameAndKeyForEdit: "Inform the name and key for edit.",
      removalFailedContactUs: "Removal failed. Contact us",
      diveIntoEditDashboard: "Dive into the edit dashboard.",
      operationInsight: "Operation Insight",
      dashboard: "Dashboard",
      name: "Name",
      key: "Key",
      pleaseInformNameOfDashboard: "Please inform the name of dashboard.",
      informDashboardName: "Inform dashboard name",
      pleaseInformKeyOfDashboard: "Please inform the key of dashboard.",
      add: "Add",
      newNameDashboard: "New name Dashboard",
      newKey: "New Key",
      save: "Save",
      cancel: "Cancel",
      edit: "Edit",
      areYouSureWantDeleteDashaboard:
        "Are you sure you want to delete this dashboard?",
      delete: "Delete",
      videoTutorials: "Video tutorials",
      errorToGetData: "Error to get data. Please contact us.",
      errorToAddData: "Error to add data. Please contact us.",
      needToInformIdForUpdateData: "Need to inform ID for update data",
      errorToUpdateData: "Error to update data. Please contact us.",
      needInformIdForDeleteData: "Need to inform ID for delete data",
      errorToDeleteData: "Error to delete data. Please contact us.",
      yes: "Yes",
      no: "No",
      startCreatingFirstInsight:
        "Start creating your first Insight by adding a dashboard",
      editDashboard: "Edit Dashboard",
      deleteDashboard: "Delete Dashboard",
      areYouSureCantUndoThis:
        "Are you sure? {{dashboardName}} will be deleted!",
      newDashboard: "New Dashboard",
      search: "Search",
      dashboardSuccessOnSave: "Success! Your dashboard has been saved",
    },
    operationRuleLocation: {
      youNeedSelectDashboardToAddLocation:
        "Oops! You need to select a dashboard to add a location",
      locationRemovalFailedContactUs: "Location removal failed. Contact us",
      editLocation: "Edit Location",
      operationInsight: "Operation Insight",
      location: "Location",
      diveEditDashboard: "diveEditDashboard",
      selectDashboard: "Select a Dashboard",
      addNewLocation: "Add New Location",
      edit: "Edit",
      areYouSureWantDeleteLocation:
        "Are you sure you want to delete this location?",
      yes: "Yes",
      no: "No",
      delete: "Delete",
      videoTutorials: "Video tutorials",
      add: "Add",
      cancel: "Cancel",
      youNeed4AlphaNumericToTheKey:
        "Oops! You need to use 4 alphanumeric characters to the key. ex: ABCD, ABC1, A1BC",
      name: "Name",
      pleaseInputLocationName: "Please input the location's name!",
      key: "Key",
      pleaseInputInitialNameLocation:
        "Please input the initial name for location!",
      inputLocationSummary: "Please input the location's summary",
      region: "Region",
      pleaseSelectRegionFromList: "Select a region",
      station: "Station",
      pleaseSelectStationFromList: "Select a station",
      errorGetLocationData: "Error to get location data. Please contact us.",
      errorAddLocationData: "Error to add location data. Please contact us.",
      errorUpdateLocationData:
        "Error to update location data. Please contact us.",
      errorDeleteLocationData: "Error to delete data. Please contact us.",
      summary: "Summary",
      addNewLocationToYourDashboard: "Add a new location to your dashboard.",
      deleteLocation: "Delete Location",
      areYouSureCantUndoThis: "Are you sure? {{locationName}} will be deleted!",
      save: "Save",
      youNeedToFillAllTheFields: "You need to fill in all fields",
      locationSuccessOnSave: "Success! Your location has been saved",
    },
    operationRuleLocationInsight: {
      youNeedSelectLocationToEditInsight:
        "Oops! You need to select a location to edit a insight",
      locationRemovalFailedContactUs: "Location removal failed. Contact us",
      youNeedSelectLocationToAddInsight:
        "Oops! You need to select a location to add a new insight",
      editInsight: "Edit Insight",
      operationInsight: "Operation Insight",
      insight: "Insight",
      diveEditDashboard: "Dive into the edit dashboard.",
      selectLocation: "Select a location",
      addNewInsight: "Add New Insight",
      edit: "Edit",
      areYouSureWantDeleteInsight:
        "Are you sure you want to delete this insight?",
      delete: "Delete",
      videoTutorials: "Video tutorials",
      informGroupConditionInsight:
        "Oops! Inform a group of condition of this insight.",
      informConditionGroupsInsight:
        "Oops! Inform a condition in the groups of this insight.",
      errorCriticalUpdateGroupConditions:
        "Error critical on update group conditions. Please, contact us!",
      add: "Add",
      name: "Name",
      restrictionType: "Restrictive",
      noRestrictionType: "Permissive",
      insightType: "Insight Type",
      tooltipRestrictionType:
        "Active: Insight with restrictive event conditions.  Inactive: Insight with permissive event conditions",
      pleaseInputInsightName: "Please input the insight's name!",
      stopNavigationForSmallBoats: "Stop navigation for small boats",
      high: "High",
      medium: "Medium",
      low: "Low",
      pleaseEnterSummary: "Please enter a summary",
      theNavigationShouldStoppedWindsTooStrong:
        "The navigation should be stopped because winds are too strong",
      conditions: "Conditions",
      conditionsInsideConditionGroupShouldHaveSameLogicalOperator:
        "Conditions inside condition group should have same logical operator. If you want different logical operators, please add another group",
      pleaseSelectEnvironmentalVariable:
        "Please select a environmental variable",
      isGreaterThan: "is greater than",
      isEqualToOrGreaterThan: "is equal to or greater than",
      isEqualTo: "is equal to",
      isLessThan: "is less than",
      isEqualToOrLessThan: "is equal to or less than",
      pleaseEnterValue: "Please enter the value!",
      addNewCondition: "New condition",
      removeGroup: "Remove group",
      addNewGroup: "New group",
      errorWhileGetDataForInsights:
        "Error while try get data for insights. Please contact us.",
      errorWhileAddInsight: "Error while try add insight. Please contact us.",
      internalErrorToUpdateData:
        "Internal error to update data. Please contact us.",
      errorDeleteInsight: "Error to delete the insight. Please contact us.",
      yes: "Yes",
      no: "No",
      cancel: "Cancel",
      error: "Error",
      details: "Details",
      priority: "Priority",
      summary: "Summary",
      location: "Location",
      and: "AND",
      or: "OR",
      weReAlmostTheNowRegisterInsight:
        "We're almost there! Now you just need to register your first insight",
      areYouSureCantUndoThis: "Are you sure? {{insightName}} will be deleted!",
      save: "Save",
      deleteInsight: "Delete Insight",
      group: "Group",
      type: "Type",
      youNeedToFillAllTheFields: "You need to fill in all required fields",
      addNewConditionGroupInsight:
        "Add a new group of weather conditions to create your insight",
      andVerifyConditionTrueOrVerifyAtLeastConditionTrue:
        "AND: Checks if all conditions are true. OR: Checks if at least one of the conditions is true.",
      groupsAreConditionsAddOneConditions:
        "Groups are sets of weather conditions. Add at least one weather condition for each created group",
      nameRequired: "Name is required",
      summaryRequired: "Summary is required",
      valueRequired: "Value is required",
      insightSuccessOnSave: "Success! Your Insight has been saved",
      deleteGroup: "Delete group",
      areYouSureWantDeleteGroup: "Are you sure you want to delete this group?",
    },
    insightManager: {
      insightManager: "Insights Manager",
      dashboards: "Dashboards",
      locations: "Locations",
      insights: "Insights",
    },
  },
  registerUser: {
    errorWhileLoadUserAreaOptions: "Error while load user area options",
    youHaveSuccessfullyRegistered:
      "Horaaaay! You have successfully registered and are now able to login!",
    errorWhileRegisterUser: "Error while register user",
    expiredInvitation: "Expired invitation",
    sorryThePageYouVisitedNotExist:
      "Sorry, the page you visited does not exist.",
    youInvitedJoinI4cast: "You've been invited to join i4cast®!",
    invitedBy: "Invited by",
    name: "Name",
    pleaseInputYourName: "Please input your name",
    nameMustContainOnlyLetters: "Name must contain only letters",
    lastName: "Last name",
    pleaseInputYourLastName: "Please input your last name",
    lastNameMustContainOnlyLetters: "Last name must contain only letters",
    username: "Username",
    pleaseInputYourUsername: "Please input your username!",
    lastNameMustStartWithLetter:
      "Must start with a letter, contain only alphanumeric characters, '_' and '.'",
    usernameAlreadyHasAccount: "Username already has an i4cast account",
    password: "Password",
    pleaseInputPassword: "Please input your password!",
    passwordMustContain: "Must contain at least 6 digits and one letter",
    phone: "Phone",
    jobArea: "Job area",
    jobPosition: "Job position",
    register: "Register",
    errorInInvitation: "Error in invitation",
  },
  settings: {
    unitMeasurement: "Unit of measurement",
    success: "Success !",
    yourChangesHaveSaved: "Your changes have been saved.",
    changingUnitStillNotAppliedOperationInsights:
      " Changing the units is still not being applied to the Operation Insights!",
    wind: "WIND",
    knots: "knots",
    ms: "m/s",
    mph: "mph",
    kmh: "km/h",
    current: "CURRENT",
    wave: "WAVE",
    m: "m",
    km: "km",
    ft: "ft",
    waterLevel: "WATER LEVEL",
    cm: "cm",
    precipitation: "PRECIPITATION",
    mm: "mm",
    inches: "inches",
    settings: "Settings",
    selectHowYouWantToSee: "Select how you want to view",
    visibility: "Visibility",
  },
  admin: {
    changePassword: {
      passwordNotMatch: "Password not match",
      successChangedPassword: "Success ! You changed your password",
      currentPassword: "Current password",
      newPassword: "New password",
      confirmNewPassword: "Confirm new password",
      updatePassword: "Update Password",
    },
    myData: {
      pleaseInformPhoneNumber: "Please, inform your phone number.",
      dataEditedSuccess: "Data edited with success",
      myData: "My Data",
      name: "Name",
      pleaseInputYourName: "Please input your name!",
      middleName: "Middle Name",
      lastName: "Last Name",
      pleaseInputYourLastName: "Please input your last name!",
      changePassword: "Change Password",
      email: "Email",
      pleaseInputYourEmail: "Please input your e-mail!",
      selectYourJobPosition: "Select your job position",
      pleaseSelectJobPosition: "Please select your job position from the list!",
      selectYourJobArea: "Select your job area",
      pleaseSelectJobArea: "Please select your job area from the list!",
      edit: "Edit",
      portuguese: "Portuguese",
      english: "english",
      errorGetUserData: "Error to get user data. Please contact us.",
      errorUpdateUserData: "Error to update user data. Please contact us.",
      errorGetUserPositionData:
        "Error to get user position data. Please contact us.",
      errorGetUserAreaData: "Error to get user area data. Please contact us.",
      success: "Success",
      errorUpdateUserLanguage:
        "Error to update user language. Please contact us.",
      error: "Error",
      phone: "Phone",
    },
    userManager: {
      yourInviteSuccessfullySent:
        "Hooraaay! Your invite was successfully sent and a new user is coming soon!",
      errorWhileSendInvitation:
        "Error while try send invitation. Please contact us.",
      userPermissionGroupSuccessfully:
        "User permission group successfully applied",
      errorWhileEditUser: "Error while try edit user. Please contact us.",
      userDataNotFoundOnList: "User data not found on list. Please contact us.",
      userDeactivatedSuccessfully: "User deactivated successfully",
      userReactivatedSuccessfully: "User reactivated successfully",
      errorWhileDeactivateUser:
        "Error while try deactivate user. Please contact us.",
      errorWhileReactivateUser:
        "Error while try reactivate user. Please contact us.",
      userManager: "User Manager",
      inviteUser: "Invite user",
      inviteNewUserFromOrganization:
        "Invite a new user from your organization to join i4cast®!",
      invite: "Invite",
      thisIsStillNotValidEmail: "This is still not a valid email!",
      pleaseInputYourEmail: "Please input your email!",
      emailAlreadyHasi4cast: "Email already has an i4cast account",
      userGroup: "User group",
      admin: "Admin",
      hasPermissionManageInviteUsers:
        "Has permission to manage and invite new users.",
      basic: "Basic",
      canAccessEditAllFeatures: "Can access and edit all i4cast features.",
      pleaseInput: "Please Input",
      granted: "Granted",
      denied: "Denied",
      name: "Name",
      username: "Username",
      email: "Email",
      permissionGroup: "Permission Group",
      accessToI4cast: "Access to i4cast®",
      areYouSureWantDeactivateUser:
        "Are you sure you want to deactivate this user?",
      areYouSureWantreactivateUser:
        "Are you sure you want to reactivate this user?",
      yes: "Yes",
      no: "No",
      deactivate: "Deactivate",
      reactivate: "Reactivate",
      ok: "Ok",
      reset: "Reset",
      invalidUserGroup: "Invalid user group",
      expiredInvitation: "Expired invitation",
      invitedAlreadyUsed: "Invite already used",
      thisEmailAlreadyInvited: "This email has already invited",
      thisEmailAlreadyUse: "This email is already in use",
      invitationNotFound: "Invitation not found",
      readOnly: "Read only",
      allowVisualizeData:
        "Allows you to view data and information without the ability to change it.",
    },
  },
  analytics: {
    waterLevel: "Water level",
    weather: "Weather",
    current: "Current",
    wind: "Wind",
    wave: "Wave",
    selectRegion: "Select a region",
    selectStation: "Select a station",
    sensorDataNotAvailable:
      "Sorry, but the sensor data for this station is not available.",
    errorGetSensorData:
      "Error to try get data for measured chart. Please contact us.",
    sensorData: "Sensor data",
    errorToTryGetDataStationOnAnalytics:
      "Error to try get data station on measured analytics chart type. Please contact us.",
    sorryButNoSensorsAreAvailableforData:
      "Sorry, but no sensors are available for this data type.",
  },
  atmOcean: {
    clickToGoBackPastForecasts: "Click to go back to past forecasts",
    youNeedSelectStationToExport:
      "Oops! You need to select a station to export the data.",
    waterFlow: "Water Flow",
    waveSea: "Wave Sea",
    waveSwell: "Wave Swell",
    selectStation: "Select a station",
    exportCsv: "Export .csv",
    time: "Time",
  },
  dynamicUKC: {
    dynamicClearanceNotAvailable:
      "Dynamic Under-Keel Clearance calculations are not available for you company. Would you like to request this functionality?",
    gotIt: "Got it!",
    skip: "skip",
    settings: "Settings",
    ukcRestriction: "UKC RESTRICTION",
    meters: "Meters",
    close: "Close",
    save: "Save",
    station: "Station",
    travelCode: "Travel code",
    dynamicUkcDocking: "Dynamic UKC - Docking",
    maneuverDraft: "Maneuver Draft",
    dynamicUkc: "Dynamic UKC",
    ukcRestrictionLower: "UKC restriction",
    dynamicUkcUndocking: " Dynamic UKC - Undocking",
    selectShip: "Select a ship",
    alert: "Alert",
    clickToBeRedirected: "Click below to be redirected to the Dynamic FAQ",
    gotToDynamicUkc: "Go to Dynamic UKC",
  },
  forecastChart: {
    forecastChartFormStation: {
      informStationDescription: "Inform a station description.",
      failedGetVirtualStationGeolocation:
        "Failed to get virtual station geolocation. Please contact us.",
      youAreOutOfRegionArea: "Oops! You are out of region area.",
      stationDescription: "Station description",
      save: "Save",
      doYouWantSaveStation: "Do you want to save station?",
      saveStation: "Save Station",
      areYouSureWantDeleteStation:
        "Are you sure you want to delete this station?",
      yes: "Yes",
      no: "No",
      requiredField: "Required field",
      invalidLatLng: "Invalid latitude and longitude",
      searchCoordsLatLng: "Search coordinates (latitude and longitude)",
      cancel: "Cancel",
      search: "Search",
      searchCoord: "Search coordinates",
      removeStation: "Remove station",
      unauthorizedUser:
        "Oops! It looks like you don't have permission to delete this station. Please get in touch with the system administrator",
      youOnlyHaveReadPermission: "You only have read permission",
    },
    forecastChartPage: {
      youAreOutOfRegionArea: "Oops! You are out of your region area.",
      thereIsAlreadyStationName: "There is already a station with this name:",
      errorToSaveVirtualStation:
        "Error to save virtual station. Please contact us.",
      successSationHasSaved: "Success! The station has been saved",
      errorToRemoveStation: "Error to remove station. Please contact us.",
      station: "Station:",
      errorToGetDataStationMeteogramChartType:
        "Error to try get data station on meteogram chart type. Please contact us.",
      errorToGetDataValidStationMeteogramChartType:
        "Error to try get data valid station on meteogram chart type. Please contact us.",
      stationWithInsight: "Station with Insight",
      clickBlueMarkersInTheMap:
        "Click on the blue markers in the map if you would like",
      toDisplayForecastChartWaveSpectra:
        " to display forecast charts with data type Wave Spectra ",
      letsGo: "Let's go",
      newEraForForecast: "A new era for forecasting",
      geospatialInsight: "Geospatial Insight",
      exploreGeospatialInsights:
        "Explore geospatial insights to understand weather conditions in your region. Make decisions with a complete view of weather conditions in strategic areas to optimize your operations and planning.",
      minimizeRisks: "Minimize risks",
      analysingPoints: "analyzing points around you",
      customAnalysis:
        "Analysis Personalization: Personalize your climate analyzes according to the selected Insights",
      newEraGeospatial: "A new geospatial era",
      inI4castPush: "in i4cast drives strategic decision-making.",
      insightForAllStations: "Insight for all stations",
      chooseInsight:
        "Choose an insight and navigate between stations to view it.",
      insightType: "Types of Insight",
      nowToCreateAnInsight:
        "Now, when you create an Insight, you can categorize it as 'Permissive' or 'Restrictive'. This means that when you select an Insight in the Forecast charts screen, it will be represented according to the chosen category as follows:",
      permissive: "Permissive",
      noEvent: "No event",
      warning: "Attention",
      withEvent: "With event",
      restrictive: "Restrictive",
      gotIt: "Understood",
      back: "Back",
      next: "Next",
      skip: "Skip",
      last: "Close",
      weWillGuideYouThrough:
        "We'll guide you through the Forecast Chart to expand your climate risk management.",
      clickToAccessYourList:
        "Access your list of insights or type the name to optimize the search. When selecting the insight, you will see the restriction or permission on the map.",
      youAreNowAble:
        "Now you can analyze different environmental conditions on the same graph. Click on the desired environmental condition to activate/deactivate it.",
      youCanViewTwoForecast:
        "You can view two forecast modes on the chart. Forecast Mode: The best hyper-local forecast is displayed. It is ideal for analyzing a specific scenario.",
      probabilityMode:
        "Probability mode [New✨]: Allows you to explore the probability of future scenarios occurring. It is ideal for analyzing different scenarios and planning dynamically.",
      sensorMode:
        "Sensor mode [New✨]: Allows you to explore the sensors that you have. It is ideal for analyzing your data.",
      theInneCircleDisplays:
        "The inner circle displays the predicted condition of the insight for the current period. The outer circle displays the predicted condition of the insight for the future period of the timeline.",
      dragTheTimeline:
        "Drag the timeline to view the insight prediction over time.",
      whenSlectingAnInsight:
        "When selecting an Insight, icons for nearby stations within a 5km radius will be swapped with colored circles representing Insight conditions, following the legend for easier interpretation.",
      forEachSelectedEnvironmental:
        "For each selected environmental condition, the best prediction (black line) is displayed together with the 50% and 80% probability of occurrence, and median (dashed yellow line). The closer the median and the smaller the spread of probabilities, the more reliable will be the future scenario.",
      ifTheViewedRegion:
        "If the viewed region lacks probability data, please contact us.",
      sensorForecastData:
        "Oops! No sensors here. Click on a spot with sensor icons to see the data.",
      noSensorWasFound: "No sensor detected for the selected variable.",
      stationsWithSensor:
        "✨News: Stations with sensor data will be highlighted on the map.",
      theMostRecentMeasuredData:
        "The most recent measured data will be immediately displayed on the map.",
      toIdentifyTheMeasured:
        "To identify the measured variable, hover your mouse over the sensor to see more details.",
      clickOnTheSensor:
        "Click on the sensor to view the measured data on the graph.",
      nowYouCanAccess:
        "You can now access the MetOcean forecast report directly from i4cast",
      justClickOnTheHighlighted: "Just click on the highlighted button",
      selectVariablesAndStations:
        "Select the variables and stations you want to view.",
      yourReportWillBeReady: "Your report will be ready in a few seconds. 📄",
      lastWarning: "One last warning 💡:",
      youWillBeRedirected:
        "you will be directed to another tab, so make sure your browser has not blocked the pop-up.",
      toGenerateMetoceanReport:
        "To generate the MetOcean forecast report, click here.",
    },
    forecastChartPanelHeader: {
      noData: "No insight found!",
      enterAtLeastThreeLetters: "Enter at least 3 letters",
      enterYourInsight: "Enter your Insight here",
      showInsightInForecastCharts: "Show Insight in Forecast Charts",
      insightWithoutData: "Stations without data",
      theGrayIconIndicates:
        "The gray icon indicates a lack of data for the selected Insight",
      thereIsNoInsightDataAvailable:
        "There is no insight data available for this station",
      noEventsForThisStation: "There are no events foreseen for this station",
      markAsFavorite: "Mark as favorite for quick access!",
      insightFavoriteSuccessOnSave:
        "Success! Your Insight has been marked as favorite",
      opsErrorMarkingInsightAsFavorite:
        "Oops! Error marking insight as favorite",
      insightFavoriteSuccessOnDelete:
        "Success! Your Insight has been marked as favorite",
      opsErrorOnRemoveInsightAsFavorite:
        "Oops! Error on remove insight as favorite",
      removeFavorite: "Remove favorite",
      selectStation: "Oops! Please select a station to show the insight!",
      selectInsightToEnable: "Select an insight to enable this visualization.",
    },
    forecastChartInsightTimeline: {
      swapToSetRanges: "Swipe to set ranges on the outer circles.",
      useTheCursorToAdjustTime:
        "Use the cursor to adjust time ranges in the outer circle. Drag to set new periods.",
    },
    forecastChartInsightLegend: {
      realTimeForecast: "Real-time forecast",
      forecastTimeline: "Timeline range",
      innerCircle: "Inner circle",
      outerCircle: "Outer circle",
    },
    forecastChartPanelEnvironmentalButtons: {
      weather: "Weather",
      wave: "Waves",
      visibility: "Visibility",
      waveHeight: "Wave Height",
      waveHeightMax: "Wave Height Max",
      wavePeakPeriod: "Peak Period",
      waveMeanPeriod: "Mean Period",
      waterFlow: "Water Flow",
      stationWithInsight: "Station with Insight",
      wind: "Wind",
      wind10m: "Wind 10m",
      wind50m: "Wind 50m",
      wind100m: "Wind 100m",
      gust10m: "Gust 10m",
      gust50m: "Gust 50m",
      gust100m: "Gust 100m",
      temperature: "Temperature",
      waveTotal: "Wave",
      waveSea: "Sea",
      waveSwell: "Swell",
      rain: "Precipitation",
    },
    forecastChartPanelMap: {
      searchLocationOrLatLon: "search location or lat, lon",
      exactLocation: "Exact location",
      insightDataUpdate: "Geospatial markers reset.",
      insightDataUpdateDescription:
        "Last insight calculation 15 minutes ago for this region. Data updated in selected area.",
      searchAddress: "Search address",
      noInsightData: "no Insight data",
      sensorDataIsDelayed: "Sensor data is delayed.",
      loading: "Loading",
      noRadarDataAvailable: "No radar data available.",
    },
    forecastChartPanelMeteogram: {
      all: "All",
      dataInterval: "Data Interval",
      refreshToseeNewData: "Refresh to see new data available",
      thereIsnotDataAvailable:
        "Oops! There isn't {{empty}} {{location}} {{empty2}} data available at this location.",
      thereIsnotProbabilisticDataAvailable:
        "Oops! There is no {{empty}} {{location}} {{empty2}} probabilistic data available at this location.",
      thereIsnotSensorDataAvailable:
        "Oops! There is no {{empty}} {{location}} {{empty2}} measured data available at this location.",
      somethingWrong: "Ops! Something went wrong!",
      lastUpdatedAt: "Last updated at",
      delayedSensor: "The sensor data is",
      nextUpdateEstimatedAt: "Next update estimated at",
      aboutForecast: "About the forecasts",
      aboutSensor: "About sensors",
      aboutProbabilistic: "About probability",
      hours: " hours",
      hour: " hour",
      min: "min",
      share: "Share forecast",
      newsOnForecast: "New in forecasts",
      nowItsPossibleShare:
        "Now it is possible to share forecasts with other people easily and quickly! By clicking on",
      onForecastScreen:
        "on the Forecast Charts screen, you can email your predictions.",
      close: "close",
      gotIt: "Got it",
      insights: "Insights",
      select: "Select",
      selectInsightFromList: "Select an Insight to be displayed on the chart",
      compareInsightsWithMeteogram: "Compare insights next to the meteogram!",
      selectInsight: "Select Insight",
      showInsight: "Show Insight",
      noEventsForThisStation: "There are no events foreseen for this station",
      toViewTheInsightSelectALongerPeriod:
        "In the next {{hours}}h there will be no event. To view the Insight select a longer period",
      nowYouCanVisualizeTheInsightOnMeteogram:
        "Now you can view your Insight along with the Meteogram! A station with Insight is represented on the map in green, just select it, choose the Insight you want to view and that's it! Your Insight will appear on the graph",
      noEvent: "no event",
      warning: "warning",
      withEvent: "with event",
      insightOff: "Insight off",
      insightCloseToBeingActivated: "Insight close to being activated",
      insightOn: "Insight on",
      clickHereToOpenTheGuide: "Click to learn more about the forecast.",
      confidence50: "50% confidence",
      confidence80: "80% confidence",
      median: "Median",
      windSpeed10m: "Wind speed 10m",
      windSpeed50m: "Wind speed 50m",
      significantWaveHeight: "Significant wave height",
      closeChart: "Close chart",
      unavailable: "Unavailable",
      deterministic: "Forecast",
      probabilistic: "Probability",
      windsGusts: "Winds/Gusts",
      preciptation: "Precipitation",
      waveHeights: "Wave heights",
      peakPeriod: "Peak period",
      wavePower: "Wave Power",
      clickOnMapToOpen: "Click on the map to open the chart again.",
      itAppearsThatYourRegion:
        "This region does not have probability data for the selected variable",
      contactOurTeamToEnsure:
        "Contact us so we can implement this feature to you!✨",
      thisRegionDoesNotHavePrecip:
        "This region does not have data on minimum and maximum precipitation or the probability of rain.",
      thisRegionDoesNotHaveVisibility:
        "This region does not have data on minimum, maximum visibility and probability of visibility",
      getInTouch: "Get in touch",
      forecastChart: "Forecast chart",
      probabilisticChart: "Probability chart",
      sensorChart: "Measured data chart",
      showTutorial: "Show tutorial",
      watchTutorial: "Watch tutorial",
      showTutorialsListButton: "See list of other tutorials",
      hideTutorialsListButton: "Hide extra tutorials",
      forecastTutorialLabel: "Show Forecast tutorial",
      geospatialTutorialLabel: "Show Geospatial tutorial",
      probabilisticTutorialLabel: "Show Probabilistic tutorial",
      initialSensorTutorialLabel: "Show Initial sensor tutorial",
      sensorTutorialLabel: "Show Sensor tutorial",
      selectStationAndInsight: "Select station and insight",
      selectStation: "Select station",
      metOceanForecastReport: "MetOcean Forecast Report",
      metOceanReportTutorialLabel: "Show Metocean Report tutorial",
      probability: "Probability",
      reflectivity: "Reflectivity (dBZ)",
      vweak: "V.WEAK",
      weak: "WEAK",
      moderate: "MODERATE",
      strong: "STRONG",
      vstrong: "V.STRONG",
      precipitationPotential: "Precipitation Potential (mm/h)",
      radarDataUnavailable: "Radar data unavailable. Please try again later.",
      waterLevel: "Water Level",
    },
    forecastChartPanelMeteogramEnvironmentalButtons: {
      wave: "Wave",
      waveSea: "Wave sea",
      waveSwell: "Wave Swell",
    },
    forecastChartPanelMeteogramWeatherButtons: {
      weather: "Wind",
      weatherTemperature: "Temperature",
    },
    forecastChartPastForecast: {
      noPastDataAvailable: "No past data available",
      pastForecast: "Past Forecasts",
      pastMeasurement: "Past measurements",
      time: "Time:",
    },
    forecastChartRequestSpectraStationButton: {
      yourRequestSuccessfullySent: "Your request was successfully sent!",
      requestSeaSwellAtThisLocation:
        "Request sea and swell waves at this location",
    },
    forecastChartMeteogramStore: {
      errorTryGetDataChartMeteogram:
        "Error to try get data for chart meteogram. Please contact us.",
      noUpcomingEvents: "NO UPCOMING EVENTS",
    },
    forecastChartProbabilisticStore: {
      errorTryGetData: "Error to try get data. Please contact us.",
    },
    metOceanReport: {
      metOceanForecastReport: "MetOcean Forecast Report",
      generate: "Generate",
      selectAtLeastOneVariable: "Select at least one variable and one station",
      selectAtLeastOneStation: "Select a station for the selected variable",
      selectTheVariablesAndStations:
        "Select the variables and stations you want to view",
      weather: "Weather",
      wave: "Wave",
      waterFlow: "Water flow",
      cancel: "Cancel",
      generatingForecast: "Generating forecast report...",
      pleaseWaitAMoment:
        "Please wait a moment, this may take a few seconds! ⏳",
      weWereUnableToOpen:
        "We were unable to open the PDF because the browser blocked pop-ups. Please enable pop-ups in your browser settings and try again.",
    },
  },
  portOfCallStore: {
    errorToGetOperationWindowData:
      "Error to get operation window data. Please contact us.",
    errorToGetDockingDynamicUkcData:
      "Error to get docking dynamic UKC data. Please contact us.",
  },
  unitMeasurementStore: {
    errorToAddUnitMeasurement:
      "Error to add unit measurement. Please contact us",
  },
  whatIsNew: {
    whatIsNew: "What's new!",
    markAllAsRead: "Mark all as read.",
  },
  share: {
    shareForecast: "Share forecast",
    period: "Period",
    all: "all",
    environmentalVariables: "Environmental variables",
    weather: "Weather",
    selectStation: "Select station",
    wave: "Wave",
    waterFlow: "Water Flow",
    recipients: "Recipients",
    sendMeThisEmail: "Send me this email",
    externalRecipients: "External recipients",
    inviteToI4cast: "Invite to i4cast",
    message: "Message (optional)",
    cancel: "Cancel",
    submt: "Submit",
    inThisFieldItsPossibleShareByEmail:
      "Share via email with people who are not yet part of i4cast.",
    separateEmailsWithComma:
      "Separate emails with a comma. Ex: john@i4sea.com, new@i4sea.com",
    selectAtLeastOneEnvironmentalVariable:
      "Select at least one environmental variable and one season.",
    emailsBelowAreNotValid: "The emails below are not valid.",
    selectAtLeastOneVariable: "Select at least one variable and a station",
    youMustSelectAtLeast:
      "You must select at least one internal or external recipient",
    selectAtLeastOneStation: "Select a station for the selected variable",
    successEmailHasSend: "Success! Your email has been sent",
    errorSendingEmail: "Error sending the e-mail",
    helloIWouldLikeToShare:
      "Hello, I would like to share with you the i4cast forecast data. Attached to this email.",
    thereIsnotDataInThisStation:
      "There is no data of type {{environmentalTypeTranslated}} on station: {{stationName}}. Please select another station",
    water_flow: "Water Flow",
    shareReportByEmail:
      "Share a forecast report via email without leaving i4cast!",
    forGuestUsersMustUseAnEmailDomain:
      "Invite new users to i4cast. Only users with your company's domain can be invited (Ex.: joao@i4sea.com). If you need to invite other users, please contact us.",
  },
  dualList: {
    selectAll: "Select all",
    notSelected: "Not Selected",
    deselectAll: "Deselect All",
    selected: "Selected",
    search: "Search",
  },
  occurrence: {
    reportOccurrence: "Report occurrence",
    reportOccurrenceDetail:
      "Report an occurrence that had impact on your operations. Based on this, our system will be able to identify and notify similar forecasts in the future.",
    cancel: "Cancel",
    save: "Save",
    selectStation: "Select nearest station to the location of the occurrence.",
    selectEnvironmentalVariables:
      "Select the environmental variables that may have impacted this occurrence.",
    ocurrencePeriod: "Occurrence period",
    singleDate: "Single date",
    period: "Start and end",
    description: "Occurence description",
    strongWinds: "Strong winds stops container operations",
    latitude: "Latitude",
    longitude: "Longitude",
    forecastHelp: "Was the i4cast forecast right?",
    wantToBeNotified:
      "I want to be notified when similar conditions are forecasted",
    yes: "Yes",
    no: "No",
    wind: "Wind",
    current: "Current",
    wave: "Wave",
    rain: "Rain",
    fog: "Fog",
    waterLevel: "Water Level",
    temperature: "Temperature",
    occurrences: "Occurrences",
    newOccurrence: "Report occurrence",
    search: "Search",
    descriptionRequired: "Description is required!",
    stationRequired: "Station is required!",
    latitudeRequired: "Latitude is required!",
    longitudeRequired: "Longitude is required!",
    selectAtleastOneVariable:
      "Select at least one environmental variable that may have impacted the occurrence.",

    successReport: "Occurrence successfully reported!",
    errorReport: "Error while report occurrence!",
    deleteOccurrence: "Delete Occurrence",
    delete: "Delete",
    areYouSureWantDelete: "Are you sure you want to delete this Occurrence?",
    errorEditOccurrence: "Error while editing occurrence!",
    successEditOccurrence: "Success! Your occurrence has been saved",
    errorYouNeedToSelectAnOccurrence: "Error! You need to select an occurrence",
    youDonyHaveAOccurrenceYet:
      "You don't have a occurrence yet. Click the button to report one occurrence",
    addOccurrence: "Report Occurrence",
    time: "Time:",
    selectDate: "Please select a date",
    dateRequired: "Date is required!",
    exactLocation:
      "Would you like to provide the exact location of the incident? (optional)",
    selectOption: "Select a option!",
    startDateMustBeLessThanEndDate:
      "The start date must be less than the end date",
    windInfo:
      "By selecting this option, i4cast will use wind speed, direction, and gust data to identify similar events.",
    waveInfo:
      "By selecting this option, i4cast will use significant wave height, direction, and peak period data to identify similar events.",
    rainInfo:
      "By selecting this option, i4cast will use precipitation data to identify similar events.",
    fogInfo:
      "By selecting this option, i4cast will use visibility data to identify similar events.",
    temperatureInfo:
      "By selecting this option, i4cast will use air temperature data to identify similar events.",
    waterLevelInfo:
      "By selecting this option, i4cast will use water level data to identify similar events.",
    currentInfo:
      "By selecting this option, i4cast will use current speed and direction data to identify similar events.",
    forecastCorrect:
      "In your opinion, did i4cast's forecast help you identify this occurrence?",
    occurrencesAreEventsThatImpactYourOperation:
      "Occurrences are events that can impact your operations. Report occurrences so that i4cast becomes smarter. We will identify similar forecasts in the future and you will be notified.",
    startHourIsRequired: "Start hour is required",
    endHourIsRequired: "End hour is required",
    importOccurrence: "Import occurrence",
    selectFileToImport: "Select a file to import (pdf, excel, word and txt):",
    import: "Import",
    successOnImport:
      "Success! We have received your file with the occurrences.",
    youNeedToChooseFile: "Select a file to import!",
    weAreProcessingTheWorkSheet:
      "We are processing the worksheet with the occurrences. We will notify you shortly as soon as they are completed.",
    theSelectedFileExceedsMaxium:
      "The selected file exceeds the maximum allowed size of 5MB.",
    importYourOccurrencesSpreadSheet:
      "Do you already have a document with a record of weather events? Import it here and we'll register it for you!",
    successfullyUploaded: "Successfully uploaded!",
    yourFileWithOccurrenceWasSent:
      "Your file with occurrences was sent successfully and as soon as they are registered in i4cast, you will be notified by email!",
    iGotIt: "I got it!",
    selectAFile: "Select file",
    selectedFile: "Selected file:",
    noFileSelected: "No file selected",
    newsInOccurrence: "News in occurrences",
    nowItsPossibleImportSpreadSheet:
      "It is now possible to import a spreadsheet or document with your occurrences, making registration easier and making i4cast smarter!",
    try: "Try",
  },
  feedback: {
    successSendFeedback: "Success! Your feedback has been sent",
    errorSendFeedback: "Error sending feedback",
    thisFieldIsRequired: "This field is required",
    send: "To send",
    yes: "Yes",
    no: "No",
    typeYourAnswerHere: "Type your answer here",
  },
  manageStation: {
    manageStation: "Station Manager",
    configureWhichStationViewHistoricalData:
      "Configure which stations you want to view historical data",
    name: "Name",
    region: "Region",
    latLon: "Location (lat, lon)",
    historicalData: "Historical data",
    search: "Search",
    station: "Station",
    page: "Page",
    of: "of",
    filterBy: "Filter by",
    forecastDataType: "Forecast Data Type",
    weather: "Weather",
    wave: "Wave",
    waveSpectra: "Wave Spectra",
    waveSea: "Wave Sea",
    waveSwell: "Wave Swell",
    waterFlow: "Water Flow",
    dataHistory: "Data history",
    environmentalType: "Environmental type",
    selectAtLeastOneEnvironmentalType: "Select at least one environmental type",
    save: "Save",
    successEditStation: "Success! Your station has been saved",
    errorEditStation: "Error editing station!",
    errorNoEnvironmentalDataAtStation:
      "There are environmental types not available for this station!",
    enterAtLeastThreeLetters: "Enter at least 3 letters",
    notFound: "Not found",
    clean: "Clean",
  },
  analyticsReporting: {
    period: "Analysis period",
    environmentalType: "Environmental Type",
    weather: "Weather",
    location: "Location",
    environmentalVariable: "Environmental variable",
    windSpeed: "Wind speed",
    direction: "Direction",
    minimum: "Minimum",
    maximum: "Maximum",
    knots: "knots",
    forecastAdvancePeriod: "Period of each forecast",
    before: "before",
    generateAnalysis: "Generate analysis",
    hoursEarned: "Hours earned",
    hoursGainIsHowMuchMoreAccuratei4castWas:
      "Hours gained is how much more accurate i4cast was relative to the global model in hours.",
    moreThanGlobal: "more assertive than the global model",
    eventsCaptured: "Captured events",
    fillINTheFieldsBelowViewData: "Fill in the fields below to view the data",
    eventsCapturedRepresentTheQuantityOfEvents:
      "Events captured represents the number of events that i4cast captured more than the global model",
    degrees: "degrees",
    region: "Region",
    selectRegion: "Select a region",
    selectLocation: "Select a location",
    selectEvent: "Select an event",
    isGreaterOrEqual: "Greater or equal",
    isLessOrEqual: "Less or equal",
    startDate: "Start date",
    endDate: "End date",
    requiredField: "Required field",
    isGreaterOrEqualThan: "Greater than or equal to",
    isLessOrEqualThan: "Less than or equal to",
    between: "in between",
    to: "to",
    globalModel: "Global Model",
    sensor: "Measured",
    numberOfEvents: "Number of events",
    events: "Events",
    comparisonOfi4castForecastPerformance:
      "Comparison of i4cast forecast performance with global forecast.",
    fillAtLeastOneField: "Fill in at least one of the fields",
    generatingAnalysis: "generating analysis...",
    moreAssertiveForecastHours: "More assertive forecast than the global model",
    weAreGeneratingAnalysis:
      "We are generating your analysis, this may take a few seconds.",
    weDoNotHaveDataAvailable:
      "We do not have data available for the selected filter. Enter new information to try again.",
    timeCalculation: "Time Calculation",
    timeCalculationIsAPercentageOfTime:
      "Time calculation represents the percentage of time the filtered condition was active in your region",
    ofTheTimeWithWinds: "of the time with winds",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    mostFrequently: "More frequent in",
    errorGeneratingAnalytics: "Error generating analysis",
    theMaximumValueMustBeGreater:
      "The maximum value must be greater or equal than the minimum value",
    ofTheTimeWithWindsGreaterOrEqual:
      "of the period with greater or equal wind",
    ofTheTimeWithWindsLessOrEqual: "of the period with less or equal wind",
    someMonthsMayNotBeDisplayed:
      "Some months may not be displayed on the chart due to insufficient data for performance analysis.",
    monthlyAverageWindSpeed: "Monthly average wind speed",
    windDirection: "Wind direction",
    average: "Average",
    standardDeviation: "Standard deviation",
    eventFrequency: "Event frequency",
    first: "First",
  },
  eventHistory: {
    selectStationAndDefinePeriod:
      "Select a station and initial date to view a comparative analysis of the forecast over a 5 day period.",
    eventHistory: "Event history",
    selectStation: "Select a station",
    startDate: "Initial date",
    endDate: "Final date",
    weather: "Weather",
    wave: "Wave",
    waterFlow: "Water flow",
    waterLevel: "Water level",
    current: "Current",
    sensor: "Sensor",
    global: "Global model",
    noGlobalModelDataFound:
      "No global model data was found for the selected filter",
    noSensorDataFound: "No sensor data was found for the selected filter",
    noi4castDataFound: "No i4cast data was found for the selected filter",
    selectStationToViewData: "Select a station to view data",
    environmentalVariable: "Environmental variable",
  },
  eventAnalysis: {
    eventAnalysis: "Event Analysis",
    occurrence: "Occurrence",
    period: "Period",
    location: "Location",
    weather: "Weather",
    wave: "Wave",
    waterFlow: "Water flow",
    wind: "Wind",
    gust: "Gust",
    rain: "Rain",
    minimumValue: "Minimum of",
    averageValue: "Average of",
    maxValue: "Maximum",
    accumulatedValue: "Accumulation",
    peakValue: "Peak at",
    averageWindGust: "Average Wind | Gust",
    maxWindGust: "Maximum Wind | Gust",
    averageWavePeriod: "Average Height | Period",
    maxWavePeriod: "Maximum Height | Period",
    minWaterFlowCurrent: "Minimum Current",
    maxWaterFlowCurrent: "Maximum Current",
    timeSeries: "Time series",
    theChartBelowShows:
      "The chart below represents a time series of raw data from the occurrence period.",
    significantWaveHeight: "Significant wave height",
    wavePeakPeriod: "Wave peak period",
    waterLevel: "Water level",
    current: "Current",
    selectOccurrence: "Select an occurrence",
    selectAnOccurrenceToBegin: "Select an occurrence to begin your analysis",
    knots: "knots",
    latitude: "latitude",
    longitude: "longitude",
    noi4castDataFound: "No i4cast data was found for the selected occurrence",
  },
  environmentalVariableStore: {
    windSpeed: "Wind - Speed ​​at 10m",
    windDirection: "Wind - Direction at 10m",
    windGusts: "Wind - Gust at 10m",
    waterLevel: "Water level",
    currentVelocity: "Current - Speed",
    currentDirection: "Current - Direction",
    waveHeight: "Wave - Significant Height",
    waveDirection: "Wave - Direction of the Peak",
    peakPeriod: "Wave - Peak Period",
    meanPeriod: "Wave - Middle Period",
    seaWaveHeight: "Wave Sea - Significant Height",
    seaWaveDirection: "Wave Sea - Peak Direction",
    seaPeakPeriod: "Wave Sea - Peak Period",
    swellWaveHeight: "Wave Swell - Significant Swell Height",
    swellWaveDirection: "Wave Swell - Peak Direction",
    swellPeakPeriod: "Wave Swell - Peak Swell Period",
    precipitation: "Precipitation",
    windSpeedAt80m: "Wind - Speed ​​at 50m",
    windDirectionAt80m: "Wind - Direction at 50m",
    windGustsAt80m: "Wind - Gust at 50m",
    windSpeedAt100m: "Wind - Speed ​​at 100m",
    windDirectionAt100m: "Wind - Direction at 100m",
    windGustsAt100m: "Wind - Gust at 100m",
    maximumWaveHeight: "Wave - Maximum Height",
    visibility: "Visibility",
    airTemperature: "Air temperature",
    dewPoint: "Dew point",
    precicip_min_20km: "Minimum and maximum precipitation",
    precicip_probability: "Precipitation probability",
  },
  environmentalVariableEnum: {
    weather: "Weather",
    weather_temperature: "Temperature",
    wave: "Waves",
    wave_sea: "Wind wave",
    wave_swell: "Swell wave",
    water_flow: "Water flow",
    wind_vel: "Wind 10m",
    wind_dir: "Direction 10m",
    gust: "Gust 10m",
    water_level: "Water level",
    current_vel: "Current velocity",
    current_dir: "Current direction",
    wave_height: "Wave - significant height",
    wave_dir: "Wave - direction",
    peak_period: "Wave - peak period",
    mean_period: "Wave - mean period",
    wave_height_sea: "Wind wave - height",
    wave_dir_sea: "Wind wave - direction",
    peak_period_sea: "Wind wave - peak period",
    wave_height_swell: "Swell wave - height",
    wave_dir_swell: "Swell wave - direction",
    peak_period_swell: "Swell wave - peak period",
    precip: "Precipitation",
    wind_vel_80m: "Wind 50m",
    wind_dir_80m: "Direction 50m",
    gust_80m: "Gust 50m",
    wind_vel_100m: "Wind 100m",
    wind_dir_100m: "Direction 100m",
    gust_100m: "Gust 100m",
    wave_height_max: "Wave - max height",
    visibility: "Visibility",
    probability: "Probability (%)",
    visibility_maximum_20km: "Visibility max",
    visibility_minimum_20km: "Minimum and maximum visibility",
    visibility_probability_1000m: "Prob. Visibility less than 1km (%)",
    visibility_probability_1500m: "Prob. Visibility less than 1.5km (%)",
    visibility_probability_2000m: "Prob. Visibility less than 2km (%)",
    temp_air: "Air temperature",
    dew_point: "Dew point",
    wave_power: "Power",
    mean_period_swell: "Swell wave - mean period",
    mean_period_sea: "Wind wave - mean period",
    rel_humidity: "Relative humidity at 2m",
    precip_min_20km: "Minimum and maximum precipitation",
    precip_probability: "Precipitation probability",
    precipitation: "Precipitation",
  },
  utils: {
    now: "Right now",
    minAgo: "{{minutes}}min ago",
    oneHourAgo: "1 hour ago",
    hoursAgo: "{{hours}}h ago",
    oneDayAgo: "1 day ago",
    daysAgo: "{{days}} days ago",
    longTimeAgo: "Long time",
  },
  riverForecastChart: {
    extremeHigh: "Extreme high",
    veryHigh: "Very high",
    high: "High",
    mostLikely: "Most likely",
    low: "Low",
    veryLow: "Very low",
    extremeLow: "Extreme low",
    sensor: "Sensor",
  },
  "404": {
    oopsPageNotFound: "Oops, page not found",
    goToLogin: "Go to login",
  },
  "500": {
    oopsAnErrorOccurred: "Oops, an error occurred",
    tryAgain: "Try again",
  },
};
