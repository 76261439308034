import { JoyrideStorage } from "enum/JoyrideStorage";
import { getForecastSteps } from "../steps/ForecastJoySteps";
import GuidedTutorial from "./GuidedTutorial";

function InitialGuidedTutorial({ shouldRunJoyride = false }) {
  return (
    <GuidedTutorial
      stepsProps={getForecastSteps()}
      storage={[
        JoyrideStorage.FORECAST_RIDE_ON_LOCAL_STORAGE,
        JoyrideStorage.INITIAL_SENSOR_RIDE_ON_LOCAL_STORAGE,
        JoyrideStorage.INITIAL_METOCEAN_REPORT_RIDE_ON_LOCAL_STORAGE,
      ]}
      shouldRunJoyride={shouldRunJoyride}
    />
  );
}

export default InitialGuidedTutorial;
