import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Flex,
  Modal as ModalChakra,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  useDisclosure,
  Text,
  Divider,
  Center,
  Box,
} from "@chakra-ui/react";
import { Modal, Form, Select, Spin } from "antd";

import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import LineChart from "../../components/LineChart/LineChart";
import { ManeuverAction } from "../../enum/ManeuverAction";
import { StoreState } from "../../enum/StoreState";
import { useStores } from "../../stores/setup/use-store";
import BuildIcon from "@material-ui/icons/Build";
import { useTranslation } from "react-i18next";
import { MacroRegion } from "../../enum/MacroRegion";
import { SCREEN_HORIZONTAL_SPACING } from "../../constants";

const DynamicUKC: React.FC = () => {
  const {
    dataStores: { portOfCallStore, regionStore },
    uiStores: { menuViewStore },
  } = useStores();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [meters, setMeters] = useState(portOfCallStore.ukcRestriction);
  const [isRedirected, setIsRedirected] = useState(false);

  const { t } = useTranslation("translation", {
    keyPrefix: "dynamicUKC",
  });

  useEffect(
    () => {
      const companyId = getCompanyId();
      if (companyId === "7" || companyId === "14") {
        portOfCallStore.getOperationWindowForDynamicDraftApi();
      } else {
        Modal.confirm({
          title: t("alert"),
          icon: <ExclamationCircleOutlined />,
          content: t("dynamicClearanceNotAvailable"),
          okText: t("gotIt"),
          cancelText: t("skip"),
          onOk: () => {
            window.open(
              "https://docs.google.com/forms/d/1BaF35M3TETCTG4QapZURWHXxmR5gdbd0wqi8HrfW54I/edit",
              "_blank"
            );
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (MacroRegion.PORT_OF_PECEM === regionStore.macroRegionMain?.origin) {
      menuViewStore.getTechnomarAuthApiUKC();
      setIsRedirected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionStore.macroRegionMain]);

  const getCompanyId = () => localStorage.getItem("companyId") || "0";

  const handlerOnChangeShip = (value: number) => {
    portOfCallStore.getManeuverUKCApi(value, ManeuverAction.DOCKING);
    portOfCallStore.getManeuverUKCApi(value, ManeuverAction.UNDOCKING);
    portOfCallStore.getClientOperationalWindowId(value);
  };

  const handleSaveMeters = () => {
    if (meters) {
      portOfCallStore.setUkcRestriction(meters);
    }
    onClose();
  };

  return (
    <Box mt={8} mx={SCREEN_HORIZONTAL_SPACING}>
      {isRedirected ? (
        <Box mt={12}>
          <Center>
            <Box>
              <Text fontSize="md" color={"black"}>
                {t("clickToBeRedirected")}
              </Text>
            </Box>
          </Center>
          <Center>
            <Button
              mt={4}
              layerStyle="ocean"
              _hover={{ layerStyle: "ocean" }}
              onClick={() => menuViewStore.getTechnomarAuthApiUKC()}
            >
              {t("gotToDynamicUkc")}
            </Button>
          </Center>
        </Box>
      ) : (
        <>
          <Spin spinning={portOfCallStore.state === StoreState.PEDDING}>
            <Form.Item
              key={`selectOperationWindow-${portOfCallStore.operationWindowSelected}`}
              name="selectOperationWindow"
              label={t("selectShip")}
              style={{ display: "inline-block", width: "65%" }}
            >
              <Flex>
                <Select
                  key={`selectOperationWindow-${portOfCallStore.operationWindowSelected}`}
                  defaultValue={
                    portOfCallStore.operationWindowSelected > 0
                      ? portOfCallStore.operationWindowSelected
                      : undefined
                  }
                  onChange={handlerOnChangeShip}
                >
                  {portOfCallStore.operationWindowForDynamicDraft.map(
                    (value) => (
                      <Select.Option value={value.id} key={value.id}>
                        {value.shipName} -{" "}
                        {DateTime.fromISO(value.estimatedTimeOfArrival, {
                          zone: "America/Bahia",
                        }).toFormat("dd/MM/yyyy HH:mm")}{" "}
                        and{" "}
                        {DateTime.fromISO(value.estimatedTimeOfDeparture, {
                          zone: "America/Bahia",
                        }).toFormat("dd/MM/yyyy HH:mm")}{" "}
                        - Berth: {value.berthDescription}
                      </Select.Option>
                    )
                  )}
                </Select>

                <Button
                  leftIcon={<BuildIcon fontSize="small" />}
                  color="white"
                  backgroundColor={"#1E1853"}
                  onClick={onOpen}
                  size="sm"
                  ml={4}
                  _hover={{ backougroundColor: "#474087" }}
                >
                  {t("settings")}
                </Button>
              </Flex>
              <ModalChakra isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent color={"#000000"}>
                  <ModalHeader>{t("ukcRestriction")}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    {t("meters")}
                    <NumberInput
                      variant="outline"
                      //@ts-ignore
                      placeholder={t("meters") as string}
                      value={meters}
                      onChange={(m) => setMeters(m)}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </ModalBody>

                  <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={onClose}>
                      {t("close")}
                    </Button>
                    <Button colorScheme="blue" onClick={handleSaveMeters}>
                      {t("save")}
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </ModalChakra>
            </Form.Item>

            {getCompanyId() === "7" && (
              <Flex>
                <Text fontWeight="bold" fontSize="md" color={"black"}>
                  {t("station")}:
                </Text>
                <Text ml={1} fontSize="md" color={"black"}>
                  Ponto 14.3 (-12.9500, -38.5238)
                </Text>
              </Flex>
            )}
            {getCompanyId() === "14" && (
              <Flex>
                <Text fontWeight="bold" fontSize="md" color={"black"}>
                  {t("station")}:
                </Text>
                <Text ml={1} fontSize="md" color={"black"}>
                  Ponto restrição 15.2m
                </Text>
              </Flex>
            )}
            <Flex>
              <Text fontWeight="bold" fontSize="md" color={"black"}>
                {t("travelCode")}:
              </Text>
              <Text color={"black"} fontSize="md" ml={1}>
                {portOfCallStore.clientOperationWindowId}
              </Text>
            </Flex>
          </Spin>
          <Spin spinning={portOfCallStore.stateDocking === StoreState.PEDDING}>
            <Center>
              <Text fontSize={"2xl"} color={"black"}>
                {t("dynamicUkcDocking")}
              </Text>
            </Center>
            <Center>
              {portOfCallStore.maneuverUKCDocking.draft && (
                <Text fontSize={"md"} color={"black"}>
                  {t("maneuverDraft")}:{" "}
                  {portOfCallStore.maneuverUKCDocking.draft}m
                </Text>
              )}
            </Center>
            <LineChart
              options={{
                time: {
                  useUTC: true,
                  timezoneOffset: 180,
                },
                chart: {
                  zoomType: "xy",
                  height: 260,
                },
                title: {
                  text: "",
                },
                xAxis: {
                  type: "datetime",
                  gridLineWidth: 1,
                  labels: { format: "{value:%d. %b %H:%M}" },
                },
                yAxis: {
                  min: 0,
                  max: (
                    portOfCallStore.maneuverUKCDocking.limits.max * 1.2
                  ).toFixed(),
                  title: {
                    text: t("meters"),
                  },
                },
                tooltip: {
                  valueDecimals: 2,
                  valueSuffix:
                    " " + portOfCallStore.maneuverUKCDocking.serie.units,
                },
                series: [
                  {
                    data: portOfCallStore.maneuverUKCDocking.serie.data,
                    name: t("dynamicUkc"),
                  },
                  {
                    type: "line",
                    color: "red",
                    marker: {
                      enabled: false,
                    },
                    name: t("ukcRestrictionLower"),
                    data: portOfCallStore.getUKCRestrictionLine(
                      ManeuverAction.DOCKING
                    ),
                  },
                ],
              }}
            />
          </Spin>
          <Divider mb={4} mt={4} />
          <Spin
            spinning={portOfCallStore.stateUndocking === StoreState.PEDDING}
          >
            <Center>
              <Text fontSize={"2xl"} color={"black"}>
                {t("dynamicUkcUndocking")}
              </Text>
            </Center>
            <Center>
              {portOfCallStore.maneuverUKCUndocking.draft && (
                <Text fontSize={"md"} color={"black"}>
                  {t("maneuverDraft")}:{" "}
                  {portOfCallStore.maneuverUKCUndocking.draft}m
                </Text>
              )}
            </Center>
            <LineChart
              options={{
                time: {
                  useUTC: true,
                  timezoneOffset: 180,
                },
                chart: {
                  zoomType: "xy",
                  height: 260,
                  type: "spline",
                },
                title: {
                  text: "",
                },
                xAxis: {
                  type: "datetime",
                  gridLineWidth: 1,
                  labels: { format: "{value:%d. %b %H:%M}" },
                },
                yAxis: {
                  min: 0,
                  max: (
                    portOfCallStore.maneuverUKCUndocking.limits.max * 1.2
                  ).toFixed(),
                  title: {
                    text: t("meters"),
                  },
                },
                tooltip: {
                  valueDecimals: 2,
                  valueSuffix:
                    " " + portOfCallStore.maneuverUKCUndocking.serie.units,
                },
                series: [
                  {
                    data: portOfCallStore.maneuverUKCUndocking.serie.data,
                    name: t("dynamicUkc"),
                  },
                  {
                    type: "line",
                    color: "red",
                    marker: {
                      enabled: false,
                    },
                    name: t("ukcRestrictionLower"),
                    data: portOfCallStore.getUKCRestrictionLine(
                      ManeuverAction.UNDOCKING
                    ),
                  },
                ],
              }}
            />
          </Spin>
        </>
      )}
    </Box>
  );
};
export default observer(DynamicUKC);
