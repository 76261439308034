import { Step } from "react-joyride";

import JoyrideContent from "../../../../components/JoyrideContent/JoyrideContent";
import guideGeospatial from "../../../../assets/guide-geospatial.jpg";
import i18n from "../../../../i18n";

export const geospatialJoySteps: Step[] = [
  {
    target: ".joyride-geoespatial-legend",
    disableBeacon: true,
    content: (
      <JoyrideContent
        text={
          i18n.t(
            "forecastChart.forecastChartPage.theInneCircleDisplays"
          ) as string
        }
      />
    ),
  },
  {
    target: ".joyride-timeline",
    content: (
      <JoyrideContent
        text={
          i18n.t("forecastChart.forecastChartPage.dragTheTimeline") as string
        }
      />
    ),
  },
  {
    target: ".joyride-geospatial",
    placement: "center",
    content: (
      <JoyrideContent
        image={{
          src: guideGeospatial,
          alt: "Geospatial guide",
        }}
        text={
          i18n.t(
            "forecastChart.forecastChartPage.whenSlectingAnInsight"
          ) as string
        }
      />
    ),
  },
];
