import { DateTime } from "luxon";

export function roundToNearestHour(dateTime: number) {
  const currentDateTime = DateTime.fromMillis(dateTime);
  const minute = currentDateTime.minute;

  if (minute >= 30) {
    return currentDateTime.plus({ hours: 1 }).startOf("hour");
  } else {
    return currentDateTime.startOf("hour");
  }
}
