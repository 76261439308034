import api from "./api";
import { IStation, IManageStationTable, IStationsFilter } from "../stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import { CancelToken } from "axios";

export default class ManageStationService {
  async getStations({ limit, offset, station_description, macroregion_description }: IStationsFilter, cancelToken?: CancelToken): Promise<IManageStationTable> {
    const result = await api.post(`v1/station-manager/load-stations/limit/${limit}/offset/${offset}`,
      {
        station_description,
        macroregion_description
      },
      { cancelToken });

    return result.data;
  }
  async updateStation({ station_id, region_id, environmentalTypes, data_history }: IStation): Promise<{ success: boolean }> {
    const result = await api.put(`/v1/station-manager/station/${station_id}`, {
      region_id,
      environmental_types: environmentalTypes,
      data_history
    });
    return result.data;
  }
}
