import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Center, Flex, Grid, GridItem, Progress, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../stores/setup/use-store";
import waveHeightIcon from "../../../assets/event-analysis-card/wave-height.svg";
import wavePeriodIcon from "../../../assets/event-analysis-card/wave-period.svg";
import arrowIcon from "../../../assets/event-analysis-card/arrow-up.svg";
import Card from "./components/Card";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { divIcon } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import Compass from "./components/Compass";
import { IStation } from "../../../stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import { IOccurrenceDescription } from "../../../types/IOccurrence";
import { StoreState } from "../../../enum/StoreState";
import { transformGeo } from "../../../util/meteogramCharts/transformGeo/transformGeo";
import { EnvironmentalType } from "../../../enum/EnvironmentalType";
import MapLegend from "./components/MapLegend";
import Meteogram from "./components/Meteogram";

interface WaveTabProps {
  selectedOccurrenceDescription: IOccurrenceDescription | undefined;
  station: IStation | undefined;
}

const WaveTab: React.FC<WaveTabProps> = ({
  selectedOccurrenceDescription,
  station,
}) => {
  const {
    dataStores: { occurrenceStore },
  } = useStores();

  const { t } = useTranslation("translation", {
    keyPrefix: "eventAnalysis",
  });

  const [stationMarkerPosition, setStationMarkerPosition] =
    useState<
      | {
          lat: number;
          lng: number;
        }
      | undefined
    >();

  const averageMaxData = useMemo(() => {
    if (
      occurrenceStore.waveData &&
      Object.keys(occurrenceStore.waveData as object).length
    ) {
      return occurrenceStore.waveData;
    } else {
      return null;
    }
  }, [occurrenceStore.waveData]);

  useEffect(() => {
    if (occurrenceStore.occurrence) {
      const { initialDate, finalDate, stationId, regionId } =
        occurrenceStore.occurrence;

      occurrenceStore.getOccurrenceAnalysis({
        regionId,
        stationId,
        environmentalType: EnvironmentalType.WAVE,
        initialDate,
        finalDate,
      });
    }

    if (station) {
      setStationMarkerPosition({
        lat: station?.station_lat,
        lng: station?.station_lon,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOccurrenceDescription]);

  return (
    <>
      <Grid templateColumns={"repeat(10, 1fr)"} gap={4}>
        <GridItem colSpan={3}>
          <Flex flexDir="column" gap={4}>
            <Card
              icon={waveHeightIcon}
              title={t("significantWaveHeight")}
              textLeft={t("averageValue")}
              textRight={t("maxValue")}
              valueLeft={
                averageMaxData
                  ? averageMaxData?.waveHeightData?.average.toFixed(1) +
                    " " +
                    "m"
                  : ""
              }
              valueRight={
                averageMaxData
                  ? averageMaxData?.waveHeightData?.max.toFixed(1) + " " + "m"
                  : ""
              }
              iconLeft={arrowIcon}
              iconLeftRotation={
                averageMaxData
                  ? averageMaxData?.waveDirectionAverage + 180
                  : undefined
              }
              bottomLeftText={
                averageMaxData
                  ? transformGeo(averageMaxData?.waveDirectionAverage)
                  : undefined
              }
              iconRight={arrowIcon}
              iconRightRotation={
                averageMaxData
                  ? averageMaxData?.waveDirectionMax + 180
                  : undefined
              }
              bottomRightText={
                averageMaxData
                  ? transformGeo(averageMaxData?.waveDirectionMax)
                  : undefined
              }
              isLoading={
                selectedOccurrenceDescription &&
                occurrenceStore.state === StoreState.PEDDING
              }
            />

            <Card
              icon={wavePeriodIcon}
              title={t("wavePeakPeriod")}
              textLeft={t("averageValue")}
              textRight={t("maxValue")}
              valueLeft={
                averageMaxData
                  ? averageMaxData?.peakPeriodData?.average.toFixed(1) +
                    " " +
                    "s"
                  : ""
              }
              valueRight={
                averageMaxData
                  ? averageMaxData?.peakPeriodData?.max.toFixed(1) + " " + "s"
                  : ""
              }
              iconLeft={arrowIcon}
              iconLeftRotation={
                averageMaxData
                  ? averageMaxData?.waveDirectionAverage + 180
                  : undefined
              }
              bottomLeftText={
                averageMaxData
                  ? transformGeo(averageMaxData?.waveDirectionAverage)
                  : undefined
              }
              iconRight={arrowIcon}
              iconRightRotation={
                averageMaxData
                  ? averageMaxData?.waveDirectionMax + 180
                  : undefined
              }
              bottomRightText={
                averageMaxData
                  ? transformGeo(averageMaxData?.waveDirectionMax)
                  : undefined
              }
              isLoading={
                selectedOccurrenceDescription &&
                occurrenceStore.state === StoreState.PEDDING
              }
            />
          </Flex>
        </GridItem>
        <GridItem rowSpan={10} colSpan={7}>
          {occurrenceStore.occurrence ? (
            occurrenceStore.waveChart &&
            Object.keys(occurrenceStore.waveChart as object).length ? (
              <>
                <MapLegend
                  averageColor="#9D53F2"
                  averageText={t("averageWavePeriod") as string}
                  maxColor="#286793"
                  maxText={t("maxWavePeriod") as string}
                />

                <MapContainer
                  key="mapStation"
                  center={stationMarkerPosition}
                  zoom={12}
                  scrollWheelZoom={false}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: 8,
                  }}
                >
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {stationMarkerPosition && averageMaxData && (
                    <>
                      <Marker
                        position={stationMarkerPosition}
                        icon={divIcon({
                          html: renderToStaticMarkup(
                            <i
                              style={{
                                color: "#000",
                                marginLeft: "-8px",
                                marginTop: "-10px",
                              }}
                              className="fas fa-map-marker-alt fa-2x"
                            />
                          ),
                        })}
                      ></Marker>
                      <Marker
                        position={stationMarkerPosition}
                        icon={divIcon({
                          html: renderToStaticMarkup(
                            <Compass
                              maxDegrees={averageMaxData?.waveDirectionMax?.toString()}
                              maxPointerText={`${averageMaxData?.waveHeightData?.max.toFixed(
                                1
                              )}m | ${averageMaxData?.peakPeriodData?.max.toFixed(
                                1
                              )}s`}
                              averageDegrees={averageMaxData?.waveDirectionAverage?.toString()}
                              averagePointerText={`${averageMaxData?.waveHeightData?.average?.toFixed(
                                1
                              )}m | ${averageMaxData?.peakPeriodData?.average?.toFixed(
                                1
                              )}s`}
                            />
                          ),
                        })}
                      ></Marker>
                    </>
                  )}
                </MapContainer>
              </>
            ) : (
              <Center
                h="100%"
                w="100%"
                borderRadius={8}
                bg="white"
                color="newBlue.500"
              >
                {occurrenceStore.state === StoreState.PEDDING ? (
                  <Progress w="500px" size="xs" isIndeterminate />
                ) : (
                  <Text>{t("noi4castDataFound")}</Text>
                )}
              </Center>
            )
          ) : !selectedOccurrenceDescription ? (
            <Center
              h="100%"
              w="100%"
              borderRadius={8}
              bg="white"
              color="newBlue.500"
            >
              <Text>{t("selectAnOccurrenceToBegin")}</Text>
            </Center>
          ) : (
            <Center
              h="100%"
              w="100%"
              borderRadius={8}
              bg="white"
              color="newBlue.500"
            >
              {occurrenceStore.state === StoreState.PEDDING && (
                <Progress w="500px" size="xs" isIndeterminate />
              )}
            </Center>
          )}
        </GridItem>
      </Grid>

      <Meteogram
        description={selectedOccurrenceDescription}
        chart={occurrenceStore.waveChart}
        timezone={
          occurrenceStore.waveChart?.data?.plotOptions?.series.timezoneOffset
        }
        chartXAxis={occurrenceStore.i4castChartXAxis}
        chartYAxis={occurrenceStore.i4castChartYAxis}
      />
    </>
  );
};
export default observer(WaveTab);
