import { EnvironmentalVariables } from "../../../../enum/EnvironmentalVariables";
import { VisualizationType } from "../../../../enum/VisualizationType";
import i18n from "../../../../i18n";
import { ISerieConfigMap } from "./types/ISerieConfigMap";

export const getWaterFlowConfigMap = (
  type: VisualizationType
): Record<string, ISerieConfigMap> => {
  const forecastColors: Partial<Record<EnvironmentalVariables, string>> = {
    [EnvironmentalVariables.CURRENT_VEL]: "#ff3333",
    [EnvironmentalVariables.WATER_LEVEL]: "#337ab7",
    [EnvironmentalVariables.CURRENT_DIR]: "#ff3333",
  };

  const measurementColors: Partial<Record<EnvironmentalVariables, string>> = {
    [EnvironmentalVariables.CURRENT_VEL]: "#226373",
    [EnvironmentalVariables.WATER_LEVEL]: "#54D3C0",
    [EnvironmentalVariables.CURRENT_DIR]: "#226373",
  };

  const getColor = (
    variable: EnvironmentalVariables,
    type: VisualizationType
  ) => {
    let colorMap: Record<string, string>;
    switch (type) {
      case VisualizationType.FORECAST:
        colorMap = forecastColors;
        break;
      case VisualizationType.SENSOR:
        colorMap = measurementColors;
        break;
      default:
        colorMap = {};
        break;
    }
    return colorMap[variable.toLowerCase()] || "#347ED5";
  };

  return {
    [EnvironmentalVariables.CURRENT_VEL]: {
      serieConfig: {
        color: getColor(EnvironmentalVariables.CURRENT_VEL, type),
        keys: ["x", "y", "direction"],
        yAxis: 1,
        updateYAxis: true,
        tooltip: {
          formatDirection: true,
          reverseDirection: true,
        },
      },
      yAxisConfig: {
        title: {
          text: i18n.t("environmentalVariableStore.currentVelocity"),
          style: {
            color: "#000000",
          },
        },
        min: 0,
        max: 5,
        gridLineWidth: 1,
        opposite: true,
      },
    },
    [EnvironmentalVariables.WATER_LEVEL]: {
      serieConfig: {
        color: getColor(EnvironmentalVariables.WATER_LEVEL, type),
        yAxis: 0,
        updateYAxis: true,
      },
      yAxisConfig: {
        title: {
          text: i18n.t("environmentalVariableStore.waterLevel"),
          style: {
            color: "#000000",
          },
        },
        min: 0,
        max: 2.5,
        gridLineWidth: 1,
      },
    },
    [EnvironmentalVariables.CURRENT_DIR]: {
      serieConfig: {
        type: "windbarb",
        color: getColor(EnvironmentalVariables.CURRENT_DIR, type),
        tooltip: {
          formatDirection: false,
        },
      },
    },
  };
};
