import React from "react";
import LineChart from "../../../../components/LineChart/LineChart";
import useWindowObserver from "../../../../hooks/useWindowObserver";

const ProbabilityChartMock: React.FC = () => {
  const windowSize = useWindowObserver(".main-area");

  return (
    <LineChart
      style={{ maxWidth: windowSize.width }}
      timezoneOffset={180}
      options={{
        chart: {
          zoomType: "xy",
          height: "290px",
          backgroundColor: "#ffffff",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          type: "datetime",
          gridLineWidth: 1,
          currentDateIndicator: {
            color: "#54f542",
            width: 2,
            zIndex: 4,
            dashStyle: "dash",
          },
        },

        yAxis: {
          labels: {
            style: {
              color: "#00000",
            },
          },
          title: {},
        },
        legend: {
          itemStyle: {
            fontSize: "14px",
            fontFamily: "'Hind Vadodara', 'Helvetica Neue', sans-serif",
            fontWeight: "normal",
            color: "#00000",
          },
        },

        plotOptions: {
          series: {
            label: {
              enabled: true,
              onArea: false,
              style: {
                fontFamily: "monospace",
                fontSize: "0.95em",
                color: "#2f517a",
                fontWeight: "bold",
              },
            },
          },
        },
        line: {
          color: "#ffff",
        },
        series: [
          {
            name: "deterministic",
            zIndex: 3,
            label: {
              enabled: false,
            },
            marker: {
              enabled: false,
            },
            color: "#495154",
            lineWidth: 3,
            gridLineWidth: 1,
            data: [
              [1715630400000, 0.9430541396141052],
              [1715634000000, 1.0706435441970825],
              [1715637600000, 1.161594033241272],
              [1715641200000, 1.2269141674041748],
              [1715644800000, 1.2650628089904785],
              [1715648400000, 1.2788994312286377],
              [1715652000000, 1.2728455066680908],
              [1715655600000, 1.266229510307312],
              [1715659200000, 1.2779111862182617],
              [1715662800000, 1.2920777797698975],
              [1715666400000, 1.3030635118484497],
              [1715670000000, 1.3091098070144653],
              [1715673600000, 1.306014895439148],
              [1715677200000, 1.2816638946533203],
              [1715680800000, 1.2486114501953125],
              [1715684400000, 1.2023236751556396],
              [1715688000000, 1.1488615274429321],
              [1715691600000, 1.1092606782913208],
              [1715695200000, 1.0927945375442505],
              [1715698800000, 1.1090281009674072],
              [1715702400000, 1.1624966859817505],
              [1715706000000, 1.2316328287124634],
              [1715709600000, 1.3145205974578857],
              [1715713200000, 1.3819977045059204],
              [1715716800000, 1.4399648904800415],
              [1715720400000, 1.5829999446868896],
              [1715724000000, 1.656000018119812],
              [1715727600000, 1.6660000085830688],
              [1715731200000, 1.6460000276565552],
              [1715734800000, 1.6139999628067017],
              [1715738400000, 1.5679999589920044],
              [1715742000000, 1.5230000019073486],
              [1715745600000, 1.4819999933242798],
              [1715749200000, 1.4390000104904175],
              [1715752800000, 1.3849999904632568],
              [1715756400000, 1.3370000123977661],
              [1715760000000, 1.2999999523162842],
              [1715763600000, 1.2740000486373901],
              [1715767200000, 1.25],
              [1715770800000, 1.2059999704360962],
              [1715774400000, 1.1540000438690186],
              [1715778000000, 1.1180000305175781],
              [1715781600000, 1.1540000438690186],
              [1715785200000, 1.2359999418258667],
              [1715788800000, 1.2869999408721924],
              [1715792400000, 1.371000051498413],
              [1715796000000, 1.4420000314712524],
              [1715799600000, 1.4819999933242798],
              [1715803200000, 1.5119999647140503],
              [1715806800000, 1.5520000457763672],
              [1715810400000, 1.5880000591278076],
              [1715814000000, 1.6319999694824219],
              [1715817600000, 1.6490000486373901],
              [1715821200000, 1.6619999408721924],
              [1715824800000, 1.6640000343322754],
              [1715828400000, 1.659999966621399],
              [1715832000000, 1.6419999599456787],
              [1715835600000, 1.6269999742507935],
              [1715839200000, 1.621999979019165],
              [1715842800000, 1.6239999532699585],
              [1715846400000, 1.6150000095367432],
              [1715850000000, 1.5859999656677246],
              [1715853600000, 1.5529999732971191],
              [1715857200000, 1.5160000324249268],
              [1715860800000, 1.4789999723434448],
              [1715864400000, 1.4830000400543213],
              [1715868000000, 1.5440000295639038],
              [1715871600000, 1.6019999980926514],
              [1715875200000, 1.7309999465942383],
              [1715878800000, 1.8170000314712524],
              [1715882400000, 1.8489999771118164],
              [1715886000000, 1.8660000562667847],
              [1715889600000, 1.9329999685287476],
              [1715893200000, 1.99399995803833],
              [1715896800000, 2.002000093460083],
              [1715900400000, 2.055999994277954],
              [1715904000000, 2.0759999752044678],
              [1715907600000, 2.0480000972747803],
              [1715911200000, 1.9859999418258667],
              [1715914800000, 1.9140000343322754],
              [1715918400000, 1.8300000429153442],
              [1715922000000, 1.75],
              [1715925600000, 1.6859999895095825],
              [1715929200000, 1.6319999694824219],
              [1715932800000, 1.5829999446868896],
              [1715936400000, 1.5410000085830688],
              [1715940000000, 1.503000020980835],
              [1715943600000, 1.465000033378601],
              [1715947200000, 1.4600000381469727],
              [1715950800000, 1.4759999513626099],
              [1715954400000, 1.5080000162124634],
              [1715958000000, 1.534999966621399],
              [1715961600000, 1.5640000104904175],
              [1715965200000, 1.5980000495910645],
              [1715968800000, 1.63100004196167],
              [1715972400000, 1.6670000553131104],
              [1715976000000, 1.7050000429153442],
              [1715979600000, 1.694000005722046],
              [1715983200000, 1.6649999618530273],
              [1715986800000, 1.6200000047683716],
              [1715990400000, 1.5709999799728394],
              [1715994000000, 1.5149999856948853],
              [1715997600000, 1.4539999961853027],
              [1716001200000, 1.4129999876022339],
              [1716004800000, 1.38100004196167],
              [1716008400000, 1.3569999933242798],
              [1716012000000, 1.3480000495910645],
              [1716015600000, 1.3459999561309814],
              [1716019200000, 1.3450000286102295],
              [1716022800000, 1.3220000267028809],
              [1716026400000, 1.284000039100647],
              [1716030000000, 1.2430000305175781],
              [1716033600000, 1.190999984741211],
              [1716037200000, 1.1360000371932983],
              [1716040800000, 1.0770000219345093],
              [1716044400000, 1.0199999809265137],
              [1716048000000, 0.9786999821662903],
              [1716051600000, 0.9477999806404114],
              [1716055200000, 0.9420999884605408],
              [1716058800000, 0.947700023651123],
              [1716062400000, 0.9609000086784363],
              [1716066000000, 0.9732999801635742],
              [1716069600000, 0.9883000254631042],
              [1716073200000, 1.0069999694824219],
              [1716076800000, 1.0149999856948853],
              [1716080400000, 1.0190000534057617],
              [1716084000000, 1.0160000324249268],
              [1716087600000, 1.0130000114440918],
              [1716091200000, 1.0069999694824219],
              [1716094800000, 1.0010000467300415],
              [1716098400000, 0.9957000017166138],
              [1716102000000, 0.9921000003814697],
              [1716105600000, 0.9904999732971191],
              [1716109200000, 0.9472000002861023],
              [1716112800000, 0.8748000264167786],
              [1716116400000, 0.8008000254631042],
              [1716120000000, 0.727400004863739],
              [1716123600000, 0.6563000082969666],
              [1716127200000, 0.5885000228881836],
              [1716130800000, 0.5346999764442444],
              [1716134400000, 0.49559998512268066],
              [1716138000000, 0.4700999855995178],
              [1716141600000, 0.45910000801086426],
              [1716145200000, 0.46399998664855957],
              [1716148800000, 0.4828000068664551],
              [1716152400000, 0.49869999289512634],
              [1716156000000, 0.5102999806404114],
              [1716159600000, 0.5177000164985657],
              [1716163200000, 0.5217999815940857],
              [1716166800000, 0.5231000185012817],
              [1716170400000, 0.522599995136261],
              [1716174000000, 0.5194000005722046],
              [1716177600000, 0.5185999870300293],
              [1716181200000, 0.5231999754905701],
              [1716184800000, 0.5339000225067139],
            ],
          },
          {
            name: "median",
            zIndex: 4,
            label: {
              enabled: true,
            },
            marker: {
              enabled: false,
            },
            color: "#e7990bc4",
            dashStyle: "shortDot",
            lineWidth: 3,
            gridLineWidth: 1,
            data: [
              [1715630400000, 1.3197898864746094],
              [1715634000000, 1.365079641342163],
              [1715637600000, 1.3656136989593506],
              [1715641200000, 1.3453847169876099],
              [1715644800000, 1.3275858163833618],
              [1715648400000, 1.299370527267456],
              [1715652000000, 1.2734861373901367],
              [1715655600000, 1.262709379196167],
              [1715659200000, 1.2438654899597168],
              [1715662800000, 1.2458990812301636],
              [1715666400000, 1.258137822151184],
              [1715670000000, 1.2627878189086914],
              [1715673600000, 1.2413349151611328],
              [1715677200000, 1.207411766052246],
              [1715680800000, 1.1618084907531738],
              [1715684400000, 1.1229380369186401],
              [1715688000000, 1.0904247760772705],
              [1715691600000, 1.0506887435913086],
              [1715695200000, 1.0290439128875732],
              [1715698800000, 1.0453076362609863],
              [1715702400000, 1.0869035720825195],
              [1715706000000, 1.1591510772705078],
              [1715709600000, 1.2201149463653564],
              [1715713200000, 1.3157079219818115],
              [1715716800000, 1.3760818243026733],
              [1715720400000, 1.4212894439697266],
              [1715724000000, 1.4375141859054565],
              [1715727600000, 1.4373633861541748],
              [1715731200000, 1.4194004535675049],
              [1715734800000, 1.3975353240966797],
              [1715738400000, 1.3652029037475586],
              [1715742000000, 1.3357142210006714],
              [1715745600000, 1.3083066940307617],
              [1715749200000, 1.2872655391693115],
              [1715752800000, 1.2633790969848633],
              [1715756400000, 1.2431281805038452],
              [1715760000000, 1.2149165868759155],
              [1715763600000, 1.1748902797698975],
              [1715767200000, 1.1429457664489746],
              [1715770800000, 1.1155738830566406],
              [1715774400000, 1.0867249965667725],
              [1715778000000, 1.1196246147155762],
              [1715781600000, 1.123488426208496],
              [1715785200000, 1.1390546560287476],
              [1715788800000, 1.1837964057922363],
              [1715792400000, 1.2359051704406738],
              [1715796000000, 1.299483060836792],
              [1715799600000, 1.3653130531311035],
              [1715803200000, 1.432597041130066],
              [1715806800000, 1.464186191558838],
              [1715810400000, 1.484961748123169],
              [1715814000000, 1.505976915359497],
              [1715817600000, 1.5059471130371094],
              [1715821200000, 1.4973814487457275],
              [1715824800000, 1.490962028503418],
              [1715828400000, 1.4707486629486084],
              [1715832000000, 1.4489171504974365],
              [1715835600000, 1.4342970848083496],
              [1715839200000, 1.4218878746032715],
              [1715842800000, 1.411102533340454],
              [1715846400000, 1.39616060256958],
              [1715850000000, 1.3607909679412842],
              [1715853600000, 1.3305683135986328],
              [1715857200000, 1.3175780773162842],
              [1715860800000, 1.339318037033081],
              [1715864400000, 1.3999629020690918],
              [1715868000000, 1.462040662765503],
              [1715871600000, 1.5080887079238892],
              [1715875200000, 1.5362584590911865],
              [1715878800000, 1.5725219249725342],
              [1715882400000, 1.626845121383667],
              [1715886000000, 1.697633147239685],
              [1715889600000, 1.721106767654419],
              [1715893200000, 1.7386974096298218],
              [1715896800000, 1.7266560792922974],
              [1715900400000, 1.720429539680481],
              [1715904000000, 1.6909313201904297],
              [1715907600000, 1.653658390045166],
              [1715911200000, 1.6077560186386108],
              [1715914800000, 1.5559486150741577],
              [1715918400000, 1.4958720207214355],
              [1715922000000, 1.4533263444900513],
              [1715925600000, 1.4352984428405762],
              [1715929200000, 1.3800653219223022],
              [1715932800000, 1.3276716470718384],
              [1715936400000, 1.2740174531936646],
              [1715940000000, 1.2532200813293457],
              [1715943600000, 1.229244351387024],
              [1715947200000, 1.1895983219146729],
              [1715950800000, 1.1952348947525024],
              [1715954400000, 1.189441204071045],
              [1715958000000, 1.2094919681549072],
              [1715961600000, 1.238581895828247],
              [1715965200000, 1.252510666847229],
              [1715968800000, 1.2580256462097168],
              [1715972400000, 1.2503376007080078],
              [1715976000000, 1.262513518333435],
              [1715979600000, 1.2631409168243408],
              [1715983200000, 1.2419826984405518],
              [1715986800000, 1.2208242416381836],
              [1715990400000, 1.1996660232543945],
              [1715994000000, 1.1743924617767334],
              [1715997600000, 1.1595075130462646],
              [1716001200000, 1.1426928043365479],
              [1716004800000, 1.1184170246124268],
              [1716008400000, 1.0849215984344482],
              [1716012000000, 1.0536384582519531],
              [1716015600000, 1.0263041257858276],
              [1716019200000, 0.9923124313354492],
              [1716022800000, 0.9476664066314697],
              [1716026400000, 0.8980882167816162],
              [1716030000000, 0.8535321354866028],
              [1716033600000, 0.7872615456581116],
              [1716037200000, 0.7353851795196533],
              [1716040800000, 0.7058290243148804],
              [1716044400000, 0.676171600818634],
              [1716048000000, 0.670214056968689],
              [1716051600000, 0.6724505424499512],
              [1716055200000, 0.6770364046096802],
              [1716058800000, 0.6729282736778259],
              [1716062400000, 0.7092428803443909],
              [1716066000000, 0.7245156168937683],
              [1716069600000, 0.7151172757148743],
              [1716073200000, 0.7110047340393066],
              [1716076800000, 0.7219096422195435],
              [1716080400000, 0.7075695991516113],
              [1716084000000, 0.7007109522819519],
              [1716087600000, 0.7008450031280518],
              [1716091200000, 0.6807225346565247],
              [1716094800000, 0.6696416735649109],
              [1716098400000, 0.6626924872398376],
              [1716102000000, 0.6392807364463806],
              [1716105600000, 0.6113483905792236],
              [1716109200000, 0.5958586931228638],
              [1716112800000, 0.5604932904243469],
              [1716116400000, 0.5355973839759827],
              [1716120000000, 0.4858262538909912],
              [1716123600000, 0.47888845205307007],
              [1716127200000, 0.4729711413383484],
              [1716130800000, 0.46541690826416016],
              [1716134400000, 0.46652838587760925],
              [1716138000000, 0.45638200640678406],
              [1716141600000, 0.4766272306442261],
              [1716145200000, 0.4760046601295471],
              [1716148800000, 0.5084452629089355],
              [1716152400000, 0.5196285247802734],
              [1716156000000, 0.5355497598648071],
              [1716159600000, 0.5693916082382202],
              [1716163200000, 0.588030993938446],
              [1716166800000, 0.5876840353012085],
              [1716170400000, 0.6115300059318542],
              [1716174000000, 0.6141477823257446],
              [1716177600000, 0.6352932453155518],
              [1716181200000, 0.6406115293502808],
              [1716184800000, 0.6421664953231812],
            ],
          },
          {
            name: "confidence50",
            type: "arearange",
            lineWidth: 0,
            linkedTo: ":previous",
            color: "rgb(44, 175, 254)",
            fillOpacity: 0.4,
            zIndex: 2,
            marker: {
              enabled: false,
            },
            legend: {
              enabled: true,
            },
            data: [
              [1715630400000, 1.2901504039764404, 1.3559160232543945],
              [1715634000000, 1.3195536136627197, 1.3948088884353638],
              [1715637600000, 1.3339747190475464, 1.393534779548645],
              [1715641200000, 1.3239015340805054, 1.3747122287750244],
              [1715644800000, 1.307354211807251, 1.3523943424224854],
              [1715648400000, 1.2797558307647705, 1.3263936042785645],
              [1715652000000, 1.2548750638961792, 1.2988059520721436],
              [1715655600000, 1.2358932495117188, 1.2819671630859375],
              [1715659200000, 1.231593132019043, 1.268635869026184],
              [1715662800000, 1.2357509136199951, 1.2717182636260986],
              [1715666400000, 1.2435920238494873, 1.276179313659668],
              [1715670000000, 1.242713451385498, 1.2805043458938599],
              [1715673600000, 1.2166647911071777, 1.261498212814331],
              [1715677200000, 1.1710867881774902, 1.2189483642578125],
              [1715680800000, 1.1401865482330322, 1.183739185333252],
              [1715684400000, 1.09663987159729, 1.158300757408142],
              [1715688000000, 1.0655728578567505, 1.1276776790618896],
              [1715691600000, 1.0329525470733643, 1.0717664957046509],
              [1715695200000, 1.0074682235717773, 1.0534098148345947],
              [1715698800000, 1.008836269378662, 1.080028772354126],
              [1715702400000, 1.0558257102966309, 1.137745976448059],
              [1715706000000, 1.1127784252166748, 1.1964975595474243],
              [1715709600000, 1.1758816242218018, 1.2642463445663452],
              [1715713200000, 1.2445447444915771, 1.345571756362915],
              [1715716800000, 1.3293753862380981, 1.419556736946106],
              [1715720400000, 1.3766429424285889, 1.4675227403640747],
              [1715724000000, 1.3870160579681396, 1.4860137701034546],
              [1715727600000, 1.392488718032837, 1.479842185974121],
              [1715731200000, 1.3815784454345703, 1.4595019817352295],
              [1715734800000, 1.3647581338882446, 1.4281260967254639],
              [1715738400000, 1.3399455547332764, 1.396531105041504],
              [1715742000000, 1.3119417428970337, 1.3581371307373047],
              [1715745600000, 1.283228874206543, 1.3319311141967773],
              [1715749200000, 1.2589855194091797, 1.3144253492355347],
              [1715752800000, 1.2379440069198608, 1.2938830852508545],
              [1715756400000, 1.214514970779419, 1.2736550569534302],
              [1715760000000, 1.189119815826416, 1.2532267570495605],
              [1715763600000, 1.1542906761169434, 1.2319278717041016],
              [1715767200000, 1.1186164617538452, 1.1901898384094238],
              [1715770800000, 1.0827802419662476, 1.1715917587280273],
              [1715774400000, 1.0578081607818604, 1.1584869623184204],
              [1715778000000, 1.0433927774429321, 1.1546998023986816],
              [1715781600000, 1.052431583404541, 1.1825919151306152],
              [1715785200000, 1.069678544998169, 1.215312123298645],
              [1715788800000, 1.1081066131591797, 1.262129783630371],
              [1715792400000, 1.1590161323547363, 1.3234407901763916],
              [1715796000000, 1.2094076871871948, 1.3785476684570312],
              [1715799600000, 1.2962126731872559, 1.4584362506866455],
              [1715803200000, 1.374132752418518, 1.5084137916564941],
              [1715806800000, 1.4049272537231445, 1.5532746315002441],
              [1715810400000, 1.430194616317749, 1.557721495628357],
              [1715814000000, 1.4388492107391357, 1.5643235445022583],
              [1715817600000, 1.4499214887619019, 1.5604305267333984],
              [1715821200000, 1.453560709953308, 1.550112009048462],
              [1715824800000, 1.4309800863265991, 1.547810673713684],
              [1715828400000, 1.4206840991973877, 1.5233983993530273],
              [1715832000000, 1.407211422920227, 1.5098341703414917],
              [1715835600000, 1.3892725706100464, 1.499929666519165],
              [1715839200000, 1.370060920715332, 1.497102975845337],
              [1715842800000, 1.3578847646713257, 1.4883983135223389],
              [1715846400000, 1.340086817741394, 1.4599424600601196],
              [1715850000000, 1.304582953453064, 1.4192808866500854],
              [1715853600000, 1.267791986465454, 1.388845443725586],
              [1715857200000, 1.2511918544769287, 1.3890236616134644],
              [1715860800000, 1.260055422782898, 1.427547812461853],
              [1715864400000, 1.2867077589035034, 1.4842478036880493],
              [1715868000000, 1.3159937858581543, 1.5455946922302246],
              [1715871600000, 1.3558788299560547, 1.5718010663986206],
              [1715875200000, 1.4277520179748535, 1.6171350479125977],
              [1715878800000, 1.47944176197052, 1.6593241691589355],
              [1715882400000, 1.5230436325073242, 1.6976292133331299],
              [1715886000000, 1.5718464851379395, 1.7735122442245483],
              [1715889600000, 1.611088752746582, 1.7725739479064941],
              [1715893200000, 1.6327295303344727, 1.8107359409332275],
              [1715896800000, 1.6428446769714355, 1.8484318256378174],
              [1715900400000, 1.6297458410263062, 1.8467597961425781],
              [1715904000000, 1.5944139957427979, 1.8006057739257812],
              [1715907600000, 1.5674450397491455, 1.7444536685943604],
              [1715911200000, 1.5295850038528442, 1.675008773803711],
              [1715914800000, 1.4728046655654907, 1.6139541864395142],
              [1715918400000, 1.4181396961212158, 1.5613200664520264],
              [1715922000000, 1.3666071891784668, 1.5294322967529297],
              [1715925600000, 1.3423887491226196, 1.4957315921783447],
              [1715929200000, 1.2891596555709839, 1.450100064277649],
              [1715932800000, 1.2351409196853638, 1.4052248001098633],
              [1715936400000, 1.176093339920044, 1.3676347732543945],
              [1715940000000, 1.134117603302002, 1.3309077024459839],
              [1715943600000, 1.084834098815918, 1.318444848060608],
              [1715947200000, 1.0373191833496094, 1.2838531732559204],
              [1715950800000, 1.0709856748580933, 1.3020566701889038],
              [1715954400000, 1.1055551767349243, 1.3109149932861328],
              [1715958000000, 1.1119736433029175, 1.3338936567306519],
              [1715961600000, 1.1164298057556152, 1.36650812625885],
              [1715965200000, 1.1270915269851685, 1.3822835683822632],
              [1715968800000, 1.1355841159820557, 1.3993902206420898],
              [1715972400000, 1.1254878044128418, 1.4091285467147827],
              [1715976000000, 1.122419834136963, 1.3753877878189087],
              [1715979600000, 1.1344670057296753, 1.3530269861221313],
              [1715983200000, 1.111484408378601, 1.3332042694091797],
              [1715986800000, 1.103965401649475, 1.3202815055847168],
              [1715990400000, 1.089381217956543, 1.3077247142791748],
              [1715994000000, 1.0472259521484375, 1.2804081439971924],
              [1715997600000, 1.0102862119674683, 1.2536264657974243],
              [1716001200000, 0.9707387685775757, 1.216649055480957],
              [1716004800000, 0.9426012635231018, 1.2027775049209595],
              [1716008400000, 0.9136483669281006, 1.1799120903015137],
              [1716012000000, 0.8887145519256592, 1.1682509183883667],
              [1716015600000, 0.8513862490653992, 1.1183314323425293],
              [1716019200000, 0.8183563947677612, 1.0724166631698608],
              [1716022800000, 0.7865955829620361, 1.03249990940094],
              [1716026400000, 0.7440000176429749, 0.9814544320106506],
              [1716030000000, 0.7014043927192688, 0.933041512966156],
              [1716033600000, 0.6423474550247192, 0.8789682388305664],
              [1716037200000, 0.6060361862182617, 0.8407070636749268],
              [1716040800000, 0.582885205745697, 0.8198885917663574],
              [1716044400000, 0.5453342199325562, 0.7914918065071106],
              [1716048000000, 0.5280972719192505, 0.7676600813865662],
              [1716051600000, 0.5176119804382324, 0.7611120939254761],
              [1716055200000, 0.5099067687988281, 0.7936398983001709],
              [1716058800000, 0.5516623258590698, 0.7959567308425903],
              [1716062400000, 0.5908747315406799, 0.7949141263961792],
              [1716066000000, 0.6293613314628601, 0.8140716552734375],
              [1716069600000, 0.618886411190033, 0.8056639432907104],
              [1716073200000, 0.6211613416671753, 0.8089410662651062],
              [1716076800000, 0.620528519153595, 0.8259450197219849],
              [1716080400000, 0.6086483001708984, 0.8058010339736938],
              [1716084000000, 0.5906956195831299, 0.8073195219039917],
              [1716087600000, 0.5839259624481201, 0.8016526699066162],
              [1716091200000, 0.5734989643096924, 0.7930251955986023],
              [1716094800000, 0.5677375197410583, 0.7879547476768494],
              [1716098400000, 0.5493348836898804, 0.7764588594436646],
              [1716102000000, 0.5309526920318604, 0.7555561065673828],
              [1716105600000, 0.5140421390533447, 0.7346532940864563],
              [1716109200000, 0.499511182308197, 0.7081911563873291],
              [1716112800000, 0.48285359144210815, 0.6816326975822449],
              [1716116400000, 0.4604394733905792, 0.665059506893158],
              [1716120000000, 0.4296833276748657, 0.6424186825752258],
              [1716123600000, 0.42112845182418823, 0.6425889134407043],
              [1716127200000, 0.4140497148036957, 0.6308023929595947],
              [1716130800000, 0.400620698928833, 0.622329592704773],
              [1716134400000, 0.38800790905952454, 0.6122386455535889],
              [1716138000000, 0.36719948053359985, 0.6166484355926514],
              [1716141600000, 0.3427884578704834, 0.6318053007125854],
              [1716145200000, 0.35193145275115967, 0.6460399627685547],
              [1716148800000, 0.3750156760215759, 0.6836006045341492],
              [1716152400000, 0.404157429933548, 0.726270318031311],
              [1716156000000, 0.42376282811164856, 0.7583894729614258],
              [1716159600000, 0.4442528486251831, 0.7865720987319946],
              [1716163200000, 0.4496171772480011, 0.7955150604248047],
              [1716166800000, 0.45600438117980957, 0.7881602644920349],
              [1716170400000, 0.46239161491394043, 0.8169649243354797],
              [1716174000000, 0.4687788486480713, 0.8549419641494751],
              [1716177600000, 0.4789781868457794, 0.8649976253509521],
              [1716181200000, 0.4820455312728882, 0.8674263954162598],
              [1716184800000, 0.48511290550231934, 0.8676071166992188],
            ],
          },
          {
            name: "confidence80",
            type: "arearange",
            lineWidth: 0,
            linkedTo: ":previous",
            color: "rgb(44, 175, 254)",
            fillOpacity: 0.3,
            zIndex: 1,
            marker: {
              enabled: false,
            },
            data: [
              [1715630400000, 1.266509771347046, 1.3723163604736328],
              [1715634000000, 1.3000571727752686, 1.412622332572937],
              [1715637600000, 1.3081024885177612, 1.4058676958084106],
              [1715641200000, 1.3084485530853271, 1.3887544870376587],
              [1715644800000, 1.2851558923721313, 1.3631393909454346],
              [1715648400000, 1.2600464820861816, 1.3400708436965942],
              [1715652000000, 1.2380927801132202, 1.3165597915649414],
              [1715655600000, 1.222577452659607, 1.303479552268982],
              [1715659200000, 1.203016996383667, 1.3012830018997192],
              [1715662800000, 1.2056857347488403, 1.30094313621521],
              [1715666400000, 1.2159030437469482, 1.3199193477630615],
              [1715670000000, 1.2191798686981201, 1.3308212757110596],
              [1715673600000, 1.1970901489257812, 1.2926958799362183],
              [1715677200000, 1.1492013931274414, 1.257265567779541],
              [1715680800000, 1.1147183179855347, 1.2399500608444214],
              [1715684400000, 1.0803496837615967, 1.2043592929840088],
              [1715688000000, 1.0423510074615479, 1.1658909320831299],
              [1715691600000, 1.0023441314697266, 1.1122187376022339],
              [1715695200000, 0.9897351861000061, 1.095477819442749],
              [1715698800000, 0.9749118089675903, 1.1043169498443604],
              [1715702400000, 1.010383129119873, 1.1653714179992676],
              [1715706000000, 1.0669878721237183, 1.242398977279663],
              [1715709600000, 1.1244447231292725, 1.3226386308670044],
              [1715713200000, 1.2115156650543213, 1.4213459491729736],
              [1715716800000, 1.272947072982788, 1.4716342687606812],
              [1715720400000, 1.324737787246704, 1.501961350440979],
              [1715724000000, 1.3642003536224365, 1.5067747831344604],
              [1715727600000, 1.371528148651123, 1.5091960430145264],
              [1715731200000, 1.3627071380615234, 1.4921832084655762],
              [1715734800000, 1.3428486585617065, 1.4708757400512695],
              [1715738400000, 1.3058863878250122, 1.4331161975860596],
              [1715742000000, 1.2756524085998535, 1.3927953243255615],
              [1715745600000, 1.2519869804382324, 1.3630168437957764],
              [1715749200000, 1.2313015460968018, 1.3405267000198364],
              [1715752800000, 1.210829257965088, 1.327622890472412],
              [1715756400000, 1.1806175708770752, 1.3142318725585938],
              [1715760000000, 1.1538019180297852, 1.2946631908416748],
              [1715763600000, 1.119429588317871, 1.2734434604644775],
              [1715767200000, 1.0797855854034424, 1.230927586555481],
              [1715770800000, 1.0539395809173584, 1.2000045776367188],
              [1715774400000, 1.0300753116607666, 1.1837208271026611],
              [1715778000000, 1.0234442949295044, 1.203947901725769],
              [1715781600000, 1.0287232398986816, 1.2092983722686768],
              [1715785200000, 1.0431629419326782, 1.2708499431610107],
              [1715788800000, 1.068432092666626, 1.3479046821594238],
              [1715792400000, 1.115071415901184, 1.4322960376739502],
              [1715796000000, 1.1646612882614136, 1.4616395235061646],
              [1715799600000, 1.2262886762619019, 1.4979498386383057],
              [1715803200000, 1.304480791091919, 1.5683293342590332],
              [1715806800000, 1.3458809852600098, 1.6212050914764404],
              [1715810400000, 1.3727270364761353, 1.649609923362732],
              [1715814000000, 1.3978451490402222, 1.661223292350769],
              [1715817600000, 1.4069628715515137, 1.6814831495285034],
              [1715821200000, 1.391158103942871, 1.6646133661270142],
              [1715824800000, 1.3729355335235596, 1.6259982585906982],
              [1715828400000, 1.354506492614746, 1.5928326845169067],
              [1715832000000, 1.3481190204620361, 1.5883913040161133],
              [1715835600000, 1.3354319334030151, 1.5735670328140259],
              [1715839200000, 1.3306279182434082, 1.540754795074463],
              [1715842800000, 1.3236830234527588, 1.5381330251693726],
              [1715846400000, 1.2914557456970215, 1.5067492723464966],
              [1715850000000, 1.2441294193267822, 1.472596287727356],
              [1715853600000, 1.209045648574829, 1.4256243705749512],
              [1715857200000, 1.1901936531066895, 1.4412686824798584],
              [1715860800000, 1.213173508644104, 1.4676251411437988],
              [1715864400000, 1.2550855875015259, 1.5057315826416016],
              [1715868000000, 1.2794970273971558, 1.5983030796051025],
              [1715871600000, 1.312593936920166, 1.6629480123519897],
              [1715875200000, 1.3616466522216797, 1.6884937286376953],
              [1715878800000, 1.445007562637329, 1.7429113388061523],
              [1715882400000, 1.4646044969558716, 1.798050045967102],
              [1715886000000, 1.5095596313476562, 1.8648760318756104],
              [1715889600000, 1.549680471420288, 1.9128347635269165],
              [1715893200000, 1.5741961002349854, 1.9310989379882812],
              [1715896800000, 1.563748836517334, 1.9364385604858398],
              [1715900400000, 1.5521678924560547, 1.9128334522247314],
              [1715904000000, 1.5277910232543945, 1.866590976715088],
              [1715907600000, 1.4825196266174316, 1.8386229276657104],
              [1715911200000, 1.437319278717041, 1.7584748268127441],
              [1715914800000, 1.4059147834777832, 1.6573193073272705],
              [1715918400000, 1.3624804019927979, 1.6150789260864258],
              [1715922000000, 1.3279221057891846, 1.5788999795913696],
              [1715925600000, 1.288130760192871, 1.550054907798767],
              [1715929200000, 1.2312301397323608, 1.5227917432785034],
              [1715932800000, 1.1803756952285767, 1.4760448932647705],
              [1715936400000, 1.1257402896881104, 1.4311769008636475],
              [1715940000000, 1.0810983180999756, 1.3996940851211548],
              [1715943600000, 1.0413713455200195, 1.3677163124084473],
              [1715947200000, 0.9986282587051392, 1.3528788089752197],
              [1715950800000, 0.9692250490188599, 1.3650912046432495],
              [1715954400000, 0.9455971121788025, 1.3798377513885498],
              [1715958000000, 0.924058198928833, 1.421689748764038],
              [1715961600000, 0.9685075283050537, 1.4207253456115723],
              [1715965200000, 1.0241718292236328, 1.4326789379119873],
              [1715968800000, 1.0088366270065308, 1.4534536600112915],
              [1715972400000, 1.0248289108276367, 1.4659672975540161],
              [1715976000000, 1.057837963104248, 1.4986730813980103],
              [1715979600000, 1.051605463027954, 1.5404973030090332],
              [1715983200000, 1.0184612274169922, 1.476259708404541],
              [1715986800000, 0.9843699336051941, 1.4836996793746948],
              [1715990400000, 0.9537966847419739, 1.4506791830062866],
              [1715994000000, 0.9065009355545044, 1.4072365760803223],
              [1715997600000, 0.8687046766281128, 1.3624078035354614],
              [1716001200000, 0.8309083580970764, 1.321694254875183],
              [1716004800000, 0.7924929261207581, 1.2968276739120483],
              [1716008400000, 0.7540774941444397, 1.2552502155303955],
              [1716012000000, 0.7156620621681213, 1.2368495464324951],
              [1716015600000, 0.6726894974708557, 1.2035877704620361],
              [1716019200000, 0.6372136473655701, 1.160040259361267],
              [1716022800000, 0.6045316457748413, 1.1184604167938232],
              [1716026400000, 0.5754092931747437, 1.0619064569473267],
              [1716030000000, 0.5341821908950806, 1.00535249710083],
              [1716033600000, 0.4920606017112732, 0.948798418045044],
              [1716037200000, 0.45258307456970215, 0.9073282480239868],
              [1716040800000, 0.4143636226654053, 0.8671361207962036],
              [1716044400000, 0.3911518454551697, 0.878216028213501],
              [1716048000000, 0.39609572291374207, 0.8912795782089233],
              [1716051600000, 0.40103960037231445, 0.9081229567527771],
              [1716055200000, 0.40598347783088684, 0.8964102268218994],
              [1716058800000, 0.3989867568016052, 0.9574092030525208],
              [1716062400000, 0.3919900059700012, 0.9681856036186218],
              [1716066000000, 0.3849932551383972, 1.0282692909240723],
              [1716069600000, 0.4103844463825226, 1.0566256046295166],
              [1716073200000, 0.43038347363471985, 1.0713692903518677],
              [1716076800000, 0.45033419132232666, 1.0739624500274658],
              [1716080400000, 0.46939146518707275, 1.0594193935394287],
              [1716084000000, 0.4539422392845154, 1.0397734642028809],
              [1716087600000, 0.45070013403892517, 1.030333161354065],
              [1716091200000, 0.44305533170700073, 1.0124911069869995],
              [1716094800000, 0.4354105293750763, 0.9835123419761658],
              [1716098400000, 0.425545334815979, 0.9253016114234924],
              [1716102000000, 0.42388054728507996, 0.9175321459770203],
              [1716105600000, 0.4313619136810303, 0.8739637136459351],
              [1716109200000, 0.4033139944076538, 0.8482947945594788],
              [1716112800000, 0.39054930210113525, 0.7988618016242981],
              [1716116400000, 0.37290364503860474, 0.7513169646263123],
              [1716120000000, 0.3669782280921936, 0.7501875758171082],
              [1716123600000, 0.38122156262397766, 0.7824155688285828],
              [1716127200000, 0.3784303367137909, 0.7817872166633606],
              [1716130800000, 0.3616231679916382, 0.8436368703842163],
              [1716134400000, 0.33727842569351196, 0.905486524105072],
              [1716138000000, 0.3150727450847626, 0.967336118221283],
              [1716141600000, 0.29096508026123047, 1.0291857719421387],
              [1716145200000, 0.3003661334514618, 1.005664348602295],
              [1716148800000, 0.3026615381240845, 0.9821428060531616],
              [1716152400000, 0.28125008940696716, 0.9586213827133179],
              [1716156000000, 0.25983864068984985, 1.0082846879959106],
              [1716159600000, 0.25075215101242065, 1.0484389066696167],
              [1716163200000, 0.2450391948223114, 1.066720724105835],
              [1716166800000, 0.25520631670951843, 1.068457841873169],
              [1716170400000, 0.25796782970428467, 1.0701948404312134],
              [1716174000000, 0.2607293128967285, 1.0719319581985474],
              [1716177600000, 0.26349082589149475, 1.0736689567565918],
              [1716181200000, 0.266252338886261, 1.0754060745239258],
              [1716184800000, 0.2790656089782715, 1.1233978271484375],
            ],
          },
        ],
      }}
    />
  );
};
export default ProbabilityChartMock;
