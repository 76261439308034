import { FieldTimeOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Select,
  Spin,
  Switch,
  Table,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DBAction } from "../../../enum/DBAction";
import { OperationRuleAlertNotificationType } from "../../../enum/OperationRuleAlertNotificationType";
import { StoreState } from "../../../enum/StoreState";
import { UserExperience } from "../../../enum/UserExperience";
import {
  IOperationRuleAlertNotification,
  IOperationRuleAlertUser,
} from "../../../stores/data/insightAlerts/insightAlertModel";
import { useStores } from "../../../stores/setup/use-store";
import { processObjectsForBackend } from "../../../util/processObjectsForBackend/processObjectsForBackend";
import SliderAlertBetweenHours from "./Components/SliderAlertBetweenHours";
import { convertDecimalToHour } from "../../../util/convertHours/convertDecimalToHour";
import { convertHourToDecimal } from "../../../util/convertHours/convertHourToDecimal";

interface InsightAlertModalProps {
  onCancel: () => void;
}

interface InsightAlertModalValues {
  alertName: string;
  dashboard: number;
  insight: number;
  location: number;
  minimumEventDuration: number;
  minutesType: number;
  notificationName: string;
  notificationDescription: string;
  status: boolean;
}

const InsightAlertModal: React.FC<InsightAlertModalProps> = ({ onCancel }) => {
  const {
    dataStores: { insightAlertStore, authStore },
  } = useStores();

  const { t } = useTranslation("translation", {
    keyPrefix: "oceanWeatherIntelligence.insightAlert",
  });

  const [form] = Form.useForm();
  const { Panel } = Collapse;
  const [usersTableSelected, setUsersTableSelected] = useState<React.Key[]>([]);
  const [rangeHoursEvent, setRangeHoursEvent] = useState<number[]>([0, 24]);

  useEffect(() => {
    if (insightAlertStore.operationRuleAlertAction === DBAction.CREATE) {
      form.resetFields();
      form.setFieldsValue({ status: true });
      setRangeHoursEvent([0, 24]);
      setUsersTableSelected([]);
      insightAlertStore.setOperationRuleAlertUserSelected([]);
    } else if (insightAlertStore.operationRuleAlertAction === DBAction.UPDATE) {
      if (insightAlertStore.operationRuleAlert) {
        const { name, active, notifications, recipients } =
          insightAlertStore.operationRuleAlert;
        const recipientSelected = recipients.filter((value) => value.id > 0);
        insightAlertStore.setOperationRuleAlertUserSelected(recipientSelected);
        insightAlertStore.setOperationRuleAlertUserSelectedBeforeEdit(
          recipientSelected
        );
        const {
          operationRuleId,
          operationRuleLocationId,
          operationRuleLocationItemId,
        } = insightAlertStore.operationRuleAlertForTableForEdit;

        let fieldsValue: Partial<InsightAlertModalValues> = {
          alertName: name,
          dashboard: operationRuleId,
          location: operationRuleLocationId,
          insight: operationRuleLocationItemId,
          minimumEventDuration: notifications[0].minimumEventDuration,
          minutesType: notifications[0].minutesType,
          notificationName: notifications[0].title,
          notificationDescription: notifications[0].description,
          status: active,
        };

        if (notifications[0]?.startTime && notifications[0]?.endTime) {
          const startTime = convertHourToDecimal(
            notifications[0].startTime == "23:59"
              ? "24:00"
              : notifications[0].startTime
          );
          const endTime = convertHourToDecimal(
            notifications[0].endTime == "23:59"
              ? "24:00"
              : notifications[0].endTime
          );
          setRangeHoursEvent([startTime, endTime]);
        } else {
          setRangeHoursEvent([0, 24]);
        }
        form.setFieldsValue(fieldsValue);
      }

      if (insightAlertStore.operationRuleAlertUserSelected.length > 0) {
        let selectRowsId: React.Key[] = [];

        for (const value of insightAlertStore.operationRuleAlertUserSelected) {
          if (value.id > 0) {
            selectRowsId.push(value.userId);
          }
        }

        setUsersTableSelected(selectRowsId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    insightAlertStore.operationRuleAlert,
    insightAlertStore.operationRuleAlertAction,
    insightAlertStore.insightAlertModalVisible,
  ]);

  const handleOnchangeSelectDashboard = (operationRuleId: number) => {
    if (operationRuleId > 0) {
      form.resetFields(["location", "insight"]);

      insightAlertStore.getOperationRuleLocationModalAPI(operationRuleId);
    }
  };

  const handleOnChangeSelectLocation = (operationRuleLocationId: number) => {
    if (operationRuleLocationId) {
      form.resetFields(["insight"]);
      insightAlertStore.getOperationRuleLocationInsightModalAPI(
        operationRuleLocationId
      );
    }
  };

  const clearUserTableSelected = () => {
    setUsersTableSelected([]);
    insightAlertStore.setOperationRuleAlertUserSelected([]);
  };

  const handleOnSaveFormAlertModal = (value: InsightAlertModalValues) => {
    if (insightAlertStore.operationRuleAlertUserSelected.length === 0) {
      message.info(t("selectRecipient"), 5);
      return;
    }
    let startTime: string | undefined;
    let endTime: string | undefined;
    if (rangeHoursEvent.length == 2) {
      startTime =
        rangeHoursEvent[0] == 24
          ? "23:59"
          : convertDecimalToHour(rangeHoursEvent[0]);
      endTime =
        rangeHoursEvent[1] == 24
          ? "23:59"
          : convertDecimalToHour(rangeHoursEvent[1]);
    }
    if (insightAlertStore.operationRuleAlertAction === DBAction.CREATE) {
      const recipients: IOperationRuleAlertUser[] =
        insightAlertStore.operationRuleAlertUserSelected.map((value) => {
          return {
            id: 0,
            userId: value.userId,
            userName: value.userName,
            action: DBAction.CREATE,
          };
        });

      let notificationForInsertion: IOperationRuleAlertNotification = {
        id: 0,
        title: value.notificationName,
        description: value.notificationDescription,
        minimumEventDuration: value.minimumEventDuration,
        minutesType: value.minutesType,
        type: OperationRuleAlertNotificationType.BEFORE,
        action: DBAction.CREATE,
      };

      if (startTime && endTime) {
        notificationForInsertion = {
          ...notificationForInsertion,
          startTime,
          endTime,
        };
      }

      insightAlertStore.addOperationRuleAlertAPI({
        id: 0,
        active: value.status,
        name: value.alertName,
        operationRuleLocationItemId: value.insight,
        notifications: [notificationForInsertion],
        recipients: recipients,
      });
      form.resetFields();
      clearUserTableSelected();
      insightAlertStore.setOperationRuleDetailSelected(0);
      insightAlertStore.setOperationRuleLocationDetailSelected(0);
    } else if (insightAlertStore.operationRuleAlertAction === DBAction.UPDATE) {
      const recipientProcessedForBackend = processObjectsForBackend(
        insightAlertStore.operationRuleAlertUserSelectedBeforeEdit,
        insightAlertStore.operationRuleAlertUserSelected,
        "id"
      );

      let notificationForUpdate: IOperationRuleAlertNotification = {
        id: insightAlertStore.operationRuleAlert?.notifications[0].id,
        title: value.notificationName,
        description: value.notificationDescription,
        minimumEventDuration: value.minimumEventDuration,
        minutesType: value.minutesType,
        type: OperationRuleAlertNotificationType.BEFORE,
        action: DBAction.UPDATE,
      };

      if (startTime && endTime) {
        notificationForUpdate = {
          ...notificationForUpdate,
          startTime,
          endTime,
        };
      }

      insightAlertStore.setOperationRuleAlert({
        id: insightAlertStore.operationRuleAlert.id,
        active: value.status,
        name: value.alertName,
        operationRuleLocationItemId: value.insight,
        notifications: [notificationForUpdate],
        recipients: recipientProcessedForBackend,
      });

      insightAlertStore.updateOperationRuleAlertAPI();
    }

    insightAlertStore.setInsightAlertModalVisible(false);
  };

  const columns = [
    {
      title: t("allUsers"),
      dataIndex: "userName",
    },
  ];

  const rowSelection = {
    selectedRowKeys: usersTableSelected,
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: IOperationRuleAlertUser[]
    ) => {
      if (selectedRows) {
        setUsersTableSelected(selectedRowKeys);
        insightAlertStore.setOperationRuleAlertUserSelected(selectedRows);
      }
    },
    getCheckboxProps: () => ({
      disabled: authStore.readOnlyUser,
    }),
  };
  return (
    <Modal
      bodyStyle={{ height: 500, overflow: "scroll" }}
      visible={insightAlertStore.insightAlertModalVisible}
      title={
        insightAlertStore.operationRuleAlertAction === DBAction.CREATE
          ? t("addAlert")
          : t("editAlert")
      }
      footer={[
        <Button disabled={authStore.readOnlyUser} onClick={onCancel}>
          {t("cancel")}
        </Button>,
        <Button
          type="primary"
          disabled={authStore.readOnlyUser}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                handleOnSaveFormAlertModal(values as InsightAlertModalValues);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          {insightAlertStore.operationRuleAlertAction === DBAction.CREATE
            ? t("add")
            : t("edit")}
        </Button>,
      ]}
      onCancel={onCancel}
    >
      <Spin spinning={insightAlertStore.state === StoreState.PEDDING}>
        {insightAlertStore.state === StoreState.ERROR ? (
          <Alert
            message="Error"
            description={insightAlertStore.messageError}
            type="error"
            showIcon
            closable
          />
        ) : (
          ""
        )}
        <Form
          form={form}
          layout="vertical"
          name="alertForm"
          initialValues={{ modifier: "public" }}
          style={{ marginTop: 10 }}
        >
          <>
            <Form.Item
              name="alertName"
              label={t("alertName")}
              style={{ display: "inline-block", width: "83%" }}
              rules={[
                {
                  required: true,
                  message: t("pleaseInputAlertName") as string,
                },
                {
                  max: 100,
                  message: t("alertNameExceedCharactersLimit") as string,
                },
              ]}
            >
              <Input disabled={authStore.readOnlyUser} />
            </Form.Item>

            <Form.Item
              name="status"
              label={t("status")}
              labelAlign="left"
              style={{ display: "inline-block", paddingLeft: 18 }}
              valuePropName="checked"
            >
              <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                defaultChecked
                checked={true}
                disabled={authStore.readOnlyUser}
              />
            </Form.Item>
          </>
          <Form.Item
            name="dashboard"
            label="Dashboard"
            rules={[
              {
                required: true,
                message: t("selectDashboardFromList") as string,
              },
            ]}
          >
            <Select
              id="selectDashboard"
              onChange={handleOnchangeSelectDashboard}
              disabled={authStore.readOnlyUser}
            >
              {insightAlertStore.operationRuleModal.map((value) => (
                <Select.Option value={value.id} key={value.id}>
                  {value.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="location"
            label={t("location")}
            rules={[
              {
                required: true,
                message: t("selectLocationFromList") as string,
              },
            ]}
          >
            <Select
              id="selectLocation"
              onChange={handleOnChangeSelectLocation}
              disabled={authStore.readOnlyUser}
            >
              {insightAlertStore.operationRuleLocationModal.map((value) => (
                <Select.Option value={value.id} key={value.id}>
                  {value.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="insight"
            label={t("insight")}
            rules={[
              {
                required: true,
                message: t("selectInsightFromList") as string,
              },
            ]}
          >
            <Select id="selectInsight" disabled={authStore.readOnlyUser}>
              {insightAlertStore.operationRuleLocationInsightModal.map(
                (value) => (
                  <Select.Option value={value.id} key={value.id}>
                    {value.name}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>

          <Divider orientation="left">{t("notification")}</Divider>
          <Collapse defaultActiveKey={["1"]}>
            <Panel header={t("beforeEventsStart")} key="1">
              <Form.Item
                name="minutesType"
                label={t("upTo")}
                rules={[
                  {
                    required: true,
                    message: t("selectTimeBeforeEvent") as string,
                  },
                ]}
              >
                <Select
                  id="selectMinutesType"
                  disabled={authStore.readOnlyUser}
                >
                  <Select.Option value={30} key={"30"}>
                    30 {t("minutes")}
                  </Select.Option>
                  <Select.Option value={60} key={"60"}>
                    1 {t("hour")}
                  </Select.Option>
                  <Select.Option value={120} key={"120"}>
                    2 {t("hours")}
                  </Select.Option>
                  <Select.Option value={240} key={"240"}>
                    4 {t("hours")}
                  </Select.Option>
                  <Select.Option value={480} key={"480"}>
                    8 {t("hours")}
                  </Select.Option>
                  <Select.Option value={1440} key={"1440"}>
                    1 {t("day")}
                  </Select.Option>
                  <Select.Option value={2880} key={"2880"}>
                    2 {t("days")}
                  </Select.Option>
                  <Select.Option value={5760} key={"5760"}>
                    4 {t("days")}
                  </Select.Option>
                  <Select.Option value={8640} key={"8640"}>
                    6 {t("days")}
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="notificationName"
                label={t("notificationName")}
                rules={[
                  {
                    required: true,
                    message: t("pleaseInputAlertName") as string,
                  },
                  {
                    max: 100,
                    message: t(
                      "notificationNameExceedCharactersLimit"
                    ) as string,
                  },
                ]}
              >
                <Input disabled={authStore.readOnlyUser} />
              </Form.Item>
              <Form.Item
                name="notificationDescription"
                label={t("description")}
                rules={[
                  {
                    required: true,
                    message: t("inputLocationSummary") as string,
                  },
                ]}
              >
                <TextArea maxLength={100} disabled={authStore.readOnlyUser} />
              </Form.Item>

              <Form.Item
                name="minimumEventDuration"
                label={t("minimunEventDuration")}
              >
                <Select
                  id="selectMinimumEventDuration"
                  disabled={authStore.readOnlyUser}
                >
                  <Select.Option value={15} key={"15"}>
                    15 {t("minutes")}
                  </Select.Option>
                  <Select.Option value={30} key={"30"}>
                    30 {t("minutes")}
                  </Select.Option>
                  <Select.Option value={45} key={"45"}>
                    45 {t("minutes")}
                  </Select.Option>
                  <Select.Option value={60} key={"60"}>
                    1 {t("hour")}
                  </Select.Option>
                  <Select.Option value={120} key={"120"}>
                    2 {t("hours")}
                  </Select.Option>
                  <Select.Option value={180} key={"180"}>
                    3 {t("hours")}
                  </Select.Option>
                  <Select.Option value={240} key={"240"}>
                    4 {t("hours")}
                  </Select.Option>
                  <Select.Option value={360} key={"360"}>
                    6 {t("hours")}
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="rangeHoursEvent"
                label={t("eventTimeRange")}
                style={{ alignItems: "center" }}
              >
                <div
                  style={{
                    paddingRight: "16px",
                    paddingLeft: "16px",
                    paddingTop: "32px",
                  }}
                >
                  <SliderAlertBetweenHours
                    key={`sliderAlertBetweenHours-${rangeHoursEvent[0]}-${rangeHoursEvent[1]}`}
                    value={rangeHoursEvent}
                    onAfterChange={(value: number[]) => {
                      setRangeHoursEvent(value);
                    }}
                    disabled={authStore.readOnlyUser}
                  />
                </div>
                <Typography.Text>
                  {t("insightLocationTimezone")}
                </Typography.Text>
              </Form.Item>
            </Panel>
            <Panel header={t("otherConfigurationOptions")} key="2">
              <Form.Item
                style={{
                  display: "inline-block",
                  paddingTop: 30,
                  paddingLeft: 10,
                }}
              >
                <Button
                  type="primary"
                  icon={<FieldTimeOutlined />}
                  style={{
                    margin: "0 8px",
                  }}
                  onClick={() => {
                    insightAlertStore.addLogUserExperienceAPI(
                      UserExperience.OWI_ALERT_NOTIFICATION_CONFIG_EVENT_START
                    );
                    message.info(t("configurationCommingSoon"), 5);
                  }}
                  disabled={authStore.readOnlyUser}
                >
                  {t("eventStart")}
                </Button>
              </Form.Item>
              <Form.Item style={{ display: "inline-block", paddingTop: 30 }}>
                <Button
                  type="primary"
                  icon={<FieldTimeOutlined />}
                  style={{
                    margin: "0 8px",
                  }}
                  onClick={() => {
                    insightAlertStore.addLogUserExperienceAPI(
                      UserExperience.OWI_ALERT_NOTIFICATION_CONFIG_EVENT_FINISH
                    );
                    message.info(t("configurationCommingSoon"), 5);
                  }}
                  disabled={authStore.readOnlyUser}
                >
                  {t("eventFinish")}
                </Button>
              </Form.Item>
            </Panel>
          </Collapse>
          <Divider orientation="left">{t("recipient")}</Divider>

          <Table
            pagination={{ pageSize: 50 }}
            scroll={{ y: 240 }}
            rowKey={(record) => record.userId}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            columns={columns}
            dataSource={insightAlertStore.operationRuleAlertUser}
          />
        </Form>
      </Spin>
    </Modal>
  );
};

export default observer(InsightAlertModal);
