import React, { FC, InputHTMLAttributes } from "react";
import {
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { NumberInput } from "../NumberInput/NumberInput";

export interface NumberInputWithElementProps
  extends InputHTMLAttributes<HTMLInputElement> {
  icon?: JSX.Element;
  width?: string;
  inputStyle?: React.CSSProperties;
  suffix?: string;
}

export const NumberInputWithElement: FC<NumberInputWithElementProps> = ({
  icon,
  width,
  inputStyle,
  suffix,
  ...inputProps
}) => {
  return (
    <InputGroup w={width}>
      {icon && (
        <InputLeftElement
          color="newBlue.500"
          pointerEvents="none"
          children={icon}
        />
      )}
      <NumberInput inputStyle={inputStyle} {...inputProps} />
      {suffix && (
        <InputRightElement
          children={
            <Text
              color={inputStyle?.color || "rgba(30, 82, 151, 0.8)"}
              fontWeight="normal"
              mr={6}
              backgroundColor={inputStyle?.backgroundColor || "#ffffff"}
            >
              {suffix}
            </Text>
          }
        />
      )}
    </InputGroup>
  );
};
