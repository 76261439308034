import { IAnalyticsReportingForm, IEventsData, IForecastPerformance, IGetEventsPerformance } from "../types/IAnalyticsReporting";
import api from "./api";

export default class AnalyticsReportingService {
	async getForecastPerformance({
		initialDate, finalDate, regionId, stationId, environmentalVariableId, minValue, maxValue, forecastPeriod
	}: IAnalyticsReportingForm): Promise<IForecastPerformance> {
		const result = await api.post("/v1/forecast-performance/get-forecast-performance", {
			initialDate,
			finalDate,
			stationId,
			regionId,
			environmentalVariableId,
			minValue,
			maxValue,
			forecastPeriod
		}
		);
		return result.data;
	}

	async getEvents({
		regionId, stationId, environmentalType, initialDate, finalDate
	}: IGetEventsPerformance): Promise<IEventsData[]> {
		const result = await api.post("/v1/forecast-performance/get-events", {
			initialDate,
			finalDate,
			stationId,
			regionId,
			environmentalType
		}
		);
		return result.data;
	}
}
