import { ConfigProvider, Slider, SliderSingleProps } from "antd";
import React from "react";
import { convertDecimalToHour } from "../../../../util/convertHours/convertDecimalToHour";

export interface SliderAlertBetweenHoursProps {
  key?: React.Key | null | undefined;
  onAfterChange?: (value: number[]) => void;
  value: number[];
  disabled?: boolean;
}

export const SliderAlertBetweenHours = (
  props: SliderAlertBetweenHoursProps
) => {
  const { key, onAfterChange, value, disabled } = props;

  const [val, setVal] = React.useState<number[]>(value);

  const marks: SliderSingleProps["marks"] = {
    0: "0:00",

    6: "6:00",

    12: "12:00",

    18: "18:00",

    24: "23:59",
  };

  const formatter = (value?: number) =>
    `${value == 24 ? "23:59" : convertDecimalToHour(value || 0)}`;

  return (
    <ConfigProvider
      //@ts-ignore
      getPopupContainer={(triggerNode?: HTMLElement) => triggerNode?.parentNode}
    >
      <Slider
        key={key}
        range
        marks={marks}
        value={val}
        defaultValue={[0, 24]}
        min={0}
        max={24}
        step={0.5}
        tooltip={{
          formatter,
          open: true,
          color: "#1677ff",
          placement: "top",
        }}
        onChange={(value) => setVal(value)}
        onAfterChange={onAfterChange}
        disabled={disabled}
      />
    </ConfigProvider>
  );
};

export default React.memo(SliderAlertBetweenHours);
