import React, { ReactElement } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { Input } from "../../../../components/Input/Input";
interface DashboardModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (values?: any) => void;
  title: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  initialRef?: React.RefObject<HTMLInputElement>;
  nameFieldName: string;
  nameFieldLabel?: string;
  nameFieldPlaceholder?: string;
  nameFieldMaxLength?: number;
  keyFieldName: string;
  keyFieldLabel?: string;
  keyFieldPlaceholder?: string;
  keyFieldMaxLength?: number;
  formik?: any;
  initialValues: any;
}

export const DashboardModal: React.FC<DashboardModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  cancelButtonText,
  confirmButtonText,
  initialRef,
  nameFieldName,
  nameFieldLabel,
  nameFieldPlaceholder,
  nameFieldMaxLength,
  keyFieldName,
  keyFieldLabel,
  keyFieldPlaceholder,
  keyFieldMaxLength,
  formik,
  initialValues,
}): ReactElement => {
  const { values, handleChange } = formik;
  return (
    <Modal
      closeOnOverlayClick={false}
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Formik initialValues={initialValues} onSubmit={onConfirm}>
        {() => (
          <>
            <ModalOverlay />
            <ModalContent bg={"#f0f1f2"}>
              <ModalHeader color="newBlue.500">{title}</ModalHeader>
              <ModalCloseButton color={"black"} />

              <ModalBody pb={6} px={14}>
                <FormControl style={{ marginBottom: "20px" }} isRequired>
                  <FormLabel color="newBlue.500" fontSize={"large"}>
                    {nameFieldLabel}
                  </FormLabel>
                  <Input
                    autoFocus
                    placeholder={nameFieldPlaceholder}
                    maxLength={nameFieldMaxLength}
                    name={nameFieldName}
                    value={values[nameFieldName]}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel color="newBlue.500" fontSize={"large"}>
                    {keyFieldLabel}
                  </FormLabel>
                  <Input
                    placeholder={keyFieldPlaceholder}
                    maxLength={keyFieldMaxLength}
                    name={keyFieldName}
                    value={values[keyFieldName]}
                    onChange={(e) => {
                      e.target.value = e.target.value.toLocaleUpperCase();
                      handleChange(e);
                    }}
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="ghost"
                  color="newBlue.500"
                  onClick={onClose}
                  mr={3}
                >
                  {cancelButtonText}
                </Button>
                <Button
                  layerStyle="ocean"
                  _hover={{ layerStyle: "ocean" }}
                  onClick={() => onConfirm(values)}
                  type="submit"
                >
                  {confirmButtonText}
                </Button>
              </ModalFooter>
            </ModalContent>
          </>
        )}
      </Formik>
    </Modal>
  );
};
