import { makeAutoObservable } from "mobx";
import { StoreState } from "../../../enum/StoreState";
import { RootEnv } from "../../setup/create-store";
import { getEnv } from "mobx-easy";
import { RealtimeStorageEntity } from "../../../types/realtimeStorageTypes";
import { IQuestionFeedback } from "../../../types/IQuestionFeedback";
import { UserExperience } from "../../../enum/UserExperience";

export default class FeedbackStore {
  state: string = StoreState.DONE;

  questionFeedback: IQuestionFeedback | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setQuestionFeedback(value: IQuestionFeedback | undefined) {
    this.questionFeedback = value;
  }

  reset() {
    this.setState(StoreState.DONE);
    this.setQuestionFeedback(undefined);
  }

  subscribeQuestionFeedback(
    userId: number,
    callback: (storage: IQuestionFeedback | undefined) => void
  ) {
    const { googleFirebase } = getEnv<RootEnv>();

    try {
      googleFirebase.onSnapshot(
        RealtimeStorageEntity.QuestionFeedback,
        {
          filter: [
            {
              key: "userId",
              operator: "==",
              value: userId,
            },
          ],
          limit: 1,
        },
        (storage: IQuestionFeedback[]) => {
          if (storage?.length) {
            return callback(storage[0]);
          }
          return callback(undefined);
        }
      );
    } catch (error) {
      console.log(`Error while try snapShot feedbacks`);
    }
  }

  async sendQuestionFeedback(
    questionId: string,
    question: string,
    answer: string
  ) {
    this.setState(StoreState.PEDDING);
    const { feedbackService } = getEnv<RootEnv>();

    try {
      const result = await feedbackService.sendQuestionFeedback(
        questionId,
        question,
        answer
      );

      this.setState(StoreState.DONE);
      this.setQuestionFeedback(undefined);
      return result.success;
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  async deleteQuestionFeedback(questionId: string) {
    this.setState(StoreState.PEDDING);
    const { feedbackService } = getEnv<RootEnv>();

    try {
      await feedbackService.deleteQuestionFeedback(questionId);

      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  async addLogUserExperienceAPI(
    userExperience: UserExperience,
    details?: string
  ) {
    const { logServices } = getEnv<RootEnv>();
    logServices.addLogUserExperience(userExperience, details);
  }
}
