import { Box, Button, Tooltip } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useRef, useMemo } from "react";
import DatePicker from "react-datepicker";
import { useStores } from "../../../stores/setup/use-store";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TodayIcon from "@material-ui/icons/Today";
import { useTranslation } from "react-i18next";
import pt from "date-fns/locale/pt-BR";
import en from "date-fns/locale/en-GB";
import { VisualizationType } from "../../../enum/VisualizationType";
interface ForecastChartPastForecastProps {
  disable?: boolean;
}

const ForecastChartPastForecast: React.FC<ForecastChartPastForecastProps> = (
  props
) => {
  const {
    dataStores: {
      forecastChartPageStore,
      forecastChartMeteogramStore,
      oceanWeatherIntelligenceStore,
      regionStore,
    },
  } = useStores();

  const windowWidth = useRef(window.innerWidth);
  const isSmallScreen = windowWidth.current <= 1300;

  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartPastForecast",
  });

  useEffect(() => {
    forecastChartMeteogramStore.clearSelectForecastDateAvailable();
    setClickedDate(false);
    if (forecastChartPageStore.clickMapLatLng) {
      forecastChartPageStore.updateMacroRegionOrigin(
        forecastChartPageStore.clickMapLatLng,
        regionStore.macroRegions
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastChartPageStore.clickMapLatLng]);

  useEffect(() => {
    forecastChartMeteogramStore.clearSelectForecastDateAvailable();
    setStartDate(
      oceanWeatherIntelligenceStore.processDateCurrent(DateTime.local())
    );
  }, [
    forecastChartMeteogramStore,
    forecastChartPageStore.selectedStation,
    oceanWeatherIntelligenceStore,
  ]);

  const [startDate, setStartDate] = useState(
    oceanWeatherIntelligenceStore.processDateCurrent(DateTime.local())
  );
  const [clickedDate, setClickedDate] = useState(false);

  const getTextByVisualizationType = useMemo(() => {
    switch (forecastChartMeteogramStore.visualizationType) {
      case VisualizationType.FORECAST:
        return t("pastForecast");
      case VisualizationType.SENSOR:
        if (forecastChartMeteogramStore.isSensorMarker) {
          return t("pastMeasurement");
        } else {
          return t("pastForecast");
        }
      default:
        return t("pastForecast");
    }
  }, [
    forecastChartMeteogramStore.isSensorMarker,
    forecastChartMeteogramStore.visualizationType,
    t,
  ]);

  //@ts-ignore
  const CustomDatePickerButton = React.forwardRef(({ value, onClick }, ref) =>
    props.disable ? (
      <Tooltip label={t("noPastDataAvailable")} fontSize="sm" placement="top">
        <Button
          //@ts-ignore
          ref={ref}
          cursor={"not-allowed"}
          className="CustomDatePickerButton"
          size="xs"
          p={isSmallScreen ? 2 : 1}
          pr={2}
          disabled={true}
          _hover={{ backgroundColor: "none" }}
          _disabled={{ layerStyle: "darkOcean" }}
          leftIcon={
            isSmallScreen ? undefined : (
              <TodayIcon
                style={{ marginLeft: "6px", width: "16px", height: "16px" }}
              />
            )
          }
          fontSize={isSmallScreen ? "12px" : undefined}
          borderRadius={4}
        >
          {clickedDate ? value : getTextByVisualizationType}
        </Button>
      </Tooltip>
    ) : (
      <Button
        //@ts-ignore
        ref={ref}
        cursor={"pointer"}
        className="CustomDatePickerButton"
        onClick={onClick}
        size="xs"
        p={isSmallScreen ? 2 : 1}
        pr={2}
        layerStyle="darkOcean"
        _hover={{ layerStyle: "darkOcean" }}
        leftIcon={
          isSmallScreen ? undefined : (
            <TodayIcon
              style={{ marginLeft: "6px", width: "16px", height: "16px" }}
            />
          )
        }
        fontSize={isSmallScreen ? "12px" : undefined}
        borderRadius={4}
      >
        {clickedDate ? value : getTextByVisualizationType}
      </Button>
    )
  );

  const handleSelectForecastDateAvailable = (value: Date) => {
    forecastChartMeteogramStore.setSelectForecastDateAvailable(
      DateTime.fromISO(value.toISOString())
    );

    forecastChartPageStore.setActionCallMeteogramChart(
      !forecastChartPageStore.actionCallMeteogramChart
    );
  };

  return (
    <Box zIndex={99999999}>
      {clickedDate ? (
        <Button
          layerStyle="darkOcean"
          _hover={{ layerStyle: "darkOcean" }}
          mr={2}
          size="xs"
          onClick={() => {
            forecastChartMeteogramStore.clearSelectForecastDateAvailable();
            forecastChartPageStore.setActionCallMeteogramChart(
              !forecastChartPageStore.actionCallMeteogramChart
            );
            setClickedDate(false);
            setStartDate(
              oceanWeatherIntelligenceStore.processDateCurrent(DateTime.local())
            );
          }}
          borderRadius={4}
        >
          <ArrowBackIcon style={{ width: "18px", height: "18px" }} />
        </Button>
      ) : (
        ""
      )}

      <DatePicker
        selected={startDate}
        onChange={(date: Date) => {
          setStartDate(date);
          setClickedDate(true);
          handleSelectForecastDateAvailable(date);
        }}
        popperPlacement={"right-end"}
        maxDate={DateTime.local().toJSDate()}
        timeIntervals={15}
        customInput={<CustomDatePickerButton />}
        dateFormat="MMMM d, yyyy H:mm"
        timeInputLabel={t("time") as string}
        showTimeInput
        locale={i18n.language === "en" ? en : pt}
      />
    </Box>
  );
};

export default observer(ForecastChartPastForecast);
