import {
  IOperationRuleAlert,
  IOperationRuleAlertForTable,
} from "../stores/data/insightAlerts/insightAlertModel";
import api from "./api";

export default class InsightAlertService {
  async getOperationRuleAlertForTable(
    operationRuleId?: number,
    operationRuleLocationId?: number
  ): Promise<IOperationRuleAlertForTable[]> {
    const result = await api.post(
      `/v1/operationRuleAlert/getOperationRuleAlertForTable`,
      {
        operation_rule_id: operationRuleId,
        operation_rule_location_id: operationRuleLocationId,
      }
    );
    if (result.status === 200) {
      return result.data;
    }
    throw new Error("Error while get operation rule alerts");
  }

  async getOperationRuleAlert(
    operationRuleAlertId: number
  ): Promise<IOperationRuleAlert> {
    const result = await api.post(
      `/v1/operationRuleAlert/getOperationRuleAlert`,
      {
        operation_rule_alert_id: operationRuleAlertId,
      }
    );
    if (result.status === 200) {
      return result.data;
    }
    throw new Error("Error while get operation rule alerts");
  }

  async deleteOperationRuleAlert(id: number): Promise<boolean> {
    const result = await api.delete(`/v1/operationRuleAlert/${id}`);
    if (result.status === 200 || result.status === 201) {
      return true;
    }
    throw new Error("Error while delete operation rule alert");
  }

  async updateOperationRuleAlert(
    operationRuleAlert: IOperationRuleAlert
  ): Promise<IOperationRuleAlert> {
    const result = await api.put(
      `/v1/OperationRuleAlert/${operationRuleAlert.id}`,
      {
        operationRuleAlert: operationRuleAlert,
      }
    );
    if (result.status === 200 || result.status === 201) {
      return result.data;
    }
    throw new Error("Error while update Alerts");
  }

  async addOperationRuleAlert(
    operationRuleAlert: IOperationRuleAlert
  ): Promise<IOperationRuleAlert> {
    const result = await api.post(`/v1/OperationRuleAlert`, {
      operationRuleAlert: operationRuleAlert,
    });
    if (result.status === 200 || result.status === 201) {
      return result.data;
    }
    throw new Error("Error while add Alert");
  }
}
