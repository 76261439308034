import { CancelToken } from "axios";
import { EnvironmentalType } from "../enum/EnvironmentalType";
import { ISensorMeasuredData } from "../types/ISensorMeasuredData";
import api from "./api";

export interface IGetSensorMakerLocationsParams {
  envType: EnvironmentalType;
  macroRegionId?: number;
  controller?: AbortController;
}
export default class SensorService {
  async getSensorMakerLocations({
    envType,
    macroRegionId,
    controller,
  }: IGetSensorMakerLocationsParams): Promise<ISensorMeasuredData[]> {
    const result = await api.get(
      `/v1/atmocean/load-stations-measured-data?environmental_type=${envType}&macro_region_id=${
        macroRegionId ?? null
      }`,
      { signal: controller?.signal }
    );
    return result.data;
  }
}
