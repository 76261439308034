import React from "react";
import checkIcon from "../../../assets/checkIcon.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import {
  WrapperLoginForm,
  ContainerLogin,
  FormLogin,
  InputLogin,
  ButtonLogin,
  NewPasswordTitleText,
  InputLabelLogin,
  ContainerNewPasswordTitle,
  DivErrorLogin,
  DivErrorLoginText,
  NewPasswordText,
  LinkArea,
} from "../styles";
import { useTranslation } from "react-i18next";

export interface NewPasswordFormProps {
  title: string;
  text: string;
  verificationCode: string;
  password: string;
  error: boolean;
  onSubmit: (e: any) => void;
  onChange: (e: any) => void;
  onClickGoBackLogin: (e: any) => void;
  isLoading: boolean;
}

export const NewPasswordForm: React.FC<NewPasswordFormProps> = ({
  title,
  text,
  verificationCode,
  password,
  error,
  onChange,
  onSubmit,
  onClickGoBackLogin,
  isLoading,
}) => {
  const isDisabled = error
    ? true
    : verificationCode.length === 0 || password.length === 0;

  const { t } = useTranslation("translation", {
    keyPrefix: "login.newPasswordForm",
  });

  const icon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#ffffff" }} spin />
  );

  return (
    <>
      <WrapperLoginForm>
        <ContainerNewPasswordTitle>
          <img src={checkIcon} alt="check icon" height="40vh" width="40vh" />
          <NewPasswordTitleText>{title}</NewPasswordTitleText>
          <NewPasswordText>{text}</NewPasswordText>
        </ContainerNewPasswordTitle>
        <ContainerLogin>
          <FormLogin>
            <InputLabelLogin>
              {t("verificationCode")}
              <InputLogin
                placeholder={t("verificationCode") as string}
                name="verificationCode"
                value={verificationCode}
                onChange={onChange}
              />
            </InputLabelLogin>
            <InputLabelLogin>
              {t("newPassword")}
              <InputLogin
                placeholder={t("newPassword") as string}
                type="password"
                name="password"
                value={password}
                onChange={onChange}
              />
            </InputLabelLogin>
            <ButtonLogin onClick={onSubmit} disabled={isDisabled}>
              {!isLoading ? (
                t("resetPassword")
              ) : (
                <Spin
                  indicator={icon}
                  spinning={true}
                  style={{ color: "#ffffff" }}
                ></Spin>
              )}
            </ButtonLogin>
          </FormLogin>
          {error && (
            <DivErrorLogin>
              <DivErrorLoginText>
                {t("verificationCodePasswordIncorrect")}
              </DivErrorLoginText>
            </DivErrorLogin>
          )}
          <LinkArea>
            <a
              style={{ color: "rgba(0, 0, 0, 0.64)" }}
              href="#"
              onClick={onClickGoBackLogin}
            >
              {t("returnToLogin")}
            </a>
          </LinkArea>
        </ContainerLogin>
      </WrapperLoginForm>
    </>
  );
};
