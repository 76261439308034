import { observer } from "mobx-react-lite";
import React from "react";
import { useStores } from "../../../../stores/setup/use-store";
import MeteogramChart from "../../../../components/MeteogramChart/MeteogramChart";
import { meteogramFormatTooltip } from "../../../../util/meteogramCharts/meteogramFormatTooltip/meteogramFormatTooltip";

interface SensorChartProps {
  chartHeight: string;
  style?: React.CSSProperties | undefined;
}

const SensorChart: React.FC<SensorChartProps> = ({ chartHeight, style }) => {
  const {
    dataStores: { analyticsChartStore },
  } = useStores();

  return (
    <MeteogramChart
      style={style}
      timezoneOffset={
        analyticsChartStore.chartMeteogramData.plotOptions.series
          .timezoneOffset ?? 0
      }
      options={{
        chart: {
          zoomType: "xy",
          height: chartHeight,
          backgroundColor: "#ffffff",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          type: "datetime",
          gridLineWidth: 1,
          offset: 40,
          currentDateIndicator: {
            enabled: true,
            color: "#54f542",
            width: 2,
            zIndex: 4,
            dashStyle: "dash",
          },
        },
        plotOptions: {
          series: { gapSize: 2 },
        },
        yAxis: analyticsChartStore.chartMeteogramYAxis,
        tooltip: {
          shared: true,
          useHTML: true,
          formatter: function () {
            const { x, y, points } = this;
            return meteogramFormatTooltip(x, y, points);
          },
        },
        legend: {
          itemStyle: {
            fontSize: "1em",
            fontFamily: "'Hind Vadodara', 'Helvetica Neue', sans-serif",
            fontWeight: "normal",
            color: "#00000",
          },
        },
        series: analyticsChartStore.chartMeteogramDataSeries,
      }}
    />
  );
};

export default observer(SensorChart);
