import { useToast } from "@chakra-ui/react";

interface I4castAlertProps {
  message: string;
  type: "error" | "success" | "info" | "warning" | undefined;
}

export const CustomToast = () => {
  const toast = useToast();
  const id = "id-toast";

  const addToast = ({ message, type }: I4castAlertProps) => {
    if (!toast.isActive(id)) {
      toast({
        id,
        description: message,
        status: type,
        position: "top",
        duration: 3000,
        variant: "left-accent",
        containerStyle: {
          color: "black",
        },
      });
    }
  };

  return { addToast };
};
