import { JoyrideStorage } from "../../enum/JoyrideStorage";

export const getIfJoyrideIsDoneInStorage = (
  storage: JoyrideStorage
): boolean => {
  const ride = localStorage.getItem(storage) === "true";
  return ride;
};

export const setIfJoyrideIsDoneInStorage = (
  storage: JoyrideStorage,
  ride: boolean
) => {
  localStorage.setItem(storage, ride.toString());
  window.dispatchEvent(new Event("storage"));
};
