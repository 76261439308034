import { IStation } from "../../stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";

export interface Coordinate {
  latitude: number;
  longitude: number;
}

export const extractCoordinates = (stations: IStation[]): Coordinate[] => {
  return stations.map((station) => ({
    latitude: station.station_lat,
    longitude: station.station_lon,
  }));
};

const degreesToRadians = (degrees: number): number => {
  return (degrees * Math.PI) / 180;
};

const calculateDistance = (coord1: Coordinate, coord2: Coordinate): number => {
  const earthRadiusKm = 6371;

  const dLat = degreesToRadians(coord2.latitude - coord1.latitude);
  const dLon = degreesToRadians(coord2.longitude - coord1.longitude);

  const lat1 = degreesToRadians(coord1.latitude);
  const lat2 = degreesToRadians(coord2.latitude);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return earthRadiusKm * c;
};

export const getCoordinatesWithinRadius = (
  center: Coordinate,
  coordinates: Coordinate[],
  radiusKm: number
): Coordinate[] => {
  const coordinatesWithinRadius: Coordinate[] = [];

  for (const coord of coordinates) {
    const distance = calculateDistance(center, coord);
    if (distance <= radiusKm) {
      coordinatesWithinRadius.push(coord);
    }
  }

  return coordinatesWithinRadius;
};
