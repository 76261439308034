import React from "react";
import { Center, Spinner } from "@chakra-ui/react";

interface LoadingProps {
  styleContainer?: React.CSSProperties;
  styleSpinner?: React.CSSProperties;
}

function Loading({ styleSpinner, styleContainer }: LoadingProps) {
  return (
    <Center
      position="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
      bg="rgba(0, 0, 0, 0.15)"
      zIndex="9999"
      w="100%"
      h="100%"
      style={styleContainer}
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.300"
        color="newBlue.500"
        size="xl"
        style={{ marginLeft: 64, ...styleSpinner }}
      />
    </Center>
  );
}

export default Loading;
