import api from "./api";

export default class MetOceanService {
  async getPDFReport(queryString: string): Promise<Blob> {
    const response = await api.get(
      `/v1/metocean-report/download?forecastConfig=${queryString}`,
      { responseType: "blob" }
    );
    const blob = new Blob([response.data], { type: "application/pdf" });

    return blob;
  }
}
