import { roundToNearestHour } from "../roundHours/roundHours";

export const formatterHourTooltip = (
  hour: number,
  timelineStartDate: number,
  timezoneOffset: number
): string => {
  const startDate = roundToNearestHour(timelineStartDate).toUTC(
    timezoneOffset * -1
  );

  const initialHour = startDate.hour;

  const adjustedHour = (hour + initialHour) % 24;

  return adjustedHour + "h";
};
