import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsGantt from "highcharts/modules/gantt";
import { useTranslation } from "react-i18next";

highchartsGantt(Highcharts);

interface ganttChartProps {
  timezoneOffset: number;
  options: HighchartsReact.Props;
  style?: React.CSSProperties;
}

const ptBR = {
  months: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  shortMonths: [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ],
  weekdays: [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ],
};

const en = {
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  weekdays: [
    "Sunday",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Friday",
    "Saturday",
  ],
};

const GanttChart: React.FC<ganttChartProps> = (props) => {
  const { i18n } = useTranslation("translation");
  const [ganttConfig, setGanttConfig] = useState<any>({
    time: {
      timezoneOffset: props.timezoneOffset,
    },
  });

  useEffect(() => {
    if (i18n.language === "pt-BR") {
      setGanttConfig({ ...ganttConfig, lang: ptBR });
    } else if (i18n.language === "en") {
      setGanttConfig({ ...ganttConfig, lang: en });
    }
    Highcharts.setOptions(ganttConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div style={props.style}>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"ganttChart"}
        allowChartUpdate={true}
        immutable={true}
        options={props.options}
      />
    </div>
  );
};

export default GanttChart;
