import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useStores } from "../../../../stores/setup/use-store";
import LineChart from "../../../../components/LineChart/LineChart";
import { probabilisticFormatTooltip } from "../../../../util/probabilisticCharts/probabilisticFormatTooltip";
import { useTranslation } from "react-i18next";

interface ProbabilisticChartProps {
  chartHeight: string;
  style?: React.CSSProperties | undefined;
}

const ProbabilisticChart: React.FC<ProbabilisticChartProps> = ({
  chartHeight,
  style,
}) => {
  const {
    dataStores: { forecastChartProbabilisticStore, environmentalVariableStore },
  } = useStores();

  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartPanelMeteogram",
  });

  const serieChartNameByEnvironmentalVariable = useMemo(() => {
    const environmentalVariable =
      forecastChartProbabilisticStore.probabilisticForecastData
        ?.environmentalVariable;

    if (!environmentalVariable) return "";

    return environmentalVariableStore.getFullNameOfEnvironmentalVariable(
      environmentalVariable
    );
  }, [
    environmentalVariableStore,
    forecastChartProbabilisticStore.probabilisticForecastData,
  ]);

  return (
    <LineChart
      style={style}
      timezoneOffset={
        forecastChartProbabilisticStore.probabilisticForecastData
          ?.timezoneOffset ?? 180
      }
      options={{
        chart: {
          zoomType: "xy",
          height: chartHeight,
          backgroundColor: "#ffffff",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          type: "datetime",
          gridLineWidth: 1,
          currentDateIndicator: {
            color: "#54f542",
            width: 2,
            zIndex: 4,
            dashStyle: "dash",
          },
        },

        yAxis: {
          labels: {
            format: `{value} ${forecastChartProbabilisticStore.unitMeasurementUserConfig}`,
            style: {
              color: "#00000",
            },
          },
          title: {
            text: serieChartNameByEnvironmentalVariable,
            style: {
              color: "#00000",
            },
          },
          min: forecastChartProbabilisticStore.probabilisticForecastData?.min,
          max: forecastChartProbabilisticStore.probabilisticForecastData?.max,
        },
        tooltip: {
          shared: true,
          useHTML: true,
          formatter: function () {
            const { x, points } = this;

            return probabilisticFormatTooltip(x, points);
          },
        },
        legend: {
          itemStyle: {
            fontSize: "1em",
            fontFamily: "'Hind Vadodara', 'Helvetica Neue', sans-serif",
            fontWeight: "normal",
            color: "#00000",
          },
        },

        plotOptions: {
          series: {
            label: {
              enabled: true,
              onArea: false,
              style: {
                fontFamily: "monospace",
                fontSize: "0.95em",
                color: "#2f517a",
                fontWeight: "bold",
              },
            },
          },
        },
        line: {
          color: "#ffff",
        },
        series: [
          ...forecastChartProbabilisticStore.getSerieByEnvironmentalVariable(),
          {
            name: t("median"),
            data: forecastChartProbabilisticStore.median,
            zIndex: 4,
            label: {
              enabled: true,
            },
            marker: {
              enabled: false,
            },
            color: "#e7990bc4",
            dashStyle: "shortDot",
            lineWidth: 3,
            gridLineWidth: 1,
            tooltip: {
              valueDecimals: 2,
              valueSuffix:
                forecastChartProbabilisticStore.unitMeasurementUserConfig,
            },
          },
          {
            name: t("confidence50"),
            data: forecastChartProbabilisticStore.confidence50,
            type: "arearange",
            lineWidth: 0,
            linkedTo: ":previous",
            color: "rgb(44, 175, 254)",
            fillOpacity: 0.4,
            zIndex: 2,
            marker: {
              enabled: false,
            },
            legend: {
              enabled: true,
            },
            tooltip: {
              valueDecimals: 2,
              valueSuffix:
                forecastChartProbabilisticStore.unitMeasurementUserConfig,
            },
          },
          {
            name: t("confidence80"),
            data: forecastChartProbabilisticStore.confidence80,
            type: "arearange",
            lineWidth: 0,
            linkedTo: ":previous",
            color: "rgb(44, 175, 254)",
            fillOpacity: 0.3,
            zIndex: 1,
            marker: {
              enabled: false,
            },
            tooltip: {
              valueDecimals: 2,
              valueSuffix:
                forecastChartProbabilisticStore.unitMeasurementUserConfig,
            },
          },
        ],
      }}
    />
  );
};

export default observer(ProbabilisticChart);
