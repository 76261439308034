import { Alert, Button, Card, Form, Input, message, Select, Spin } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { StoreState } from "../../../enum/StoreState";
import { useStores } from "../../../stores/setup/use-store";
import ChangePasswordModal from "../../../pages/Login/ChangePassword";

import "./MyData.css";
import { useTranslation } from "react-i18next";
import { Box, Flex } from "@chakra-ui/react";
import { InternationalPhoneInput } from "../../../components/InternationalPhoneInput/InternationalPhoneInput";

interface FormMyDataValues {
  name: string;
  lastName: string;
  middleName: string;
  login: string;
  email: string;
  area: number;
  position: number;
}

const MyData: React.FC = () => {
  const {
    dataStores: { userConfigStore, authStore },
  } = useStores();
  const [form] = Form.useForm();
  const [phoneData, setPhoneData] = useState<{ phone?: string; code?: string }>(
    {}
  );

  const { t } = useTranslation("translation", {
    keyPrefix: "admin.myData",
  });

  useEffect(() => {
    userConfigStore.getUserConfigApi();
    userConfigStore.getUserAreaApi();
    userConfigStore.getUserPositionApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userConfigStore.userConfig.login !== "") {
      const {
        name,
        middle_name,
        last_name,
        email,
        login,
        area_id,
        position_id,
        phone_code,
        phone_number,
      } = userConfigStore.userConfig;

      form.setFieldsValue({
        name: name,
        lastName: last_name,
        middleName: middle_name,
        login: login,
        email: email,
        area: area_id > 0 ? area_id : "",
        position: position_id > 0 ? position_id : "",
      });
      setPhoneData({
        code: phone_code || "55",
        phone: phone_number,
      });
    }
  }, [form, userConfigStore, userConfigStore.userConfig]);

  const handleOnEdit = () => {
    const formValues: FormMyDataValues = form.getFieldsValue();
    if (formValues) {
      const { name, lastName, middleName, email, area, position } = formValues;

      if (!area || !position) {
        return;
      }
      if (!phoneData.phone) {
        message.error(t("pleaseInformPhoneNumber"));
        return;
      }

      userConfigStore.updateUserConfig(
        name,
        lastName,
        email,
        area,
        position,
        middleName,
        phoneData.code,
        phoneData.phone
      );
    }
  };

  return (
    <Box mt={8} overflow="hidden">
      {userConfigStore.state === StoreState.ERROR ? (
        <Alert
          message={t("error")}
          description={userConfigStore.messageError}
          type="error"
          showIcon
          closable
        />
      ) : (
        ""
      )}
      {userConfigStore.state === StoreState.SUCCESS ? (
        <Alert
          message={t("success")}
          description={t("dataEditedSuccess")}
          type="success"
          showIcon
          closable
        />
      ) : (
        ""
      )}
      <ChangePasswordModal
        onCancel={() => authStore.setModalChangePasswordVisible(false)}
      />
      <Card title={t("myData")}>
        <Form
          form={form}
          layout="vertical"
          name="form"
          size="large"
          initialValues={{ modifier: "public" }}
          style={{ marginTop: 10, marginLeft: "17%" }}
        >
          <Flex alignItems={"center"}>
            <Form.Item
              name="name"
              label={t("name")}
              style={{ display: "inline-block", width: "40%" }}
              rules={[
                {
                  required: true,
                  message: t("pleaseInputYourName") as string,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="Phone"
              label={t("phone")}
              style={{
                paddingLeft: "10px",
                width: "40%",
              }}
            >
              <InternationalPhoneInput
                style={{ width: "100%" }}
                phoneData={phoneData}
                setPhoneData={setPhoneData}
              />
            </Form.Item>
          </Flex>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              name="middleName"
              label={t("middleName")}
              style={{
                display: "inline-block",
                width: "40%",
              }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="lastName"
              label={t("lastName")}
              style={{
                display: "inline-block",
                paddingLeft: 10,
                width: "40%",
              }}
              rules={[
                {
                  required: true,
                  message: t("pleaseInputYourLastName") as string,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form.Item>

          <Form.Item className="inputLogin" name="login" label="Login">
            <Input readOnly={true} style={{ backgroundColor: "#82827247" }} />
          </Form.Item>

          <Button
            type="primary"
            style={{
              display: "inline-block",
              marginLeft: 10,
              marginTop: 48,
              width: 163,
            }}
            onClick={() => authStore.setModalChangePasswordVisible(true)}
          >
            {t("changePassword")}
          </Button>

          <Form.Item
            name="email"
            label={t("email")}
            style={{
              display: "inline-block",
              paddingLeft: 10,
              width: "40%",
            }}
            rules={[
              {
                required: true,
                message: t("pleaseInputYourEmail") as string,
              },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            style={{ display: "inline-block", width: "40%" }}
            name="position"
            label={t("selectYourJobPosition")}
            rules={[
              {
                required: true,
                message: t("pleaseSelectJobPosition") as string,
              },
            ]}
          >
            <Select id="selectPosition">
              {userConfigStore.userPosition.map((value) => (
                <Select.Option value={value.id} key={value.id}>
                  {value.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            style={{ display: "inline-block", width: "40%", paddingLeft: 10 }}
            name="area"
            label={t("selectYourJobArea")}
            rules={[
              {
                required: true,
                message: t("pleaseSelectJobArea") as string,
              },
            ]}
          >
            <Select id="selectArea">
              {userConfigStore.userArea.map((value) => (
                <Select.Option value={value.id} key={value.id}>
                  {value.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="editButton"
            style={{ display: "inline-block", width: "80%" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{
                float: "right",
                backgroundColor: "orange",
                borderColor: "orange",
                marginTop: 10,
              }}
              onClick={handleOnEdit}
            >
              {t("edit")}
            </Button>
          </Form.Item>
          <Spin
            style={{
              marginTop: 24,
              marginLeft: -91,
            }}
            spinning={userConfigStore.state === StoreState.PEDDING}
          ></Spin>
        </Form>
      </Card>
    </Box>
  );
};

export default observer(MyData);
