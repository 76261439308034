import { action, makeObservable, observable } from "mobx";
import { getEnv } from "mobx-easy";
import { DataType, EnvironmentalType } from "../../../enum/EnvironmentalType";
import { StoreState } from "../../../enum/StoreState";
import { RootEnv } from "../../setup/create-store";
import { IStation } from "../oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import i18n from "../../../i18n";
export default class AnalyticsStore {
  state: string = StoreState.DONE;

  messageError: string = "";

  stateChart: string = StoreState.DONE;

  mapCenterLatitude: number = 0;

  mapCenterLongitude: number = 0;

  tabStateActivty: EnvironmentalType = EnvironmentalType.WATER_FLOW;

  stations: IStation[] = [];

  selectedStation: number = 0;

  noStationAvailableInfo: string = "";

  actionCallMeteogramChart: boolean = false;

  constructor() {
    makeObservable(this, {
      state: observable,
      messageError: observable,
      mapCenterLatitude: observable,
      mapCenterLongitude: observable,
      tabStateActivty: observable,
      stations: observable,
      selectedStation: observable,
      stateChart: observable,
      noStationAvailableInfo: observable,
      actionCallMeteogramChart: observable,
      setState: action,
      setMessageError: action,
      setStateChart: action,
      setNoStationAvailableInfo: action,
      setActionCallMeteogramChart: action,
    });
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setMessageError(value: string) {
    this.messageError = value;
  }

  setStateChart(value: StoreState) {
    this.stateChart = value;
  }

  setTabStateActivity(environmentalType: EnvironmentalType) {
    this.tabStateActivty = environmentalType;
  }

  setStations(values: IStation[]) {
    this.stations = values;
  }

  setSelectedStation(values: number) {
    this.selectedStation = values;
  }

  setNoStationAvailableInfo(value: string) {
    this.noStationAvailableInfo = value;
  }

  setMapCenter(): void {
    const foundStation = this.stations.find(
      (value) => value.station_id === this.selectedStation
    );

    this.mapCenterLatitude = foundStation?.station_lat || 0;
    this.mapCenterLongitude = foundStation?.station_lon || 0;
  }

  setActionCallMeteogramChart() {
    this.actionCallMeteogramChart = !this.actionCallMeteogramChart;
  }

  reset() {
    this.setStations([]);
    this.setSelectedStation(0);
    this.setTabStateActivity(EnvironmentalType.WATER_FLOW);
    this.setState(StoreState.DONE);
  }

  async getMeasuredStationsAPI(
    environmental_type: EnvironmentalType,
    macroRegionId: number
  ) {
    this.setState(StoreState.PEDDING);
    const { analyticsService } = getEnv<RootEnv>();

    this.setStations([]);
    this.setSelectedStation(0);
    try {
      this.setTabStateActivity(environmental_type);
      const stationsResult: IStation[] =
        await analyticsService.getMeasuredStations(
          environmental_type,
          macroRegionId
        );

      if (stationsResult.length > 0) {
        const found = stationsResult.find(
          (value) => value.station_id === this.selectedStation
        );
        if (!found) {
          this.setSelectedStation(stationsResult[0].station_id);
        }
      } else {
        this.setSelectedStation(0);
      }

      this.setStations(stationsResult);
      this.setMapCenter();
      this.setActionCallMeteogramChart();
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t("analytics.errorToTryGetDataStationOnAnalytics")
      );
    }
    if (this.selectedStation === 0) {
      this.setNoStationAvailableInfo(
        i18n.t("analytics.sorryButNoSensorsAreAvailableforData")
      );
      this.setNoStationAvailableInfo("");
    }
  }
}
