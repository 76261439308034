import { useEffect, useState } from "react";

export default function useListenStorageChange(key: string) {
  const [localStorageValue, setLocalStorageValue] = useState<boolean>(
    localStorage.getItem(key) === "true"
  );

  useEffect(() => {
    function storageChange(e: StorageEvent) {
      const newValue = localStorage.getItem(key) === "true";
      setLocalStorageValue(newValue);
    }

    window.addEventListener("storage", storageChange);
    return () => {
      window.removeEventListener("storage", storageChange);
    };
  }, [key]);

  return {
    localStorageValue,
  };
}
