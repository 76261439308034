import MenuViewStore from "./menuView/menuViewStore";
import NavigatorAndWindowStore from "./navigatorAndWindowStore/navigatorAndWindowStore";
import WathIsNewStore from "./wathIsNew/wathIsNewStore";

export default class UiStore {
  menuViewStore: MenuViewStore;
  whatIsNewStore: WathIsNewStore;
  navigatorAndWindowStore: NavigatorAndWindowStore;

  constructor() {
    this.menuViewStore = new MenuViewStore();
    this.whatIsNewStore = new WathIsNewStore();
    this.navigatorAndWindowStore = new NavigatorAndWindowStore();
  }
}
