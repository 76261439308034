import React, { FC, useEffect, useState } from "react";
import { Button, Table, Result } from "antd";
import { IUser } from "../../types/IUser";

export interface UserTableRowProps {
  key: React.Key;
  user: string;
}

export interface UserTableProps {
  users: IUser[];
  pageSize?: number;
  onChangeTable: (
    selectedRowKeys: React.Key[],
    selectedRows: UserTableRowProps[]
  ) => void;
}

export const UserTable: FC<UserTableProps> = ({
  users,
  pageSize,
  onChangeTable,
}) => {
  const [dataTable, setDataTable] = useState<UserTableRowProps[]>([]);

  useEffect(() => {
    const userTableRowProps: UserTableRowProps[] = users.map((user) => {
      return {
        key: user.id,
        user: user.name,
      };
    });
    setDataTable(userTableRowProps);
  }, [users]);

  const columns = [
    {
      title: "Users",
      dataIndex: "user",
      render: (text: string) => <a>{text}</a>,
    },
  ];
  return (
    <Table
      rowSelection={{
        type: "checkbox",
        onChange: onChangeTable,
      }}
      columns={columns}
      dataSource={dataTable}
      pagination={{ pageSize: pageSize || 10 }}
    />
  );
};
