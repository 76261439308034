import api from "./api";

export default class OperationRuleAlertUserService {
  async getOperationRuleAlertUser(operationRuleAlertId?: number) {
    let result;
    result = await api.post(
      "/v1/operationRuleAlert/getOperationRuleAlertUser",
      {
        operationRuleAlertId: operationRuleAlertId,
      }
    );
    return result.data;
  }
}
