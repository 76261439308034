import { useEffect } from "react";
import { useStores } from "stores/setup/use-store";

export default function useLoadFavoriteInsight(
  hasSearch: boolean,
  handleChangeInsight: (value: string) => void
) {
  const {
    dataStores: { forecastChartPageStore, forecastChartInsightStore },
  } = useStores();

  useEffect(() => {
    if (
      forecastChartPageStore.selectedStation &&
      forecastChartPageStore.regionId &&
      forecastChartInsightStore.favoriteInsight
    ) {
      forecastChartPageStore.setLoadFavoriteInsight(
        !forecastChartPageStore.loadFavoriteInsight
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    forecastChartPageStore.regionId,
    forecastChartPageStore.selectedStation,
    forecastChartInsightStore.favoriteInsight,
  ]);

  useEffect(() => {
    if (
      forecastChartInsightStore.favoriteInsight &&
      !hasSearch &&
      !forecastChartPageStore.favoriteInsightAlreadyLoaded
    ) {
      handleChangeInsight(forecastChartInsightStore.favoriteInsight);
      forecastChartPageStore.setFavoriteInsightAlreadyLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastChartPageStore.loadFavoriteInsight]);
}
