import { EnvironmentalVariables } from "enum/EnvironmentalVariables";
import { reverseDirection } from "util/meteogramCharts/meteogramFormatTooltip/meteogramFormatTooltip";
import useConvertValueToUserUnit from "hooks/useConvertValueToUserUnit";

export interface SensorMarkerIcon {
  degrees?: number;
  value?: number;
  markerIcon?: string | undefined;
  defaultUnit?: string;
  environmentalVariable?: string;
  isSelected?: boolean;
}

export const SensorMarker = ({
  degrees,
  value,
  markerIcon,
  defaultUnit,
  environmentalVariable,
  isSelected = false,
}: SensorMarkerIcon) => {
  const { unit } = useConvertValueToUserUnit({
    value,
    environmentalVariable,
    defaultUnit,
  });

  const isCurrent =
    environmentalVariable === EnvironmentalVariables.CURRENT_VEL;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 6,
        padding: isSelected ? "0.1rem 0.4rem" : "0 0.3rem",
        width: "max-content",
        background: "#286795",
        borderRadius: 6,
        border: isSelected ? "0.1rem solid white" : "",
      }}
    >
      {!!markerIcon && (
        <img
          style={{
            transform: isCurrent
              ? `rotate(${reverseDirection(degrees ?? 0)}deg)`
              : `rotate(${degrees}deg)`,
            minWidth: "0.5rem",
            minHeight: "0.5rem",
          }}
          src={markerIcon}
          alt="marker icon"
        />
      )}

      {unit && (
        <h3
          style={{
            fontSize: "0.6rem",
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          {unit.toFixed(1)}
        </h3>
      )}
    </div>
  );
};
