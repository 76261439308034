import { IAtmOceanMeteogram } from "../../atmOcean/atmOceanModel";

export const chartMeteogramYAxisMock = [
  {
    // Primary yAxis
    labels: {
      format: "{value}",
      style: {
        color: "#00000",
      },
    },
    title: {
      text: "Environmental Variable 1",
      style: {
        color: "#00000",
      },
    },
  },
  {
    // Secondary yAxis
    title: {
      text: "Environmental Variable 2",
      style: {
        color: "#00000",
      },
    },
    labels: {
      format: "{value} mm",
      style: {
        color: "#00000",
      },
    },
    opposite: true,
  },
];

export const chartMeteogramXAxisMock = [
  {
    currentDateIndicator: {
      color: "#54f542",
      width: 2,
      zIndex: 4,
      dashStyle: "dash",
    },
    type: "datetime",
    gridLineWidth: 1,
    offset: 40,
  },
];

export const chartMeteogramDataMock: IAtmOceanMeteogram = {
  plotOptions: {
    series: { pointInterval: 0, pointStart: 0, timezoneOffset: 180 },
  },
  series: [
    {
      name: "",
      environmental_variable: "",
      units: "",
      units_symbol: "",
      min: 0,
      max: 0,
      data: [],
    },
  ],
};
