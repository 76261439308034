import { EnvironmentalType } from "../enum/EnvironmentalType";
import { IOccurrence, IOccurrenceAnalysisResponse, IOccurrenceDescription } from "../types/IOccurrence";
import api from "./api";

type IOccurrenceIdRequired = Omit<IOccurrence, "id"> &
  Required<Pick<IOccurrence, "id">>;

export interface IGetOccurrenceAnalysis {
  stationId: number;
  regionId: number;
  environmentalType: EnvironmentalType;
  initialDate: number;
  finalDate?: number;
}

export default class OccurrenceService {
  async getOccurrencies(): Promise<IOccurrence[]> {
    const response = await api.get(`/v1/occurrence`);
    return response.data;
  }

  async getOccurrence(id: string): Promise<IOccurrence> {
    const response = await api.get(`/v1/occurrence/${id}`);
    return response.data;
  }

  async createOccurrence(
    occurrence: Omit<IOccurrence, "id">
  ): Promise<{ id: string }> {
    const response = await api.post(`/v1/occurrence`, { ...occurrence });
    return response.data;
  }

  async updateOccurrence(
    occurrence: IOccurrenceIdRequired
  ): Promise<{ success: boolean }> {
    const response = await api.put(`/v1/occurrence/${occurrence.id}`, {
      ...occurrence,
    });
    return response.data;
  }

  async deleteOccurrence(id: string): Promise<{ success: boolean }> {
    const response = await api.delete(`/v1/occurrence/${id}`);

    return response.data;
  }

  async getOccurrencesDescription(): Promise<IOccurrenceDescription[]> {
    const response = await api.post(`/v1/occurrence/get-occurrences-description`);
    return response.data;
  }

  async getOccurrenceAnalysis({
    regionId, stationId, environmentalType, initialDate, finalDate
  }: IGetOccurrenceAnalysis): Promise<IOccurrenceAnalysisResponse> {
    const response = await api.post(`/v1/occurrence/get-occurrence-analysis`, {
      initialDate,
      finalDate,
      stationId,
      regionId,
      environmentalType
    });
    return response.data;
  }

  async importOccurrences(
    occurrenceFile: FormData
  ): Promise<{ success: boolean }> {
    const response = await api.post(`/v1/occurrence/upload`, occurrenceFile, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
}
