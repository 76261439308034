import React, { useCallback } from "react";
import NavGroup from "./NavGroup";
import NavItem from "./NavItem";
import { findChartMenuParentId } from "../ChartMenuConfig";

export interface ChartMenuData {
  id: string;
  text: string;
  icon: string;
  iconStyle?: React.CSSProperties;
  meteogram?: boolean;
  children?: ChartMenuData[];
}

export interface ChartMenuProps {
  data: ChartMenuData[];
  tabStateActivty: string;
  styles?: React.CSSProperties;
  onClick: (id: string) => void;
}

const ChartMenu: React.FC<ChartMenuProps> = ({
  data,
  tabStateActivty,
  styles,
  onClick,
}: ChartMenuProps) => {
  const isChartMenuOpen = useCallback(
    (
      id: string,
      item: ChartMenuData,
      menu: ChartMenuData[],
      tabStateActivty: string
    ): boolean => {
      if (item.id === id && tabStateActivty === id) {
        return true;
      } else {
        const _parentId = findChartMenuParentId(menu, tabStateActivty);
        if (_parentId) {
          return isChartMenuOpen(id, item, menu, _parentId);
        }
      }

      return false;
    },
    []
  );

  return (
    <nav
      style={{
        background: "rgba(0, 0, 0, 0.37)",
        color: "#546063",
        borderRadius: 4,
        fontWeight: "bold",
        cursor: "pointer",
        width: "35px",
        ...styles,
      }}
    >
      {data.map((item) => (
        <NavGroup
          environmentalType={item.id}
          event={() => onClick(item.id)}
          isActive={tabStateActivty === item.id}
          isOpen={isChartMenuOpen(item.id, item, data, tabStateActivty)}
          text={item.text}
          icon={<img src={item.icon} alt={item.text} style={item.iconStyle} />}
        >
          {item.children?.map((subItem) => (
            <NavItem
              isActive={tabStateActivty === subItem.id}
              isOpen={true}
              environmentalType={subItem.id}
              isSubItem={true}
              event={() => onClick(subItem.id)}
              text={subItem.text}
              icon={
                <img
                  src={subItem.icon}
                  alt={subItem.text}
                  style={subItem.iconStyle}
                />
              }
            />
          ))}
        </NavGroup>
      ))}
    </nav>
  );
};

export default ChartMenu;
