import { message } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { StoreState } from "../../../enum/StoreState";
import { useStores } from "../../../stores/setup/use-store";
import OperationRuleLocationModal from "./Components/OperationRuleLocationModal";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { divIcon, LatLngExpression } from "leaflet";
import "./styles.css";

import "video-react/dist/video-react.css";
import { getEnv } from "mobx-easy";
import { RootEnv } from "../../../stores/setup/create-store";
import { IStation } from "../../../stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import {
  Box,
  Select,
  Button,
  Flex,
  FormLabel,
  useDisclosure,
  useToast,
  AlertIcon,
  Alert,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import EmptyState from "../../../components/EmptyState/EmptyState";
import { IOperationRuleLocation } from "../../../stores/data/operationRuleLocations/OperationRuleLocationModel";
import { AlertDelete } from "../../../components/AlertDelete/AlertDelete";
import InsightList from "../../../components/List/List";
import { useFormik } from "formik";
import { IOperationRule } from "../../../stores/data/operationRules/OperationRuleModel";
import Loading from "../../../components/Loading/Loading";
import { renderToStaticMarkup } from "react-dom/server";
import i18n from "i18n";

export interface OperatinRuleLocationFormProps extends IOperationRuleLocation {
  stationRegion: string;
}

const OperationRuleLocationDetail: React.FC = () => {
  const {
    dataStores: {
      operationRuleStore,
      operationRuleLocationStore,
      operationRuleLocationInsightStore,
      oceanWeatherIntelligenceStore,
      regionStore,
      authStore,
    },
  } = useStores();
  const { oceanWeatherIntelligenceService } = getEnv<RootEnv>();

  const [visibleModalvideo, setVisibleModalVideo] = useState(false);
  const [allStations, setAllStations] = useState<IStation[]>([]);
  const [centerMap, setCenterMap] = useState<LatLngExpression>();
  const [locationRemove, setLocationRemove] =
    useState<IOperationRuleLocation | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const toast = useToast();

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const formik = useFormik({
    initialValues: {
      name: "",
      initialName: "",
      description: "",
      stationRegion: "",
      regionId: 0,
      macroRegionId: 0,
    },
    onSubmit: () => {},
  });

  const formikEdit = useFormik({
    initialValues: {
      name: "",
      initialName: "",
      description: "",
      stationRegion: "",
      regionId: 0,
      macroRegionId: 0,
    },
    onSubmit: () => {},
  });

  const { t } = useTranslation("translation", {
    keyPrefix: "oceanWeatherIntelligence.operationRuleLocation",
  });

  useEffect(() => {
    if (operationRuleStore.operationRules.length === 0) {
      operationRuleStore.getOperationRulesApi();
    } else if (
      operationRuleStore.operationRuleMainId > 0 &&
      operationRuleLocationStore.operationRuleLocations?.length === 0
    ) {
      operationRuleLocationStore
        .getOperationRuleLocationsApi(operationRuleStore.operationRuleMainId)
        .then((locations) => {
          if (!locations?.length) {
            operationRuleLocationStore.setHasLocations(false);
            operationRuleLocationInsightStore.setHasInsights(false);
          } else {
            operationRuleLocationStore.setHasLocations(true);
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAllStations([]);
    let stationsConcat: IStation[] = [];
    regionStore.macroRegions.forEach((macroRegion) => {
      oceanWeatherIntelligenceService
        .getStationForLocation(macroRegion.id)
        .then((stations) => {
          stationsConcat = stationsConcat.concat(stations);
          setAllStations(stationsConcat);
          if (macroRegion.main && stations.length) {
            setCenterMap({
              lat: Number(stations[0].station_lat),
              lng: Number(stations[0].station_lon),
            });
          }
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionStore.macroRegions]);

  const handleSelectDashboardChange = (value: number) => {
    if (value) {
      operationRuleLocationStore.setOperationRuleLocationMainId(value);
      operationRuleStore.setOperationRuleMain(value);
      operationRuleLocationStore.getOperationRuleLocationsApi(value);
      operationRuleLocationInsightStore.setHasInsights(true);
      if (operationRuleLocationStore.operationRuleLocationMainId > 0) {
        operationRuleLocationInsightStore.getOperationRuleLocationInsightApi(
          operationRuleLocationStore.operationRuleLocationMainId
        );
      } else {
        operationRuleLocationInsightStore.setOperationRuleLocationItens([]);
      }

      oceanWeatherIntelligenceStore.setChangeForUpdate({
        update: true,
        expiration: 0,
      });
    }
  };

  const handleOnEdit = (value: IOperationRuleLocation) => {
    if (
      !operationRuleStore.operationRuleMainId ||
      operationRuleStore.operationRuleMainId <= 0
    ) {
      message.info(t("youNeedSelectDashboardToAddLocation"));
    } else {
      formikEdit.setFieldValue("name", value.name);
      formikEdit.setFieldValue("initialName", value.initialName);
      formikEdit.setFieldValue("description", value.description);
      formikEdit.setFieldValue(
        "stationRegion",
        `${value.stationId}-${value.regionId}`
      );
      formikEdit.setFieldValue("regionId", value.regionId);
      formikEdit.setFieldValue("macroRegionId", value.macroRegionId);
      setIsEditing(true);
      operationRuleLocationStore.setOperationRuleLocation(value);
      onOpen();
    }
  };

  const handleOnConfirmDelete = () => {
    const locationId = locationRemove?.id;
    if (locationId && locationId > 0) {
      operationRuleLocationStore
        .deleteOperationRuleLocation(locationId)
        .then((success) => {
          if (success) {
            if (!operationRuleLocationStore.operationRuleLocations?.length) {
              operationRuleLocationStore.setHasLocations(false);
              operationRuleLocationInsightStore.setHasInsights(false);
            } else {
              operationRuleLocationStore.setHasLocations(true);
            }
          }
        });
      oceanWeatherIntelligenceStore.setChangeForUpdate({
        update: true,
        expiration: 0,
      });
      if (
        operationRuleLocationStore.operationRuleLocationMainId === locationId
      ) {
        operationRuleLocationStore.setOperationRuleLocationMainId(
          operationRuleLocationStore.operationRuleLocations?.length === 0
            ? 0
            : operationRuleLocationStore.operationRuleLocations[0].id
        );
        operationRuleLocationInsightStore.reset();
        operationRuleLocationStore.setActionCallInsightsList();
      }
      setLocationRemove(null);
      onDeleteClose();
    } else {
      message.error(t("locationRemovalFailedContactUs"));
    }
  };

  const handleAddOrEditData = (
    values: OperatinRuleLocationFormProps,
    isEditing?: boolean
  ) => {
    const { name, initialName, description, stationRegion, macroRegionId } =
      values;

    const hasValue =
      name && initialName && description && stationRegion && macroRegionId;

    if (hasValue) {
      const stationRegionArray = stationRegion.split("-");
      if (stationRegionArray?.length !== 2) {
        return;
      }
      const stationId = stationRegionArray[0];
      const regionId = stationRegionArray[1];

      const stationEntity = oceanWeatherIntelligenceStore.getStationForLocation(
        parseInt(stationId),
        parseInt(regionId)
      );
      if (/^[A-Z0-9]{4}$/.test(initialName)) {
        const locationId = operationRuleLocationStore.operationRuleLocation?.id;

        let operationRuleLocation = {
          id: 0,
          name: name,
          initialName: initialName,
          description: description,
          stationId: parseInt(stationId.toString()),
          regionId: stationEntity.region_id!,
          macroRegionId,
          operationRuleId: operationRuleStore.operationRuleMainId,
        };

        operationRuleLocationStore.setOperationRuleLocation(
          operationRuleLocation
        );
        if (isEditing && locationId && locationId > 0) {
          operationRuleLocation = { ...operationRuleLocation, id: locationId };
          operationRuleLocationStore.setOperationRuleLocation(
            operationRuleLocation
          );
          operationRuleLocationStore.updateOperationRuleLocation().then(() => {
            if (operationRuleLocationStore.state !== StoreState.ERROR) {
              toast({
                title: t("locationSuccessOnSave"),
                status: "success",
                position: "bottom-right",
                isClosable: true,
              });
            }
          });
        } else {
          operationRuleLocationStore.addOperationRuleLocation().then(() => {
            if (operationRuleLocationStore.state !== StoreState.ERROR) {
              toast({
                title: t("locationSuccessOnSave"),
                status: "success",
                position: "bottom-right",
                isClosable: true,
              });
            }
          });
        }

        oceanWeatherIntelligenceStore.setChangeForUpdate({
          update: true,
          expiration: 0,
        });

        onClose();
      } else {
        toast({
          title: t("youNeed4AlphaNumericToTheKey"),
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    } else {
      toast({
        title: t("youNeedToFillAllTheFields"),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const handleOpenDeleteModal = (location: IOperationRuleLocation) => {
    setLocationRemove(location);
    onDeleteOpen();
  };

  const handleOpenAddModal = () => {
    setIsEditing(false);
    onOpen();
    formik.resetForm();
  };

  const customMarkerIcon = () => {
    return divIcon({
      html: renderToStaticMarkup(
        <i
          style={{ color: "rgb(46, 50, 51, 1)" }}
          className="fas fa-map-marker-alt fa-1x"
        />
      ),
    });
  };

  return (
    <Box>
      {operationRuleLocationStore.state === StoreState.ERROR && (
        <Alert status="error" mb={4} color="black">
          <AlertIcon />
          {operationRuleLocationStore.messageError}
        </Alert>
      )}
      {authStore.readOnlyUser && (
        <Alert bg="#bee3f8" status="info" mb={4} color="gray.700">
          <AlertIcon />
          {i18n.t("readingModePleaseContact")}
        </Alert>
      )}

      {operationRuleLocationStore.state === StoreState.PEDDING && <Loading />}

      <OperationRuleLocationModal
        formik={isEditing ? formikEdit : formik}
        isOpen={isOpen}
        onClose={onClose}
        initialRef={initialRef}
        onConfirm={handleAddOrEditData}
        title={isEditing ? t("editLocation") : t("addNewLocation")}
        confirmButtonText={
          isEditing ? (t("save") as string) : (t("add") as string)
        }
        isEditing={isEditing}
      />

      <AlertDelete
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        titleLabel={t("deleteLocation")}
        bodyText={t("areYouSureCantUndoThis", {
          locationName: locationRemove?.name,
        })}
        cancelButtonLabel={t("cancel")}
        deleteButtonLabel={t("delete")}
        handleDelete={handleOnConfirmDelete}
      />

      <Flex>
        <Box>
          <FormLabel color={"newBlue.500"}>{t("selectDashboard")}</FormLabel>
          <Select
            w={"260px"}
            borderRadius={8}
            color={"newBlue.500"}
            backgroundColor="#ffffff"
            size="md"
            key={`selectDashboard-${operationRuleStore.operationRuleMainId}`}
            onChange={(e) =>
              handleSelectDashboardChange(parseInt(e.target.value))
            }
            value={operationRuleStore.operationRuleMainId}
            _hover={{ borderColor: "newBlue.500" }}
            _focus={{ borderColor: "none" }}
            name={t("selectDashboard") as string}
          >
            {operationRuleStore.operationRules.map((value: IOperationRule) => (
              <option
                style={{ color: "black" }}
                value={value.id}
                key={value.id}
              >
                {value.name}
              </option>
            ))}
          </Select>
        </Box>
        {!authStore.readOnlyUser && (
          <Button
            mt={8}
            ml={4}
            layerStyle="ocean"
            _hover={{ layerStyle: "ocean" }}
            leftIcon={<AddIcon />}
            onClick={handleOpenAddModal}
          >
            {t("addNewLocation")}
          </Button>
        )}
      </Flex>

      <Box mt={4}>
        <MapContainer
          key={`mapLocationStation-${centerMap}`}
          center={centerMap}
          zoom={5}
          zoomControl={true}
          scrollWheelZoom={true}
          attributionControl={false}
          style={{ height: "25vh", borderRadius: "16px" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.i4sea.com">i4sea</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {allStations.map((value) =>
            value.station_lat !== null && value.station_lon ? (
              <Marker
                key={`marker${value.station_id}-${value.macro_region_origin}`}
                position={[value.station_lat, value.station_lon]}
                icon={customMarkerIcon()}
              >
                <Popup>{value.station_name}</Popup>
              </Marker>
            ) : (
              ""
            )
          )}
        </MapContainer>
      </Box>

      {operationRuleLocationStore.operationRuleLocations?.length > 0 && (
        <InsightList
          list={operationRuleLocationStore.operationRuleLocations}
          onEdit={handleOnEdit}
          onDelete={handleOpenDeleteModal}
          showDeleteButton={!authStore.readOnlyUser}
        />
      )}

      {!operationRuleLocationStore.operationRuleLocations?.length &&
        !operationRuleLocationStore.hasLocations && (
          <EmptyState
            key={`id-${operationRuleLocationStore.hasLocations}`}
            description={t("addNewLocationToYourDashboard")}
            buttonAction={handleOpenAddModal}
            buttonIcon={<AddIcon />}
            buttonText={
              !authStore.readOnlyUser
                ? (t("addNewLocation") as string)
                : undefined
            }
          />
        )}
    </Box>
  );
};

export default observer(OperationRuleLocationDetail);
