import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";
import { useStores } from "../../../stores/setup/use-store";
import {
  MapContainer,
  Marker,
  TileLayer,
  Tooltip,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { divIcon, LatLngExpression, LeafletMouseEvent } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { IStation } from "../../../stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import { useTranslation } from "react-i18next";

import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";

import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import { TooltipContent } from "../../../components/TooltipComponent/ToolltipComponent";
import { EnvironmentalType } from "enum/EnvironmentalType";
import SensorMarkerIcon from "pages/ForecastChart/ForecastChartPanelMap/Components/SensorMarkerIcon";
import { VisualizationType } from "enum/VisualizationType";
import { ISensorMeasuredData, MeasuredData } from "types/ISensorMeasuredData";

interface MapMarkerChildProps {
  station: IStation;
}

const PanelMap: React.FC = () => {
  const {
    dataStores: {
      forecastChartPageStore,
      regionStore,
      riverForecastChartStore,
    },
  } = useStores();

  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartPanelMap",
  });

  const MapChild = () => {
    const map = useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        map.locate();
        riverForecastChartStore.setOpenMeteogram(true);

        riverForecastChartStore.setSelectedStation(0);
        riverForecastChartStore.setClickMapLatLng(e.latlng);
        riverForecastChartStore.updateMacroRegionOrigin(
          { lat, lng },
          regionStore.macroRegions
        );
        riverForecastChartStore.setActionCallMapClick();
      },
    });

    return <></>;
  };

  const MapMarkerChild: React.FC<MapMarkerChildProps> = (props) => {
    const { station } = props;
    const {
      station_id,
      station_name,
      station_lat,
      station_lon,
      region_id,
      measuredData,
    } = station;

    const handleMarkerClick = useCallback(
      (e: LeafletMouseEvent) => {
        riverForecastChartStore.setOpenMeteogram(true);

        if (e.latlng === riverForecastChartStore.clickMapLatLng) {
          return;
        }
        riverForecastChartStore.setSelectedStation(station_id);
        riverForecastChartStore.setRegionId(region_id as number);
        riverForecastChartStore.setClickMapLatLng(e.latlng);

        riverForecastChartStore.updateMacroRegionOrigin(
          e.latlng,
          regionStore.macroRegions
        );
        riverForecastChartStore.setActionCallMapClick();
      },
      [region_id, station_id]
    );

    const stationName = station_name;

    const isDataDelayed = measuredData && !measuredData.length;

    const isSensorTab =
      riverForecastChartStore.visualizationType === VisualizationType.SENSOR;

    const getMeasuredDataValue = (key: keyof MeasuredData) =>
      isSensorTab && measuredData?.[0] ? measuredData[0][key] : undefined;

    return (
      <Marker
        position={[station_lat, station_lon]}
        icon={getIconForStation(props.station)}
        eventHandlers={{
          click: handleMarkerClick,
        }}
      >
        <Tooltip>
          <TooltipContent
            zoomLevel={forecastChartPageStore.mapZoom}
            title={stationName}
            value={getMeasuredDataValue("value") as number}
            defaultUnit={getMeasuredDataValue("units") as string}
            lastUpdate={
              isSensorTab && measuredData?.length
                ? new Date(measuredData?.[0].date)
                : undefined
            }
            environmentalVariable={
              getMeasuredDataValue("environmental_variable") as string
            }
            showFooterText={isSensorTab ? isDataDelayed : undefined}
          />
        </Tooltip>
      </Marker>
    );
  };

  const customMarkerIconSelected = divIcon({
    html: renderToStaticMarkup(
      <i style={{ color: "#2E3233" }} className="fas fa-map-marker fa-2x" />
    ),
  });

  const customMarkerIcon = divIcon({
    html: renderToStaticMarkup(
      <i style={{ color: "#2E3233" }} className="fas fa-map-marker-alt fa-1x" />
    ),
  });

  const customMarkerUserIconSelected = divIcon({
    html: renderToStaticMarkup(
      <i style={{ color: "#2E3233" }} className="far fa-star fa-1x" />
    ),
  });

  const getCenterMap = (): LatLngExpression => {
    if (
      riverForecastChartStore.mapCenterLatitude &&
      riverForecastChartStore.mapCenterLongitude
    ) {
      return [
        riverForecastChartStore.mapCenterLatitude,
        riverForecastChartStore.mapCenterLongitude,
      ];
    }
    return [0, 0];
  };

  const sensorMarkerIcon = (
    sensorItem: ISensorMeasuredData,
    isSelected: boolean
  ) => {
    return divIcon({
      html: renderToStaticMarkup(
        <SensorMarkerIcon
          sensorItem={sensorItem as unknown as ISensorMeasuredData}
          isSelected={isSelected}
          tabStateActivity={EnvironmentalType.WATER_FLOW}
        />
      ),
    });
  };

  const getIconForStation = useCallback(
    (station: IStation) => {
      const selectedStation = riverForecastChartStore.stations?.find(
        (marker) =>
          marker.station_id === station.station_id &&
          marker.region_id === station.region_id
      );

      const isSensorTab =
        riverForecastChartStore.visualizationType === VisualizationType.SENSOR;

      if (isSensorTab && selectedStation && selectedStation.measuredData) {
        const isSelected =
          selectedStation.station_id ===
            riverForecastChartStore.selectedStation &&
          selectedStation.region_id === riverForecastChartStore.regionId;

        return sensorMarkerIcon(
          selectedStation as unknown as ISensorMeasuredData,
          isSelected
        );
      }

      if (
        station.station_id === riverForecastChartStore.selectedStation &&
        station.region_id === riverForecastChartStore.regionId
      ) {
        return customMarkerIconSelected;
      }

      return customMarkerIcon;
    },
    [
      customMarkerIcon,
      customMarkerIconSelected,
      riverForecastChartStore.regionId,
      riverForecastChartStore.selectedStation,
      riverForecastChartStore.stations,
      riverForecastChartStore.visualizationType,
    ]
  );

  const FlyMapTo = () => {
    const map = useMap();

    useEffect(() => {
      if (forecastChartPageStore.searchedLatLng) {
        map.flyTo(forecastChartPageStore.searchedLatLng, 10);
        forecastChartPageStore.setSearchedLatLng(null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forecastChartPageStore.searchedLatLng]);

    return null;
  };

  const SearchControl = () => {
    const map = useMap();

    const handleSearchLocation = useCallback((e: any) => {
      const { x, y } = e.location;

      riverForecastChartStore.setSelectedStation(0);
      riverForecastChartStore.setClickMapLatLng({
        lat: y,
        lng: x,
      });
      riverForecastChartStore.updateMacroRegionOrigin(
        { lat: y, lng: x },
        regionStore.macroRegions
      );
    }, []);

    useEffect(() => {
      const provider = new OpenStreetMapProvider();
      //@ts-ignore
      const searchControl = new GeoSearchControl({
        provider,
        marker: {
          icon: customMarkerUserIconSelected,
        },
        searchLabel: t("searchAddress"),
        autoClose: true,
        retainZoomLevel: true,
      });

      searchControl.__proto__.onSubmit = function ({ query, data }: any) {
        const latLongRegex = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
        const match = query.match(latLongRegex);

        if (match) {
          const [lat, lng] = query.split(",");
          map.flyTo(
            {
              lat,
              lng,
            },
            15
          );
          riverForecastChartStore.setSelectedStation(0);
          riverForecastChartStore.setClickMapLatLng({
            lat,
            lng,
          });
          riverForecastChartStore.updateMacroRegionOrigin(
            { lat, lng },
            regionStore.macroRegions
          );
        } else if (data) {
          this.showResult(data);
        }
      };

      map.on("geosearch/showlocation", handleSearchLocation);

      map.addControl(searchControl);

      return () => {
        map.removeControl(searchControl);
        map.off("geosearch/showlocation", handleSearchLocation);
      };
    }, [map, handleSearchLocation]);

    useEffect(() => {
      const handleResizeTooltip = () => {
        const zoom = map.getZoom();
        forecastChartPageStore.setMapZoom(zoom);
      };

      map.on("zoom", handleResizeTooltip);
    }, [map]);

    return null;
  };

  return (
    <MapContainer
      key={`mapStation-${riverForecastChartStore.mapCenterLatitude}`}
      center={getCenterMap()}
      zoom={forecastChartPageStore.mapZoom}
      scrollWheelZoom={true}
      className="map"
      attributionControl={false}
      zoomControl={true}
    >
      <FlyMapTo key={`id-${forecastChartPageStore.searchedLatLng}`} />

      <SearchControl />

      <MapChild />

      {riverForecastChartStore.clickMapLatLng &&
        riverForecastChartStore.selectedStation === 0 && (
          <Marker
            position={riverForecastChartStore.clickMapLatLng}
            icon={customMarkerUserIconSelected}
          ></Marker>
        )}

      <TileLayer
        attribution='&copy; <a href="https://www.i4sea.com">i4sea</a>'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <div>
        {riverForecastChartStore.stations.map((station) => (
          <MapMarkerChild
            key={`${station.station_id}-${station.region_id}`}
            station={station}
          />
        ))}
      </div>
    </MapContainer>
  );
};

export default observer(PanelMap);
