import { makeAutoObservable } from "mobx";
import { getEnv } from "mobx-easy";
import { StoreState } from "../../../enum/StoreState";
import { RootEnv } from "../../setup/create-store";
import { IAuthProfilePayload } from "./AuthModel";
import i18n from "../../../i18n";

export default class AuthStore {
  state: string = StoreState.DONE;

  messageError: string = "";

  recoveryUserId: number = 0;

  modalVisible: boolean = false;

  childModalVisible: boolean = false;

  modalChangePasswordVisible: boolean = false;

  superUser: boolean = false; // internal adminstrator i4sea user

  adminUser: boolean = false; // company adminstrator client user

  readOnlyUser: boolean = false;

  formLoginVisible: boolean = true;

  formRecoverPasswordVisible: boolean = false;

  formNewPasswordVisible: boolean = false;

  newPasswordSavedVisible: boolean = false;

  userId: number = 0;

  userMarcroRegions: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setMessageError(value: string) {
    this.messageError = value;
  }

  setRecoveryUserId(value: number) {
    this.recoveryUserId = value;
  }

  setModalVisible(value: boolean) {
    this.modalVisible = value;
  }

  setChildModalVisible(value: boolean) {
    this.childModalVisible = value;
  }

  setModalChangePasswordVisible(value: boolean) {
    this.modalChangePasswordVisible = value;
  }

  setSuperUser(value: boolean) {
    this.superUser = value;
    localStorage.setItem("@super_user", value.toString());
  }

  setAdminUser(value: boolean) {
    this.adminUser = value;
    localStorage.setItem("@admin_user", value.toString());
  }

  setReadOnlyUser(value: boolean) {
    this.readOnlyUser = value;
    localStorage.setItem("@read_only_user", value.toString());
  }

  setUserId(value: number) {
    this.userId = value;
    localStorage.setItem("userId", value.toString());
  }

  setUserMacroRegions(value: string[]) {
    this.userMarcroRegions = value;
  }

  reset() {
    this.setState(StoreState.DONE);
    this.setMessageError("");
    this.setModalVisible(false);
    this.setChildModalVisible(false);
    this.setSuperUser(false);
    this.setAdminUser(false);
    this.setReadOnlyUser(false);
    localStorage.removeItem("@super_user");
    localStorage.removeItem("@admin_user");
    localStorage.removeItem("@read_only_user");
    this.setFormRecoverPasswordVisible(false);
    this.setFormLoginVisible(true);
    this.setNewPasswordSavedVisible(false);
    this.setFormNewPasswordVisible(false);
    this.setUserId(0);
    this.setUserMacroRegions([]);
  }

  setFormLoginVisible(value: boolean) {
    this.formLoginVisible = value;
  }

  setFormRecoverPasswordVisible(value: boolean) {
    this.formRecoverPasswordVisible = value;
  }

  setFormNewPasswordVisible(value: boolean) {
    this.formNewPasswordVisible = value;
  }

  setNewPasswordSavedVisible(value: boolean) {
    this.newPasswordSavedVisible = value;
  }

  async recoveryUserApi(info: string) {
    const { authService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);
    try {
      const recoveryUserResult: IAuthProfilePayload =
        await authService.recoveryUser(info);
      if (recoveryUserResult.userId) {
        this.setRecoveryUserId(recoveryUserResult.userId);
        this.setFormRecoverPasswordVisible(false);
        this.setFormLoginVisible(false);
        this.setNewPasswordSavedVisible(false);
        this.setFormNewPasswordVisible(true);
        this.setState(StoreState.DONE);
      } else {
        this.setState(StoreState.ERROR);
      }
    } catch (error: any) {
      if (error.hasOwnProperty("response")) {
        if (error.response.status === 406) {
          this.setMessageError(i18n.t("login.usernameNoLongerValid"));
        }
      }
      this.setState(StoreState.ERROR);
    }
  }

  async updatePasswordApi(userId: number, code: string, password: string) {
    const { authService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);
    try {
      const updatePasswordResult = await authService.updatePassword(
        userId,
        code,
        password
      );
      if (updatePasswordResult.status === 200) {
        this.setState(StoreState.DONE);
        this.setFormLoginVisible(false);
        this.setFormRecoverPasswordVisible(false);
        this.setFormNewPasswordVisible(false);
        this.setNewPasswordSavedVisible(true);
      } else {
        this.setState(StoreState.ERROR);
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  async changePasswordApi(passwordOld: string, passwordNew: string) {
    const { authService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);
    try {
      const updatePasswordResult = await authService.changePassword(
        passwordOld,
        passwordNew
      );
      if (updatePasswordResult) {
        this.setState(StoreState.SUCCESS);
        setTimeout(() => {
          this.setState(StoreState.DONE);
          this.setModalChangePasswordVisible(false);
        }, 1000);
      } else {
        this.setState(StoreState.ERROR);
        this.setMessageError(i18n.t("login.errorValidatingPassword"));
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(i18n.t("login.passwordNotBeenChanged"));
    }
  }
}
