import { Menu, Divider, Tooltip, Badge, Dropdown } from "antd";
import React from "react";

import { Empty } from "antd";
import { INotification } from "../../types/INotification";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/setup/use-store";
import ExclamationCircleFilled from "@ant-design/icons/lib/icons/ExclamationCircleFilled";
import { BellOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { Center } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const NotificationComponent: React.FC<{
  notifications: INotification[];
  countNoReadNotifications: number;
}> = ({ notifications, countNoReadNotifications }) => {
  const {
    dataStores: { notificationsStore },
  } = useStores();

  const { t } = useTranslation("translation", {
    keyPrefix: "notificationManager",
  });

  const menu = (
    <Menu style={{ width: 540, maxHeight: 800, overflowY: "auto" }}>
      {notifications && notifications.length === 0 ? (
        <div style={{ padding: 20 }}>
          <Empty description={t("allClearYouDontHaveNewMessages")} />
        </div>
      ) : (
        <>
          <div style={{ marginTop: 5, marginLeft: 10, marginBottom: -15 }}>
            <h3 style={{ color: "#1890ff" }}>{t("notifications")}</h3>
          </div>
          <Divider />

          {notifications && notifications.some((x) => !x.isRead) && (
            <span
              style={{
                float: "right",
                marginTop: -15,
                color: "#1890ff",
                cursor: "pointer",
                padding: 5,
              }}
              onClick={() => notificationsStore.setNotificationReadAPI()}
            >
              {t("markAllAsRead")}
            </span>
          )}

          {notifications &&
            notifications.map((notification: INotification) => (
              <Menu.Item
                key={notification.id}
                onClick={() =>
                  notificationsStore.setNotificationReadAPI(notification.id)
                }
                style={{ background: notification.isRead ? "" : "#1890ff14" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4 style={{ fontWeight: "bold" }}>
                        {notification.title}
                      </h4>
                      {
                        <h5 style={{ marginLeft: 5 }}>
                          {DateTime.fromMillis(
                            //@ts-ignore
                            notification.createdAt.seconds * 1000,
                            {}
                          ).toFormat("HH:mm dd/MM/yyyy")}
                        </h5>
                      }
                    </div>
                    <div style={{ padding: 3, maxWidth: 500 }}>
                      <span>{notification.description}</span>
                    </div>
                  </div>
                  <div>
                    {!notification.isRead && (
                      <Tooltip
                        placement="topRight"
                        title={t("unreadNotification")}
                      >
                        <ExclamationCircleFilled style={{ color: "#1890ff" }} />
                      </Tooltip>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    borderBottom: "1px dashed",
                    color: "#c2c2c2",
                    marginTop: 15,
                    marginBottom: -5,
                  }}
                ></div>
              </Menu.Item>
            ))}
        </>
      )}
    </Menu>
  );

  return (
    <Center
      w={8}
      h={8}
      float={"right"}
      mr={2}
      borderRadius={"full"}
      zIndex={999}
      position="relative"
    >
      <Badge color={"blue.100"} count={countNoReadNotifications} size="small">
        <Dropdown overlay={menu} trigger={["click"]}>
          <BellOutlined style={{ color: "blue.100", padding: 5 }} />
        </Dropdown>
      </Badge>
    </Center>
  );
};

export default observer(NotificationComponent);
