import { Step } from "react-joyride";

import JoyrideContent from "../../../../components/JoyrideContent/JoyrideContent";
import sensorTooltipImage from "../../../../assets/sensor-tooltip.png";
import sensorChartImage from "../../../../assets/sensor-chart.png";
import guideSensorImage from "../../../../assets/sensor.png";
import sensorHighlightImage from "../../../../assets/sensor-highlight.png";
import i18n from "../../../../i18n";

export const getSensorJoySteps = (): Step[] => [
  {
    target: ".main-area",
    placement: "center",
    disableBeacon: true,
    content: (
      <JoyrideContent
        image={{
          src: guideSensorImage,
          alt: "Guide Sensor",
        }}
        text={
          i18n.t("forecastChart.forecastChartPage.stationsWithSensor") as string
        }
      />
    ),
  },
  {
    target: ".main-area",
    placement: "center",
    content: (
      <JoyrideContent
        image={{
          src: sensorHighlightImage,
          alt: "Sensor highlight",
        }}
        text={
          i18n.t(
            "forecastChart.forecastChartPage.theMostRecentMeasuredData"
          ) as string
        }
      />
    ),
  },
  {
    target: ".main-area",
    placement: "center",
    content: (
      <JoyrideContent
        image={{
          src: sensorTooltipImage,
          alt: "Sensor tooltip",
        }}
        text={
          i18n.t(
            "forecastChart.forecastChartPage.toIdentifyTheMeasured"
          ) as string
        }
      />
    ),
  },
  {
    target: ".main-area",
    placement: "center",
    content: (
      <JoyrideContent
        image={{
          src: sensorChartImage,
          alt: "Sensor chart",
        }}
        text={
          i18n.t("forecastChart.forecastChartPage.clickOnTheSensor") as string
        }
      />
    ),
  },
];
