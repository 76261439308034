import { Step } from "react-joyride";

import JoyrideContent from "../../../../components/JoyrideContent/JoyrideContent";
import i18n from "../../../../i18n";
import oceanReport from "../../../../assets/ocean-report.png";
import allowRedirect from "../../../../assets/allow-redirect.png";

export const metOceanReportJoySteps: Step[] = [
  {
    target: ".joyride-forecast-first-step",
    placement: "center",
    disableBeacon: true,
    content: (
      <JoyrideContent
        image={{
          src: oceanReport,
          alt: "Random image",
        }}
        text={
          i18n.t("forecastChart.forecastChartPage.nowYouCanAccess") as string
        }
      />
    ),
  },
  {
    target: ".joyride-metocean-button",
    content: (
      <JoyrideContent
        text={
          i18n.t(
            "forecastChart.forecastChartPage.justClickOnTheHighlighted"
          ) as string
        }
      />
    ),
  },
  {
    target: ".joyride-forecast-first-step",
    placement: "center",
    content: (
      <JoyrideContent
        text={
          i18n.t(
            "forecastChart.forecastChartPage.selectVariablesAndStations"
          ) as string
        }
        summary={
          i18n.t(
            "forecastChart.forecastChartPage.yourReportWillBeReady"
          ) as string
        }
      />
    ),
  },
  {
    target: ".joyride-forecast-first-step",
    placement: "center",
    content: (
      <JoyrideContent
        image={{
          src: allowRedirect,
          alt: "Random image",
        }}
        text={i18n.t("forecastChart.forecastChartPage.lastWarning") as string}
        summary={
          i18n.t(
            "forecastChart.forecastChartPage.youWillBeRedirected"
          ) as string
        }
      />
    ),
  },
];
