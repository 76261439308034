import { observer } from "mobx-react-lite";
import React, { useLayoutEffect, useState } from "react";
import { EnvironmentalType } from "../../../enum/EnvironmentalType";
import { useStores } from "../../../stores/setup/use-store";

import "./Styles.css";
import { Box } from "@chakra-ui/react";
import ChartMenu, { ChartMenuData } from "./Components/ChartMenu";
import { loadChartMenuData } from "./ChartMenuConfig";
import { SCREEN_HORIZONTAL_SPACING } from "../../../constants";

interface ForecastChartPanelEnvironmentalButtonsProps {
  key?: string;
  defaultSelectedKeys: EnvironmentalType[];
  disableMenuItems?: EnvironmentalType[];
}

const ForecastChartPanelEnvironmentalButtons: React.FC<ForecastChartPanelEnvironmentalButtonsProps> =
  (props) => {
    const {
      dataStores: { forecastChartPageStore, userConfigStore },
    } = useStores();

    const [data, setData] = useState<ChartMenuData[]>([]);

    useLayoutEffect(() => {
      const _data = loadChartMenuData();
      setData(_data);
    }, [userConfigStore.currentLanguage]);

    return (
      <Box
        key={`chartMenu-${userConfigStore.currentLanguage}`}
        zIndex={999999}
        right={SCREEN_HORIZONTAL_SPACING}
        position={"absolute"}
        mt="7px"
      >
        <ChartMenu
          data={data}
          tabStateActivty={forecastChartPageStore.tabStateActivty}
          onClick={(e: string) =>
            forecastChartPageStore.handleOnChangeTabMeteogram(
              e as EnvironmentalType
            )
          }
        />
      </Box>
    );
  };

export default observer(ForecastChartPanelEnvironmentalButtons);
