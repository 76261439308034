import { Button, Card, Col, message, Row } from "antd";
import { getEnv } from "mobx-easy";
import React, { useCallback, useEffect, useState } from "react";
import { RootEnv } from "../../../stores/setup/create-store";
import { IUser } from "../../../types/IUser";
import { PermissionGroup } from "../../../permissions";
import UserEditableTable from "../../../components/UserEditableTable/UserEditableTable";
import { MailOutlined } from "@ant-design/icons";
import InviteUser from "../../../components/InviteUser/InviteUser";
import { useTranslation } from "react-i18next";
import { Box } from "@chakra-ui/react";
import i18n from "../../../i18n";
import { SCREEN_HORIZONTAL_SPACING } from "../../../constants";
import { useStores } from "stores/setup/use-store";

export enum UserInvitationErrorType {
  INVALID_USER_GROUP = "INVALID_USER_GROUP",
  EMAIL_ALREADY_IN_USE = "EMAIL_ALREADY_IN_USE",
  EMAIL_ALREADY_INVITATION = "EMAIL_ALREADY_INVITATION",
  EXPIRED_TOKEN_INVITATION = "EXPIRED_TOKEN_INVITATION",
  TOKEN_INVITATION_IN_USE = "TOKEN_INVITATION_IN_USE",
  NOT_FOUND = "NOT_FOUND",
}

export const errorMessages = {
  [UserInvitationErrorType.EMAIL_ALREADY_INVITATION]: i18n.t(
    "admin.userManager.thisEmailAlreadyInvited"
  ),
  [UserInvitationErrorType.EMAIL_ALREADY_IN_USE]: i18n.t(
    "admin.userManager.thisEmailAlreadyUse"
  ),
  [UserInvitationErrorType.INVALID_USER_GROUP]: i18n.t(
    "admin.userManager.invalidUserGroup"
  ),
  [UserInvitationErrorType.EXPIRED_TOKEN_INVITATION]: i18n.t(
    "admin.userManager.expiredInvitation"
  ),
  [UserInvitationErrorType.TOKEN_INVITATION_IN_USE]: i18n.t(
    "admin.userManager.invitedAlreadyUsed"
  ),
  [UserInvitationErrorType.NOT_FOUND]: i18n.t(
    "admin.userManager.invitationNotFound"
  ),
};

export const UserManager: React.FC = () => {
  const { adminPanelConfigService } = getEnv<RootEnv>();

  const {
    dataStores: { authStore },
  } = useStores();

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [invitationToken, setInvitationToken] = useState("");

  const { t } = useTranslation("translation", {
    keyPrefix: "admin.userManager",
  });

  useEffect(() => {
    setIsLoading(true);
    adminPanelConfigService
      .getUsersData()
      .then((users) => {
        setUsers(users);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOkModal = (email: string, userGroup: string) => {
    setIsLoading(true);
    adminPanelConfigService
      .inviteNewUser(email, userGroup)
      .then((token) => {
        if (token) {
          setInvitationToken(token);
          setModalVisible(false);
          message.success(t("yourInviteSuccessfullySent"), 5);
        } else {
          message.error(t("errorWhileSendInvitation"));
          return;
        }
      })
      .catch((error) => {
        const type: UserInvitationErrorType = error.response?.data?.name;

        const errorMessage =
          errorMessages[type] || t("errorWhileSendInvitation");
        message.error(errorMessage);
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChangeGroup = useCallback(
    (key: React.Key, value: string) => {
      setIsLoading(true);
      const newUsers = [...users];
      const index = newUsers.findIndex((item) => key === item.id);
      const admin = value === PermissionGroup.USER_ADMIN;
      if (index > -1) {
        const item = newUsers[index];
        newUsers.splice(index, 1, {
          ...item,
          admin,
        });

        adminPanelConfigService
          .updateUserGroup(Number(key), value)
          .then((result) => {
            if (result) {
              setUsers(newUsers);
              message.success(t("userPermissionGroupSuccessfully"));
            } else {
              message.error(t("errorWhileEditUser"));
              return;
            }
          })
          .catch((error) => {
            message.error(t("errorWhileEditUser"));
            return;
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        message.error(t("userDataNotFoundOnList"));
        console.log("Edit Error: User data not found on list");
      }
    },
    [users, adminPanelConfigService, t]
  );

  const onRemoveUser = useCallback(
    (key: React.Key, active: boolean) => {
      setIsLoading(true);
      const newUsers = [...users];
      const index = newUsers.findIndex((item) => key === item.id);
      if (index > -1) {
        const item = newUsers[index];
        newUsers.splice(index, 1, {
          ...item,
          active: !active,
        });

        adminPanelConfigService
          .updateUserActive(Number(key), !active)
          .then((result) => {
            if (result) {
              setUsers(newUsers);
              const msg = active
                ? t("userDeactivatedSuccessfully")
                : t("userReactivatedSuccessfully");
              message.success(msg);
            } else {
              const msg = active
                ? t("errorWhileDeactivateUser")
                : t("errorWhileReactivateUser");
              message.error(msg);
              return;
            }
          })
          .catch((error) => {
            message.error(t("errorWhileEditUser"));
            return;
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        message.error(t("userDataNotFoundOnList"));
        console.log("Edit Error: User data not found on list");
      }
    },
    [users, adminPanelConfigService, t]
  );

  return (
    <Box mt={8} mx={SCREEN_HORIZONTAL_SPACING}>
      <Card title={t("userManager")}>
        <Row>
          <Col span={24}>
            <div
              style={{
                marginBottom: 16,
                width: 120,
              }}
            >
              {!authStore.readOnlyUser && (
                <Button
                  type="primary"
                  icon={<MailOutlined />}
                  onClick={() => {
                    setModalVisible(true);
                  }}
                >
                  {t("inviteUser")}
                </Button>
              )}
              <InviteUser
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                onOk={onOkModal}
                pageLoading={isLoading}
              />
            </div>
            <UserEditableTable
              isLoading={isLoading}
              users={users}
              onPaginationChange={() => {}}
              onChangeGroup={onChangeGroup}
              onRemoveUser={onRemoveUser}
              pageSize={5}
            />
          </Col>
        </Row>
      </Card>
    </Box>
  );
};
