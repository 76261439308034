import { CancelToken } from "axios";
import {
  IOperationInsightDescription,
  IOperationRuleLocationItem,
  IOperationRuleLocationItemConditionGroup,
} from "../stores/data/operationRuleLocationInsights/operationRuleLocationInsightModel";
import api from "./api";

export default class OperationRuleLocationInsightService {
  async getOperationRuleLocationInsight(
    operationRuleLocationId: number
  ): Promise<IOperationRuleLocationItem[]> {
    let result;
    result = await api.get(
      `/v1/OperationRule/location/${operationRuleLocationId}/item`
    );
    return result.data;
  }

  async getOperationRuleLocationInsightById(
    operationRuleLocationId: number,
    id: number
  ): Promise<IOperationRuleLocationItem> {
    let result;
    result = await api.get(
      `/v1/OperationRule/location/${operationRuleLocationId}/item/${id}`
    );
    return result.data;
  }

  async createOperationRuleLocationInsight(
    name: string,
    priorityId: number,
    locationId: number,
    resume: string,
    conditionGroup: IOperationRuleLocationItemConditionGroup,
    restrictionType?: boolean
  ): Promise<IOperationRuleLocationItem> {
    const result = await api.post("/v1/OperationRule/location/item", {
      name: name,
      priority_id: priorityId,
      location_id: locationId,
      resume: resume,
      condition_group: conditionGroup,
      restriction_type: restrictionType,
    });
    if (result.status === 200) {
      return result.data;
    }
    throw new Error("Error while create new insight");
  }

  async updateOperationRuleLocationInsight(
    id: number,
    name: string,
    priorityId: number,
    locationId: number,
    resume: string,
    conditionGroup: IOperationRuleLocationItemConditionGroup,
    restrictionType?: boolean
  ): Promise<IOperationRuleLocationItem> {
    if (!id) {
      throw new Error("Need to inform ID for update data");
    }
    const result = await api.put(`/v1/OperationRule/location/item/${id}`, {
      name: name,
      priority_id: priorityId,
      location_id: locationId,
      resume: resume,
      condition_group: conditionGroup,
      restriction_type: restrictionType,
    });
    if (result.status === 200 || result.status === 201) {
      return result.data;
    }
    throw new Error("Error while update insight");
  }

  async deleteOperationRuleLocationInsight(id: number): Promise<boolean> {
    if (!id) {
      throw new Error("Need to inform ID for delete data");
    }
    const result = await api.delete(`/v1/OperationRule/location/item/${id}`);
    if (result.status === 200 || result.status === 201) {
      return true;
    }
    throw new Error("Error while delete insight");
  }

  async getOperationRuleLocationInsightsByStation(
    stationId: number,
    regionId: number
  ): Promise<IOperationInsightDescription[]> {
    const result = await api.get(
      `v1/operationRule/location/item/station/${stationId}/region/${regionId}`
    );
    if (result.status === 200) {
      return result.data;
    }
    throw new Error("Error while get insights");
  }

  async getAllOperationRuleLocationInsight(
    limit: number,
    filter?: string
  ): Promise<IOperationInsightDescription[]> {
    const result = await api.post("/v1/OperationRule/location/item/search", {
      filter,
      limit,
    });
    if (result.status === 200) {
      return result.data;
    }
    throw new Error("Error while get all insight");
  }

  async addOperationRuleLocationItemAsFavorite(
    operationRuleLocationItemId: number,
    cancelToken?: CancelToken
  ): Promise<{ success: boolean }> {
    const result = await api.post("/v1/OperationRule/location/item/favorite", {
      operationRuleLocationItemId,
    });
    if (result.status === 200) {
      return result.data;
    }
    throw new Error("Error while add insight as favorite");
  }

  async deleteOperationRuleLocationItemAsFavorite(
    favoriteId: number,
    cancelToken?: CancelToken
  ): Promise<{
    success: boolean;
  }> {
    const result = await api.delete(
      `/v1/OperationRule/location/item/favorite/${favoriteId}`
    );
    if (result.status === 200) {
      return result.data;
    }
    throw new Error("Error while delete insight as favorite");
  }

  async getFavoriteOperationRuleLocationItem(): Promise<IOperationInsightDescription> {
    const result = await api.get(
      "/v1/OperationRule/location/load-favorite-item"
    );
    if (result.status === 200) {
      return result.data;
    }
    throw new Error("Error while get favorite insight");
  }
}
