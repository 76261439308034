import { IAtmOceanMeteogramItem } from "stores/data/atmOcean/atmOceanModel";
import { Units } from "stores/data/unitMeasurement/UnitMeasurementStore";
import { EnvironmentalVariables } from "../../../enum/EnvironmentalVariables";
import { VisualizationType } from "../../../enum/VisualizationType";
import { calculateAdjustedValue } from "./calculateAdjustedValue";
import { getSerieDefaultConfig } from "./common/getSerieDefaultConfig";
import { IProcessSerieParams } from "./common/types/IProcessSerieParams";
import { ISerieConfigMap } from "./common/types/ISerieConfigMap";

const getMaxHeightByEnvironmentalVariablesMapper = (
  series: IAtmOceanMeteogramItem[],
  selectedEnvironmentalVariables: EnvironmentalVariables[]
): Map<string, number> => {
  const maxByUnit = new Map<string, number>();

  for (const serie of series) {
    const environmentalVariable =
      serie.environmental_variable as EnvironmentalVariables;

    let max = -Infinity;

    const isVisible = selectedEnvironmentalVariables
      .filter(
        (variable) =>
          variable !== EnvironmentalVariables.WIND_DIR &&
          variable !== EnvironmentalVariables.WIND_DIR_80M &&
          variable !== EnvironmentalVariables.WIND_DIR_100M &&
          variable !== EnvironmentalVariables.CURRENT_DIR &&
          variable !== EnvironmentalVariables.WAVE_DIR &&
          variable !== EnvironmentalVariables.WAVE_DIR_SEA &&
          variable !== EnvironmentalVariables.WAVE_DIR_SWELL &&
          variable !== EnvironmentalVariables.PRECIP_PROBABILITY
      )
      .includes(environmentalVariable);

    if (!isVisible || !max) continue;

    if (serie.data.length > 0 && Array.isArray(serie.data[0])) {
      for (const data of serie.data as number[][]) {
        let value = data[1];
        if (environmentalVariable === EnvironmentalVariables.PRECIP_MIN_20KM) {
          value = data[2];
        }

        if (value > max) {
          max = value;
        }
      }
    }

    if (maxByUnit.has(serie.units)) {
      const existingMax = maxByUnit.get(serie.units)!;
      maxByUnit.set(serie.units, Math.max(existingMax, max));
    } else {
      maxByUnit.set(serie.units, max);
    }
  }

  return maxByUnit;
};

export const processSerie = ({
  series,
  selectedEnvironmentalVariables,
  configMap,
  getUnitMeasurementConfig,
  addEnvironmentalVariable,
  visualizationType = VisualizationType.FORECAST,
  convertUnit,
}: IProcessSerieParams) => {
  let serieProcessed: any[] = [];
  let yAxisProcessed: any[] = [];
  let yAxisIndexRef = -1;

  const maxHeightByYIndexAndUnitMapper =
    getMaxHeightByEnvironmentalVariablesMapper(
      series,
      selectedEnvironmentalVariables
    );
  for (const value of series) {
    const environmentalVariable =
      value.environmental_variable as unknown as EnvironmentalVariables;

    const isVisible = selectedEnvironmentalVariables.includes(
      environmentalVariable
    );

    const unitsUser =
      getUnitMeasurementConfig(environmentalVariable) ||
      value.units_symbol.toLowerCase();

    const serieDefaultConfig = getSerieDefaultConfig(
      environmentalVariable,
      value.data,
      unitsUser,
      addEnvironmentalVariable(environmentalVariable, visualizationType),
      visualizationType
    );

    const environmentalVariableConfig: ISerieConfigMap =
      configMap[environmentalVariable];

    if (environmentalVariableConfig) {
      serieProcessed.push({
        ...serieDefaultConfig,
        ...environmentalVariableConfig.serieConfig,
        yAxisConfig: environmentalVariableConfig.yAxisConfig,
        tooltip: {
          ...serieDefaultConfig.tooltip,
          ...environmentalVariableConfig.serieConfig.tooltip,
        },
        visible: visualizationType === VisualizationType.SENSOR || isVisible,
        unitSymbol: value.units,
      });
    }

    if (!environmentalVariableConfig) {
      continue;
    }

    const yAxisConfig = environmentalVariableConfig?.yAxisConfig;

    const isUpadateYAxis =
      !!environmentalVariableConfig.serieConfig?.updateYAxis;

    if (isUpadateYAxis && yAxisConfig) {
      const yAxisIndex = environmentalVariableConfig.serieConfig?.yAxis;

      if (yAxisIndex === undefined) {
        throw new Error("yAxisIndex is required");
      }

      if (yAxisIndex && yAxisIndexRef === -1) {
        yAxisIndexRef = yAxisIndex;
      }

      const maxHeight = maxHeightByYIndexAndUnitMapper.get(value.units);

      let yMax = yAxisConfig.max;
      let maxHeightConverted = maxHeight;

      if (unitsUser && unitsUser !== value.units_symbol.toLowerCase()) {
        yMax = convertUnit(
          yAxisConfig.max,
          value.units as Units,
          unitsUser as Units
        );
      }

      serieProcessed[serieProcessed?.length - 1] = {
        ...serieProcessed[serieProcessed?.length - 1],
        defaultMaxConverted: yMax,
      };

      const adjustedValueMax = calculateAdjustedValue(yMax, maxHeightConverted);

      yAxisProcessed[yAxisIndex] = {
        ...environmentalVariableConfig.yAxisConfig,
        labels: {
          format: `{value} ${unitsUser}`,
          style: {
            color: "#000000",
          },
        },
        min: calculateAdjustedValue(
          environmentalVariableConfig.yAxisConfig?.min || 0,
          value?.min,
          false
        ),
        max: adjustedValueMax,
        defaultMaxConverted: yMax,
      };
    }
  }

  if (!yAxisProcessed?.length) {
    yAxisProcessed = [
      {
        title: {
          text: "",
          style: {
            color: "#000000",
          },
        },
        min: 0,
        max: 50,
        labels: { format: "{value}", style: { color: "#000000" } },
        gridLineWidth: 1,
      },
    ];
  }

  return { serie: serieProcessed, yAxis: yAxisProcessed };
};
