import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Box } from "@chakra-ui/react";
import { Button, Col, message, Modal, Result, Row, Select, Spin } from "antd";
import { SelectValue } from "antd/lib/select";
import { getEnv } from "mobx-easy";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationSubmission } from "../../components/NotificationSubmission/NotificationSubmission";
import { NotificationWizzard } from "../../components/NotificationWizzard/NotificationWizzard";
import {
  UserTable,
  UserTableRowProps,
} from "../../components/UserTable/UserTable";
import { RootEnv } from "../../stores/setup/create-store";
import { ICompany } from "../../types/ICompany";
import { IUser } from "../../types/IUser";

export const NotificationManager: React.FC = () => {
  const { notificationService } = getEnv<RootEnv>();

  const { t } = useTranslation("translation", {
    keyPrefix: "notificationManager",
  });

  const [isCompaniesLoading, setIsCompaniesLoading] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isSendLoading, setIsSendLoading] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [userTableRowPropos, setUserTableRowPropos] = useState<
    UserTableRowProps[]
  >([]);
  const [selectedCompany, setSelectedCompany] = useState<ICompany>();
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationContent, setNotificationContent] = useState("");
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendFailed, setSendFailed] = useState(false);

  useEffect(() => {
    setIsCompaniesLoading(true);
    notificationService
      .getCompanies()
      .then((result) => {
        setCompanies(result);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsCompaniesLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsCompaniesLoading, setCompanies]);

  const onChangeUserTable = useCallback(
    (selectedRowKeys: React.Key[], selectedRows: UserTableRowProps[]) => {
      setUserTableRowPropos(selectedRows);
    },
    [setUserTableRowPropos]
  );

  const onChangeCompany = useCallback(
    (value: SelectValue) => {
      setUserTableRowPropos([]);
      if (value) {
        setIsUserLoading(true);
        setSelectedCompany(
          companies.find((company) => company.id === Number(value))
        );
        notificationService
          .getUsersForNotification(Number(value))
          .then((result) => {
            setUsers(result);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsUserLoading(false);
          });
      }
    },
    [companies, notificationService]
  );

  const onClickNext = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [setCurrentStep, currentStep]);

  const onClickPrevious = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [setCurrentStep, currentStep]);

  const onChangeTitle = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setNotificationTitle(value);
    },
    [setNotificationTitle]
  );

  const onChangeContent = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.target.value;
      setNotificationContent(value);
    },
    [setNotificationContent]
  );

  const onClickSendNotification = useCallback(() => {
    if (!notificationTitle) {
      message.info(t("pleaseEnterMessageTitle"));
      return;
    }
    if (!notificationContent) {
      message.info(t("pleaseEnterMessageContent"));
      return;
    }
    Modal.confirm({
      title: t("confirm"),
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        setIsSendLoading(true);
        notificationService
          .sendPushNotificationImmediately(
            userTableRowPropos.map((user) => user.key as number),
            notificationTitle,
            notificationContent
          )
          .then((result) => {
            if (result.success) {
              setSendSuccess(true);
            }
          })
          .catch((error) => {
            setSendFailed(true);
            console.error(error);
          })
          .finally(() => {
            setIsSendLoading(false);
          });
      },
      content: t("confirmPushNotificationSent", {
        totalUsers: userTableRowPropos.length,
      }),
      okText: t("send"),
      cancelText: t("Cancel"),
    });
  }, [
    notificationTitle,
    notificationContent,
    userTableRowPropos,
    t,
    notificationService,
  ]);

  const reloadScreen = useCallback(() => {
    document.location.reload();
  }, []);

  return (
    <Box mt={8}>
      <Row>
        <Col span={18} offset={3}>
          <NotificationWizzard currentStep={currentStep} />

          <>
            <Row gutter={12} hidden={currentStep !== 0}>
              <Col xs={32} md={16} lg={12}>
                <Spin spinning={isCompaniesLoading}>
                  <Select
                    key="selectCompanies"
                    style={{ width: "100%", marginTop: "25px" }}
                    listItemHeight={10}
                    listHeight={250}
                    placeholder={t("selectCompany")}
                    onChange={onChangeCompany}
                  >
                    {companies.map((company) => (
                      <Select.Option
                        value={company.id}
                        key={company.id.toString()}
                      >
                        {company.fantasy_name} - {company.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Spin>
              </Col>
            </Row>
            <div style={{ marginTop: 25 }} hidden={currentStep !== 0}>
              <Spin spinning={isUserLoading}>
                <UserTable
                  onChangeTable={onChangeUserTable}
                  users={users}
                  pageSize={5}
                />
              </Spin>

              <Button
                type={"primary"}
                style={{ marginTop: 25 }}
                onClick={onClickNext}
                disabled={userTableRowPropos.length === 0}
              >
                {t("next")}
              </Button>
            </div>
          </>

          <>
            <Col
              span={18}
              offset={3}
              hidden={currentStep !== 1 || sendSuccess || sendFailed}
            >
              <Spin spinning={isSendLoading}>
                <NotificationSubmission
                  NumberRecipients={userTableRowPropos.length}
                  cardTitle={`${selectedCompany?.fantasy_name} - ${selectedCompany?.name}`}
                  onChangeTitle={onChangeTitle}
                  onChangeContent={onChangeContent}
                />
                <Button
                  type={"primary"}
                  style={{ marginTop: 25 }}
                  onClick={onClickPrevious}
                >
                  {t("previous")}
                </Button>
                <Button
                  type={"primary"}
                  style={{ marginTop: 25, marginLeft: 15 }}
                  onClick={onClickSendNotification}
                >
                  {t("sendPushNotification")}
                </Button>
              </Spin>
            </Col>

            {sendSuccess && (
              <Result
                status="success"
                title={t("pushNotificationSentSuccessfully")}
                subTitle=""
                extra={[
                  <Button
                    type="primary"
                    key="newUserDocumentValidation"
                    onClick={reloadScreen}
                  >
                    {t("sendNewPushes")}
                  </Button>,
                ]}
              />
            )}

            {sendFailed && (
              <Result
                status="error"
                title={t("submissionFailed")}
                subTitle={t("checkModifingInformationResubmiting")}
                extra={[
                  <Button
                    type="primary"
                    key="newUserDocumentValidation"
                    onClick={() => setSendFailed(false)}
                  >
                    {t("previous")}
                  </Button>,
                  <Button
                    key="newUserDocumentValidation"
                    onClick={onClickSendNotification}
                  >
                    {t("tryAgain")}
                  </Button>,
                ]}
              />
            )}
          </>
        </Col>
      </Row>
    </Box>
  );
};
