import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import NavItem, { NavItemProps } from "./NavItem";

interface NavGroupProps extends NavItemProps {
  children: ReactNode;
}

const NavGroup: React.FC<NavGroupProps> = ({
  event,
  isActive,
  isOpen,
  environmentalType,
  text,
  icon,
  children,
}) => {
  return (
    <>
      <NavItem
        environmentalType={environmentalType}
        event={event}
        isActive={isActive}
        text={text}
        icon={icon}
      />

      <div
        style={{
          justifyContent: "space-between",
          position: "relative",
          transition: "all .6s",
          opacity: isOpen ? 1 : 0,
          maxHeight: isOpen ? "250px" : "0px",
        }}
      >
        {children}
      </div>
    </>
  );
};

export default observer(NavGroup);
