import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type AlertNewImportFunctionalityProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  showButton: boolean;
};

export const AlertNewImportFunctionality: React.FC<AlertNewImportFunctionalityProps> =
  ({ isOpen, onClose, onConfirm, showButton = true }) => {
    const { t } = useTranslation("translation", {
      keyPrefix: "occurrence",
    });

    const [isChecked, setIsChecked] = useState(false);

    const handleConfirm = () => {
      onConfirm();
      setIsChecked(true);
    };

    const handleClose = () => {
      onClose();
      setIsChecked(true);
    };

    return (
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent bg={"newBlue.500"} color="white" p={2}>
          <ModalHeader color="white">
            <span role="img" aria-label="fest">
              🎉
            </span>
            {t("newsInOccurrence")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody color="white">
            <p>{t("nowItsPossibleImportSpreadSheet")}</p>
          </ModalBody>
          {showButton && (
            <ModalFooter>
              <Button
                onClick={handleConfirm}
                ml={2}
                disabled={isChecked}
                colorScheme="whiteAlpha"
              >
                {t("try")}
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    );
  };
