import { JoyrideStorage } from "enum/JoyrideStorage";
import { probabilisticJoySteps } from "../steps/ProbabilisticJoySteps";
import GuidedTutorial from "./GuidedTutorial";

export default function ProbabilisticGuidedTutorial({
  shouldRunJoyride = false,
}) {
  return (
    <GuidedTutorial
      stepsProps={probabilisticJoySteps}
      storage={[JoyrideStorage.PROBABILISTIC_ON_LOCAL_STORAGE]}
      shouldRunJoyride={shouldRunJoyride}
    />
  );
}
