import { observer } from "mobx-react-lite";
import React from "react";
import { useStores } from "../../../../stores/setup/use-store";

import { useTranslation } from "react-i18next";

import useWindowObserver from "../../../../hooks/useWindowObserver";
import LineChart from "components/LineChart/LineChart";
import { meteogramFormatTooltip } from "util/meteogramCharts/meteogramFormatTooltip/meteogramFormatTooltip";

interface ForecastChartsProps {}

const ForecastCharts: React.FC<ForecastChartsProps> = () => {
  const windowSize = useWindowObserver(".main-area");

  const {
    dataStores: { riverForecastChartStore },
  } = useStores();

  const getChartHeight = () => {
    if (windowSize.width < 480) {
      return `${windowSize.height * 0.461}px`;
    } else if (windowSize.width < 768) {
      return `${windowSize.height * 0.399}px`;
    } else if (windowSize.width < 1200) {
      return `${windowSize.height * 0.393}px`;
    } else {
      return `${windowSize.height * 0.376}px`;
    }
  };

  const chartHeight = getChartHeight();

  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartPanelMeteogram",
  });

  return (
    <LineChart
      style={{ maxWidth: windowSize.width }}
      timezoneOffset={
        riverForecastChartStore.riverForecastData?.timezoneOffset ?? 180
      }
      options={{
        chart: {
          zoomType: "xy",
          height: chartHeight,
          backgroundColor: "#ffffff",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          type: "datetime",
          gridLineWidth: 1,
          currentDateIndicator: {
            color: "#54f542",
            width: 2,
            zIndex: 4,
            dashStyle: "dash",
          },
        },
        plotOptions: {
          series: { gapSize: 2 },
        },
        yAxis: {
          labels: {
            format: `{value} ${riverForecastChartStore.riverForecastData?.yAxisData?.unitUser}`,
            style: {
              color: "#00000",
            },
          },
          title: {
            text: t("waterLevel"),
            style: {
              color: "#00000",
            },
          },
          min: riverForecastChartStore.riverForecastData?.yAxisData?.min,
          max: riverForecastChartStore.riverForecastData?.yAxisData?.max,
        },
        tooltip: {
          shared: true,
          useHTML: true,
          formatter: function () {
            const { x, y, points } = this;
            return meteogramFormatTooltip(x, y, points);
          },
        },
        legend: {
          itemStyle: {
            fontSize: "14px",
            fontFamily: "'Hind Vadodara', 'Helvetica Neue', sans-serif",
            fontWeight: "normal",
            color: "#00000",
          },
        },
        line: {
          color: "#ffff",
        },
        series: riverForecastChartStore.riverForecastData?.seriesData,
      }}
    />
  );
};

export default observer(ForecastCharts);
