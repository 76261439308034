import { makeObservable, observable, action, makeAutoObservable } from "mobx";
import { getEnv } from "mobx-easy";
import { StoreState } from "../../../enum/StoreState";
import { IMacroRegion } from "../../../types/IMacroRegion";
import { RootEnv } from "../../setup/create-store";

export default class RegionStore {
  state: string = StoreState.DONE;

  messageError: string = "";

  macroRegions: IMacroRegion[] = [];

  macroRegionMain: IMacroRegion;

  mainRegionTimezoneOffset: number | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setMessageError(value: string) {
    this.messageError = value;
  }

  setMacroRegions(value: IMacroRegion[]) {
    this.macroRegions = value;
  }

  setMacroRegionMain(value: IMacroRegion) {
    this.macroRegionMain = value;
  }

  findMacroRegionById(id: number) {
    return this.macroRegions.find((macroRegion) => macroRegion.id === id);
  }

  setMainRegionTimezoneOffset(value: number | null) {
    this.mainRegionTimezoneOffset = value;
  }

  reset() {
    this.setState(StoreState.DONE);
    this.setMessageError("");
    this.setMacroRegions([]);
    this.setMainRegionTimezoneOffset(null);
  }

  async getMacroRegionApi() {
    const { regionService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);
    try {
      const macroRegionResult: IMacroRegion[] =
        await regionService.getMacroRegion();
      if (macroRegionResult) {
        this.setMacroRegions(macroRegionResult);
        const foundMacroRegionMain = macroRegionResult.find(
          (macroRegion) => macroRegion.main
        );
        if (foundMacroRegionMain) {
          this.setMacroRegionMain(foundMacroRegionMain);
        }
        this.setState(StoreState.DONE);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getMainRegionTimezoneOffset() {
    const { regionService } = getEnv<RootEnv>();
    const timezoneOffset = await regionService.getMainRegionTimezonOffset();
    this.setMainRegionTimezoneOffset(timezoneOffset);
  }

  async load() {
    this.getMacroRegionApi();
    this.getMainRegionTimezoneOffset();
  }
}
