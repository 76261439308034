import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Textarea,
  Text,
  Center,
  RadioGroup,
  Stack,
  FormControl,
  FormErrorMessage,
  useToast,
  Box,
  Divider,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import FeedbackStore from "../../stores/data/feedback/feedbackStore";
import { UserExperience } from "../../enum/UserExperience";
import feedbackImage from "../../assets/feedback.svg";
import Loading from "../../components/Loading/Loading";
import { StoreState } from "../../enum/StoreState";

type FeedbackModalProps = {
  isOpen: boolean;
  feedbackStore: FeedbackStore;
};

type FeedbackFormValues = {
  answer: string;
};

export const FeedbackModal: React.FC<FeedbackModalProps> = ({
  isOpen,
  feedbackStore,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "feedback",
  });
  const toast = useToast();

  const [initialValue] = useState({
    answer: "",
  });

  const questionFeedback = feedbackStore.questionFeedback;

  const validateForm = (values: FeedbackFormValues) => {
    let errors = {} as FeedbackFormValues;

    if (!values.answer && questionFeedback?.isObjectiveQuestion) {
      errors.answer = "Answer is required!";
    }

    return errors;
  };

  const onSubmitForm = (values: FeedbackFormValues) => {
    if (questionFeedback) {
      feedbackStore.setQuestionFeedback(undefined);
      const { id, question } = questionFeedback;

      feedbackStore
        .sendQuestionFeedback(id, question, values.answer)
        .then((success) => {
          if (success) {
            toast({
              title: t("successSendFeedback"),
              status: "success",
              position: "bottom-right",
              isClosable: true,
            });
          } else {
            toast({
              title: t("errorSendFeedback"),
              status: "error",
              position: "bottom-right",
              isClosable: true,
            });
          }
        })
        .catch(() => {
          toast({
            title: t("errorSendFeedback"),
            status: "error",
            position: "bottom-right",
            isClosable: true,
          });
        });
    }
  };

  const handleCloseModal = () => {
    if (questionFeedback) {
      feedbackStore.setQuestionFeedback(undefined);

      const { id, question } = questionFeedback;
      feedbackStore.deleteQuestionFeedback(id);

      feedbackStore.addLogUserExperienceAPI(
        UserExperience.QUESTION_FEEDBACK_CLOSE_BUTTON,
        JSON.stringify(question)
      );
    }
  };

  return (
    <Modal
      key={`id-${feedbackStore.questionFeedback}`}
      closeOnOverlayClick={false}
      size={"xl"}
      isCentered
      isOpen={isOpen}
      onClose={handleCloseModal}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) " />
      <ModalContent pt={2} pl={2} pr={2}>
        <ModalCloseButton color="gray.500" />

        <Formik<FeedbackFormValues>
          initialValues={initialValue}
          onSubmit={onSubmitForm}
          validate={validateForm}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={false}
        >
          {({ getFieldProps, submitForm, setFieldValue, errors }) => (
            <>
              <ModalBody color="gray.500">
                {feedbackStore.state === StoreState.PEDDING && <Loading />}
                <Center py={6}>
                  <img
                    src={feedbackImage}
                    alt="wind"
                    style={{ width: 155, marginRight: 35 }}
                  />
                  <Box>
                    <Text color="newBlue.500" fontSize={"xl"} mb={4}>
                      {questionFeedback?.question}
                    </Text>
                    <FormControl as="fieldset" isInvalid={!!errors.answer}>
                      {questionFeedback?.isObjectiveQuestion ? (
                        <>
                          <Textarea
                            autoFocus
                            maxLength={100}
                            bg="white"
                            color="rgba(30, 82, 151, 0.8)"
                            borderColor="gray.100"
                            fontWeight={"light"}
                            borderRadius={8}
                            size="sm"
                            placeholder={t("typeYourAnswerHere") as string}
                            {...getFieldProps("answer")}
                          />
                          <FormErrorMessage>
                            {t("thisFieldIsRequired")}
                          </FormErrorMessage>
                        </>
                      ) : (
                        <>
                          <RadioGroup
                            color="gray.600"
                            {...getFieldProps("answer")}
                          >
                            <Stack spacing={4} direction="row">
                              <Button
                                layerStyle="greenOcean"
                                _hover={{ layerStyle: "greenOcean" }}
                                borderColor="gray.400"
                                onClick={() => {
                                  setFieldValue("answer", "yes");
                                  submitForm();
                                }}
                              >
                                <Text fontSize={"xl"}>
                                  {t("yes") as string}
                                </Text>
                              </Button>
                              <Button
                                layerStyle="lightRedOcean"
                                _hover={{ layerStyle: "lightRedOcean" }}
                                onClick={() => {
                                  setFieldValue("answer", "no");
                                  submitForm();
                                }}
                                borderColor="gray.400"
                              >
                                <Text fontSize={"xl"}>{t("no") as string}</Text>
                              </Button>
                            </Stack>
                          </RadioGroup>
                        </>
                      )}
                    </FormControl>
                  </Box>
                </Center>
              </ModalBody>
              {questionFeedback?.isObjectiveQuestion && (
                <>
                  <Divider borderColor="gray.300" mb={2} />
                  <ModalFooter pt={0} pl={2} pr={2} pb={2}>
                    <Button
                      layerStyle="ocean"
                      _hover={{ layerStyle: "ocean" }}
                      onClick={submitForm}
                    >
                      {t("send")}
                    </Button>
                  </ModalFooter>
                </>
              )}
            </>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
