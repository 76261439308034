import React, { LegacyRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { FieldArray, Formik, FormikProps, FormikTouched } from "formik";
import { DBAction } from "../../../enum/DBAction";
import { StoreState } from "../../../enum/StoreState";
import { IOperationRuleLocation } from "../../../stores/data/operationRuleLocations/OperationRuleLocationModel";
import {
  InsightFormModalValues,
  IOperationRuleLocationItemConditionGroup,
} from "../../../stores/data/operationRuleLocationInsights/operationRuleLocationInsightModel";
import { useStores } from "../../../stores/setup/use-store";
import { insightBuilderFormSchema } from "./InsightBuilderFormSchema";

import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { PlusOutlined } from "@ant-design/icons";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import { Input } from "../../../components/Input/Input";
import EmptyState from "../../../components/EmptyState/EmptyState";
import emptyInsightGroup from "../../../assets/emptyInsightGroup.svg";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { AlertDelete } from "../../../components/AlertDelete/AlertDelete";
import Alert from "antd/lib/alert";
import { NumberInputWithElement } from "../../../components/NumberInputWithElement/NumberInputWithElement";

interface OperationRuleLocationInsightModalProps {
  isOpen: boolean;
  onClose: () => void;
  location: IOperationRuleLocation;
}

interface ModalBodyRefType {
  scrollTo(options?: ScrollToOptions): void;
  scrollHeight: number;
  scrollIntoView: any;
}

const OperationRuleLocationInsightModal: React.FC<OperationRuleLocationInsightModalProps> =
  ({ location, isOpen, onClose }) => {
    const {
      dataStores: {
        operationRuleLocationInsightStore,
        oceanWeatherIntelligenceStore,
        authStore,
      },
    } = useStores();
    const {
      isOpen: isDeleteOpen,
      onOpen: onDeleteOpen,
      onClose: onDeleteClose,
    } = useDisclosure();

    const toast = useToast();
    const modalBodyRef = useRef<ModalBodyRefType>(null);

    const defaultEnvironmentalVariableId = 7;
    const initialFormValues = {
      name: "",
      location: location?.id || 0,
      priority: 1,
      resume: "",
      groups: [],
      restrictionType: true,
    };
    const [initialValues, setInitialValues] = useState<any>(initialFormValues);
    const [openGroups, setOpenGroups] = useState<number[]>([]);
    const [formGroupIndex, setFormGroupIndex] = useState<number>(0);

    const { t } = useTranslation("translation", {
      keyPrefix: "oceanWeatherIntelligence.operationRuleLocationInsight",
    });

    useEffect(() => {
      if (
        operationRuleLocationInsightStore.operationRuleLocationItemAction ===
        DBAction.UPDATE
      ) {
        loadForm();
      } else {
        loadEmptyForm();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      isOpen,
      oceanWeatherIntelligenceStore.environmentalVariables,
      operationRuleLocationInsightStore.operationRuleLocationItem,
      operationRuleLocationInsightStore.actionCallCreateInsight,
    ]);

    const loadForm = () => {
      const {
        name,
        operationRuleLocationId,
        priorityId,
        resume,
        conditionGroup,
        restrictionType,
      } = operationRuleLocationInsightStore.operationRuleLocationItem;

      const conditionGroupFormModal =
        operationRuleLocationInsightStore.processGroupConditionForFormModal(
          conditionGroup,
          []
        );

      operationRuleLocationInsightStore.setInsightFormModalValuesGroups(
        conditionGroupFormModal
      );

      let fields: Partial<InsightFormModalValues> = {
        name: name,
        location: operationRuleLocationId,
        priority: priorityId,
        resume: resume,
        restrictionType,
      };
      setInitialValues({
        ...fields,
        groups: conditionGroupFormModal,
      });
    };

    const loadEmptyForm = () => {
      setInitialValues(initialFormValues);
    };

    const handleOnAddData = (values: InsightFormModalValues) => {
      let conditionsGroup: IOperationRuleLocationItemConditionGroup;
      const { name, priority, resume, groups, restrictionType } = values;

      const hasValue = name && priority && resume && groups;

      if (groups.length === 0) {
        toast({
          title: t("informGroupConditionInsight"),
          status: "error",
          position: "top",
          isClosable: true,
        });
        return;
      }
      if (
        !operationRuleLocationInsightStore.groupConditionsValidation(groups)
      ) {
        toast({
          title: t("informConditionGroupsInsight"),
          status: "error",
          position: "top",
          isClosable: true,
        });

        return;
      }

      if (hasValue) {
        switch (
          operationRuleLocationInsightStore.operationRuleLocationItemAction
        ) {
          case DBAction.CREATE:
            conditionsGroup =
              operationRuleLocationInsightStore.processGroupConditionForBackend(
                groups,
                DBAction.CREATE
              );
            operationRuleLocationInsightStore.setOperationRuleLocationItem({
              id: 0,
              name: values.name,
              resume: values.resume,
              priorityId: parseFloat(values.priority.toString()),
              operationRuleLocationId: location.id,
              conditionGroup: conditionsGroup,
              restrictionType,
            });
            operationRuleLocationInsightStore
              .addOperationRuleLocationInsight()
              .then(() => {
                if (
                  operationRuleLocationInsightStore.state !== StoreState.ERROR
                ) {
                  toast({
                    title: t("insightSuccessOnSave"),
                    status: "success",
                    position: "bottom-right",
                    isClosable: true,
                  });
                }
              });

            oceanWeatherIntelligenceStore.setChangeForUpdate({
              update: true,
              expiration: 0,
            });
            operationRuleLocationInsightStore.setOperationRuleLocationInsightModalVisible(
              false
            );
            operationRuleLocationInsightStore.setHasInsights(true);

            onClose();
            break;

          case DBAction.UPDATE:
            const conditionGroupOrigin =
              operationRuleLocationInsightStore.processGroupConditionForBackend(
                operationRuleLocationInsightStore.insightFormModalValuesGroups,
                DBAction.UPDATE
              );
            const conditionGroupEdited =
              operationRuleLocationInsightStore.processGroupConditionForBackend(
                values.groups,
                DBAction.UPDATE
              );
            const conditionGroupDiff =
              operationRuleLocationInsightStore.getUpdateOperationRuleLocationItemConditionGroup(
                conditionGroupOrigin,
                conditionGroupEdited
              );

            if (
              operationRuleLocationInsightStore.operationRuleLocationItem.id <=
              0
            ) {
              operationRuleLocationInsightStore.setState(StoreState.ERROR);
              operationRuleLocationInsightStore.setMessageError(
                t("errorCriticalUpdateGroupConditions")
              );
              return;
            }

            operationRuleLocationInsightStore.setOperationRuleLocationItem({
              id: operationRuleLocationInsightStore.operationRuleLocationItem
                .id,
              name: values.name,
              resume: values.resume,
              priorityId: values.priority,
              operationRuleLocationId: location?.id || 0,
              conditionGroup: conditionGroupDiff,
              restrictionType,
            });
            operationRuleLocationInsightStore
              .updateOperationRuleLocationInsight()
              .then(() => {
                setInitialValues(initialFormValues);
                if (
                  operationRuleLocationInsightStore.state !== StoreState.ERROR
                ) {
                  toast({
                    title: t("insightSuccessOnSave"),
                    status: "success",
                    position: "bottom-right",
                    isClosable: true,
                  });
                }
              });
            oceanWeatherIntelligenceStore.setChangeForUpdate({
              update: true,
              expiration: 0,
            });
            operationRuleLocationInsightStore.setOperationRuleLocationInsightModalVisible(
              false
            );

            onClose();
            break;
        }
      }
    };

    const handleToggleGroup = (index: number) => {
      if (openGroups.includes(index)) {
        setOpenGroups(openGroups.filter((itemIndex) => itemIndex !== index));
      } else {
        setOpenGroups([...openGroups, index]);
      }
    };

    const isInvalidValue = (
      form: FormikProps<InsightFormModalValues>,
      touched: FormikTouched<InsightFormModalValues>,
      groupIndex: number,
      conditionIndex: number
    ) => {
      const { errors } = form;
      const groups = errors.groups?.[groupIndex] as any;
      return (
        !!groups?.conditions?.[conditionIndex]?.value &&
        (touched.groups?.[groupIndex]?.conditions?.[conditionIndex]?.value ||
          touched.groups?.[groupIndex]?.conditions?.[conditionIndex]?.units)
      );
    };

    const handleRemoveGroup = (index: number) => {
      setFormGroupIndex(index);
      onDeleteOpen();
    };

    return (
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size="3xl"
      >
        <Formik<InsightFormModalValues>
          initialValues={initialValues}
          onSubmit={(values) => {
            handleOnAddData(values);
          }}
          enableReinitialize={true}
          validationSchema={insightBuilderFormSchema}
        >
          {({
            values,
            getFieldProps,
            setValues,
            errors,
            touched,
            setFieldTouched,
            submitForm,
          }) => (
            <>
              <ModalOverlay />

              <ModalContent w={"60rem"} bg={"#f0f1f2"}>
                <ModalHeader color="newBlue.500">
                  {operationRuleLocationInsightStore.operationRuleLocationItemAction ===
                  DBAction.CREATE
                    ? t("addNewInsight")
                    : t("editInsight")}
                </ModalHeader>
                <ModalCloseButton color={"black"} />
                <ModalBody
                  pb={6}
                  px={20}
                  maxH="72.5vh"
                  overflowY="auto"
                  sx={{
                    "&::-webkit-scrollbar": {
                      width: "12px",
                      borderRadius: "8px",
                      backgroundColor: `rgba(40, 103, 149, 0.3)`,
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "8px",
                      backgroundColor: `rgba(40, 103, 149, 0.6)`,
                    },
                  }}
                >
                  <AlertDelete
                    key={`alert-${formGroupIndex}`}
                    isOpen={isDeleteOpen}
                    onClose={onDeleteClose}
                    titleLabel={t("deleteGroup")}
                    bodyText={t("areYouSureWantDeleteGroup")}
                    cancelButtonLabel={t("cancel")}
                    deleteButtonLabel={t("delete")}
                    handleDelete={() => {
                      let currentValue = values;
                      currentValue.groups.splice(formGroupIndex, 1);
                      setValues(currentValue);
                      onDeleteClose();
                    }}
                  />
                  {operationRuleLocationInsightStore.state === "error" && (
                    <Alert
                      message={t("error")}
                      description={
                        operationRuleLocationInsightStore.messageError
                      }
                      type="error"
                      showIcon
                      closable
                    />
                  )}

                  <Grid templateColumns="repeat(5, 1fr)" gap={2}>
                    <GridItem colSpan={3}>
                      <FormControl>
                        <FormLabel color="newBlue.500" fontSize={"large"}>
                          {t("location")}
                          <Text
                            fontWeight={"light"}
                            color="rgba(30, 82, 151, 0.8)"
                            mt={2}
                          >
                            {location?.name}
                          </Text>
                        </FormLabel>
                      </FormControl>
                    </GridItem>
                    <GridItem colStart={4} colEnd={6}>
                      <FormControl id="restrictionType">
                        <FormLabel color="newBlue.500" fontSize={"large"}>
                          {t("insightType")}
                          <Tooltip
                            label={t("tooltipRestrictionType")}
                            fontSize="md"
                            hasArrow
                          >
                            <Icon
                              color={"gray.500"}
                              as={InfoOutlinedIcon}
                              pt={1}
                              position={"absolute"}
                            />
                          </Tooltip>
                        </FormLabel>

                        <Grid templateColumns="repeat(3, 1fr)">
                          <Center>
                            <Text
                              fontWeight={"light"}
                              color="rgba(30, 82, 151, 0.8)"
                            >
                              {t("noRestrictionType")}
                            </Text>

                            <Switch
                              key={`switch-restrictionType-${values.restrictionType}`}
                              fontWeight={"light"}
                              colorScheme="red"
                              sx={{
                                "span.chakra-switch__track:not([data-checked])":
                                  {
                                    backgroundColor: "green.500",
                                  },
                              }}
                              ml={2}
                              mr={2}
                              size={"md"}
                              {...getFieldProps("restrictionType")}
                              defaultChecked={values.restrictionType}
                              onChange={() => {
                                let currentValue = values;
                                currentValue.restrictionType =
                                  !values.restrictionType;
                                setValues(currentValue);
                              }}
                              disabled={authStore.readOnlyUser}
                            />
                            <Text
                              fontWeight={"light"}
                              color="rgba(30, 82, 151, 0.8)"
                            >
                              {t("restrictionType")}
                            </Text>
                          </Center>
                        </Grid>
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={3}>
                      <FormControl
                        mb={4}
                        isRequired
                        id="name"
                        isInvalid={!!errors.name}
                      >
                        <FormLabel color="newBlue.500" fontSize={"large"}>
                          {t("name")}
                        </FormLabel>
                        <Input
                          placeholder={
                            t("stopNavigationForSmallBoats") as string
                          }
                          maxLength={60}
                          {...getFieldProps("name")}
                          disabled={authStore.readOnlyUser}
                        />
                        <FormErrorMessage>{t("nameRequired")}</FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem colStart={4} colEnd={6}>
                      <FormControl mb={4} isRequired id="priority">
                        <FormLabel color="newBlue.500" fontSize={"large"}>
                          {t("priority")}
                        </FormLabel>
                        <Select
                          borderRadius={8}
                          backgroundColor="#ffffff"
                          color={"rgba(30, 82, 151, 0.8)"}
                          fontSize={14}
                          size="md"
                          borderColor={"#ffffff"}
                          _focus={{ borderColor: "none" }}
                          defaultValue={1}
                          {...getFieldProps("priority")}
                          disabled={authStore.readOnlyUser}
                        >
                          <option value={1} key={1}>
                            {t("high")}
                          </option>
                          <option value={2} key={2}>
                            {t("medium")}
                          </option>
                          <option value={3} key={3}>
                            {t("low")}
                          </option>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </Grid>
                  <FormControl
                    mb={4}
                    isRequired
                    id="resume"
                    isInvalid={!!errors.resume && touched.resume}
                  >
                    <FormLabel color="newBlue.500" fontSize={"large"}>
                      {t("summary")}
                    </FormLabel>
                    <Textarea
                      maxLength={100}
                      style={{
                        backgroundColor: "white",
                        borderColor: "white",
                        color: "rgba(30, 82, 151, 0.8)",
                        fontSize: 14,
                        fontWeight: "normal",
                      }}
                      placeholder={
                        t("theNavigationShouldStoppedWindsTooStrong") as string
                      }
                      borderRadius={8}
                      size="sm"
                      _focus={{
                        border: "2px",
                        borderColor: "newBlue.500",
                        boxShadow: "0px 4px 10px rgba(40, 103, 149, 0.1)",
                      }}
                      borderColor="gray.300"
                      {...getFieldProps("resume")}
                      disabled={authStore.readOnlyUser}
                    />
                    <FormErrorMessage>{t("summaryRequired")}</FormErrorMessage>
                  </FormControl>
                  <Flex mb={2}>
                    <Text
                      color="newBlue.500"
                      fontSize={"large"}
                      fontWeight="bold"
                    >
                      {t("conditions")}
                    </Text>
                    <Tooltip
                      label={t("groupsAreConditionsAddOneConditions")}
                      fontSize="sm"
                      hasArrow
                    >
                      <Icon
                        color={"gray.500"}
                        as={InfoOutlinedIcon}
                        ml={2}
                        pt={1}
                      />
                    </Tooltip>
                  </Flex>
                  {!values?.groups?.length && (
                    <EmptyState
                      description={t("addNewConditionGroupInsight")}
                      buttonAction={() => {
                        let currentValue = values;
                        currentValue.groups.push({
                          LogicalOperatorGroups: "and",
                          conditions: [
                            {
                              mathOperator: ">",
                              environmentalVariable:
                                defaultEnvironmentalVariableId,
                              units: "knots",
                            },
                          ],
                        });
                        setValues(currentValue);
                      }}
                      image={emptyInsightGroup}
                      imageSize="15vw"
                      buttonIcon={<AddIcon />}
                      buttonText={t("addNewGroup") as string}
                    />
                  )}
                  <FieldArray name="groups">
                    {() => (
                      <>
                        {values?.groups?.map((group, groupIndex) => (
                          <Box
                            p={4}
                            mt={3}
                            mb={4}
                            bg={"#ffffff"}
                            borderRadius={8}
                            alignItems="center"
                            maxH={openGroups.includes(groupIndex) ? "65px" : ""}
                            ref={modalBodyRef as LegacyRef<HTMLDivElement>}
                            key={`box-groups[${groupIndex}]`}
                          >
                            {groupIndex > 0 && (
                              <FormControl>
                                <Center mt={-10} mb={2}>
                                  <Select
                                    key={`select-[${groupIndex}]`}
                                    borderRadius={8}
                                    backgroundColor="#ffffff"
                                    fontSize={14}
                                    borderColor="newBlue.500"
                                    size="sm"
                                    w="80px"
                                    color={"rgba(30, 82, 151, 0.8)"}
                                    _focus={{
                                      borderColor: "none",
                                    }}
                                    value={
                                      values.groups[groupIndex]
                                        .LogicalOperatorGroups || "and"
                                    }
                                    onChange={(value) => {
                                      let currentValue = values;

                                      currentValue.groups[
                                        groupIndex
                                      ].LogicalOperatorGroups =
                                        value.target.value;
                                      setValues(currentValue);
                                    }}
                                    disabled={authStore.readOnlyUser}
                                  >
                                    <option value={"and"} key={"and"}>
                                      {t("and")}
                                    </option>
                                    <option value={"or"} key={"or"}>
                                      {t("or")}
                                    </option>
                                  </Select>
                                </Center>
                              </FormControl>
                            )}
                            <Flex justifyContent="space-between">
                              <Button
                                variant="unstyled"
                                onClick={() => handleToggleGroup(groupIndex)}
                              >
                                <Flex>
                                  <IconButton
                                    color="newBlue.500"
                                    ml={-2}
                                    icon={
                                      openGroups.includes(groupIndex) ? (
                                        <ExpandMoreIcon />
                                      ) : (
                                        <ExpandLessIcon />
                                      )
                                    }
                                    variant="unstyled"
                                    size="sm"
                                    aria-label={""}
                                    mt={"-1px"}
                                  ></IconButton>
                                  <Text
                                    size="md"
                                    fontWeight={"bold"}
                                    color="newBlue.500"
                                  >
                                    {t("group")} {groupIndex + 1}
                                  </Text>
                                </Flex>
                              </Button>
                              {values.groups.length === groupIndex + 1 &&
                                !openGroups.includes(groupIndex) &&
                                !authStore.readOnlyUser && (
                                  <Button
                                    size="sm"
                                    layerStyle="lightRedOcean"
                                    _hover={{
                                      layerStyle: "lightRedOcean",
                                    }}
                                    onClick={() => {
                                      handleRemoveGroup(groupIndex);
                                    }}
                                    leftIcon={<DeleteOutline />}
                                  >
                                    {t("removeGroup")}
                                  </Button>
                                )}
                            </Flex>

                            <Box
                              display={
                                openGroups.includes(groupIndex) ? "none" : ""
                              }
                            >
                              <FieldArray
                                name={`groups[${groupIndex}].conditions`}
                              >
                                {({ form }) => (
                                  <>
                                    {group?.conditions?.map(
                                      (condition, conditionIndex) => (
                                        <Box
                                          ref={
                                            modalBodyRef as LegacyRef<HTMLDivElement>
                                          }
                                          key={`box-groups[${groupIndex}].conditions[${conditionIndex}]`}
                                        >
                                          {conditionIndex > 0 && (
                                            <Center py={2}>
                                              <Divider borderColor="newBlue.500" />
                                              <Text
                                                fontSize="md"
                                                fontWeight={"bold"}
                                                color="newBlue.500"
                                                px={2}
                                                key={`groups[${groupIndex}].conditions[${conditionIndex}].LogicalOperator`}
                                              >
                                                {t(
                                                  values.groups[groupIndex]
                                                    .conditions[1]
                                                    .LogicalOperator as string
                                                )}
                                              </Text>
                                              <Divider borderColor="newBlue.500" />
                                            </Center>
                                          )}
                                          {conditionIndex === 0 && (
                                            <Flex gap={2}>
                                              <Text color="newBlue.500" mt={1}>
                                                {t("type")}
                                              </Text>

                                              <FormControl
                                                isRequired
                                                id={`groups[${groupIndex}].conditions[${conditionIndex}].LogicalOperator`}
                                                width={"80px"}
                                              >
                                                <Select
                                                  disabled={
                                                    group?.conditions.length <=
                                                      1 ||
                                                    authStore.readOnlyUser
                                                  }
                                                  fontSize={14}
                                                  mb={6}
                                                  borderRadius={8}
                                                  backgroundColor="#f0f1f2"
                                                  size="sm"
                                                  color={
                                                    "rgba(30, 82, 151, 0.8)"
                                                  }
                                                  _focus={{
                                                    borderColor: "none",
                                                  }}
                                                  value={
                                                    group?.conditions.length <=
                                                    1
                                                      ? "and"
                                                      : ((group.conditions[1]
                                                          ?.LogicalOperator ||
                                                          group.conditions[0]
                                                            ?.LogicalOperator ||
                                                          "and") as string)
                                                  }
                                                  onChange={(value) => {
                                                    let currentValue = {
                                                      ...values,
                                                    };
                                                    let lengthGroup =
                                                      currentValue.groups[
                                                        groupIndex
                                                      ].conditions.length - 1;
                                                    for (
                                                      let i = 1;
                                                      i <= lengthGroup;
                                                      i++
                                                    ) {
                                                      if (
                                                        currentValue.groups[
                                                          groupIndex
                                                        ].conditions[i]
                                                      ) {
                                                        currentValue.groups[
                                                          groupIndex
                                                        ].conditions[
                                                          i
                                                        ].LogicalOperator =
                                                          value.target.value;
                                                      }
                                                    }
                                                    setValues(currentValue);
                                                    if (lengthGroup > 1) {
                                                      toast({
                                                        title: t(
                                                          "conditionsInsideConditionGroupShouldHaveSameLogicalOperator"
                                                        ),
                                                        status: "info",
                                                        position: "top",
                                                        isClosable: true,
                                                      });
                                                    }
                                                  }}
                                                >
                                                  <option value={"and"} key={1}>
                                                    {t("and")}
                                                  </option>
                                                  <option value={"or"} key={2}>
                                                    {t("or")}
                                                  </option>
                                                </Select>
                                              </FormControl>
                                              <Tooltip
                                                label={t(
                                                  "andVerifyConditionTrueOrVerifyAtLeastConditionTrue"
                                                )}
                                                fontSize="md"
                                                hasArrow
                                              >
                                                <Icon
                                                  pt={1}
                                                  color={"gray.500"}
                                                  as={InfoOutlinedIcon}
                                                />
                                              </Tooltip>
                                            </Flex>
                                          )}

                                          <Box>
                                            <Grid
                                              templateColumns="repeat(12, 1fr)"
                                              gap={6}
                                            >
                                              <GridItem colSpan={4}>
                                                <FormControl isRequired>
                                                  <Select
                                                    borderRadius={8}
                                                    backgroundColor="#f0f1f2"
                                                    fontSize={14}
                                                    size="md"
                                                    color={
                                                      "rgba(30, 82, 151, 0.8)"
                                                    }
                                                    _focus={{
                                                      borderColor: "none",
                                                    }}
                                                    {...getFieldProps(
                                                      "environmentalVariable"
                                                    )}
                                                    value={
                                                      values.groups[groupIndex]
                                                        .conditions[
                                                        conditionIndex
                                                      ].environmentalVariable
                                                    }
                                                    onChange={(value) => {
                                                      let currentValue = values;

                                                      currentValue.groups[
                                                        groupIndex
                                                      ].conditions[
                                                        conditionIndex
                                                      ].environmentalVariable =
                                                        parseFloat(
                                                          value.target.value
                                                        );

                                                      currentValue.groups[
                                                        groupIndex
                                                      ].conditions[
                                                        conditionIndex
                                                      ].units = operationRuleLocationInsightStore.getUnitsMeasurement(
                                                        parseInt(
                                                          value.target.value
                                                        )
                                                      );

                                                      setValues(currentValue);
                                                    }}
                                                    isDisabled={
                                                      authStore.readOnlyUser
                                                    }
                                                  >
                                                    {oceanWeatherIntelligenceStore.environmentalVariables?.map(
                                                      (value) => (
                                                        <option
                                                          value={value.id}
                                                          key={value.id}
                                                        >
                                                          {value.name}
                                                        </option>
                                                      )
                                                    )}
                                                  </Select>
                                                </FormControl>
                                              </GridItem>
                                              <GridItem colSpan={4}>
                                                <FormControl
                                                  isRequired
                                                  id={`groups[${groupIndex}]
                                                    .conditions[
                                                      ${conditionIndex}
                                                  ].mathOperator`}
                                                >
                                                  <Select
                                                    key={`mathOperator${condition.id}`}
                                                    borderRadius={8}
                                                    backgroundColor="#f0f1f2"
                                                    fontSize={14}
                                                    size="md"
                                                    color={
                                                      "rgba(30, 82, 151, 0.8)"
                                                    }
                                                    _focus={{
                                                      borderColor: "none",
                                                    }}
                                                    value={
                                                      values.groups[groupIndex]
                                                        .conditions[
                                                        conditionIndex
                                                      ].mathOperator
                                                    }
                                                    onChange={(e) => {
                                                      let currentValue = values;

                                                      currentValue.groups[
                                                        groupIndex
                                                      ].conditions[
                                                        conditionIndex
                                                      ].mathOperator =
                                                        e.target.value;

                                                      setValues(currentValue);
                                                    }}
                                                    isDisabled={
                                                      authStore.readOnlyUser
                                                    }
                                                  >
                                                    <option value={">"} key={1}>
                                                      {t("isGreaterThan")}
                                                    </option>
                                                    <option
                                                      value={">="}
                                                      key={2}
                                                    >
                                                      {t(
                                                        "isEqualToOrGreaterThan"
                                                      )}
                                                    </option>
                                                    <option value={"="} key={3}>
                                                      {t("isEqualTo")}
                                                    </option>
                                                    <option value={"<"} key={4}>
                                                      {t("isLessThan")}
                                                    </option>
                                                    <option
                                                      value={"<="}
                                                      key={5}
                                                    >
                                                      {t("isEqualToOrLessThan")}
                                                    </option>
                                                  </Select>
                                                </FormControl>
                                              </GridItem>
                                              <GridItem colSpan={3}>
                                                <FormControl
                                                  isRequired
                                                  id={`groups[${groupIndex}].conditions[${conditionIndex}].value`}
                                                  isInvalid={isInvalidValue(
                                                    form,
                                                    touched,
                                                    groupIndex,
                                                    conditionIndex
                                                  )}
                                                >
                                                  <NumberInputWithElement
                                                    placeholder="0.0"
                                                    inputStyle={{
                                                      backgroundColor:
                                                        "#f0f1f2",
                                                    }}
                                                    suffix={
                                                      values.groups[groupIndex]
                                                        .conditions[
                                                        conditionIndex
                                                      ].units
                                                    }
                                                    value={
                                                      values.groups[groupIndex]
                                                        .conditions[
                                                        conditionIndex
                                                      ].value
                                                    }
                                                    onChange={(e) => {
                                                      setFieldTouched(
                                                        `groups[${groupIndex}].conditions[${conditionIndex}].value`
                                                      );
                                                      let currentValue = {
                                                        ...values,
                                                      };
                                                      currentValue.groups[
                                                        groupIndex
                                                      ].conditions[
                                                        conditionIndex
                                                      ].value = e.target.value;

                                                      setValues(currentValue);
                                                    }}
                                                    disabled={
                                                      authStore.readOnlyUser
                                                    }
                                                  />
                                                  <FormErrorMessage>
                                                    {t("valueRequired")}
                                                  </FormErrorMessage>
                                                </FormControl>
                                              </GridItem>
                                              <GridItem colSpan={1}>
                                                <IconButton
                                                  layerStyle="lightRedOcean"
                                                  _hover={{
                                                    layerStyle: "lightRedOcean",
                                                  }}
                                                  icon={<DeleteOutline />}
                                                  size="md"
                                                  aria-label={""}
                                                  onClick={() => {
                                                    let currentValue = values;
                                                    currentValue.groups[
                                                      groupIndex
                                                    ].conditions.splice(
                                                      conditionIndex,
                                                      1
                                                    );
                                                    setValues(currentValue);
                                                  }}
                                                  disabled={
                                                    authStore.readOnlyUser
                                                  }
                                                ></IconButton>
                                              </GridItem>
                                            </Grid>
                                          </Box>
                                        </Box>
                                      )
                                    )}
                                    <Center mt={4}>
                                      {!authStore.readOnlyUser && (
                                        <Button
                                          layerStyle="lightOcean"
                                          _hover={{
                                            layerStyle: "lightOcean",
                                          }}
                                          leftIcon={<AddIcon />}
                                          onClick={() => {
                                            const logicalOperator =
                                              values.groups[groupIndex]
                                                .conditions[1]
                                                ?.LogicalOperator || "and";
                                            let currentValue = values;
                                            currentValue.groups[
                                              groupIndex
                                            ].conditions.push({
                                              mathOperator: ">",
                                              environmentalVariable:
                                                defaultEnvironmentalVariableId,
                                              units: "knots",
                                              LogicalOperator: logicalOperator,
                                            });
                                            if (
                                              currentValue.groups.length ===
                                              groupIndex + 1
                                            )
                                              setTimeout(() => {
                                                if (modalBodyRef.current) {
                                                  modalBodyRef.current.scrollIntoView(
                                                    {
                                                      behavior: "smooth",
                                                    }
                                                  );
                                                }
                                              }, 50);
                                            setValues(currentValue);
                                          }}
                                        >
                                          {t("addNewCondition")}
                                        </Button>
                                      )}
                                    </Center>
                                  </>
                                )}
                              </FieldArray>
                            </Box>
                          </Box>
                        ))}
                        {values?.groups?.length > 0 && !authStore.readOnlyUser && (
                          <Box>
                            <Button
                              mt={4}
                              layerStyle="lightOcean"
                              _hover={{ layerStyle: "lightOcean" }}
                              onClick={() => {
                                let currentValue = values;
                                currentValue.groups.push({
                                  LogicalOperatorGroups: "and",
                                  conditions: [
                                    {
                                      mathOperator: ">",
                                      environmentalVariable:
                                        defaultEnvironmentalVariableId,
                                      units: "knots",
                                    },
                                  ],
                                });
                                setTimeout(() => {
                                  if (modalBodyRef.current) {
                                    modalBodyRef.current.scrollIntoView({
                                      behavior: "smooth",
                                    });
                                  }
                                }, 50);

                                setValues(currentValue);
                              }}
                              leftIcon={<PlusOutlined />}
                            >
                              {t("addNewGroup")}
                            </Button>
                          </Box>
                        )}
                      </>
                    )}
                  </FieldArray>
                </ModalBody>
                {!authStore.readOnlyUser && (
                  <ModalFooter>
                    <Button
                      variant="ghost"
                      color="newBlue.500"
                      onClick={() => {
                        onClose();
                      }}
                      mr={3}
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      layerStyle="ocean"
                      _hover={{ layerStyle: "ocean" }}
                      onClick={submitForm}
                      type="submit"
                    >
                      {operationRuleLocationInsightStore.operationRuleLocationItemAction ===
                      DBAction.CREATE
                        ? t("add")
                        : t("save")}
                    </Button>
                  </ModalFooter>
                )}
              </ModalContent>
            </>
          )}
        </Formik>
      </Modal>
    );
  };

export default observer(OperationRuleLocationInsightModal);
