import { IRegisterUser } from "../types/IRegisterUser";
import api from "./api";

export default class AuthService {
  async recoveryUser(info: string) {
    const result = await api.post("/v1/auth/recoveryUser", {
      info: info,
    });
    return result.data;
  }

  async updatePassword(userId: number, code: string, password: string) {
    const result = await api.post("/v1/auth/updatePassword", {
      userId: userId,
      code: code,
      password: password,
    });
    return result;
  }

  async changePassword(passwordOld: string, passwordNew: string) {
    const result = await api.post("/v1/auth/changePassword", {
      passwordOld: passwordOld,
      passwordNew: passwordNew,
    });
    if (result.status === 200 || result.status === 201) {
      return result.data;
    }
    throw new Error("Error while change password");
  }

  async registerUser({
    invitationToken,
    name,
    lastName,
    login,
    password,
    areaId,
    positionId,
    phoneCod,
    phoneNumber,
  }: IRegisterUser): Promise<{ success: boolean }> {
    const result = await api.post("/v1/auth/register-user", {
      invitation_token: invitationToken,
      name,
      last_name: lastName,
      login,
      password,
      area_id: areaId,
      position_id: positionId,
      phone_code: phoneCod,
      phone_number: phoneNumber,
    });
    return result.data;
  }

  async hasUsername(username: string): Promise<boolean> {
    const result = await api.get(`v1/auth/has-username/${username}`);
    return result.data;
  }
}
