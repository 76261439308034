import { useToast } from "@chakra-ui/react";
import { EnvironmentalType } from "enum/EnvironmentalType";
import { useTranslation } from "react-i18next";
import { extractStationAndRegionIds } from "util/extractStationRegionIds/extractStationAndRegionIds";
import { FormValues, MetOceanReportRequest } from "../MetOceanReportModal";

export const useMetOceanReportDataRequest = () => {
  const toast = useToast();
  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.metOceanReport",
  });

  return (values: FormValues): MetOceanReportRequest[] => {
    const {
      weather,
      weatherLocation,
      wave,
      waveLocation,
      waterFlow,
      waterFlowLocation,
    } = values;
    const metOceanReportDataRequest: MetOceanReportRequest[] = [];

    if (!weather && !wave && !waterFlow) {
      toast({
        title: t("selectAtLeastOneVariable"),
        status: "error",
        position: "top",
        isClosable: true,
      });
      return metOceanReportDataRequest;
    }

    if (!weatherLocation && !waveLocation && !waterFlowLocation) {
      toast({
        title: t("selectAtLeastOneStation"),
        status: "error",
        position: "top",
        isClosable: true,
      });
      return metOceanReportDataRequest;
    }

    const buildForecastConfig = (
      environmentalType: EnvironmentalType,
      location: string
    ): MetOceanReportRequest => {
      const [stationId, regionId] = extractStationAndRegionIds(location);
      return {
        environmentalType,
        regionId: regionId,
        stationId: stationId,
      };
    };

    if (weather && weatherLocation) {
      metOceanReportDataRequest.push(
        buildForecastConfig(EnvironmentalType.WEATHER, weatherLocation)
      );
      metOceanReportDataRequest.push(
        buildForecastConfig(EnvironmentalType.VISIBILITY, weatherLocation)
      );
    }

    if (wave && waveLocation) {
      metOceanReportDataRequest.push(
        buildForecastConfig(EnvironmentalType.WAVE, waveLocation)
      );
    }

    if (waterFlow && waterFlowLocation) {
      metOceanReportDataRequest.push(
        buildForecastConfig(EnvironmentalType.WATER_FLOW, waterFlowLocation)
      );
    }

    return metOceanReportDataRequest;
  };
};
