import { makeObservable, observable, action } from "mobx";
import { StoreState } from "../../../enum/StoreState";
import { INotification } from "../../../types/INotification";
import { RootEnv } from "../../setup/create-store";
import { getEnv } from "mobx-easy";
import { RealtimeStorageEntity } from "../../../types/realtimeStorageTypes";
export default class notificationsStore {
  state: string = StoreState.DONE;

  notifications: INotification[] = [];

  countNoReadNotifications: number = 0;

  constructor() {
    makeObservable(this, {
      state: observable,
      notifications: observable,
      countNoReadNotifications: observable,
      setState: action,
      setNotifications: action,
      setHasNotification: action,
    });
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setNotifications(value: INotification[]) {
    const orderNotifications: INotification[] = value.sort((a, b) =>
      a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
    );

    this.notifications = orderNotifications;
  }

  setHasNotification(notifications: INotification[]) {
    const notificationsFiltered = notifications.filter((n) => !n.isRead);
    this.countNoReadNotifications = notificationsFiltered?.length || 0;
  }

  getNotification(id: string) {
    return this.notifications.find(
      (notification) => notification.id === id
    ) as INotification;
  }

  reset() {
    this.setState(StoreState.DONE);
    this.setNotifications([]);
    this.countNoReadNotifications = 0;
  }

  async setNotificationReadAPI(notificationId?: string) {
    const { notificationService } = getEnv<RootEnv>();
    try {
      if (notificationId) {
        const result = await notificationService.setNotificationRead(
          notificationId
        );
        if (result?.success) {
          this.updateNotificationSetRead(notificationId);
        }
      } else {
        this.notifications.map(async (notification) => {
          if (!notification.isRead) {
            const result = await notificationService.setNotificationRead(
              notification.id
            );
            if (result?.success) {
              this.updateNotificationSetRead(notification.id);
            }
          }
        });
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  private updateNotificationSetRead(id: string) {
    const notificationIndex = this.notifications.indexOf(
      this.getNotification(id)
    );
    this.notifications[notificationIndex] = {
      ...this.notifications[notificationIndex],
      isRead: true,
    };
    this.setHasNotification(this.notifications);
  }

  subscribeNotification(
    userId: number,
    callback: (storage: INotification[]) => void
  ) {
    const { googleFirebase } = getEnv<RootEnv>();

    try {
      googleFirebase.onSnapshot(
        RealtimeStorageEntity.Notification,
        {
          filter: [
            {
              key: "userId",
              operator: "==",
              value: userId,
            },
          ],
          limit: 50,
        },
        callback
      );
    } catch (error) {
      console.log(`Error while try snapShot notifications`);
    }
  }
}
