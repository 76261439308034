import React, { FC, useCallback, useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { ISomcDataFileLog } from "../../types/ISomcDataFileLog";
import { DateTime } from "luxon";
import { ColumnFilterItem } from "antd/lib/table/interface";

export interface ImportedForecastTableRowProps {
  key: React.Key;
  dataSource: string;
  dataType: string;
  forecastDate: string;
  macroRegion: string;
  regionOrigin: string;
  stationId: number;
  stationName: string;
  netcdfFilename: string;
  createdAt: string;
  updatedAt: string;
  tags: string[];
}

export interface ImportedForecastTableProps {
  somcDataFileLog: ISomcDataFileLog[];
  isLoading: boolean;
  pageSize?: number;
}

export const ImportedForecastTable: FC<ImportedForecastTableProps> = ({
  somcDataFileLog,
  pageSize,
  isLoading,
}) => {
  const [dataTable, setDataTable] = useState<ImportedForecastTableRowProps[]>(
    []
  );

  useEffect(() => {
    const somcLogTableRowProps: ImportedForecastTableRowProps[] =
      somcDataFileLog.map((log) => {
        return {
          key: DateTime.local().toMillis.toString(),
          dataSource: log.dataSource,
          dataType: log.dataType,
          forecastDate: log.forecastDate,
          macroRegion: log.macroRegion,
          regionOrigin: log.regionOrigin,
          stationId: log.stationId,
          stationName: log.stationName,
          netcdfFilename: log.netcdfFilename,
          createdAt: DateTime.fromMillis(log.createdAt, {
            zone: "America/Bahia",
          }).toFormat("dd/MM/yyyy HH:mm"),
          updatedAt: DateTime.fromMillis(log.updatedAt, {
            zone: "America/Bahia",
          }).toFormat("dd/MM/yyyy HH:mm"),
          tags:
            log.createdAt <= DateTime.utc().minus({ day: 1 }).toMillis()
              ? ["overdue"]
              : ["ok"],
        };
      });
    setDataTable(somcLogTableRowProps);
  }, [somcDataFileLog]);

  const getColumnFilter = useCallback(
    (item: keyof ImportedForecastTableRowProps) => {
      const filterAux: ColumnFilterItem[] = [];
      for (const row of dataTable) {
        const columnFilterItem: ColumnFilterItem = {
          text: String(row[item]),
          value: row[item] as string,
        };
        if (
          !filterAux.find((filter) => filter.value === columnFilterItem.value)
        ) {
          filterAux.push(columnFilterItem);
        }
      }

      return filterAux;
    },
    [dataTable]
  );

  const columns = [
    {
      title: "Status",
      key: "status",
      dataIndex: "tags",
      render: (tags: string[]) => (
        <>
          {tags.map((tag) => {
            let color = "green";
            if (tag === "overdue") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Macro Region",
      dataIndex: "macroRegion",
      render: (text: string) => <a>{text}</a>,
      filters: getColumnFilter("macroRegion"),
      onFilter: (value: any, record: ImportedForecastTableRowProps) =>
        record.macroRegion.includes(value),
      width: "10%",
    },
    {
      title: "Region",
      dataIndex: "regionOrigin",
      render: (text: string) => <a>{text}</a>,
      filters: getColumnFilter("regionOrigin"),
      onFilter: (value: any, record: ImportedForecastTableRowProps) =>
        record.regionOrigin.includes(value),
      width: "10%",
    },
    {
      title: "Source",
      dataIndex: "dataSource",
      render: (text: string) => <a>{text}</a>,
      filters: getColumnFilter("dataSource"),
      onFilter: (value: any, record: ImportedForecastTableRowProps) =>
        record.dataSource.includes(value),
      width: "10%",
    },
    {
      title: "Type",
      dataIndex: "dataType",
      render: (text: string) => <a>{text}</a>,
      filters: getColumnFilter("dataType"),
      onFilter: (value: any, record: ImportedForecastTableRowProps) =>
        record.dataType.includes(value),
      width: "10%",
    },
    {
      title: "Station",
      dataIndex: "stationName",
      render: (text: string) => <a>{text}</a>,
      filters: getColumnFilter("stationName"),
      onFilter: (value: any, record: ImportedForecastTableRowProps) =>
        record.stationName.includes(value),
    },
    {
      title: "Forecast Date",
      dataIndex: "forecastDate",
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: "Created At (GMT: -3h)",
      dataIndex: "createdAt",
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: "Imported At (GMT: -3h)",
      dataIndex: "updatedAt",
      render: (text: string) => <a>{text}</a>,
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={dataTable}
      pagination={{ pageSize: pageSize || 15 }}
      loading={isLoading}
    />
  );
};
