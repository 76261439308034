import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

interface MapLegendProps {
  maxText?: string | undefined;
  maxColor?: string | undefined;
  averageText?: string | undefined;
  averageColor?: string | undefined;
}

const MapLegend: React.FC<MapLegendProps> = ({
  maxText,
  maxColor,
  averageText,
  averageColor,
}) => {
  const renderPointerLegend = (
    color: string | undefined,
    text: string | undefined
  ) => {
    return (
      <Flex alignItems={"center"} p={1}>
        <div
          style={{
            minWidth: "30px",
            minHeight: "20px",
            background: color,
            marginRight: "4px",
            borderRadius: "2px",
          }}
        ></div>
        <Text
          color="newBlue.500"
          fontSize={"xs"}
          lineHeight="12px"
          fontWeight={"light"}
        >
          {text}
        </Text>
      </Flex>
    );
  };

  return (
    <Box position={"relative"}>
      <Flex
        alignItems={"center"}
        position={"absolute"}
        w="160px"
        h="80px"
        zIndex={99999}
        mt={"10px"}
        ml={"10px"}
        bg="white"
        borderRadius={8}
      >
        <Box p={2}>
          {renderPointerLegend(averageColor, averageText)}
          {renderPointerLegend(maxColor, maxText)}
        </Box>
      </Flex>
    </Box>
  );
};

export default MapLegend;
