import { useToast } from "@chakra-ui/react";
import { VisualizationType } from "enum/VisualizationType";
import { useEffect } from "react";
import ForecastChartMeteogramStore from "stores/data/forecastChart/forecastChartMeteogram/forecastChartMeteogramStore";
import ForecastChartPageStore from "stores/data/forecastChart/forecastChartPage/forecastChartPageStore";
import { useStores } from "stores/setup/use-store";

export default function useGetStationsWithSensorData(
  forecastChartPageStore: ForecastChartPageStore,
  forecastChartMeteogramStore: ForecastChartMeteogramStore
) {
  const toast = useToast();
  const {
    dataStores: { requestCancellationStore },
  } = useStores();

  useEffect(() => {
    const controller = new AbortController();

    if (
      forecastChartMeteogramStore.visualizationType === VisualizationType.SENSOR
    ) {
      toast.close(forecastChartPageStore.toastNoStationWithSensorId);
      requestCancellationStore.cancelAllRequests().then(() => {
        forecastChartPageStore.getStationsWithSensorData({
          envType: forecastChartPageStore.tabStateActivty,
          controller,
        });
      });
    }

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastChartPageStore.actionCallGetStationsWithSensor]);
}
