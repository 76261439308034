import { action, makeObservable, observable } from "mobx";

export default class WathIsNewStore {
  makeAllAsRead: boolean = false;

  constructor() {
    makeObservable(this, {
      makeAllAsRead: observable,
      setMakeAllAsRead: action,
    });
  }

  setMakeAllAsRead(value: boolean) {
    this.makeAllAsRead = value;
    localStorage.setItem("makeAllAsRead", value.toString());
  }
}
