import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Center,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../stores/setup/use-store";
import { PageTitle } from "../../../components/PageTitle/PageTitle";
import { Select } from "antd";
import i18n from "../../../i18n";
import { IStation } from "../../../stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import { IOccurrenceDescription } from "../../../types/IOccurrence";
import WeatherTab from "./WeatherTab";
import WaveTab from "./WaveTab";
import WaterFlow from "./WaterFlowTab";
import { EnvironmentalType } from "../../../enum/EnvironmentalType";

const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
};

const EventAnalysis: React.FC = () => {
  const {
    dataStores: { occurrenceStore, forecastChartPageStore },
  } = useStores();

  const { t } = useTranslation("translation", {
    keyPrefix: "eventAnalysis",
  });

  const [station, setStation] = useState<IStation>();
  const [selectedOccurrenceDescription, setSelectedOccurrenceDescription] =
    useState<IOccurrenceDescription>();

  const getFormattedDate = useCallback((timestamp: any) => {
    return timestamp
      ? new Date(timestamp).toLocaleDateString(i18n.language, dateOptions)
      : null;
  }, []);

  const timestampInitialDate = occurrenceStore.occurrence?.initialDate;
  const formattedInitialDate = getFormattedDate(timestampInitialDate);

  const timestampFinalDate = occurrenceStore.occurrence?.finalDate;
  const formattedFinalDate = getFormattedDate(timestampFinalDate);

  const filterOption = (
    input: string,
    option?: { label: string; value: string; children: any }
  ) => {
    const children = option?.children?.props?.children;
    const childrenArray = Array.isArray(children) ? children : [children];

    return childrenArray.join().toLowerCase().includes(input.toLowerCase());
  };

  const limitText = (text: string, limit: number) => {
    if (text.length <= limit) {
      return text;
    } else {
      return text.slice(0, limit) + "...";
    }
  };

  useEffect(() => {
    occurrenceStore.resetEventAnalysis();
    occurrenceStore.getOccurrencesDescription();
    forecastChartPageStore.getStationsForecastChartAPI(
      EnvironmentalType.WEATHER
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (occurrenceStore.occurrence) {
      const { stationId, regionId } = occurrenceStore.occurrence;

      const stationData = forecastChartPageStore.getStationByIdAndRegionId(
        stationId,
        regionId
      );
      setStation(stationData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occurrenceStore.occurrence]);

  const handleSelectOccurrence = (_: any, item: any) => {
    setStation(undefined);
    occurrenceStore.resetClimatologicData();
    occurrenceStore.setOccurrence(null);

    const occurrenceDescription = occurrenceStore.occurrencesDescription.find(
      (o) => o.id === item.key
    );

    setSelectedOccurrenceDescription(occurrenceDescription);

    if (occurrenceDescription) {
      occurrenceStore.getOccurrence(occurrenceDescription.id);
    }
  };

  return (
    <Box mt={8} mb={8}>
      <PageTitle text={t("eventAnalysis")} />

      <Box>
        <Text color="gray.500">{t("occurrence")}</Text>

        <Select
          showSearch
          placeholder={t("selectOccurrence")}
          optionFilterProp="children"
          style={{ width: 565 }}
          filterOption={filterOption}
          onSelect={(_, item) => handleSelectOccurrence(_, item)}
        >
          {occurrenceStore.occurrencesDescription?.map((occurrence) => (
            <Select.Option value={occurrence.description} key={occurrence.id}>
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                {limitText(occurrence.description, 60)}
              </Flex>
            </Select.Option>
          ))}
        </Select>
      </Box>

      <Flex minH={"75px"} gap={20} mt={8}>
        <Box maxW={"800px"}>
          <Text color="newBlue.500" fontWeight={"bold"}>
            {t("occurrence")}
          </Text>
          {selectedOccurrenceDescription?.description ? (
            <Text color="newBlue.500">
              {selectedOccurrenceDescription.description}
            </Text>
          ) : (
            <Center>
              <Text color="newBlue.500">-</Text>
            </Center>
          )}
        </Box>

        <Box>
          <Text color="newBlue.500" fontWeight={"bold"}>
            {t("period")}
          </Text>
          {formattedInitialDate ? (
            <>
              <Text color="newBlue.500">
                {formattedInitialDate}
                {i18n.language === "en" ? "" : "h"}
              </Text>
              <Text color="newBlue.500">
                {formattedFinalDate ? formattedFinalDate + "h" : "-"}
              </Text>
            </>
          ) : (
            <Center>
              <Text color="newBlue.500">-</Text>
            </Center>
          )}
        </Box>

        <Box>
          <Text color="newBlue.500" fontWeight={"bold"}>
            {t("location")}
          </Text>
          {station ? (
            <>
              <Text color="newBlue.500">{`${station.station_name} - ${station.macro_region_name}`}</Text>
              <Flex gap={2}>
                <Flex gap={2}>
                  <Text fontWeight={"bold"} color="newBlue.500">
                    {t("latitude")}:
                  </Text>
                  <Text color="newBlue.500">{station.station_lat}</Text>
                </Flex>
                <Flex gap={2}>
                  <Text fontWeight={"bold"} color="newBlue.500">
                    {t("longitude")}:
                  </Text>
                  <Text color="newBlue.500">{station.station_lon}</Text>
                </Flex>
              </Flex>
            </>
          ) : (
            <Center>
              <Text color="newBlue.500">-</Text>
            </Center>
          )}
        </Box>
      </Flex>

      <Tabs color="black" mt={6} isLazy>
        <TabList color="gray.500" gap={14}>
          <Tab _selected={{ color: "newBlue.500", borderColor: "newBlue.500" }}>
            {t("weather")}
          </Tab>
          <Tab _selected={{ color: "newBlue.500", borderColor: "newBlue.500" }}>
            {t("wave")}
          </Tab>
          <Tab _selected={{ color: "newBlue.500", borderColor: "newBlue.500" }}>
            {t("waterFlow")}
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel paddingX={0}>
            <WeatherTab
              key={`stationId-${station?.station_id}`}
              selectedOccurrenceDescription={selectedOccurrenceDescription}
              station={station}
            />
          </TabPanel>
          <TabPanel paddingX={0}>
            <WaveTab
              key={`stationId-${station?.station_id}`}
              selectedOccurrenceDescription={selectedOccurrenceDescription}
              station={station}
            />
          </TabPanel>
          <TabPanel paddingX={0}>
            <WaterFlow
              key={`stationId-${station?.station_id}`}
              selectedOccurrenceDescription={selectedOccurrenceDescription}
              station={station}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
export default observer(EventAnalysis);
