import React, { useEffect } from "react";
import Routes from "./routes";
import { useStores } from "./stores/setup/use-store";

const App = () => {
  const {
    uiStores: { navigatorAndWindowStore },
    dataStores: { securityStore },
  } = useStores();

  useEffect(() => {
    const loadStores = async () => {
      await Promise.all([securityStore.getTokenSet()]);
    };
    loadStores().then();
  }, []);

  useEffect(() => {
    const handleIsOnline = () => {
      navigatorAndWindowStore.setIsOnline(true);
    };
    const handleIsOffline = () => {
      navigatorAndWindowStore.setIsOnline(false);
    };
    window.addEventListener("online", handleIsOnline);
    window.addEventListener("offline", handleIsOffline);

    return () => {
      window.removeEventListener("online", handleIsOnline);
      window.removeEventListener("offline", handleIsOffline);
    };
  }, [navigatorAndWindowStore]);

  return <Routes />;
};
export default App;
