import { useEffect, useState } from "react";
import { ACTIONS, CallBackProps, EVENTS } from "react-joyride";
import { JoyrideStorage } from "../../../../enum/JoyrideStorage";
import { setIfJoyrideIsDoneInStorage } from "../../../../util/joyRideLocalStorage/joyRideLocalStorage";

export default function useGuidedTutorial(
  stepsLength: number,
  storage: JoyrideStorage[],
  shouldRunJoyride: boolean
) {
  const [{ run, stepIndex }, setForecastRide] = useState({
    run: shouldRunJoyride,
    stepIndex: 0,
  });

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const { type, action, index } = data;

    if (([ACTIONS.CLOSE, ACTIONS.SKIP] as string[]).includes(action)) {
      storage.forEach((item) => setIfJoyrideIsDoneInStorage(item, true));
      setForecastRide((prev) => ({
        ...prev,
        run: false,
        stepIndex: 0,
      }));
      return;
    }

    if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)
    ) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (action === ACTIONS.NEXT && index === stepsLength - 1) {
        setForecastRide((prev) => ({
          ...prev,
          run: false,
          stepIndex: 0,
        }));
        storage.forEach((item) => setIfJoyrideIsDoneInStorage(item, true));
        return;
      }

      setForecastRide((prev) => ({
        ...prev,
        stepIndex: nextStepIndex,
      }));
    }
  };

  useEffect(() => {
    setForecastRide((prev) => ({ ...prev, run: shouldRunJoyride }));
  }, [shouldRunJoyride]);

  return { run, stepIndex, handleJoyrideCallback };
}
