import { Step } from "react-joyride";

import JoyrideContent from "../../../../components/JoyrideContent/JoyrideContent";
import guideMeteogram from "../../../../assets/guide-meteogram.svg";
import guideProbabilistic from "../../../../assets/guide-probabilistic.svg";
import guideGeospatial from "../../../../assets/guide-geospatial.jpg";
import guideIntroImage from "../../../../assets/guide-intro-image.png";
import guideSensorImage from "../../../../assets/sensor.png";
import i18n from "../../../../i18n";
import { JoyrideStorage } from "enum/JoyrideStorage";

import { getIfJoyrideIsDoneInStorage } from "util/joyRideLocalStorage/joyRideLocalStorage";

const getForecastOnlySteps = (): Step[] => [
  {
    target: ".joyride-forecast-first-step",
    placement: "center",
    disableBeacon: true,
    content: (
      <JoyrideContent
        image={{
          src: guideIntroImage,
          alt: "Geospatial guide",
        }}
        imageStyle={{
          width: "350px",
          objectFit: "fill",
          aspectRatio: "3/2",
        }}
        text={
          i18n.t(
            "forecastChart.forecastChartPage.weWillGuideYouThrough"
          ) as string
        }
      />
    ),
  },
  {
    target: ".joyride-geospatial",
    content: (
      <JoyrideContent
        image={{
          src: guideGeospatial,
          alt: "Geospatial guide",
        }}
        text={
          i18n.t(
            "forecastChart.forecastChartPage.clickToAccessYourList"
          ) as string
        }
      />
    ),
  },
  {
    target: ".highcharts-legend",
    content: (
      <JoyrideContent
        image={{
          src: guideMeteogram,
          alt: "Guide meteogram",
        }}
        text={i18n.t("forecastChart.forecastChartPage.youAreNowAble") as string}
      />
    ),
  },
  {
    target: ".joyride-menu-types-forecast",
    placement: "left",
    content: (
      <JoyrideContent
        text={
          i18n.t(
            "forecastChart.forecastChartPage.youCanViewTwoForecast"
          ) as string
        }
      />
    ),
  },
  {
    target: ".joyride-menu-types-probabilistic",
    placement: "left",

    content: (
      <JoyrideContent
        image={{
          src: guideProbabilistic,
          alt: "Guide probabilistic",
        }}
        text={
          i18n.t("forecastChart.forecastChartPage.probabilityMode") as string
        }
      />
    ),
  },
];

const getSensorOnlySteps = (): Step[] => [
  {
    target: ".joyride-menu-types-sensor",
    placement: "left",
    disableBeacon: true,
    content: (
      <JoyrideContent
        image={{
          src: guideSensorImage,
          alt: "Guide Sensor",
        }}
        text={i18n.t("forecastChart.forecastChartPage.sensorMode") as string}
      />
    ),
  },
];

const getMetOceanReportOnlySteps = (): Step[] => [
  {
    target: ".joyride-metocean-button",
    content: (
      <JoyrideContent
        text={
          i18n.t(
            "forecastChart.forecastChartPage.toGenerateMetoceanReport"
          ) as string
        }
      />
    ),
  },
];

export const getForecastSteps = (): Step[] => {
  const showForecastSteps = !getIfJoyrideIsDoneInStorage(
    JoyrideStorage.FORECAST_RIDE_ON_LOCAL_STORAGE
  );
  const showSensorSteps = !getIfJoyrideIsDoneInStorage(
    JoyrideStorage.INITIAL_SENSOR_RIDE_ON_LOCAL_STORAGE
  );
  const showMetOceanSteps = !getIfJoyrideIsDoneInStorage(
    JoyrideStorage.INITIAL_METOCEAN_REPORT_RIDE_ON_LOCAL_STORAGE
  );
  return [
    ...(showForecastSteps ? getForecastOnlySteps() : []),
    ...(showSensorSteps ? getSensorOnlySteps() : []),
    ...(showMetOceanSteps ? getMetOceanReportOnlySteps() : []),
  ];
};
