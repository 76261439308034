export enum MacroRegion {
  ALL_SAINTS_BAY = "bts",

  PORT_OF_ACU = "acu",

  PORT_OF_SANTOS = "san",

  RIVER_RHINE_GERMANY = "rhn",

  PORT_OF_ITAGUAI = "itg",

  PORT_OF_ITAJAI = "itj",

  PATOS_LAGOON = "ldp",

  PORT_OF_PARANAGUA = "prg",

  PORT_OF_ROTTERDAM = "rot",

  PORT_OF_PECEM = "pcm",

  PORT_OF_SINES = "sns",

  COAST_OF_DENMARK = "din",

  COAST_OF_ESP_SANTO = "eps",

  COAST_OF_ANTOFAGASTA = "afg",

  COAST_OF_VALPARAISO = "val",

  BARRA_NORTE = "brn",

  SAO_FRANCISCO_DO_SUL = "sfs",

  SOUTH_BAHIA = "sba",

  COAST_OF_ALAGOAS = "mac",

  COAST_OF_RIO_GRANDE_DO_NORTE = "ntl",

  COAST_OF_PIAUI = "pia",

  COAST_OF_MARANHAO = "bsm",

  COAST_OF_PARA = "par",

  COAST_OF_RIO_DE_JANEIRO = "rja",

  COAST_OF_BELGIUM = "bel",

  MATO_GROSSO_DO_SUL = "mgs",

  PERU_NORTE = "pen",

  PERU_CENTRAL = "pec",

  PERU_SUL = "pes",

  MATO_GROSSO = "mtg",

  GERMAN_NORTH_SEA = "mdn",

  DANISH_NORTH_SEA = "den",

  SCOTLAND_NORTH_SEA = "esc",

  SOUTHERN_BIGHT_NORTH_SEA = "sbg",

  IRISH_SEA = "irl",

  BALTIC_SEA = "bal",

  AMAZON_RIVER = "man",

  PORT_OF_PIRAEUS = "gre",
}
