import React from "react";
import { Box, Flex, Text, Center, Spinner } from "@chakra-ui/react";

interface CardProps {
  icon: string;
  title: string;
  textLeft: string;
  textRight: string;
  valueLeft: string | null;
  valueRight: string | null;
  iconLeft?: string;
  iconRight?: string;
  iconLeftRotation?: number;
  iconRightRotation?: number;
  bottomLeftText?: string;
  bottomRightText?: string;
  hideBottomLeftText?: boolean;
  hideBottomRightText?: boolean;
  isLoading?: boolean;
}

const Card: React.FC<CardProps> = ({
  icon,
  title,
  textLeft,
  textRight,
  valueLeft,
  valueRight,
  iconLeft,
  iconRight,
  iconLeftRotation = 0,
  iconRightRotation = 0,
  bottomLeftText,
  bottomRightText,
  hideBottomLeftText,
  hideBottomRightText,
  isLoading,
}) => {
  return (
    <Box borderRadius={8} h={"123px"} w={"100%"} bg="white" p={2}>
      <Flex gap={2} mx={2}>
        <img src={icon} alt="wind" />
        <Text color="newBlue.500">{title}</Text>
      </Flex>

      <Flex justifyContent={"space-between"} mx={4} mt={4}>
        <Center flexDirection="column">
          <Flex gap={2}>
            <Text
              fontSize={window.innerWidth < 1800 ? "sm" : "md"}
              color="newBlue.500"
            >
              {textLeft}
            </Text>
            <Text color="newBlue.500" fontWeight={"bold"}>
              {valueLeft}
            </Text>
          </Flex>
          {!valueLeft && hideBottomLeftText && !isLoading && (
            <Text
              fontSize={window.innerWidth < 1800 ? "sm" : "md"}
              color="newBlue.500"
            >
              -
            </Text>
          )}
          <Flex gap={2}>
            {isLoading ? (
              <Spinner
                speed="0.65s"
                emptyColor="gray.300"
                color="newBlue.500"
                size="sm"
              />
            ) : bottomLeftText ? (
              <>
                <Text
                  fontSize={window.innerWidth < 1800 ? "sm" : "md"}
                  color="newBlue.500"
                >
                  {bottomLeftText}
                </Text>
                {iconLeft && (
                  <img
                    src={iconLeft}
                    alt="arrow"
                    style={{ transform: `rotate(${iconLeftRotation}deg)` }}
                  />
                )}
              </>
            ) : (
              !hideBottomLeftText && (
                <Text
                  fontSize={window.innerWidth < 1800 ? "sm" : "md"}
                  color="newBlue.500"
                >
                  -
                </Text>
              )
            )}
          </Flex>
        </Center>

        <Center flexDirection="column">
          <Flex gap={2}>
            <Text
              fontSize={window.innerWidth < 1800 ? "sm" : "md"}
              color="newBlue.500"
            >
              {textRight}
            </Text>
            <Text color="newBlue.500" fontWeight={"bold"}>
              {valueRight}
            </Text>
          </Flex>
          {!valueRight && hideBottomRightText && !isLoading && (
            <Text
              fontSize={window.innerWidth < 1800 ? "sm" : "md"}
              color="newBlue.500"
            >
              -
            </Text>
          )}
          <Flex gap={2}>
            {isLoading ? (
              <Spinner
                speed="0.65s"
                emptyColor="gray.300"
                color="newBlue.500"
                size="sm"
              />
            ) : bottomRightText ? (
              <>
                <Text
                  fontSize={window.innerWidth < 1800 ? "sm" : "md"}
                  color="newBlue.500"
                >
                  {bottomRightText}
                </Text>
                {iconRight && (
                  <img
                    src={iconRight}
                    alt="arrow"
                    style={{ transform: `rotate(${iconRightRotation}deg)` }}
                  />
                )}
              </>
            ) : (
              !hideBottomRightText && (
                <Text
                  fontSize={window.innerWidth < 1800 ? "sm" : "md"}
                  color="newBlue.500"
                >
                  -
                </Text>
              )
            )}
          </Flex>
        </Center>
      </Flex>
    </Box>
  );
};

export default Card;
