import React from "react";
import { useTranslation } from "react-i18next";
import checkIcon from "../../../assets/checkIcon.svg";
import {
  WrapperLoginForm,
  ContainerLogin,
  ButtonLogin,
  NewPasswordTitleText,
  ContainerNewPasswordTitle,
  NewPasswordText,
} from "../styles";

export interface NewPasswordSavedProps {
  title: string;
  text: string;
  onClick: (e: any) => void;
}

export const NewPasswordSaved: React.FC<NewPasswordSavedProps> = ({
  title,
  text,
  onClick,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "login.newPasswordForm",
  });
  return (
    <>
      <WrapperLoginForm>
        <ContainerNewPasswordTitle>
          <img src={checkIcon} alt="check icon" height="40vh" width="40vh" />
          <NewPasswordTitleText>{title}</NewPasswordTitleText>
          <NewPasswordText>{text}</NewPasswordText>
        </ContainerNewPasswordTitle>
        <ContainerLogin>
          <ButtonLogin onClick={onClick}>{t("returnToLogin")}</ButtonLogin>
        </ContainerLogin>
      </WrapperLoginForm>
    </>
  );
};
