import React, { FC, InputHTMLAttributes } from "react";
import { Input as InputChakra } from "@chakra-ui/react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: JSX.Element;
  width?: string;
  inputStyle?: React.CSSProperties;
}

export const Input: FC<InputProps> = ({
  placeholder,
  width,
  onChange,
  name,
  value,
  maxLength,
  inputStyle,
  disabled,
  type,
  step,
}) => {
  return (
    <InputChakra
      type={type}
      step={step}
      width={width}
      backgroundColor="white"
      borderColor="white"
      color="rgba(30, 82, 151, 0.8)"
      fontSize={14}
      fontWeight="normal"
      placeholder={placeholder}
      onChange={onChange}
      name={name}
      value={value}
      maxLength={maxLength}
      _focus={{
        border: "2px",
        borderColor: "newBlue.500",
        boxShadow: "0px 4px 10px rgba(40, 103, 149, 0.1)",
      }}
      style={inputStyle}
      disabled={disabled}
    />
  );
};
