import { Box, Center, Flex } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { ConfigProvider, Slider, ThemeConfig } from "antd";
import { SliderTooltipProps } from "antd/es/slider";

interface TimelineProps {
  marks: Record<string | number, React.ReactNode> | undefined;
  onAfterChange: (value: number[]) => void;
  title?: string;
  titleStyle?: React.CSSProperties | undefined;
  subtitle?: string;
  subtitleStyle?: React.CSSProperties | undefined;
  icon: any;
  sliderMin?: number;
  sliderMax?: number;
  sliderStep?: number;
  sliderDefaultValue?: number[] | undefined;
  sliderTooltip?: SliderTooltipProps | undefined;
  theme?: ThemeConfig | undefined;
  sliderStyles?: Partial<Record<any, React.CSSProperties>> | undefined;
  containerStyle?: React.CSSProperties | undefined;
  slideContainerStyle?: React.CSSProperties | undefined;
  boxContainerStyle?: React.CSSProperties | undefined;
  className?: string | undefined;
}

const Timeline: React.FC<TimelineProps> = ({
  marks,
  onAfterChange,
  title,
  titleStyle,
  subtitle,
  subtitleStyle,
  icon,
  sliderMin,
  sliderMax,
  sliderStep,
  sliderDefaultValue,
  sliderTooltip,
  theme,
  sliderStyles,
  containerStyle,
  slideContainerStyle,
  boxContainerStyle,
  className,
}) => {
  return (
    <Center className={className} style={containerStyle}>
      <Box style={boxContainerStyle}>
        <Flex alignItems={"center"} gap={1}>
          {icon}
          <span style={titleStyle}>{title}</span>
          <span style={subtitleStyle}>({subtitle})</span>
        </Flex>
        <Box style={slideContainerStyle}>
          <ConfigProvider theme={theme}>
            <Slider
              range
              marks={marks}
              defaultValue={sliderDefaultValue}
              min={sliderMin || 0}
              max={sliderMax || 24}
              step={sliderStep || 1}
              styles={sliderStyles}
              tooltip={sliderTooltip}
              onAfterChange={onAfterChange}
            />
          </ConfigProvider>
        </Box>
      </Box>
    </Center>
  );
};

export default observer(Timeline);
