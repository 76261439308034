import { makeAutoObservable } from "mobx";
import { StoreState } from "../../../enum/StoreState";

export default class EventAnalysisStore {
    state: string = StoreState.DONE;

    constructor() {
        makeAutoObservable(this);
    }

    setState(value: StoreState) {
        this.state = value;
    }


    reset() {
        this.setState(StoreState.DONE);
    }


}
