export const PermissionRole = {
  INSIGHT_MANAGER: "insight-manager",
  FORECAST_CHART_MANAGER: "forecast-chart-manager",
  PUSH_NOTIFICATION_MANAGER: "push-notification-manager",
};

export const PermissionGroup = {
  USER_ADMIN: "/i4cast/user/admin",
  USER: "/i4cast/user",
  USER_RESTRICT: "/i4cast/user/restrict",
  USER_INTERNAL: "/i4cast/user/internal",
  USER_READ_ONLY: "/i4cast/user/read-only",
  API_CONSUMER: "/i4cast/api/consumer",
};
