import axios from "axios";
import { makeAutoObservable } from "mobx";
import { getEnv, getRoot } from "mobx-easy";
import {
  EnvironmentalType,
  MeteogramVariables,
} from "../../../enum/EnvironmentalType";
import { StoreState } from "../../../enum/StoreState";
import { IMeasuredDataChartParams } from "../../../services/AnalyticsService";
import RootStore from "../../root-store";
import { RootEnv } from "../../setup/create-store";
import { IAtmOceanMeteogram } from "../atmOcean/atmOceanModel";
import UnitMeasurementStore, {
  Units,
} from "../unitMeasurement/UnitMeasurementStore";
import i18n from "../../../i18n";
import { processSerie } from "../../../util/meteogramCharts/processSerie/processSerie";
import { getWeatherConfigMap } from "../../../util/meteogramCharts/processSerie/common/getWeatherConfigMap";
import { EnvironmentalVariables } from "../../../enum/EnvironmentalVariables";
import { getEnvironmentalVariablesDefault } from "../../../util/meteogramCharts/processSerie/common/getEnvironmentalVariablesDefault";
import { VisualizationType } from "../../../enum/VisualizationType";
import { getWaveConfigMap } from "../../../util/meteogramCharts/processSerie/common/getWaveConfigMap";
import { getWaterFlowConfigMap } from "../../../util/meteogramCharts/processSerie/common/getWaterFlowConfigMap";
import { getCurrentConfigMap } from "util/meteogramCharts/processSerie/common/getCurrentConfigMap";
import { ONE_HOUR_IN_MILLISECONDS } from "../../../constants";
import RequestCancellationStore from "../requestCancellationStore/RequestCancellationStore";

export default class AnalyticsChartStore {
  state: string = StoreState.DONE;

  messageError: string = "";

  chartMeteogramData: IAtmOceanMeteogram = {
    plotOptions: {
      series: { pointInterval: 0, pointStart: 0, timezoneOffset: 180 },
    },
    series: [
      {
        name: "",
        environmental_variable: "",
        units: "",
        units_symbol: "",
        min: 0,
        max: 0,
        data: [],
      },
    ],
  };

  chartMeteogramDataSeries: any[] = [];
  chartMeteogramYAxis: any[] = [
    {
      // Primary yAxis
      labels: {
        format: "{value}",
        style: {
          color: "#00000",
        },
      },
      title: {
        text: "Environmental Variable 1",
        style: {
          color: "#00000",
        },
      },
    },
    {
      // Secondary yAxis
      title: {
        text: "Environmental Variable 2",
        style: {
          color: "#00000",
        },
      },
      labels: {
        format: "{value} mm",
        style: {
          color: "#00000",
        },
      },
      opposite: true,
    },
  ];

  noDataAvailableInfo: string = "";

  isChartDataEmpty: boolean = false;

  isLastChartDateGreaterThan3Hours = false;

  lastChartDate: number | null = null;

  selectedEnvironmentalVariables: EnvironmentalVariables[] = [
    ...getEnvironmentalVariablesDefault(EnvironmentalType.WEATHER),
    EnvironmentalVariables.WIND_VEL_80M, // for vattenfall
    EnvironmentalVariables.WIND_VEL_100M,
  ];

  actionCallGetMeasuredChart: boolean = false;

  requestCancellationStore: RequestCancellationStore;

  constructor(requestCancellationStore: RequestCancellationStore) {
    makeAutoObservable(this);
    this.requestCancellationStore = requestCancellationStore;
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setMessageError(value: string) {
    this.messageError = value;
  }
  setChartMeteogramData(value: IAtmOceanMeteogram) {
    this.chartMeteogramData = value;
  }

  setChartMeteogramDataSeries(value: any[]) {
    this.chartMeteogramDataSeries = value;
  }

  setChartMeteogramYAxis(value: any[]) {
    this.chartMeteogramYAxis = value;
  }

  setNoDataAvailableInfo(value: string) {
    this.noDataAvailableInfo = value;
  }

  setIsChartDataEmpty(value: boolean) {
    this.isChartDataEmpty = value;
  }

  setActionCallGetMeasuredChart() {
    this.actionCallGetMeasuredChart = !this.actionCallGetMeasuredChart;
  }

  resetChartMeteogramData() {
    this.setChartMeteogramYAxis([
      {
        // Primary yAxis
        labels: {
          format: "{value}",
          style: {
            color: "#00000",
          },
        },
        title: {
          text: "Environmental Variable 1",
          style: {
            color: "#00000",
          },
        },
      },
      {
        // Secondary yAxis
        title: {
          text: "Environmental Variable 2",
          style: {
            color: "#00000",
          },
        },
        labels: {
          format: "{value} mm",
          style: {
            color: "#00000",
          },
        },
        opposite: true,
      },
    ]);
    this.setChartMeteogramDataSeries([]);
    this.setChartMeteogramData({
      plotOptions: {
        series: { pointInterval: 0, pointStart: 0, timezoneOffset: 180 },
      },
      series: [
        {
          name: "",
          environmental_variable: "",
          units: "",
          units_symbol: "",
          min: 0,
          max: 0,
          data: [],
        },
      ],
    });
  }

  setSelectedEnvironmentalVariables(value: EnvironmentalVariables[]) {
    this.selectedEnvironmentalVariables = value;
  }

  setLastChartDate(value: number) {
    this.lastChartDate = value;
  }

  setIsLastChartDateGreaterThan3Hours(value: boolean) {
    this.isLastChartDateGreaterThan3Hours = value;
  }

  reset() {
    this.resetChartMeteogramData();
    this.setState(StoreState.DONE);
    this.setMessageError("");
    this.setSelectedEnvironmentalVariables([
      ...getEnvironmentalVariablesDefault(EnvironmentalType.WEATHER),
      EnvironmentalVariables.WIND_VEL_80M,
      EnvironmentalVariables.WIND_VEL_100M,
    ]);
    this.setIsChartDataEmpty(false);
    this.setIsLastChartDateGreaterThan3Hours(false);
  }

  getLastChartDate(chartData: IAtmOceanMeteogram): number {
    let lastUpdate: number;
    const chartDataValues = chartData?.series[0]?.data;
    const lastChartDataValueIndex =
      chartDataValues.length === 0 ? 0 : chartDataValues.length - 1;

    const lastChartDataValue = chartDataValues[lastChartDataValueIndex];
    if (Array.isArray(lastChartDataValue)) {
      if (Array.isArray(lastChartDataValue[0])) {
        lastUpdate = lastChartDataValue[0][0];
      } else {
        lastUpdate = lastChartDataValue[0];
      }
    } else {
      lastUpdate = lastChartDataValue;
    }

    return lastUpdate;
  }

  getIsLastChartDateGreaterThan3Hours(lastUpdate: number): boolean {
    const currentTime = new Date();
    const threeHoursInMilliseconds = 3 * ONE_HOUR_IN_MILLISECONDS;

    const timeDifference = currentTime.getTime() - lastUpdate;

    return timeDifference > threeHoursInMilliseconds;
  }

  async getMeasuredDataChartApi(params: IMeasuredDataChartParams) {
    const { analyticsService } = getEnv<RootEnv>();
    const {
      dataStores: { forecastChartMeteogramStore },
    } = getRoot<RootStore>();
    this.setState(StoreState.PEDDING);
    this.resetChartMeteogramData();
    const { controller } = params;
    try {
      if (params.stationId > 0) {
        if (controller) {
          this.requestCancellationStore.addController(controller);
        }

        let chartData: IAtmOceanMeteogram =
          await analyticsService.getMeasuredDataChart({
            ...params,
            daysMeasured: params.daysMeasured ?? 2,
          });

        chartData = await this.convertUnitMeasurementMeteogramSerie(chartData);

        if (!forecastChartMeteogramStore.selectForecastDateAvailable) {
          const lastChartDate = this.getLastChartDate(chartData);

          const isLastChartDateGreaterThan3Hours =
            this.getIsLastChartDateGreaterThan3Hours(lastChartDate);

          this.setLastChartDate(lastChartDate);

          this.setIsLastChartDateGreaterThan3Hours(
            isLastChartDateGreaterThan3Hours
          );
        }

        this.setChartMeteogramData(chartData);
        this.processMeteogramSerie(params.environmentalType, chartData.series);

        if (!chartData.series[0]?.data?.length) {
          this.setNoDataAvailableInfo(
            i18n.t("analytics.sensorDataNotAvailable")
          );
          this.setNoDataAvailableInfo("");
          this.setIsChartDataEmpty(true);
        }
      } else {
        this.setIsChartDataEmpty(true);
      }

      this.setState(StoreState.DONE);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("axios request cancelled");
      } else {
        this.setState(StoreState.ERROR);
        this.setMessageError(i18n.t("analytics.errorGetSensorData"));
      }
    } finally {
      if (controller) {
        this.requestCancellationStore.removeController(controller);
      }
    }
  }

  async convertUnitMeasurementMeteogramSerie(chartData: IAtmOceanMeteogram) {
    const {
      dataStores: { unitMeasurementStore },
    } = getRoot<RootStore>();

    chartData.series.forEach((serie) => {
      const unitsUser = unitMeasurementStore.getUnitMeasurementConfig(
        serie.environmental_variable as MeteogramVariables
      );
      if (unitsUser && unitsUser !== serie.units_symbol.toLowerCase()) {
        serie.data = serie.data.map((value: any) => {
          if (typeof value === "number") {
            return unitMeasurementStore.convertUnit(
              value,
              serie.units_symbol.toLowerCase() as Units,
              unitsUser as Units
            );
          } else if (typeof value === "object") {
            if (value.length === 2) {
              return [
                value[0],
                unitMeasurementStore.convertUnit(
                  value[1],
                  serie.units_symbol.toLowerCase() as Units,
                  unitsUser as Units
                ),
              ];
            } else if (value.length === 3) {
              return [
                value[0],
                unitMeasurementStore.convertUnit(
                  value[1],
                  serie.units_symbol.toLowerCase() as Units,
                  unitsUser as Units
                ),
                value[2],
              ];
            }
          }
          return value;
        });
      }
    });
    return chartData;
  }

  async processMeteogramSerie(
    environmentalType: EnvironmentalType,
    series: any[]
  ) {
    let serieProcessed: any[] = [];
    let yAxisProcessed: any[] = [];
    const {
      dataStores: { unitMeasurementStore, forecastChartMeteogramStore, forecastChartPageStore },
    } = getRoot<RootStore>();

    let _environmentalVariablesDefault =
      getEnvironmentalVariablesDefault(environmentalType);
    if (environmentalType === EnvironmentalType.WEATHER) {
      //for Vattenfall
      _environmentalVariablesDefault.push(
        ...[
          EnvironmentalVariables.WIND_VEL_80M,
          EnvironmentalVariables.WIND_VEL_100M,
        ]
      );
    }

    const isSameTypeEnv = this.checkHasSameTypeSelectedEnv(
      this.selectedEnvironmentalVariables,
      _environmentalVariablesDefault
    );
    if (!isSameTypeEnv) {
      this.setSelectedEnvironmentalVariables(_environmentalVariablesDefault);
    }
    const processResult = this.processEnvironmentalType(
      environmentalType,
      series,
      VisualizationType.SENSOR,
      unitMeasurementStore,
      forecastChartMeteogramStore.addEnvironmentalVariable
    );

    serieProcessed = serieProcessed.concat(processResult.serie);
    yAxisProcessed = yAxisProcessed.concat(processResult.yAxis);

    if (forecastChartPageStore.tabStateActivty === EnvironmentalType.WATER_FLOW && yAxisProcessed[0]) {
      const minValue = series[0]?.min - (series[0]?.min * 0.1);
      yAxisProcessed[0].min = minValue;
    }

    if (!yAxisProcessed?.length) {
      this.setNoDataAvailableInfo(i18n.t("analytics.sensorDataNotAvailable"));
      this.setNoDataAvailableInfo("");
      this.setIsChartDataEmpty(true);
    }

    serieProcessed.map((serie) => {
      const _timestamps = serie.data;
      const interval = 30 * 60 * 1000; // 30 minutes
      const additionalTime = 2 * 60 * 60 * 1000; // 2 hours
      this.extendTimestamps(_timestamps, interval, additionalTime);
      serie.data = this.extendTimestamps(_timestamps, interval, additionalTime);
    });

    this.setChartMeteogramDataSeries(serieProcessed);
    this.setChartMeteogramYAxis(yAxisProcessed);
  }

  extendTimestamps(
    timestamps: any[][],
    interval: number,
    additionalTime: number
  ) {
    if (!timestamps?.length) {
      return;
    }

    let lastTimestamp = timestamps[timestamps.length - 1][0];

    const currentTime = Date.now();

    const twelveHoursInMillis = 12 * 60 * 60 * 1000;
    if (lastTimestamp < currentTime - twelveHoursInMillis) {
      return timestamps;
    }

    while (lastTimestamp <= currentTime + additionalTime) {
      lastTimestamp += interval;
      timestamps.push([lastTimestamp, null, null]);
    }
    return timestamps;
  }

  processEnvironmentalType(
    environmentalType: EnvironmentalType,
    series: any[],
    visualizationType: VisualizationType,
    unitMeasurementStore: UnitMeasurementStore,
    addEnvironmentalVariable: any
  ) {
    let configMapFunction;
    switch (environmentalType) {
      case EnvironmentalType.WATER_FLOW:
        configMapFunction = getWaterFlowConfigMap;
        break;
      case EnvironmentalType.CURRENT:
        configMapFunction = getCurrentConfigMap;
        break;
      case EnvironmentalType.WEATHER:
        configMapFunction = getWeatherConfigMap;
        break;
      case EnvironmentalType.WAVE:
      case EnvironmentalType.WAVE_SEA:
      case EnvironmentalType.WAVE_SWELL:
        configMapFunction = getWaveConfigMap;
        break;
      default:
        throw new Error("Invalid environmental type");
    }
    try {
      const processSerieResult = processSerie({
        series,
        selectedEnvironmentalVariables: this.selectedEnvironmentalVariables,
        configMap: configMapFunction(visualizationType),
        visualizationType,
        getUnitMeasurementConfig: unitMeasurementStore.getUnitMeasurementConfig,
        convertUnit: unitMeasurementStore.convertUnit,
        addEnvironmentalVariable,
      });

      return processSerieResult;
    } catch (error) {
      console.error("Error processing serie", error);
    }

    return { serie: [], yAxis: [] };
  }

  checkHasSameTypeSelectedEnv(
    selectedEnvironmentalVariables: EnvironmentalVariables[],
    defaultEnvironmentalVariables: EnvironmentalVariables[]
  ) {
    return selectedEnvironmentalVariables.some((env) =>
      defaultEnvironmentalVariables.includes(env)
    );
  }

  handleChangeToSensorTab() {
    const {
      dataStores: {
        forecastChartPageStore,
        forecastChartProbabilisticStore,
        forecastChartMeteogramStore,
      },
    } = getRoot<RootStore>();

    forecastChartMeteogramStore.setState(StoreState.DONE);
    forecastChartProbabilisticStore.setState(StoreState.DONE);
    forecastChartMeteogramStore.setIsChartDataEmpty(false);

    forecastChartMeteogramStore.setVisualizationType(VisualizationType.SENSOR);
    forecastChartPageStore.setActionCallGetStationsWithSensor();
  }
}
