import { observer } from "mobx-react-lite";
import React from "react";
import { useStores } from "../../../../stores/setup/use-store";
import MeteogramChart from "../../../../components/MeteogramChart/MeteogramChart";
import { meteogramFormatTooltip } from "../../../../util/meteogramCharts/meteogramFormatTooltip/meteogramFormatTooltip";

interface ForecastChartProps {
  chartHeight: string;
  style?: React.CSSProperties | undefined;
}

const ForecastChart: React.FC<ForecastChartProps> = ({
  chartHeight,
  style,
}) => {
  const {
    dataStores: { forecastChartMeteogramStore },
  } = useStores();

  return (
    <MeteogramChart
      style={style}
      timezoneOffset={
        forecastChartMeteogramStore.chartMeteogramData.plotOptions.series
          .timezoneOffset ?? 180
      }
      options={{
        plotOptions: {
          series: {
            label: {
              enabled: false,
            },
            marker: {
              radius: 3,
            },
          },
        },
        chart: {
          zoomType: "xy",
          height: chartHeight,
          backgroundColor: "#ffffff",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: forecastChartMeteogramStore.chartMeteogramXAxis,
        yAxis: forecastChartMeteogramStore.chartMeteogramYAxis,
        tooltip: {
          shared: true,
          useHTML: true,
          formatter: function () {
            const { x, y, points } = this;
            return meteogramFormatTooltip(
              x,
              y,
              points,
              forecastChartMeteogramStore.renderInsightTitleOnMeteogram(25)
            );
          },
        },
        legend: {
          itemStyle: {
            fontSize: "1em",
            fontFamily: "'Hind Vadodara', 'Helvetica Neue', sans-serif",
            fontWeight: "normal",
            color: "#00000",
          },
        },
        series: forecastChartMeteogramStore.chartMeteogramDataSeries,
      }}
    />
  );
};

export default observer(ForecastChart);
