import { Spin } from "antd";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

import {
  WrapperLoginForm,
  ContainerLogin,
  FormLogin,
  InputLogin,
  ButtonLogin,
  TitleText,
  InputLabelLogin,
  ContainerLoginTitle,
  DivErrorLogin,
  DivErrorLoginText,
  LinkArea,
} from "../styles";
import { useTranslation } from "react-i18next";

export interface RecoverPasswordFormProps {
  title: string;
  email: string;
  error: boolean;
  errorMessage?: string;
  onSubmit: (e: any) => void;
  onChange: (e: any) => void;
  onClickGoBackLogin: (e: any) => void;
  isLoading: boolean;
}

export const RecoverPasswordForm: React.FC<RecoverPasswordFormProps> = ({
  title,
  email,
  error,
  errorMessage,
  onChange,
  onSubmit,
  onClickGoBackLogin,
  isLoading,
}) => {
  const validateEmail = (email: string) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };
  const { t } = useTranslation("translation", {
    keyPrefix: "login.recoverPasswordForm",
  });

  const isDisabled = !validateEmail(email);

  const icon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#ffffff" }} spin />
  );

  return (
    <>
      <WrapperLoginForm>
        <ContainerLoginTitle>
          <TitleText>{title}</TitleText>
        </ContainerLoginTitle>
        <ContainerLogin>
          <FormLogin>
            <InputLabelLogin>
              {t("typeYourEmail")}
              <InputLogin
                type="email"
                placeholder="example@email.com"
                value={email}
                onChange={onChange}
              />
            </InputLabelLogin>
            <ButtonLogin disabled={isDisabled} onClick={onSubmit}>
              {!isLoading ? (
                t("sendInstructionsByEmail")
              ) : (
                <Spin
                  indicator={icon}
                  spinning={true}
                  style={{ color: "#ffffff" }}
                ></Spin>
              )}
            </ButtonLogin>
          </FormLogin>
          {error && (
            <DivErrorLogin>
              <DivErrorLoginText>
                {errorMessage ? errorMessage : t("emailIncorrect")}
              </DivErrorLoginText>
            </DivErrorLogin>
          )}
          <LinkArea>
            <a
              style={{ color: "rgba(0, 0, 0, 0.64)" }}
              href="#"
              onClick={onClickGoBackLogin}
            >
              {t("returnToLogin")}
            </a>
          </LinkArea>
        </ContainerLogin>
      </WrapperLoginForm>
    </>
  );
};
