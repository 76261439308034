import React, { ChangeEvent, ChangeEventHandler, FC } from "react";
import { Card, Descriptions, Input } from "antd";

export interface NotificationSubmissionProps {
  cardTitle: string;
  NumberRecipients: number;
  onChangeTitle: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeContent: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const NotificationSubmission: FC<NotificationSubmissionProps> = ({
  cardTitle,
  NumberRecipients,
  onChangeTitle,
  onChangeContent,
}) => {
  const TextArea = Input.TextArea;
  return (
    <>
      <Card
        style={{
          borderColor: "rgb(24, 144, 255)",
          width: "auto",
          marginTop: 25,
        }}
        key={`card-${cardTitle}`}
        title={cardTitle}
      >
        <Descriptions column={1}>
          <Descriptions.Item label="Number of recipients">
            {NumberRecipients}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Input
        style={{ marginTop: 25, width: "100%" }}
        placeholder="Input title"
        key={"title"}
        maxLength={30}
        showCount
        onChange={onChangeTitle}
      ></Input>
      <TextArea
        style={{ marginTop: 25, width: "100%" }}
        key={"content"}
        rows={5}
        maxLength={115}
        placeholder={"Input a content"}
        showCount
        onChange={onChangeContent}
      ></TextArea>
    </>
  );
};
