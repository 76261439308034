// components/LoadingState.tsx
import React from "react";
import { Center, Box, Text, Progress } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const LoadingState: React.FC = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.metOceanReport",
  });
  return (
    <Center flexDirection="column" gap={4}>
      <Box textAlign={"center"}>
        <Text fontWeight={"bold"} color="newBlue.500">
          {t("generatingForecast")}
        </Text>
        <p>{t("pleaseWaitAMoment")}</p>
      </Box>
      <Progress w="400px" size="xs" isIndeterminate />
    </Center>
  );
};
