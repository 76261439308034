import React, { useCallback, useState } from "react";
import "./I4castLayout.css";
import { useStores } from "../stores/setup/use-store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Alert, AlertIcon, Box } from "@chakra-ui/react";
import NavBar from "../components/NavBar/NavBar";
import { useHistory, useLocation } from "react-router-dom";
import { getConfig } from "../config/app.config";
import { ConfigKey } from "../enum/ConfigKey";
import Header from "../components/Header/Header";
import { getMenuListMock } from "../stores/ui/menuView/menuItem.mock";
import OccurrenceFormModal from "../pages/Occurrence/Components/OcurrenceFormModal";
import { FeedbackModal } from "../pages/FeedbackModal/FeedbackModal";
import { cn } from "../util/cn/cn";
import { useTranslation } from "react-i18next";

const I4castLayout: React.FC<{ children: any }> = ({ children }) => {
  const {
    dataStores: {
      authStore,
      regionStore,
      notificationsStore,
      unitMeasurementStore,
      userConfigStore,
      oceanWeatherIntelligenceStore,
      securityStore,
      forecastChartMeteogramStore,
      occurrenceStore,
      feedbackStore,
    },
    uiStores: { menuViewStore, whatIsNewStore, navigatorAndWindowStore },
    dataStores,
  } = useStores();
  const [superUser, setSuperUser] = useState(false);
  const [adminUser, setAdminUser] = useState(false);

  const { name } = userConfigStore.userConfig;

  let history = useHistory();
  const location = useLocation();

  useEffect(() => {
    securityStore.getTokenSet().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    userConfigStore.getUserConfigApi();
  }, [userConfigStore]);

  useEffect(() => {
    regionStore.load();
  }, [regionStore]);

  useEffect(() => {
    userConfigStore.getUserLanguage();
  }, [userConfigStore]);

  useEffect(() => {
    let userIdAux = parseInt(localStorage.getItem("userId") || "0");
    if (!userIdAux) {
      userIdAux = authStore.userId;
    }

    notificationsStore.subscribeNotification(userIdAux, (snapashot) => {
      notificationsStore.setHasNotification(snapashot);
      notificationsStore.setNotifications(snapashot);
    });

    feedbackStore.subscribeQuestionFeedback(userIdAux, (snapshot) => {
      if (snapshot) {
        const { userId } = snapshot;

        if (userId === userIdAux) {
          feedbackStore.setQuestionFeedback(snapshot);
        }
      }
    });
  }, [notificationsStore, authStore.userId, feedbackStore]);

  useEffect(
    () => {
      if (localStorage.getItem("@super_user") === "true") {
        setSuperUser(true);
      } else {
        setSuperUser(authStore.superUser);
      }

      if (localStorage.getItem("@admin_user") === "true") {
        setAdminUser(true);
      } else {
        setAdminUser(authStore.adminUser);
      }

      if (localStorage.getItem("@read_only_user") === "true") {
        authStore.setReadOnlyUser(true);
      }

      menuViewStore.setMooringLinesInMenuList();
      menuViewStore.setRedirectDynamicUKC();

      const version = getConfig(ConfigKey.VERSION);

      const versionStorage = localStorage.getItem("@i4cast_version");

      if (version !== versionStorage) {
        localStorage.setItem("@i4cast_version", version);
        whatIsNewStore.setMakeAllAsRead(false);
      } else {
        const makeAllAsRead = localStorage.getItem("makeAllAsRead");

        if (makeAllAsRead === "true") {
          whatIsNewStore.setMakeAllAsRead(true);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuViewStore, authStore]
  );

  useEffect(() => {
    let unitMeasurementConfig: any = localStorage.getItem(
      "unitMeasurementConfig"
    );

    if (unitMeasurementConfig) {
      const unitConfig = unitMeasurementStore.unitMeasurementConfig;

      try {
        if (unitMeasurementConfig !== JSON.stringify(unitConfig)) {
          unitMeasurementStore.setAllUnitMeasurementConfig(unitConfig);
          localStorage.setItem(
            "unitMeasurementConfig",
            JSON.stringify(unitConfig)
          );
        } else {
          unitMeasurementConfig = JSON.parse(unitMeasurementConfig || "");
          unitMeasurementStore.setAllUnitMeasurementConfig(
            unitMeasurementConfig
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [unitMeasurementStore]);

  const handleLogout = useCallback(() => {
    menuViewStore.reset();
    authStore.reset();

    dataStores.resetDataStores();
    securityStore.clearTokenSet();
    history.push("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuViewStore, authStore, dataStores, history]);

  const handleChangeLanguage = async (language: string) => {
    oceanWeatherIntelligenceStore.setChangeForUpdate({
      update: true,
      expiration: 0,
    });
    oceanWeatherIntelligenceStore.setActionCallGanttChart();

    await userConfigStore.updateUserLanguage(language);
    menuViewStore.setMenuList(getMenuListMock());
  };

  const { t } = useTranslation("translation", {
    keyPrefix: "login.login",
  });

  return (
    <Box
      className={cn("layout-grid", {
        "layout-grid-open": menuViewStore.navSize === "large",
        "layout-grid-collapsed": menuViewStore.navSize === "small",
      })}
    >
      <Box className="menu-area">
        <Header
          name={name}
          handleLogout={handleLogout}
          notificationsStore={notificationsStore}
          handleChangeLanguage={handleChangeLanguage}
          occurrenceStore={occurrenceStore}
          authStore={authStore}
        />
      </Box>

      {feedbackStore.questionFeedback && name && (
        <FeedbackModal
          key={`id-${feedbackStore.questionFeedback.id}`}
          isOpen={!!feedbackStore.questionFeedback}
          feedbackStore={feedbackStore}
        />
      )}
      <Box className="side-area">
        <NavBar
          menuList={menuViewStore.menuList}
          menuItemSelected={menuViewStore.selectedMenuItem}
          handleNavBar={(e) => menuViewStore.setNavSize(e)}
          pathname={location.pathname}
          mainRegion={regionStore.macroRegionMain}
          superUser={superUser}
          adminUser={adminUser}
          forecastChartMeteogramStore={forecastChartMeteogramStore}
          logUserExperience={menuViewStore.addLogUserExperienceAPI}
        />
      </Box>
      <Box
        w="100%"
        transition="all 0.2s ease-out"
        mr={"24px"}
        zIndex={1}
        className="main-area"
        position="relative"
      >
        {!navigatorAndWindowStore.isOnline && (
          <Alert
            width="auto"
            position="absolute"
            top="7px"
            left="50%"
            transform="translateX(-50%)"
            zIndex={450}
            rounded="base"
            bg="#bee3f8"
            status="info"
            mb={4}
            color="gray.700"
          >
            <AlertIcon />
            {t("itAppearsYouAreDisconnected")}
          </Alert>
        )}
        {children}
      </Box>
      <OccurrenceFormModal />
    </Box>
  );
};

export default observer(I4castLayout);
