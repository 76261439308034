import { IOperationRule } from "../stores/data/operationRules/OperationRuleModel";
import api from "./api";

export default class OperationRuleService {
  async getOperationRule(id?: number) {
    let result;
    if (id) {
      result = await api.get(`/v1/operationRule/${id}`);
      return result.data;
    }
    result = await api.get(`/v1/operationRule`);
    return result.data;
  }

  async createOperationRule(
    name: string,
    initialsName: string
  ): Promise<IOperationRule> {
    const result = await api.post(`/v1/operationRule`, {
      name: name,
      initials_name: initialsName,
    });
    if (result.status === 200) {
      return result.data;
    }
    throw new Error("Error while create new operation rule");
  }

  async updateOperationRule(
    id: number,
    name: string,
    initialsName: string
  ): Promise<boolean> {
    if (!id) {
      throw new Error("Need to inform ID for update data");
    }
    const result = await api.put(`/v1/operationRule/${id}`, {
      name: name,
      initials_name: initialsName,
    });
    if (result.status === 200 || result.status === 201) {
      return true;
    }
    throw new Error("Error while update operation rule");
  }

  async deleteOperationRule(id: number): Promise<boolean> {
    if (!id) {
      throw new Error("Need to inform ID for delete data");
    }
    const result = await api.delete(`/v1/operationRule/${id}`);
    if (result.status === 200 || result.status === 201) {
      return true;
    }
    throw new Error("Error while delete operation rule");
  }
}
