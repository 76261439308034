import { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Checkbox,
  Flex,
  Input,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

import { IUserShare } from "../../../../stores/data/userConfig/userConfigModal";
import { useTranslation } from "react-i18next";

interface DualListRecipientsProps {
  users: IUserShare[];
  setFieldValue: any;
}

const DualListRecipients = ({
  users,
  setFieldValue,
}: DualListRecipientsProps) => {
  const [selectedUsers, setSelectedUsers] = useState<IUserShare[]>([]);
  const [unselectedUsers, setUnselectedUsers] = useState<IUserShare[]>(users);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [unselectedFilter, setUnselectedFilter] = useState("");

  const { t } = useTranslation("translation", {
    keyPrefix: "dualList",
  });

  useEffect(() => {
    setFieldValue("recipients", selectedUsers);
  }, [selectedUsers, setFieldValue, unselectedUsers]);

  const handleUnSelectAll = () => {
    setUnselectedUsers([...unselectedUsers, ...selectedUsers]);
    setSelectedUsers([]);
  };

  const handleSelectAll = () => {
    setSelectedUsers([...selectedUsers, ...unselectedUsers]);
    setUnselectedUsers([]);
  };

  const handleSelect = (user: IUserShare) => {
    setSelectedUsers([...selectedUsers, user]);
    setUnselectedUsers(unselectedUsers.filter((u) => u.id !== user.id));
    setFieldValue("recipients", selectedUsers);
  };

  const handleDeselect = (user: IUserShare) => {
    setUnselectedUsers([...unselectedUsers, user]);
    setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id));
  };

  return (
    <Flex>
      <SimpleGrid columns={2} spacingX="40px" width="100%">
        <Box
          borderRadius="md"
          p={2}
          bg={"white"}
          h="30vh"
          overflowY="auto"
          width={"350px"}
          sx={{
            "&::-webkit-scrollbar": {
              width: "12px",
              borderRadius: "8px",
              backgroundColor: `rgba(40, 103, 149, 0.3)`,
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "8px",
              backgroundColor: `rgba(40, 103, 149, 0.6)`,
            },
          }}
        >
          <Stack spacing={2}>
            <Flex alignItems="center" justifyContent={"space-between"}>
              <Checkbox
                size="sm"
                borderColor="newBlue.500"
                isChecked={unselectedUsers.length === 0}
                onChange={handleSelectAll}
              >
                <Text fontSize={"sm"}>{t("selectAll")}</Text>
              </Checkbox>
              <Text ml={2} fontSize={"sm"} fontWeight="bold">
                {t("notSelected")} ({unselectedUsers.length})
              </Text>
            </Flex>
            <Box>
              <Input
                size={"sm"}
                placeholder={t("search") as string}
                value={selectedFilter}
                onChange={(e) => setSelectedFilter(e.target.value)}
              />
            </Box>
            {unselectedUsers
              .filter((user) =>
                `${user.name} ${user.email}`
                  .toLowerCase()
                  .includes(selectedFilter.toLowerCase())
              )
              .map((user) => (
                <Flex key={user.id} alignItems="center" gap={2}>
                  <Checkbox
                    borderColor="newBlue.500"
                    size="sm"
                    onChange={() => handleSelect(user)}
                  />
                  <Avatar
                    size="sm"
                    w={6}
                    h={6}
                    name={user.name}
                    color="white"
                  />
                  <Box>
                    <Text>{user.name}</Text>
                    <Text>{user.email}</Text>
                  </Box>
                </Flex>
              ))}
          </Stack>
        </Box>
        <Box
          borderRadius="md"
          p={2}
          bg={"white"}
          maxH="30vh"
          overflowY="auto"
          sx={{
            "&::-webkit-scrollbar": {
              width: "12px",
              borderRadius: "8px",
              backgroundColor: `rgba(40, 103, 149, 0.3)`,
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "8px",
              backgroundColor: `rgba(40, 103, 149, 0.6)`,
            },
          }}
        >
          <Stack spacing={2}>
            <Flex alignItems="center" justifyContent={"space-between"}>
              <Checkbox
                size="sm"
                borderColor="newBlue.500"
                isChecked={selectedUsers.length === 0}
                onChange={handleUnSelectAll}
              >
                {t("deselectAll")}
              </Checkbox>
              <Text ml={2} fontWeight="bold">
                {t("selected")} ({selectedUsers.length})
              </Text>
            </Flex>
            <Box>
              <Input
                size={"sm"}
                placeholder={t("search") as string}
                value={unselectedFilter}
                onChange={(e) => setUnselectedFilter(e.target.value)}
              />
            </Box>
            {selectedUsers
              .filter((user) =>
                `${user.name} ${user.email}`
                  .toLowerCase()
                  .includes(unselectedFilter.toLowerCase())
              )
              .map((user) => (
                <Flex key={user.id} alignItems="center" gap={2}>
                  <Checkbox
                    isChecked
                    borderColor="newBlue.500"
                    size="sm"
                    onChange={() => handleDeselect(user)}
                  />
                  <Avatar
                    size="sm"
                    w={6}
                    h={6}
                    name={user.name}
                    color="white"
                  />
                  <Box>
                    <Text>{user.name}</Text>
                    <Text>{user.email}</Text>
                  </Box>
                </Flex>
              ))}
          </Stack>
        </Box>
      </SimpleGrid>
    </Flex>
  );
};

export default DualListRecipients;
