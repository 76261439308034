import React, { useState, useEffect } from "react";
import {
  Box,
  Center,
  Checkbox,
  CloseButton,
  Flex,
  Input,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { IStationsFilter } from "../../../../stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";

const ManageStationFilterDropdown: React.FC<{
  items: (string | undefined)[];
  initialItems: (string | undefined)[];
  selectedFilters: string[];
  onFilterChange: (item: string) => void;
  showFilter: boolean;
  setShowFilter: (value: boolean) => void;
  searchIconRef: React.RefObject<HTMLDivElement>;
  dropdownRef: React.RefObject<HTMLDivElement>;
  column: string;
  getItems: (stationRequest: IStationsFilter) => void;
  handleResetFilter: (item: string) => void;
  searchFilter: string;
  setSearchFilter: (value: string) => void;
}> = ({
  items,
  initialItems,
  selectedFilters,
  onFilterChange,
  showFilter,
  setShowFilter,
  searchIconRef,
  dropdownRef,
  column,
  getItems,
  handleResetFilter,
  searchFilter,
  setSearchFilter,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "manageStation",
  });

  const [filteredItems, setFilteredItems] =
    useState<(string | undefined)[]>(items);
  const [isNumberOfLettersValid, setIsNumberOfLettersValid] = useState(false);

  useEffect(() => {
    setFilteredItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    if (!searchFilter && selectedFilters?.length) {
      setFilteredItems(initialItems);
    } else {
      setFilteredItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  const handleSearchFilter = (value: string) => {
    setSearchFilter(value);

    if (!value) {
      if (selectedFilters?.length) {
        setFilteredItems(initialItems);
      } else {
        setFilteredItems([]);
      }
    } else {
      if (value.length >= 3) {
        if (column === t("name")) {
          getItems({
            limit: 0,
            offset: 0,
            station_description: value,
          });
        } else {
          getItems({
            limit: 10,
            offset: 0,
            macroregion_description: value,
          });
        }

        setIsNumberOfLettersValid(true);
      } else {
        setFilteredItems([]);
        setIsNumberOfLettersValid(false);
      }
    }
  };

  const resetFilter = () => {
    setSearchFilter("");
    handleResetFilter("name");
  };

  return (
    <>
      {showFilter && (
        <Box
          ref={dropdownRef}
          position="absolute"
          top={`calc(${searchIconRef?.current?.offsetTop}px + 190px)`}
          left={`calc(${searchIconRef?.current?.parentElement?.offsetLeft}px + 280px)`}
          zIndex={1}
          p={4}
          bg="white"
          boxShadow="md"
          borderRadius="md"
          overflowY="auto"
          w="230px"
          maxH="330px"
          color="black"
          sx={{
            "&::-webkit-scrollbar": {
              width: "8px",
              borderRadius: "8px",
              backgroundColor: `#c8c7c7`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `rgba(0, 0, 0, 0.2)`,
              borderRadius: 8,
            },
          }}
        >
          <Flex alignItems="center" justifyContent="space-between" mb={2}>
            <Text fontWeight="bold">
              {t("filterBy")} {column}
            </Text>
            <CloseButton size="sm" onClick={() => setShowFilter(false)} />
          </Flex>
          <Center>
            <Input
              size={"sm"}
              placeholder={t("search") as string}
              _placeholder={{
                fontSize: "12px",
              }}
              value={searchFilter}
              onChange={(e) => handleSearchFilter(e.target.value)}
              autoFocus
            />
            <Box cursor={"pointer"} onClick={resetFilter}>
              <Text ml={2} fontSize={"13px"} color="newBlue.500">
                {t("clean")}
              </Text>
            </Box>
          </Center>

          {!filteredItems.length && !isNumberOfLettersValid && (
            <Box color="newBlue.500" mt={4}>
              {t("enterAtLeastThreeLetters")}
            </Box>
          )}

          {!filteredItems.length && isNumberOfLettersValid && (
            <Box color="newBlue.500" mt={4}>
              {t("notFound")}
            </Box>
          )}

          <Flex flexDirection={"column"}>
            {filteredItems?.map(
              (item) =>
                item && (
                  <Checkbox
                    p={1}
                    key={item}
                    isChecked={selectedFilters?.includes(item)}
                    onChange={() => onFilterChange(item)}
                  >
                    {item}
                  </Checkbox>
                )
            )}
          </Flex>
        </Box>
      )}
    </>
  );
};

export default ManageStationFilterDropdown;
