import { config } from "process";
import { EnvironmentalType } from "../enum/EnvironmentalType";
import api from "./api";

export interface IShareServicesParams {
  period?: number;
  forecastConfig: {
    environmentalType: EnvironmentalType;
    regionId: string;
    stationId: string;
  }[];
  recipientsUserIds?: number[];
  externalRecipientsEmails?: string[];
  inviteExternalRecipients: boolean;
  includesSender: boolean;
  message?: string;
}

export default class ShareService {
  async shareForecast({
    period,
    forecastConfig,
    recipientsUserIds,
    externalRecipientsEmails,
    inviteExternalRecipients,
    includesSender,
    message,
  }: IShareServicesParams): Promise<{ success: boolean }> {
    const forecastConfigFormated = forecastConfig.map((config) => {
      return {
        ...config,
        regionId: parseInt(config.regionId),
        stationId: parseInt(config.stationId),
      };
    });
    const result = await api.post(`/v1/share/forecast`, {
      period,
      forecast_config: forecastConfigFormated,
      recipients_user_ids: recipientsUserIds,
      external_recipients_emails: externalRecipientsEmails,
      invite_external_recipients: inviteExternalRecipients,
      includes_sender: includesSender,
      message: message,
    });
    return result.data;
  }
}
