import { Form, Input, Modal, Select, SelectProps, Space } from "antd";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { PermissionGroup } from "../../permissions";
import { getEnv } from "mobx-easy";
import { RootEnv } from "../../stores/setup/create-store";
import { validateEmail } from "../../util/validateEmail/validateEmail";
import { useTranslation } from "react-i18next";

interface InviteUserProps {
  visible: boolean;
  pageLoading?: boolean;
  onCancel: () => void;
  onOk: (email: string, userGroup: string) => void;
}

const InviteUser: React.FC<InviteUserProps> = ({
  visible,
  pageLoading,
  onCancel,
  onOk,
}) => {
  const { adminPanelConfigService } = getEnv<RootEnv>();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("translation", {
    keyPrefix: "admin.userManager",
  });

  const options: SelectProps["options"] = [
    {
      label: t("admin"),
      value: PermissionGroup.USER_ADMIN,
      desc: t("hasPermissionManageInviteUsers"),
    },
    {
      label: t("basic"),
      value: PermissionGroup.USER,
      desc: t("canAccessEditAllFeatures"),
    },
    {
      label: t("readOnly"),
      value: PermissionGroup.USER_READ_ONLY,
      desc: t("allowVisualizeData"),
    },
  ];

  useEffect(() => {
    form.setFieldsValue({
      group: PermissionGroup.USER,
      email: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const emailInUseValidator = async (value: string) => {
    if (validateEmail(value)) {
      setIsLoading(true);
      return new Promise((resolve, reject) => {
        adminPanelConfigService
          .verifyEmailInUse(value)
          .then((result) => {
            if (result) {
              return reject(result);
            } else {
              return resolve(result);
            }
          })
          .catch((error) => {
            console.log("Could not validate email");
          })
          .finally(() => setIsLoading(false));
      });
    }
  };

  return (
    <Modal
      title={t("inviteNewUserFromOrganization")}
      destroyOnClose={true}
      visible={visible}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      closable={true}
      okText={t("invite")}
      okButtonProps={{ disabled: isLoading || pageLoading }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onOk(
              form.getFieldsValue(true).email,
              form.getFieldsValue(true).group || PermissionGroup.USER
            );
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
        style={{ marginTop: 10 }}
      >
        <Space>
          <Form.Item
            name="email"
            label={t("email")}
            style={{ display: "inline-block", width: 240 }}
            validateStatus={isLoading ? "validating" : ""}
            hasFeedback
            rules={[
              {
                message: t("thisIsStillNotValidEmail") as string,
                validator: (_: any, value) => {
                  return validateEmail(value)
                    ? Promise.resolve()
                    : Promise.reject();
                },
              },
              {
                required: true,
                message: t("pleaseInputYourEmail") as string,
              },
              {
                message: t("emailAlreadyHasi4cast") as string,
                validator: (_: any, value: string) =>
                  emailInUseValidator(value),
              },
            ]}
          >
            <Input
              placeholder="email@email.com"
              onChange={(e) => {
                form.setFieldsValue({
                  email: e.target.value,
                });
              }}
            />
          </Form.Item>
        </Space>
        <Form.Item label={t("userGroup")}>
          <Select
            defaultValue={PermissionGroup.USER}
            onChange={(value: string) => {
              form.setFieldsValue({
                group: value,
              });
            }}
            style={{ width: 100 }}
            dropdownMatchSelectWidth={false}
            options={options}
            optionRender={(option) => (
              <>
                <h3>{option.data.label}</h3>
                <p style={{ color: "grey" }}>{option.data.desc}</p>
              </>
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default observer(InviteUser);
