import React, { useEffect } from "react";
import { Alert } from "antd";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../stores/setup/use-store";
import { StoreState } from "../../../../enum/StoreState";
import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Select,
  Textarea,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { Input } from "../../../../components/Input/Input";
import { IStation } from "../../../../stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import { IMacroRegion } from "../../../../types/IMacroRegion";
import Loading from "../../../../components/Loading/Loading";
import { OperatinRuleLocationFormProps } from "../OperationRuleLocationDetail";

interface OperationRuleLocationModalProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onConfirm: (
    values: OperatinRuleLocationFormProps,
    isEditing?: boolean
  ) => void;
  confirmButtonText?: string;
  initialRef?: React.RefObject<HTMLInputElement>;
  formik?: any;
  isEditing: boolean;
}

const OperationRuleLocationModal: React.FC<OperationRuleLocationModalProps> = ({
  onConfirm,
  title,
  isOpen,
  onClose,
  initialRef,
  formik,
  confirmButtonText,
  isEditing,
}) => {
  const {
    dataStores: {
      operationRuleLocationStore,
      oceanWeatherIntelligenceStore,
      regionStore,
      authStore,
    },
  } = useStores();

  const { values, handleChange, initialValues } = formik;
  const { t } = useTranslation("translation", {
    keyPrefix: "oceanWeatherIntelligence.operationRuleLocation",
  });

  useEffect(() => {
    if (operationRuleLocationStore.operationRuleLocation && isEditing) {
      const { stationId, regionId, macroRegionId } =
        operationRuleLocationStore.operationRuleLocation;

      if (macroRegionId && macroRegionId > 0) {
        oceanWeatherIntelligenceStore
          .getStationForLocationAPI(macroRegionId)
          .then(() => {
            formik.setFieldValue("stationRegion", `${stationId}-${regionId}`);
            formik.setFieldValue("macroRegionId", macroRegionId);
          });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationRuleLocationStore.operationRuleLocation, isEditing]);

  const handleChangeRegion = async (value: string) => {
    if (value) {
      formik.setFieldValue("macroRegionId", parseInt(value));
      formik.setFieldValue("stationRegion", undefined);

      oceanWeatherIntelligenceStore.getStationForLocationAPI(parseInt(value));
    }
  };

  const handleChangeStation = async (value: string) => {
    if (value) {
      formik.setFieldValue("stationRegion", value);
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
    >
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        <>
          <ModalOverlay />
          <ModalContent bg={"#f0f1f2"}>
            <ModalHeader color="newBlue.500">{title}</ModalHeader>
            <ModalCloseButton color={"black"} />
            <ModalBody pb={6} px={14}>
              {operationRuleLocationStore.state === StoreState.ERROR ? (
                <Alert
                  message="Error"
                  description={operationRuleLocationStore.messageError}
                  type="error"
                  showIcon
                  closable
                />
              ) : (
                ""
              )}
              {oceanWeatherIntelligenceStore.state === StoreState.PEDDING && (
                <Loading styleSpinner={{ marginLeft: 0 }} />
              )}
              <>
                <FormControl mb={4} isRequired>
                  <FormLabel color="newBlue.500" fontSize={"large"}>
                    {t("name")}
                  </FormLabel>
                  <Input
                    autoFocus
                    placeholder={t("name") as string}
                    maxLength={60}
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    disabled={authStore.readOnlyUser}
                  />
                </FormControl>
                <FormControl mb={4} isRequired>
                  <FormLabel color="newBlue.500" fontSize={"large"}>
                    {t("key")}
                  </FormLabel>
                  <Input
                    placeholder={t("key") as string}
                    maxLength={4}
                    name="initialName"
                    value={values.initialName}
                    onChange={(e) => {
                      e.target.value = e.target.value.toLocaleUpperCase();
                      handleChange(e);
                    }}
                    disabled={authStore.readOnlyUser}
                  />
                </FormControl>
                <FormControl mb={4} isRequired>
                  <FormLabel color="newBlue.500" fontSize={"large"}>
                    {t("summary")}
                  </FormLabel>
                  <Textarea
                    maxLength={100}
                    color={"newBlue.500"}
                    value={values.description}
                    onChange={handleChange}
                    name="description"
                    placeholder={t("summary") as string}
                    borderRadius={8}
                    style={{
                      backgroundColor: "white",
                      borderColor: "white",
                      color: "rgba(30, 82, 151, 0.8)",
                      fontSize: 14,
                      fontWeight: "normal",
                    }}
                    size="sm"
                    _focus={{
                      border: "2px",
                      borderColor: "newBlue.500",
                      boxShadow: "0px 4px 10px rgba(40, 103, 149, 0.1)",
                    }}
                    borderColor="gray.300"
                    disabled={authStore.readOnlyUser}
                  />
                </FormControl>
                <FormControl mb={4} isRequired>
                  <FormLabel color="newBlue.500" fontSize={"large"}>
                    {t("region")}
                  </FormLabel>
                  <Select
                    borderRadius={8}
                    color={
                      values.macroRegionId
                        ? "rgba(30, 82, 151, 0.8)"
                        : "gray.400"
                    }
                    backgroundColor="#ffffff"
                    size="md"
                    fontSize={14}
                    onChange={(e) => handleChangeRegion(e.target.value)}
                    value={values.macroRegionId}
                    _focus={{ borderColor: "none" }}
                    name={"macroRegionId"}
                    placeholder={t("pleaseSelectRegionFromList") as string}
                    disabled={authStore.readOnlyUser}
                  >
                    {regionStore.macroRegions?.map((value: IMacroRegion) => (
                      <option
                        style={{ color: "rgba(30, 82, 151, 0.8)" }}
                        value={value.id}
                        key={value.id}
                      >
                        {value.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl mb={4} isRequired>
                  <FormLabel color="newBlue.500" fontSize={"large"}>
                    {t("station")}
                  </FormLabel>
                  <Select
                    id="selectStation"
                    borderRadius={8}
                    color={
                      values.stationRegion
                        ? "rgba(30, 82, 151, 0.8)"
                        : "gray.400"
                    }
                    fontSize={14}
                    backgroundColor="#ffffff"
                    size="md"
                    onChange={(e) => handleChangeStation(e.target.value)}
                    value={values.stationRegion}
                    _focus={{ borderColor: "none" }}
                    name={"stationRegion"}
                    placeholder={t("pleaseSelectStationFromList") as string}
                    disabled={authStore.readOnlyUser}
                  >
                    {oceanWeatherIntelligenceStore.stations?.map(
                      (value: IStation) => (
                        <option
                          style={{ color: "rgba(30, 82, 151, 0.8)" }}
                          value={`${value.station_id}-${value.region_id}`}
                          key={`${value.station_id}-${value.region_id}`}
                        >
                          {value.station_name}
                        </option>
                      )
                    )}
                  </Select>
                </FormControl>
              </>
            </ModalBody>
            {!authStore.readOnlyUser && (
              <ModalFooter>
                <Button
                  variant="ghost"
                  color="newBlue.500"
                  onClick={onClose}
                  mr={3}
                >
                  {t("cancel")}
                </Button>
                <Button
                  layerStyle="ocean"
                  _hover={{ layerStyle: "ocean" }}
                  onClick={() => onConfirm(values, isEditing)}
                  type="submit"
                >
                  {confirmButtonText}
                </Button>
              </ModalFooter>
            )}
          </ModalContent>
        </>
      </Formik>
    </Modal>
  );
};

export default observer(OperationRuleLocationModal);
