import { getRoot } from "mobx-easy";
import { MeteogramVariables } from "../enum/EnvironmentalType";
import { Units } from "../stores/data/unitMeasurement/UnitMeasurementStore";
import RootStore from "../stores/root-store";

type UseConvertValueToUserUnitProps = {
  value?: number;
  environmentalVariable?: string;
  defaultUnit?: string;
};

export default function useConvertValueToUserUnit({
  value,
  defaultUnit,
  environmentalVariable,
}: UseConvertValueToUserUnitProps) {
  const {
    dataStores: { unitMeasurementStore },
  } = getRoot<RootStore>();

  const unitsUser = unitMeasurementStore.getUnitMeasurementConfig(
    environmentalVariable as MeteogramVariables
  );

  const getUnitBasedOnUserConfig = () => {
    if (!defaultUnit || !value) return null;

    return unitMeasurementStore.convertUnit(
      value,
      defaultUnit.toLowerCase() as Units,
      unitsUser as Units
    );
  };

  const unit = getUnitBasedOnUserConfig();

  return {
    unit,
    unitsUser,
  };
}
