import React, { ChangeEvent, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useToast,
  ModalBody,
  Text,
  Button,
  ModalFooter,
  Flex,
  Textarea,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Loading from "../../../components/Loading/Loading";
import { observer } from "mobx-react";
import { useStores } from "../../../stores/setup/use-store";
import { StoreState } from "../../../enum/StoreState";

interface OccurrenceImportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const OcurrenceImportModal: React.FC<OccurrenceImportModalProps> = ({
  isOpen,
  onClose,
}) => {
  const {
    dataStores: { occurrenceStore },
  } = useStores();
  const toast = useToast();
  const { t } = useTranslation("translation", {
    keyPrefix: "occurrence",
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [description, setDescription] = useState<string>("");
  const [descriptionError, setDescriptionError] = useState<string | null>(null);

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    const maxSize = 5 * 1024 * 1024; // Tamanho máximo em bytes (5MB)

    if (file && file.size > maxSize) {
      event.target.value = "";
      toast({
        title: t("theSelectedFileExceedsMaxium"),
        status: "error",
        position: "top",
        isClosable: true,
      });
    } else {
      setSelectedFile(file || null);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      toast({
        title: t("youNeedToChooseFile"),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }

    if (!description) {
      setDescriptionError(t("descriptionRequired"));
    }

    if (!description || !selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("description", description);

    const success = await occurrenceStore.importOccurrences(formData);

    if (success) {
      onClose();

      toast({
        title: t("successOnImport"),
        status: "success",
        position: "bottom-right",
        isClosable: true,
      });

      setTimeout(() => {
        occurrenceStore.setOpenSuccessImportModal(true);
      }, 1000);

      setSelectedFile(null);
    }
  };

  const handleChangeDescription = (value: string) => {
    if (value) {
      setDescriptionError(null);
    }
    setDescription(value);
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setSelectedFile(null);
      }}
      size={"4xl"}
    >
      <ModalOverlay />
      <ModalContent bg={"#f0f1f2"}>
        <ModalHeader fontWeight="light" color="newBlue.500">
          <Text fontSize="2xl">{t("importOccurrence")}</Text>
          <Text fontSize={"md"} fontWeight="light" color="gray.500">
            {t("importYourOccurrencesSpreadSheet")}
          </Text>
        </ModalHeader>

        <ModalCloseButton color={"black"} />

        {occurrenceStore.importState === StoreState.PEDDING && (
          <Loading styleSpinner={{ marginLeft: 0 }} />
        )}

        <ModalBody color="newBlue.500">
          <Text fontSize="sm" mb={2}>
            {t("description")}*
          </Text>
          <FormControl isInvalid={!!descriptionError} w={"auto"} mb={4}>
            <Textarea
              maxLength={5000}
              style={{
                backgroundColor: "white",
                borderColor: "white",
                color: "rgba(30, 82, 151, 0.8)",
                fontSize: 14,
                fontWeight: "normal",
              }}
              borderRadius={8}
              size="sm"
              _focus={{
                border: "2px",
                borderColor: "newBlue.500",
                boxShadow: "0px 4px 10px rgba(40, 103, 149, 0.1)",
              }}
              onChange={(e) => handleChangeDescription(e.target.value)}
              borderColor="gray.300"
              placeholder={t("strongWinds") as string}
              required
            />
            {<FormErrorMessage>{descriptionError}</FormErrorMessage>}
          </FormControl>

          <Text fontSize="sm" mb={2}>
            {t("selectFileToImport")}
          </Text>

          <Flex alignItems={"center"}>
            <label
              htmlFor="uploadButton"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                padding: "0.7rem",
                backgroundColor: "#286795",
                color: "white",
                borderRadius: "4px",
                width: "200px",
              }}
            >
              <input
                id="uploadButton"
                type="file"
                onChange={handleFileInputChange}
                style={{ display: "none" }}
                accept=".xlsx, .csv, .doc, .docx, .txt, .pdf"
              />
              {t("selectAFile")}
            </label>
            {selectedFile ? (
              <Text ml={2}>
                {t("selectedFile")} {selectedFile.name}
              </Text>
            ) : (
              <Text ml={2}>{t("noFileSelected")}</Text>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            color="newBlue.500"
            onClick={() => {
              onClose();
              setSelectedFile(null);
            }}
            mr={3}
          >
            {t("cancel")}
          </Button>
          <Button
            layerStyle="ocean"
            _hover={{ layerStyle: "ocean" }}
            onClick={handleFileUpload}
            type="submit"
          >
            {t("import")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default observer(OcurrenceImportModal);
