import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import UnitMeasurement from "./UnitMeasurement/UnitMeasurement";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import { SCREEN_HORIZONTAL_SPACING } from "../../constants";

interface SettingsProps {}

const Settings: React.FC<SettingsProps> = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "settings",
  });

  return (
    <Box mt={8} mx={SCREEN_HORIZONTAL_SPACING}>
      <PageTitle text={t("settings")} />
      <Tabs color="black">
        <TabList color="newBlue.300">
          <Tab _selected={{ color: "newBlue.500", borderColor: "newBlue.500" }}>
            {t("unitMeasurement")}
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <UnitMeasurement />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default observer(Settings);
