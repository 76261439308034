import { Button, message, Result } from "antd";
import { getEnv } from "mobx-easy";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { LoginLayout } from "../../components/Login/LoginLayout/LoginLayout";
import RegisterUserForm, {
  IRegisterUserFormValues,
} from "../../components/RegisterUser/RegisterUserForm";
import { IUserAreaPosition } from "../../stores/data/userConfig/userConfigModal";
import { RootEnv } from "../../stores/setup/create-store";
import { IInvitationUser } from "../../types/IInvitationUser";
import {
  errorMessages,
  UserInvitationErrorType,
} from "../Admin/UserManager/UserManager";

const RegisterUser: React.FC = () => {
  const { invitation_token } = useParams<{ invitation_token: string }>();
  const { adminPanelConfigService, authService, userConfigService } =
    getEnv<RootEnv>();

  const [invitation, setInvitation] = useState<IInvitationUser>({
    email: "",
    requesterName: "",
  });
  const [area, setArea] = useState<IUserAreaPosition[]>([
    { id: 0, description: "" },
  ]);
  const [position, setPosition] = useState<IUserAreaPosition[]>([
    { id: 0, description: "" },
  ]);

  const { t } = useTranslation("translation", {
    keyPrefix: "registerUser",
  });

  const [invitationTokenExpired, setInvitationTokenExpired] = useState(false);

  const [errorSubTitle, setErrorSubTitle] = useState(
    t("sorryThePageYouVisitedNotExist")
  );

  const history = useHistory();

  useEffect(() => {
    setInvitation({
      email: "",
      requesterName: "",
    });
    setArea([{ id: 0, description: "" }]);
    setPosition([{ id: 0, description: "" }]);
  }, []);

  useEffect(() => {
    adminPanelConfigService
      .loadInvitation(invitation_token)
      .then((invitation) => {
        setInvitation(invitation);
      })
      .catch((error) => {
        const type: UserInvitationErrorType = error.response?.data?.name;

        const errorMessage = errorMessages[type] || t("expiredInvitation");
        setErrorSubTitle(errorMessage);

        setInvitationTokenExpired(true);
      });

    userConfigService
      .getUserArea()
      .then((area) => {
        setArea(area);
      })
      .catch((error) => {
        message.error(t("errorWhileLoadUserAreaOptions"));
      });

    userConfigService
      .getUserPosition()
      .then((position) => {
        setPosition(position);
      })
      .catch((error) => {
        message.error(t("errorWhileLoadUserAreaOptions"));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (
    values: IRegisterUserFormValues,
    invitationToken: string
  ) => {
    authService
      .registerUser({ ...values, invitationToken })
      .then((response) => {
        if (response.success) {
          history.push("/login");
          message.success(t("youHaveSuccessfullyRegistered"), 5);
        } else {
          message.error(t("errorWhileRegisterUser"));
        }
      })
      .catch((error) => {
        message.error(t("errorWhileRegisterUser"));
      });
  };

  return (
    <LoginLayout>
      {!invitationTokenExpired ? (
        <RegisterUserForm
          area={area}
          position={position}
          email={invitation.email}
          requesterName={invitation.requesterName}
          onSubimit={(values: IRegisterUserFormValues) =>
            onSubmit(values, invitation_token)
          }
        />
      ) : (
        <Result
          status="404"
          title={t("errorInInvitation")}
          subTitle={errorSubTitle}
        />
      )}
    </LoginLayout>
  );
};

export default observer(RegisterUser);
