import { Box } from "@chakra-ui/react";
import { Col } from "antd";
import { getEnv } from "mobx-easy";
import React, { useEffect, useState } from "react";
import { ImportedForecastTable } from "../../components/ImportedForecastTable/ImportedForecastTable";
import { SCREEN_HORIZONTAL_SPACING } from "../../constants";
import { RootEnv } from "../../stores/setup/create-store";
import { ISomcDataFileLog } from "../../types/ISomcDataFileLog";

export const ForecastImportChecker: React.FC = () => {
  const { somcService } = getEnv<RootEnv>();

  const [isLoading, setIsLoading] = useState(false);
  const [somcDataFileLog, setSomcDataFileLog] = useState<ISomcDataFileLog[]>(
    []
  );
  useEffect(() => {
    setIsLoading(true);
    somcService
      .getLastImportSomcData()
      .then((result) => {
        setSomcDataFileLog(result);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box mt={8} mx={SCREEN_HORIZONTAL_SPACING}>
      <Col span={24}>
        <ImportedForecastTable
          isLoading={isLoading}
          somcDataFileLog={somcDataFileLog}
          pageSize={8}
        />
      </Col>
    </Box>
  );
};
