import { makeAutoObservable } from "mobx";
import { MeteogramVariables } from "../../../enum/EnvironmentalType";
import configureMeasurements from "convert-units";
import length, {
  LengthSystems,
  LengthUnits,
} from "convert-units/definitions/length";
import area, { AreaSystems, AreaUnits } from "convert-units/definitions/area";
import speed, { SpeedUnits } from "convert-units/definitions/speed";

import { getEnv } from "mobx-easy";
import { StoreState } from "../../../enum/StoreState";
import { RootEnv } from "../../setup/create-store";
import { IAddUserUnitMeasurement } from "../../../services/UnitMeasurementService";
import i18n from "../../../i18n";

// Measures: The names of the measures being used
type Measures = "length" | "area" | "speed";
// Systems: The systems being used across all measures
type Systems = LengthSystems | AreaSystems | SpeedUnits;
// Units: All the units across all measures and their systems
export type Units = LengthUnits | AreaUnits | SpeedUnits;

const convert = configureMeasurements<Measures, Systems, Units>({
  length,
  area,
  speed,
});

export default class UnitMeasurementStore {
  unitMeasurementConfig: Record<string, string> = {
    wind_vel: "kt",
    wind_vel_80m: "kt",
    wind_vel_100m: "kt",
    gust: "kt",
    gust_80m: "kt",
    gust_100m: "kt",
    water_level: "m",
    current_vel: "kt",
    wave_height: "m",
    wave_height_sea: "m",
    wave_height_swell: "m",
    wave_height_max: "m",
    peak_period: "s",
    mean_period: "s",
    precip: "mm",
    rel_humidity: "%",
    total_cloud_cover: "%",
    precip_probability: "%",
    precip_min_20km: "mm",
    visibility: "km",
    visibility_minimum_20km: "km",
    visibility_maximum_20km: "km",
  };

  state: string = StoreState.DONE;
  messageError: string = "";
  getUnitMeasurementState: string = StoreState.DONE;

  constructor() {
    makeAutoObservable(this);
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setMessageError(value: string) {
    this.messageError = value;
  }

  getUnitMeasurementConfig = (environmentalVariable: string) => {
    return this.unitMeasurementConfig[environmentalVariable];
  };

  setUnitMeasurementConfig = (
    environmentalVariable: MeteogramVariables,
    unit: string
  ) => {
    unit = unit === "knots" ? "kt" : unit;
    this.unitMeasurementConfig[environmentalVariable] = unit;
    localStorage.setItem(
      "unitMeasurementConfig",
      JSON.stringify(this.unitMeasurementConfig)
    );
  };

  setAllUnitMeasurementConfig = (value: Record<string, string>) => {
    this.unitMeasurementConfig = value;
  };

  setGetUnitMeasurementState(state: StoreState) {
    this.getUnitMeasurementState = state;
  }

  convertUnit = (
    value: number,
    from: Units | "knots" | "Meters" | "Knots",
    to: Units
  ) => {
    if (from === "knots" || from === "Knots") from = "knot";
    if (from === "Meters") from = "m";
    from = from === ("kt" as Units) ? "knot" : from;
    to = to === ("kt" as Units) ? "knot" : to;
    return convert(value).from(from).to(to);
  };

  reset() {
    this.setState(StoreState.DONE);
    this.setMessageError("");
    localStorage.removeItem("unitMeasurementConfig");
    this.setAllUnitMeasurementConfig({
      wind_vel: "kt",
      wind_vel_80m: "kt",
      wind_vel_100m: "kt",
      gust: "kt",
      gust_80m: "kt",
      gust_100m: "kt",
      water_level: "m",
      current_vel: "kt",
      wave_height: "m",
      wave_height_sea: "m",
      wave_height_swell: "m",
      wave_height_max: "m",
      peak_period: "s",
      mean_period: "s",
      precip: "mm",
      rel_humidity: "%",
      total_cloud_cover: "%",
      precip_probability: "%",
      precip_min_20km: "mm",
      visibility: "km",
      visibility_minimum_20km: "km",
      visibility_maximum_20km: "km",
    });
  }

  async getUserUnitMeasurementApi() {
    const { unitMeasurementService } = getEnv<RootEnv>();
    try {
      this.setGetUnitMeasurementState(StoreState.PEDDING);
      const units = await unitMeasurementService.getUserUnitMeasurement();
      units.forEach((unit) => {
        this.setUnitMeasurementConfig(
          unit.environmentalVariable as MeteogramVariables,
          unit.unit_symbol.toLowerCase()
        );
      });
      this.setGetUnitMeasurementState(StoreState.DONE);
    } catch (error) {
      this.setGetUnitMeasurementState(StoreState.ERROR);
      console.log(error);
    }
  }

  async addUserUnitMeasurementApi(
    userUnitMeasurementParams: IAddUserUnitMeasurement[],
    unitSymbol: string
  ) {
    this.setState(StoreState.PEDDING);
    const { unitMeasurementService } = getEnv<RootEnv>();

    try {
      await unitMeasurementService.addUserUnitMeasurement(
        userUnitMeasurementParams
      );

      userUnitMeasurementParams.forEach((unit) => {
        this.setUnitMeasurementConfig(
          unit.environmentalVariable as MeteogramVariables,
          unitSymbol.toLowerCase()
        );
      });

      this.setState(StoreState.SUCCESS);
      setTimeout(() => {
        this.setState(StoreState.DONE);
      }, 1000);
    } catch (error) {
      console.log(error);
      this.setMessageError(
        i18n.t("unitMeasurementStore.errorToAddUnitMeasurement")
      );
      this.setState(StoreState.ERROR);
    }
  }
}
