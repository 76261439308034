import api from "./api";

export default class UserConfigService {
  async getUserConfig() {
    let result;

    result = await api.get(`/v1/userConfig`);
    return result.data;
  }

  async updateUserConfig(
    name: string,
    lastName: string,
    email: string,
    areaId: number,
    positionId: number,
    middleName?: string,
    phoneCode?: string,
    phoneNumber?: string
  ): Promise<boolean> {
    const result = await api.put(`/v1/userConfig`, {
      name: name,
      last_name: lastName,
      email: email,
      area_id: areaId,
      position_id: positionId,
      middle_name: middleName,
      phone_code: phoneCode,
      phone_number: phoneNumber,
    });
    if (result.status === 200 || result.status === 201) {
      return true;
    }
    throw new Error("Error while update user config");
  }

  async getUserPosition() {
    let result;

    result = await api.get(`/v1/userConfig/position`);
    return result.data;
  }

  async getUserArea() {
    let result;

    result = await api.get(`/v1/userConfig/area`);
    return result.data;
  }

  async updateUserLanguage(language: string): Promise<boolean> {
    const result = await api.put(`/v1/userConfig/language/${language}`);

    if (result.status === 200 || result.status === 201) {
      return true;
    }
    throw new Error("Error while update user language");
  }

  async getUserLanguage(): Promise<{ language: string | null }> {
    const result = await api.get(`/v1/userConfig/language`);

    if (result.status === 200 || result.status === 201) {
      return result.data;
    }
    throw new Error("Error while update user language");
  }

  async getUsers() {
    const result = await api.get(`/v1/userConfig/users`);

    if (result.status === 200 || result.status === 201) {
      return result.data;
    }
    throw new Error("Error while get users");
  }
}
