import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "react-datepicker/dist/react-datepicker.css";
import "./font.css";
import { StoreProvider } from "./stores/setup/store-context";
import { createStore } from "./stores/setup/create-store";
import { ChakraProvider } from "@chakra-ui/react";
import myTheme from "./styles/theme";

import "./i18n";

const rootStore = createStore();

ReactDOM.render(
  <ChakraProvider theme={myTheme}>
    <StoreProvider value={rootStore}>
      <App />
    </StoreProvider>
  </ChakraProvider>,
  document.getElementById("root")
);
