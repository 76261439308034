import { Button, Flex, Text } from "@chakra-ui/react";
import svg500 from "assets/500.svg";
import { LoginLayout } from "components/Login/LoginLayout/LoginLayout";
import { useTranslation } from "react-i18next";
import { history } from "routes";

export default function Page500() {
  const { t } = useTranslation("translation", {
    keyPrefix: "500",
  });
  const goBack = () => history.goBack();
  return (
    <LoginLayout>
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={6}
      >
        <img width={300} src={svg500} alt="404" />
        <Text fontSize="20px" color="black">
          {t("oopsAnErrorOccurred")}
        </Text>
        <Button
          onClick={goBack}
          color="white"
          bg="newBlue.500"
          border="1px solid #b0d2ec"
          _hover={{ backgroundColor: "rgba(49, 154, 221, 0.3)" }}
        >
          {t("tryAgain")}
        </Button>
      </Flex>
    </LoginLayout>
  );
}
