import { IQuestionFeedback } from "./IQuestionFeedback";
import { INotification } from "./INotification";

export enum RealtimeStorageEntity {
  Notification = "Notification",
  QuestionFeedback = "QuestionFeedback",
}

export interface RealtimeStorageEntityType {
  [RealtimeStorageEntity.Notification]: INotification;
  [RealtimeStorageEntity.QuestionFeedback]: IQuestionFeedback;
}

export interface RealtimeStorageFilter {
  filter?: { key: string; operator: string; value: any }[];
  order?: RealtimeStorageOrder[];
  limit?: number;
  offset?: number;
}

export interface RealtimeStorageOrder {
  item: string;
  type: "desc" | "asc";
}
