import { makeAutoObservable } from "mobx";

export default class RequestCancellationStore {
  controllers: AbortController[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.controllers = [];
  }

  addController(controller: AbortController) {
    this.controllers.push(controller);
  }

  removeController(controller: AbortController) {
    const index = this.controllers.indexOf(controller);
    if (index !== -1) {
      this.controllers.splice(index, 1);
    }
  }

  async cancelAllRequests() {
    const abortPromises = this.controllers?.map((controller) => {
      return new Promise<void>((resolve) => {
        controller?.abort();
        resolve();
      });
    });
    await Promise.all(abortPromises);
    this.controllers = [];
  }
}
