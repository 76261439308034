import { Button, Form, Input, message, Select, Typography } from "antd";
import { getEnv } from "mobx-easy";
import React, { useEffect, useState } from "react";
import { IUserAreaPosition } from "../../stores/data/userConfig/userConfigModal";
import { RootEnv } from "../../stores/setup/create-store";
import { validateOnlyLetters } from "../../util/validateOnlyLetters/validateOnlyLetters";
import { validatePassword } from "../../util/validatePassword/validatePassword";
import { validateUsername } from "../../util/validateUsername/validateUsername";

import "./styles.css";
import { useTranslation } from "react-i18next";
import { InternationalPhoneInput } from "../InternationalPhoneInput/InternationalPhoneInput";

export interface RegisterUserFormProps {
  email: string;
  requesterName: string;
  onSubimit: (values: any) => void;
  area: IUserAreaPosition[];
  position: IUserAreaPosition[];
}

export interface IRegisterUserFormValues {
  name: string;
  lastName: string;
  login: string;
  password: string;
  areaId: number;
  positionId: number;
  phoneNumber: string;
  phoneCod: string;
}

const RegisterUserForm: React.FC<RegisterUserFormProps> = (props) => {
  const { email, requesterName, onSubimit, area, position } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [phoneData, setPhoneData] = useState<{ phone?: string; code?: string }>(
    {}
  );
  const { t } = useTranslation("translation", { keyPrefix: "registerUser" });

  const { authService } = getEnv<RootEnv>();

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { Option } = Select;

  const usernameInUseValidator = async (value: string) => {
    if (value) {
      setIsLoading(true);
      return new Promise((resolve, reject) => {
        authService
          .hasUsername(value)
          .then((result) => {
            if (result) {
              return reject(result);
            } else {
              return resolve(result);
            }
          })
          .catch((error) => {
            console.log("Could not validate username");
          })
          .finally(() => setIsLoading(false));
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "300px",
        minWidth: "240px",
      }}
    >
      <Typography.Title
        level={3}
        style={{ textAlign: "center", color: "#1da0db", marginBottom: "2px" }}
      >
        {t("youInvitedJoinI4cast")}{" "}
      </Typography.Title>
      <Typography.Text
        style={{ fontSize: "14px", color: "rgba(30, 24, 83, 0.64)" }}
      >
        {`${t("invitedBy")} ${requesterName}`}
      </Typography.Text>
      <Form
        className="form-register-user"
        form={form}
        name="register"
        onFinish={(values: any) =>
          onSubimit({
            ...values,
            phoneCod: phoneData?.code,
            phoneNumber: phoneData?.phone,
          })
        }
        layout={"vertical"}
        size="middle"
        scrollToFirstError
      >
        <Form.Item
          name="email"
          label="Email"
          style={{ marginBottom: "8px", marginTop: "1px" }}
        >
          <Input
            disabled
            defaultValue={email}
            placeholder={email}
            style={{ borderRadius: "8px" }}
          />
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            label={t("name")}
            name="name"
            style={{
              display: "inline-block",
              width: "calc(50% - 12px)",
              marginBottom: "8px",
            }}
            rules={[
              {
                required: true,
                message: t("pleaseInputYourName") as string,
              },
              {
                message: t("nameMustContainOnlyLetters") as string,
                validator: (_: any, value) => {
                  return validateOnlyLetters(value)
                    ? Promise.resolve()
                    : Promise.reject();
                },
              },
            ]}
          >
            <Input style={{ borderRadius: "8px" }} />
          </Form.Item>
          <span
            style={{
              display: "inline-block",
              width: "24px",
              lineHeight: "32px",
              textAlign: "center",
            }}
          />
          <Form.Item
            name="lastName"
            label={t("lastName")}
            style={{
              display: "inline-block",
              width: "calc(50% - 12px)",
              marginBottom: "8px",
            }}
            rules={[
              {
                required: true,
                message: t("pleaseInputYourLastName") as string,
              },
              {
                message: t("lastNameMustContainOnlyLetters") as string,
                validator: (_: any, value) => {
                  return validateOnlyLetters(value)
                    ? Promise.resolve()
                    : Promise.reject();
                },
              },
            ]}
          >
            <Input style={{ borderRadius: "8px" }} />
          </Form.Item>
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            name="login"
            label={t("username")}
            hasFeedback
            validateStatus={isLoading ? "validating" : ""}
            style={{
              display: "inline-block",
              width: "calc(50% - 12px)",
              marginBottom: "8px",
            }}
            rules={[
              {
                required: true,
                message: t("pleaseInputYourUsername") as string,
              },
              {
                message: t("lastNameMustStartWithLetter") as string,
                validator: (_: any, value) => {
                  return validateUsername(value)
                    ? Promise.resolve()
                    : Promise.reject();
                },
              },
              {
                message: t("usernameAlreadyHasAccount") as string,
                validator: (_: any, value: string) =>
                  usernameInUseValidator(value),
              },
            ]}
          >
            <Input style={{ borderRadius: "8px" }} />
          </Form.Item>
          <span
            style={{
              display: "inline-block",
              width: "24px",
              lineHeight: "32px",
              textAlign: "center",
            }}
          >
            {" "}
          </span>
          <Form.Item
            name="password"
            label={t("password")}
            style={{
              display: "inline-block",
              width: "calc(50% - 12px)",
              marginBottom: "8px",
            }}
            rules={[
              {
                required: true,
                message: t("pleaseInputPassword") as string,
              },
              {
                message: t("passwordMustContain") as string,
                validator: (_: any, value) => {
                  return validatePassword(value)
                    ? Promise.resolve()
                    : Promise.reject();
                },
              },
            ]}
            hasFeedback
          >
            <Input.Password style={{ borderRadius: "8px" }} />
          </Form.Item>
        </Form.Item>

        <Form.Item name="Phone" label={t("phone")} style={{ marginBottom: 10 }}>
          <InternationalPhoneInput
            style={{ width: "100%" }}
            phoneData={phoneData}
            setPhoneData={setPhoneData}
          />
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            label={t("jobArea")}
            name="areaId"
            style={{
              display: "inline-block",
              width: "calc(50% - 12px)",
              marginBottom: "8px",
            }}
          >
            <Select style={{ borderRadius: "8px" }} defaultValue={""}>
              {area.map((value) => (
                <Option value={value.id} key={value.id}>
                  {value.description}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <span
            style={{
              display: "inline-block",
              width: "24px",
              lineHeight: "32px",
              textAlign: "center",
            }}
          />
          <Form.Item
            name="positionId"
            label={t("jobPosition")}
            style={{
              display: "inline-block",
              width: "calc(50% - 12px)",
            }}
          >
            <Select style={{ borderRadius: "8px" }} defaultValue={""}>
              {position.map((value) => (
                <Option value={value.id} key={value.id}>
                  {value.description}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Button
            style={{
              borderRadius: "8px",
              background: "#1da0db",
              borderColor: "#1da0db",
              width: "100%",
            }}
            type="primary"
            htmlType="submit"
          >
            {t("register")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegisterUserForm;
