import * as yup from "yup";

export const insightBuilderFormSchema = yup.object().shape({
  name: yup.string().required(),
  resume: yup.string().required(),
  groups: yup.array().of(
    yup.object().shape({
      conditions: yup.array().of(
        yup.object().shape({
          value: yup.number().required(),
        })
      ),
    })
  ),
});
