import { Button } from "@chakra-ui/react";
import { CSSProperties, MouseEventHandler } from "react";

interface VisualizationTypeButtonProps {
  className?: string;
  label: string;
  isActive: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean | undefined;
  style?: CSSProperties | undefined;
}

export const VisualizationTypeButton: React.FC<VisualizationTypeButtonProps> =
  ({ className, label, isActive, onClick, disabled, style }) => {
    const activeBg = "newBlue.500";
    const inactiveBg = "rgba(0, 0, 0, 0.37)";
    const hoverBg = isActive ? activeBg : "rgba(0, 0, 0, 0.47)";

    return (
      <Button
        className={className}
        bg={isActive ? activeBg : inactiveBg}
        _hover={{ backgroundColor: hoverBg }}
        onClick={onClick}
        disabled={disabled}
        minW="90px"
        size="xs"
        backdropFilter="blur(2px)"
        borderRight="1px solid"
        boxSizing="border-box"
        borderRadius={4}
        style={style}
      >
        {label}
      </Button>
    );
  };
