import { Box } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import sedimentationSubtitle from "../../assets/sedimentation_subtitle.svg";

const SedimentationMap: React.FC = () => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(window.innerHeight);
  }, []);

  return (
    <Box height={`${height - 40}px`} p={0}>
      <Iframe
        url="https://morfologia-map.netlify.app"
        height="100%"
        width="100%"
        display="block"
        position="relative"
      />

      <img
        src={sedimentationSubtitle}
        alt={"subtitle"}
        style={{
          position: "fixed",
          zIndex: 999999,
          top: height - 325,
          right: 0,
          marginLeft: 10,
        }}
        width={"84rem"}
      />
    </Box>
  );
};

export default observer(SedimentationMap);
