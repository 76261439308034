import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Progress,
  Spinner,
} from "@chakra-ui/react";
import TodayIcon from "@material-ui/icons/Today";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useTranslation } from "react-i18next";
import { useStores } from "../../stores/setup/use-store";
import i18n from "../../i18n";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import MeteogramChart from "../../components/MeteogramChart/MeteogramChart";
import pt from "date-fns/locale/pt-BR";
import en from "date-fns/locale/en-GB";
import { Select, Tag } from "antd";
import { EnvironmentalType } from "../../enum/EnvironmentalType";
import EmptyState from "../../components/EmptyState/EmptyState";
import emptyEventHistory from "../../assets/emptyEventHistory.svg";
import { IStationSensorForecast } from "../../stores/data/analyticsReporting/analyticsReportingStore";
import { StoreState } from "../../enum/StoreState";
import Lottie from "lottie-react";
import noData from "../../assets/animations/no-data.json";

//@ts-ignore
const InitialDatePickerButton = React.forwardRef(({ value, onClick }, ref) => (
  <Button
    //@ts-ignore
    ref={ref}
    onClick={onClick}
    backgroundColor="white"
    color={value ? "rgba(30, 82, 151, 0.8)" : "#BCBCBC"}
    fontWeight="light"
    fontSize={"1rem"}
    width={"100%"}
    leftIcon={
      window.innerWidth < 1800 ? undefined : (
        <TodayIcon style={{ width: 18, height: 18 }} />
      )
    }
    p={2}
    marginRight={4}
  >
    {value ? value : i18n.t("endDate.startDate")}
  </Button>
));

const EventHistory: React.FC = () => {
  const {
    dataStores: { analyticsReportingStore },
  } = useStores();

  const { t } = useTranslation("translation", {
    keyPrefix: "eventHistory",
  });

  const fiveDaysAgo = new Date();
  fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);

  const [startDate, setStartDate] = useState<Date>(fiveDaysAgo);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [environmentalType, setEnvironmentalType] = useState<EnvironmentalType>(
    EnvironmentalType.WEATHER
  );
  const [selectedStation, setSelectedStation] = useState<string>();

  const filterOption = (
    input: string,
    option?: { label: string; value: string; children: any }
  ) => {
    const children = option?.children?.props?.children;
    const childrenArray = Array.isArray(children) ? children : [children];

    return childrenArray.join().toLowerCase().includes(input.toLowerCase());
  };

  useEffect(() => {
    analyticsReportingStore.getStations(EnvironmentalType.WEATHER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newEndDate = new Date(startDate);
    newEndDate.setDate(newEndDate.getDate() + 5);
    setEndDate(newEndDate);
  }, [startDate]);

  useEffect(() => {
    if (startDate && endDate && environmentalType && selectedStation) {
      const stationRegionArray = selectedStation.split("-");
      if (stationRegionArray?.length !== 2) {
        return;
      }
      const stationId = stationRegionArray[0];
      const regionId = stationRegionArray[1];
      const newEndDate = new Date(startDate);
      newEndDate.setDate(newEndDate.getDate() + 5);

      analyticsReportingStore.getComparativeForecast({
        initialDate: startDate.getTime(),
        finalDate: newEndDate?.getTime(),
        stationId: parseInt(stationId),
        regionId: parseInt(regionId),
        environmentalType,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, selectedStation, environmentalType]);

  const limitText = (text: string, limit: number) => {
    if (text.length <= limit) {
      return text;
    } else {
      return text.slice(0, limit) + "...";
    }
  };

  return (
    <Box mt={8} mr={5}>
      <PageTitle text={t("eventHistory")} />

      <Text color="gray.500">{t("selectStationAndDefinePeriod")}</Text>

      <Box>
        {analyticsReportingStore.stationState === StoreState.PEDDING ? (
          <Center w="565px" bg="white" borderRadius={8} p={2}>
            <Spinner
              speed="0.65s"
              emptyColor="gray.300"
              color="newBlue.500"
              size="sm"
            />
          </Center>
        ) : (
          <Select
            showSearch
            placeholder={t("selectStation")}
            optionFilterProp="children"
            style={{ width: 565 }}
            onChange={(value) => setSelectedStation(value)}
            filterOption={filterOption}
          >
            {analyticsReportingStore.stations?.map(
              (station: IStationSensorForecast) => (
                <Select.Option
                  value={`${station.station_id}-${station.region_id}`}
                  key={`${station.station_id}-${station.region_id}`}
                >
                  <Flex alignItems={"center"} justifyContent={"space-between"}>
                    {limitText(
                      `${station.macro_region_name} - ${station.station_name}`,
                      60
                    )}
                    {station.hasSensorData && (
                      <Tag color="purple" bordered={false}>
                        {t("sensor")}
                      </Tag>
                    )}
                  </Flex>
                </Select.Option>
              )
            )}
          </Select>
        )}

        <Flex alignItems={"center"} my={4}>
          <Box mr={4}>
            <Text color="gray.500">{t("startDate")}</Text>
            <DatePicker
              selected={startDate}
              onChange={(date: Date) => {
                setStartDate(date);
              }}
              popperPlacement={"bottom-start"}
              maxDate={DateTime.local().toJSDate()}
              timeIntervals={15}
              customInput={<InitialDatePickerButton />}
              dateFormat="MMM dd, yyyy"
              locale={i18n.language === "en" ? en : pt}
              showYearDropdown
              scrollableYearDropdown
            />
          </Box>
          <Box mr={8}>
            <Text color="gray.500">{t("endDate")}</Text>

            <Text p={2} color="newBlue.500">
              {endDate &&
                DateTime.fromJSDate(endDate)
                  .setLocale(i18n.language)
                  .toFormat("MMM dd, yyyy")
                  .replace(/\./g, "")}
            </Text>
          </Box>
          <Box>
            <Text mt={"-9px"} color="gray.500">
              {t("environmentalVariable")}
            </Text>
            <RadioGroup
              color="#286795"
              onChange={(value) => setEnvironmentalType(value as any)}
              value={environmentalType}
              alignItems="center"
              mt={2}
            >
              <Stack direction="row">
                <Radio value="weather" borderColor={"gray.400"}>
                  {t("weather")}
                </Radio>
                <Radio value="wave" borderColor={"gray.400"}>
                  {t("wave")}
                </Radio>
                <Radio value="water_flow" borderColor={"gray.400"}>
                  {t("waterLevel")}
                </Radio>
                <Radio value="current" borderColor={"gray.400"}>
                  {t("current")}
                </Radio>
              </Stack>
            </RadioGroup>
          </Box>
        </Flex>
      </Box>
      {analyticsReportingStore.state === StoreState.PEDDING ? (
        <Center h="500px">
          <Progress w="500px" size="xs" isIndeterminate />
        </Center>
      ) : analyticsReportingStore.isEmptyStateCharts ? (
        <Box mt={28}>
          <EmptyState
            description={t("selectStationToViewData")}
            image={emptyEventHistory}
            imageSize="25vw"
          />
        </Box>
      ) : (
        <Accordion
          color="black"
          bg="white"
          defaultIndex={[0, 1, 2]}
          allowMultiple
          mb={4}
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box color="newBlue.500" as="span" flex="1" textAlign="left">
                  i4cast
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {Object.keys(analyticsReportingStore.i4castChart as object)
                .length ? (
                <MeteogramChart
                  timezoneOffset={
                    analyticsReportingStore.i4castChart?.data?.plotOptions
                      ?.series.timezoneOffset || 0
                  }
                  options={{
                    time: {
                      useUTC: false,
                    },
                    chart: {
                      zoomType: "xy",
                      height: 250,
                      backgroundColor: "white",
                    },
                    title: {
                      text: "",
                    },
                    subtitle: {
                      text: "",
                    },
                    xAxis: analyticsReportingStore.i4castChartXAxis,
                    yAxis: analyticsReportingStore.i4castChartYAxis,
                    legend: {
                      x: -10,
                      y: 10,
                      floating: true,
                      verticalAlign: "top",
                      itemStyle: {
                        fontSize: "14px",
                        fontFamily:
                          "'Hind Vadodara', 'Helvetica Neue', sans-serif",
                        fontWeight: "normal",
                        color: "#00000",
                      },
                    },
                    series: analyticsReportingStore.i4castChart,
                  }}
                />
              ) : (
                <>
                  <Center>
                    <Box width={"140px"}>
                      <Lottie animationData={noData} />
                    </Box>
                  </Center>
                  <Center>
                    <Text color={"blue.100"} fontSize="md">
                      {t("noi4castDataFound")}
                    </Text>
                  </Center>
                </>
              )}
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box color="newBlue.500" as="span" flex="1" textAlign="left">
                  {t("sensor")}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {Object.keys(analyticsReportingStore.sensorChart as object)
                .length ? (
                <MeteogramChart
                  timezoneOffset={
                    analyticsReportingStore.sensorChart?.data?.plotOptions
                      ?.series.timezoneOffset || 0
                  }
                  options={{
                    time: {
                      useUTC: false,
                    },
                    chart: {
                      zoomType: "xy",
                      height: 250,
                      backgroundColor: "white",
                    },
                    title: {
                      text: "",
                    },
                    subtitle: {
                      text: "",
                    },
                    xAxis: analyticsReportingStore.sensorChartXAxis,
                    yAxis: analyticsReportingStore.sensorChartYAxis,
                    legend: {
                      x: -10,
                      y: 10,
                      floating: true,
                      verticalAlign: "top",
                      itemStyle: {
                        fontSize: "14px",
                        fontFamily:
                          "'Hind Vadodara', 'Helvetica Neue', sans-serif",
                        fontWeight: "normal",
                        color: "#00000",
                      },
                    },
                    series: analyticsReportingStore.sensorChart,
                  }}
                />
              ) : (
                <>
                  <Center>
                    <Box width={"140px"}>
                      <Lottie animationData={noData} />
                    </Box>
                  </Center>
                  <Center>
                    <Text color={"blue.100"} fontSize="md">
                      {t("noSensorDataFound")}
                    </Text>
                  </Center>
                </>
              )}
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box color="newBlue.500" as="span" flex="1" textAlign="left">
                  {t("global")}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {Object.keys(analyticsReportingStore.globalChart as object)
                .length ? (
                <MeteogramChart
                  timezoneOffset={
                    analyticsReportingStore.globalChart?.data?.plotOptions
                      ?.series.timezoneOffset || 0
                  }
                  options={{
                    time: {
                      useUTC: false,
                    },
                    chart: {
                      zoomType: "xy",
                      height: 250,
                      backgroundColor: "white",
                    },
                    title: {
                      text: "",
                    },
                    subtitle: {
                      text: "",
                    },
                    xAxis: analyticsReportingStore.globalChartXAxis,
                    yAxis: analyticsReportingStore.globalChartYAxis,
                    legend: {
                      x: -10,
                      y: 10,
                      floating: true,
                      verticalAlign: "top",
                      itemStyle: {
                        fontSize: "14px",
                        fontFamily:
                          "'Hind Vadodara', 'Helvetica Neue', sans-serif",
                        fontWeight: "normal",
                        color: "#00000",
                      },
                    },
                    series: analyticsReportingStore.globalChart,
                  }}
                />
              ) : (
                <>
                  <Center>
                    <Box width={"140px"}>
                      <Lottie animationData={noData} />
                    </Box>
                  </Center>
                  <Center>
                    <Text color={"blue.100"} fontSize="md">
                      {t("noGlobalModelDataFound")}
                    </Text>
                  </Center>
                </>
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </Box>
  );
};
export default observer(EventHistory);
