import React, { useState, useEffect, useRef, LegacyRef } from "react";
import { Formik } from "formik";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
  Checkbox,
  Select,
  Textarea,
  ModalCloseButton,
  Flex,
  Box,
  useToast,
  Text,
  FormErrorMessage,
  Tooltip,
  Icon,
  Input,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Loading from "../../../components/Loading/Loading";
import { observer } from "mobx-react";
import { IStationRegion } from "../../../types/IStationRegion";
import DatePicker from "react-datepicker";
import TodayIcon from "@material-ui/icons/Today";
import pt from "date-fns/locale/pt-BR";
import en from "date-fns/locale/en-GB";
import { DateTime } from "luxon";
import { useStores } from "../../../stores/setup/use-store";
import { NumberInput } from "../../../components/NumberInput/NumberInput";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import "react-datepicker/dist/react-datepicker.css";
import { OccurrenceEnvironmentalType } from "../../../enum/OccurrenceEnvironmentalType";
import { StoreState } from "../../../enum/StoreState";
import { IOccurrence } from "../../../types/IOccurrence";
import { validateLatitude } from "../../../util/validateLatitude/validateLatitude";

interface ModalBodyRefType {
  scrollTo(options?: ScrollToOptions): void;
  scrollHeight: number;
  scrollIntoView: any;
  scrollTop: number;
}
export interface OccurrenceFormValues {
  description: string;
  allowNotification: boolean;
  forecastCorrect?: "yes" | "no";
  stationRegion: string;
  latitude?: number;
  longitude?: number;
  startDate?: Date;
  endDate?: Date;
  finalDate?: number;
  startHour?: string;
  endHour?: string;
  wind: boolean;
  current: boolean;
  wave: boolean;
  rain: boolean;
  fog: boolean;
  waterLevel: boolean;
  temperature: boolean;
}

interface OccurrenceModalProps {}

const OccurenceFormModal: React.FC<OccurrenceModalProps> = () => {
  const {
    dataStores: { occurrenceStore, authStore },
  } = useStores();
  const toast = useToast();
  const modalBodyRef = useRef<ModalBodyRefType>(null);
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "occurrence",
  });

  const [isPeriod, setIsPeriod] = useState(false);
  const [initialValues, setInitialValues] = useState<OccurrenceFormValues>({
    allowNotification: true,
    description: "",
    forecastCorrect: undefined,
    stationRegion: "",
    wind: false,
    current: false,
    wave: false,
    rain: false,
    fog: false,
    waterLevel: false,
    temperature: false,
  });

  const selectedOccurrence = occurrenceStore.selectedOccurrence;

  useEffect(() => {
    if (selectedOccurrence) {
      const {
        allowNotification,
        description,
        forecastCorrect,
        initialDate,
        stationId,
        regionId,
        finalDate,
        environmentalVariables,
        localLatLong,
      } = selectedOccurrence;

      if (finalDate) {
        setIsPeriod(true);
      }
      let latitude = "";
      let longitude = "";

      let startHour = "";
      let endHour = "";

      const occurrenceEnvironmentalVariables =
        occurrenceStore.getOccurrenceTypeFromEnvironmentalVariables(
          environmentalVariables
        );

      if (localLatLong) {
        [latitude, longitude] = localLatLong.split(";");
      }

      if (initialDate) {
        const startDate = new Date(initialDate);
        startHour = getHoursAndMinutesFromDate(startDate);
      }

      if (finalDate) {
        const endDate = new Date(finalDate);
        endHour = getHoursAndMinutesFromDate(endDate);
      }

      setInitialValues({
        allowNotification,
        description,
        forecastCorrect: forecastCorrect ? "yes" : "no",
        startDate: new Date(initialDate),
        endDate: finalDate ? new Date(finalDate) : undefined,
        startHour,
        endHour,
        stationRegion: `${stationId}-${regionId}`,
        wind: findEnvironmentalVariable(
          occurrenceEnvironmentalVariables,
          OccurrenceEnvironmentalType.WIND
        ),
        current: findEnvironmentalVariable(
          occurrenceEnvironmentalVariables,
          OccurrenceEnvironmentalType.CURRENT
        ),
        wave: findEnvironmentalVariable(
          occurrenceEnvironmentalVariables,
          OccurrenceEnvironmentalType.WAVE
        ),
        rain: findEnvironmentalVariable(
          occurrenceEnvironmentalVariables,
          OccurrenceEnvironmentalType.RAIN
        ),
        fog: findEnvironmentalVariable(
          occurrenceEnvironmentalVariables,
          OccurrenceEnvironmentalType.FOG
        ),
        waterLevel: findEnvironmentalVariable(
          occurrenceEnvironmentalVariables,
          OccurrenceEnvironmentalType.WATER_LEVEL
        ),
        temperature: findEnvironmentalVariable(
          occurrenceEnvironmentalVariables,
          OccurrenceEnvironmentalType.TEMPERATURE
        ),
        latitude: latitude ? parseFloat(latitude) : undefined,
        longitude: longitude ? parseFloat(longitude) : undefined,
      });
    }
  }, [occurrenceStore, selectedOccurrence]);

  const resetInitialValues = () => {
    setInitialValues({
      allowNotification: true,
      description: "",
      forecastCorrect: undefined,
      stationRegion: "",
      wind: false,
      current: false,
      wave: false,
      rain: false,
      fog: false,
      waterLevel: false,
      temperature: false,
      endHour: "",
      startHour: "",
    });
    setIsPeriod(false);
  };
  const findEnvironmentalVariable = (
    variables: string[],
    type: OccurrenceEnvironmentalType
  ) => variables.includes(type);

  const onSubitmForm = (values: OccurrenceFormValues) => {
    const occurrence = occurrenceStore.processOccurenceFormValues(values);

    if (occurrence.initialDate && occurrence.finalDate) {
      const initialDate = new Date(occurrence.initialDate);
      const finalDate = new Date(occurrence.finalDate);

      if (initialDate > finalDate) {
        toast({
          title: t("startDateMustBeLessThanEndDate"),
          status: "error",
          position: "top",
          isClosable: true,
        });
        return;
      }
    }

    if (selectedOccurrence) {
      const editOccurrence = { ...occurrence, id: selectedOccurrence.id };
      occurrenceStore
        .updateOccurrence(editOccurrence as IOccurrence)
        .then((response) => {
          if (response) {
            toast({
              title: t("successEditOccurrence"),
              status: "success",
              position: "bottom-right",
              isClosable: true,
            });
            occurrenceStore.setSelectedOccurrence(null);
            occurrenceStore.setIsOccurrenceModalOpen(false);
          } else {
            toast({
              title: t("errorEditOccurrence"),
              status: "error",
              position: "bottom-right",
              isClosable: true,
            });
          }
        })
        .catch(() => {
          toast({
            title: t("errorEditOccurrence"),
            status: "error",
            position: "bottom-right",
            isClosable: true,
          });
        });
    } else {
      occurrenceStore
        .addOccurrence(occurrence as IOccurrence)
        .then((response) => {
          if (response) {
            toast({
              title: t("successReport"),
              status: "success",
              position: "bottom-right",
              isClosable: true,
            });
            occurrenceStore.setSelectedOccurrence(null);
            occurrenceStore.setIsOccurrenceModalOpen(false);
          } else {
            toast({
              title: t("errorReport"),
              status: "error",
              position: "bottom-right",
              isClosable: true,
            });
          }
        })
        .catch(() => {
          toast({
            title: t("errorReport"),
            status: "error",
            position: "bottom-right",
            isClosable: true,
          });
        });
    }
    if (occurrenceStore.state === StoreState.DONE) {
      resetInitialValues();
    }
  };

  const validateForm = (values: OccurrenceFormValues) => {
    let errors = {} as OccurrenceFormValues;
    const {
      description,
      latitude,
      longitude,
      stationRegion,
      wind,
      wave,
      waterLevel,
      rain,
      fog,
      current,
      temperature,
      startDate,
      endDate,
      startHour,
      endHour,
      forecastCorrect,
    } = values;
    if (!description) {
      errors.description = "Description is required!";
    }
    if (!stationRegion) {
      errors.stationRegion = "Statation is required!";
    }
    if (latitude && !longitude) {
      errors.longitude = 1;
    }
    if (!latitude && longitude) {
      errors.latitude = 1;
    }
    if (!startDate) {
      errors.startDate = new Date();
    }
    if (isPeriod && !endDate) {
      errors.endDate = new Date();
    }
    if (!startHour) {
      errors.startHour = t("startHourIsRequired") as string;
    }
    if (isPeriod && !endHour) {
      errors.endHour = t("endHourIsRequired") as string;
    }
    if (!forecastCorrect) {
      if (modalBodyRef.current) {
        modalBodyRef.current.scrollTo({
          top: modalBodyRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
      errors.forecastCorrect = "yes";
    }
    const hasVariables = [
      wave,
      wind,
      waterLevel,
      rain,
      fog,
      temperature,
      current,
    ].some(Boolean);
    if (!hasVariables) {
      errors.wind = true;
    }
    return errors;
  };

  const getHoursAndMinutesFromDate = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  //@ts-ignore
  const CustomDatePickerButton = React.forwardRef(({ value, onClick }, ref) => (
    <Button
      //@ts-ignore
      ref={ref}
      className="CustomDatePickerButton"
      onClick={onClick}
      size="small"
      p={2}
      backgroundColor="white"
      color={value ? "rgba(30, 82, 151, 0.8)" : "#BCBCBC"}
      rightIcon={<TodayIcon style={{ marginLeft: "6px" }} />}
      fontWeight="light"
    >
      {value ? value : t("selectDate")}
    </Button>
  ));

  return (
    <Modal
      key={`id-${occurrenceStore.isOccurrenceModalOpen}`}
      closeOnOverlayClick={false}
      isOpen={occurrenceStore.isOccurrenceModalOpen}
      onClose={() => {
        occurrenceStore.setIsOccurrenceModalOpen(false);
        occurrenceStore.setSelectedOccurrence(null);
        resetInitialValues();
      }}
      size={"4xl"}
    >
      <ModalOverlay />
      <ModalContent bg={"#f0f1f2"}>
        <ModalHeader color="newBlue.500">{t("reportOccurrence")}</ModalHeader>
        <ModalCloseButton color={"black"} />

        {occurrenceStore.state === StoreState.PEDDING && (
          <Loading styleSpinner={{ marginLeft: 0 }} />
        )}

        <Formik<OccurrenceFormValues>
          initialValues={initialValues}
          onSubmit={onSubitmForm}
          key={`occurrence-form-${occurrenceStore.stations?.length}`}
          validate={validateForm}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={false}
        >
          {({
            handleChange,
            getFieldProps,
            submitForm,
            values,
            setFieldValue,
            errors,
            setFieldError,
          }) => (
            <>
              <ModalBody
                pb={6}
                px={20}
                maxH="72.5vh"
                overflowY="auto"
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "12px",
                    borderRadius: "8px",
                    backgroundColor: `rgba(40, 103, 149, 0.3)`,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "8px",
                    backgroundColor: `rgba(40, 103, 149, 0.6)`,
                  },
                }}
                ref={modalBodyRef as LegacyRef<HTMLDivElement>}
                key={`id-${initialValues}`}
              >
                <Text mb="1rem" color="gray.500" fontSize={"md"}>
                  {t("reportOccurrenceDetail")}
                </Text>

                <FormControl as="fieldset" color="newBlue.500" isRequired>
                  <FormLabel>{t("ocurrencePeriod")}</FormLabel>
                  <Box mb={4}>
                    <Button
                      onClick={() => {
                        setIsPeriod(false);
                        setFieldValue("endDate", undefined);
                        setFieldError("endDate", undefined);
                        setFieldValue("endHour", undefined);
                        setFieldError("endHour", undefined);
                      }}
                      borderRadius="8px 0 0 8px"
                      size="sm"
                      bg={isPeriod ? "white" : "rgba(49, 154, 221, 0.1)"}
                      color="newBlue.500"
                      fontSize={"14"}
                      fontWeight={"normal"}
                      _hover={{ backgroundColor: "" }}
                      _active={{ backgroundColor: "" }}
                      disabled={authStore.readOnlyUser}
                    >
                      {t("singleDate") as string}
                    </Button>
                    <Button
                      onClick={() => {
                        setIsPeriod(true);
                      }}
                      borderRadius="0 8px 8px 0"
                      size="sm"
                      bg={isPeriod ? "rgba(49, 154, 221, 0.1)" : "white"}
                      color="newBlue.500"
                      fontSize={"14"}
                      fontWeight={"normal"}
                      _hover={{ backgroundColor: "" }}
                      _active={{ backgroundColor: "" }}
                      disabled={authStore.readOnlyUser}
                    >
                      {t("period") as string}
                    </Button>
                  </Box>
                </FormControl>
                <Flex gap={4}>
                  <FormControl isInvalid={!!errors.startDate} w={"auto"}>
                    <DatePicker
                      selected={values.startDate}
                      onChange={(date) => {
                        setFieldValue("startDate", date);
                      }}
                      popperPlacement={"right-start"}
                      maxDate={DateTime.local().toJSDate()}
                      timeIntervals={15}
                      customInput={<CustomDatePickerButton />}
                      dateFormat="MMMM d, yyyy"
                      locale={i18n.language === "en" ? en : pt}
                      showYearDropdown
                      scrollableYearDropdown
                      disabled={authStore.readOnlyUser}
                    />
                    {<FormErrorMessage>{t("dateRequired")}</FormErrorMessage>}
                  </FormControl>
                  <FormControl isInvalid={!!errors.startHour} w={"auto"} mr={4}>
                    <Input
                      placeholder="Select Time"
                      size="md"
                      type="time"
                      bg="white"
                      color="newBlue.500"
                      {...getFieldProps("startHour")}
                      disabled={authStore.readOnlyUser}
                    />
                    <FormErrorMessage>{errors.startHour}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.endDate} w={"auto"}>
                    {isPeriod && (
                      <DatePicker
                        selected={values.endDate}
                        onChange={(date) => {
                          setFieldValue("endDate", date);
                        }}
                        popperPlacement={"right-start"}
                        maxDate={DateTime.local().toJSDate()}
                        minDate={values.startDate}
                        timeIntervals={15}
                        customInput={<CustomDatePickerButton />}
                        dateFormat="MMMM d, yyyy"
                        locale={i18n.language === "en" ? en : pt}
                        showYearDropdown
                        scrollableYearDropdown
                        disabled={authStore.readOnlyUser}
                      />
                    )}
                    {<FormErrorMessage>{t("dateRequired")}</FormErrorMessage>}
                  </FormControl>
                  <FormControl isInvalid={!!errors.endHour} w={"auto"}>
                    {isPeriod && (
                      <Input
                        placeholder="Select Time"
                        size="md"
                        type="time"
                        bg="white"
                        color="newBlue.500"
                        {...getFieldProps("endHour")}
                        disabled={authStore.readOnlyUser}
                      />
                    )}
                    <FormErrorMessage>{errors.endHour}</FormErrorMessage>
                  </FormControl>
                </Flex>
                <FormControl
                  isRequired
                  display="flex"
                  flexDir={"column"}
                  alignItems="start"
                  color="newBlue.500"
                  isInvalid={!!errors.description}
                >
                  <FormLabel mt={4} color="newBlue.500">
                    {t("description")}
                  </FormLabel>
                  <Textarea
                    maxLength={5000}
                    style={{
                      backgroundColor: "white",
                      borderColor: "white",
                      color: "rgba(30, 82, 151, 0.8)",
                      fontSize: 14,
                      fontWeight: "normal",
                    }}
                    borderRadius={8}
                    size="sm"
                    _focus={{
                      border: "2px",
                      borderColor: "newBlue.500",
                      boxShadow: "0px 4px 10px rgba(40, 103, 149, 0.1)",
                    }}
                    borderColor="gray.300"
                    {...getFieldProps("description")}
                    placeholder={t("strongWinds") as string}
                    disabled={authStore.readOnlyUser}
                  />
                  <FormErrorMessage>
                    {t("descriptionRequired")}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  display="flex"
                  alignItems="start"
                  flexDir={"column"}
                  color="rgba(30, 82, 151, 0.8)"
                  isInvalid={!!errors.stationRegion}
                  isRequired
                >
                  <FormLabel mt={4} color="newBlue.500">
                    {t("selectStation")}
                  </FormLabel>
                  <Select
                    id="Location"
                    placeholder={t("selectStation") as string}
                    {...getFieldProps("stationRegion")}
                    disabled={authStore.readOnlyUser}
                    bg="white"
                  >
                    {occurrenceStore.stations?.map((value: IStationRegion) => (
                      <option value={value.id} key={value.id}>
                        {value.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{t("stationRequired")}</FormErrorMessage>
                </FormControl>
                <FormControl
                  color="newBlue.500"
                  w={"220"}
                  isInvalid={!!errors.latitude}
                >
                  <FormLabel mt={4} color="newBlue.500">
                    {t("exactLocation") as string}
                  </FormLabel>
                </FormControl>
                <Flex>
                  <FormControl
                    color="newBlue.500"
                    w={"220"}
                    isInvalid={!!errors.latitude}
                  >
                    <FormLabel fontWeight={"light"} color="newBlue.500">
                      {t("latitude")}
                    </FormLabel>
                    <NumberInput
                      autoFocus
                      placeholder={"-13.2345"}
                      maxLength={10}
                      name="latitude"
                      value={values.latitude}
                      onChange={(e) => {
                        const lat = e.target.value;
                        if (!lat || validateLatitude(lat)) {
                          setFieldValue("latitude", lat);
                        }
                      }}
                      disabled={authStore.readOnlyUser}
                    />
                    <FormErrorMessage>{t("latitudeRequired")}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    color="newBlue.500"
                    w={"220"}
                    ml={8}
                    isInvalid={!!errors.longitude}
                  >
                    <FormLabel fontWeight={"light"} color="newBlue.500">
                      {t("longitude")}
                    </FormLabel>
                    <NumberInput
                      autoFocus
                      placeholder={"-38.5432"}
                      maxLength={10}
                      name="longitude"
                      value={values.longitude}
                      onChange={(e) => {
                        const lon = e.target.value;
                        if (!lon || validateLatitude(lon)) {
                          setFieldValue("longitude", lon);
                        }
                      }}
                      disabled={authStore.readOnlyUser}
                    />
                    <FormErrorMessage>
                      {t("longitudeRequired")}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <FormControl isRequired>
                  <FormLabel mt={4} color="newBlue.500">
                    {t("selectEnvironmentalVariables")}
                  </FormLabel>
                </FormControl>
                <FormControl isInvalid={!!errors.wind}>
                  <Flex bg="white" p={4} gap={4} borderRadius={8}>
                    <Flex direction={"column"} gap={4}>
                      <Checkbox
                        {...getFieldProps("wind")}
                        defaultChecked={initialValues.wind}
                        mr={3}
                        borderColor="gray.400"
                        disabled={authStore.readOnlyUser}
                      >
                        <Tooltip
                          label={t("windInfo")}
                          fontSize="sm"
                          placement="top-start"
                          hasArrow
                        >
                          <Text color="newBlue.500">{t("wind")}</Text>
                        </Tooltip>
                      </Checkbox>
                      <Checkbox
                        {...getFieldProps("current")}
                        defaultChecked={initialValues.current}
                        mr={3}
                        borderColor="gray.400"
                        disabled={authStore.readOnlyUser}
                      >
                        <Tooltip
                          label={t("currentInfo")}
                          fontSize="sm"
                          placement="top-start"
                          hasArrow
                        >
                          <Text color="newBlue.500">{t("current")}</Text>
                        </Tooltip>
                      </Checkbox>
                    </Flex>
                    <Flex direction={"column"} gap={4}>
                      <Checkbox
                        {...getFieldProps("temperature")}
                        defaultChecked={initialValues.temperature}
                        mr={3}
                        borderColor="gray.400"
                        disabled={authStore.readOnlyUser}
                      >
                        <Tooltip
                          label={t("temperatureInfo")}
                          fontSize="sm"
                          placement="top-start"
                          hasArrow
                        >
                          <Text color="newBlue.500">{t("temperature")}</Text>
                        </Tooltip>
                      </Checkbox>

                      <Checkbox
                        {...getFieldProps("rain")}
                        defaultChecked={initialValues.rain}
                        mr={3}
                        borderColor="gray.400"
                        disabled={authStore.readOnlyUser}
                      >
                        <Tooltip
                          label={t("rainInfo")}
                          fontSize="sm"
                          placement="top-start"
                          hasArrow
                        >
                          <Text color="newBlue.500">{t("rain")}</Text>
                        </Tooltip>
                      </Checkbox>
                    </Flex>
                    <Flex direction={"column"} gap={4}>
                      <Checkbox
                        {...getFieldProps("fog")}
                        defaultChecked={initialValues.fog}
                        mr={3}
                        borderColor="gray.400"
                        disabled={authStore.readOnlyUser}
                      >
                        <Tooltip
                          label={t("fogInfo")}
                          fontSize="sm"
                          placement="top-start"
                          hasArrow
                        >
                          <Text color="newBlue.500">{t("fog")}</Text>
                        </Tooltip>
                      </Checkbox>
                      <Checkbox
                        {...getFieldProps("waterLevel")}
                        defaultChecked={initialValues.waterLevel}
                        mr={3}
                        borderColor="gray.400"
                        disabled={authStore.readOnlyUser}
                      >
                        <Tooltip
                          label={t("waterLevelInfo")}
                          fontSize="sm"
                          placement="top-start"
                          hasArrow
                        >
                          <Text color="newBlue.500">{t("waterLevel")}</Text>
                        </Tooltip>
                      </Checkbox>
                    </Flex>
                    <Flex direction={"column"} gap={4}>
                      <Checkbox
                        {...getFieldProps("wave")}
                        defaultChecked={initialValues.wave}
                        mr={3}
                        borderColor="gray.400"
                        disabled={authStore.readOnlyUser}
                      >
                        <Tooltip
                          label={t("waveInfo")}
                          fontSize="sm"
                          placement="top-start"
                          hasArrow
                        >
                          <Text color="newBlue.500">{t("wave")}</Text>
                        </Tooltip>
                      </Checkbox>
                    </Flex>
                  </Flex>
                  <FormErrorMessage>
                    {t("selectAtleastOneVariable")}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  as="fieldset"
                  color="newBlue.500"
                  isRequired
                  isInvalid={!!errors.forecastCorrect}
                >
                  <Flex>
                    <FormLabel mt={4}>{t("forecastHelp")}</FormLabel>
                    <Tooltip
                      label={t("forecastCorrect")}
                      fontSize="sm"
                      hasArrow
                    >
                      <Icon
                        color={"gray.500"}
                        as={InfoOutlinedIcon}
                        pt={1}
                        mt={"14px"}
                      />
                    </Tooltip>
                  </Flex>
                  <RadioGroup
                    {...getFieldProps("forecastCorrect")}
                    isDisabled={authStore.readOnlyUser}
                  >
                    <Stack spacing={4} direction="row">
                      <Radio
                        value={"yes"}
                        isChecked={values.forecastCorrect === "yes"}
                        onChange={handleChange}
                        borderColor="gray.400"
                      >
                        {t("yes") as string}
                      </Radio>
                      <Radio
                        value={"no"}
                        isChecked={values.forecastCorrect === "no"}
                        onChange={handleChange}
                        borderColor="gray.400"
                      >
                        {t("no") as string}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  <FormErrorMessage>{t("selectOption")}</FormErrorMessage>
                </FormControl>

                <FormControl
                  display="flex"
                  alignItems="center"
                  color="newBlue.500"
                  mt={4}
                >
                  <Checkbox
                    {...getFieldProps("allowNotification")}
                    defaultChecked={initialValues.allowNotification}
                    mr={3}
                    borderColor="gray.400"
                    disabled={authStore.readOnlyUser}
                  />
                  <FormLabel mt={2} cursor="pointer">
                    {t("wantToBeNotified")}
                  </FormLabel>
                </FormControl>
              </ModalBody>
              {!authStore.readOnlyUser && (
                <ModalFooter>
                  <Button
                    variant="ghost"
                    color="newBlue.500"
                    onClick={() => {
                      occurrenceStore.setIsOccurrenceModalOpen(false);
                      occurrenceStore.setSelectedOccurrence(null);
                      resetInitialValues();
                    }}
                    mr={3}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    layerStyle="ocean"
                    _hover={{ layerStyle: "ocean" }}
                    onClick={submitForm}
                    type="submit"
                  >
                    {t("save")}
                  </Button>
                </ModalFooter>
              )}
            </>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default observer(OccurenceFormModal);
