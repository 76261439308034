import { makeAutoObservable } from "mobx";
import { getEnv } from "mobx-easy";
import { DBAction } from "../../../enum/DBAction";
import { StoreState } from "../../../enum/StoreState";
import { RootEnv } from "../../setup/create-store";
import {
  InsightFormModalValuesConditions,
  InsightFormModalValuesGroups,
  IOperationRuleLocationItem,
  IOperationRuleLocationItemCondition,
  IOperationRuleLocationItemConditionGroup,
} from "./operationRuleLocationInsightModel";
import i18n from "../../../i18n";
export default class OperationRuleLocationInsightStore {
  insightModalVisible: boolean = false;

  operationRuleLocationItem!: IOperationRuleLocationItem;

  operationRuleLocationItens: IOperationRuleLocationItem[] = [];

  insightFormModalValuesGroups: InsightFormModalValuesGroups[] = [
    {
      conditions: [
        {
          mathOperator: ">",
          environmentalVariable: 7,
          units: "knots",
          value: "",
        },
      ],
    } as unknown as InsightFormModalValuesGroups,
  ];

  operationRuleLocationItemAction: DBAction = DBAction.CREATE;

  state: string = StoreState.DONE;

  messageError: string = "";

  actionCallCreateInsight: boolean = false;

  hasInsights: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  getOperationRuleLocationInsight(id: number) {
    return this.operationRuleLocationItens.find(
      (operationRuleLocationItem) => operationRuleLocationItem.id === id
    ) as IOperationRuleLocationItem;
  }

  setOperationRuleLocationInsightModalVisible(value: boolean) {
    this.insightModalVisible = value;
  }

  setOperationRuleLocationItem(values: IOperationRuleLocationItem) {
    this.operationRuleLocationItem = values;
  }

  setOperationRuleLocationItens(values: IOperationRuleLocationItem[]) {
    this.operationRuleLocationItens = values;
  }

  setOperationRuleLocationItemAction(value: DBAction) {
    this.operationRuleLocationItemAction = value;
  }

  setInsightFormModalValuesGroups(values: InsightFormModalValuesGroups[]) {
    this.insightFormModalValuesGroups = values;
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setMessageError(value: string) {
    this.messageError = value;
  }

  setActionCallCreateInsight() {
    this.actionCallCreateInsight = !this.actionCallCreateInsight;
  }

  setHasInsights(value: boolean) {
    this.hasInsights = value;
  }

  reset() {
    this.setState(StoreState.DONE);
    this.setOperationRuleLocationItens([]);
  }

  async getOperationRuleLocationInsightApi(operationRuleLocationId: number) {
    const { operationRuleLocationInsightService } = getEnv<RootEnv>();
    this.setOperationRuleLocationItens([]);
    this.setState(StoreState.PEDDING);
    try {
      const operationRuleLocationInsight =
        await operationRuleLocationInsightService.getOperationRuleLocationInsight(
          operationRuleLocationId
        );
      if (operationRuleLocationInsight) {
        this.setOperationRuleLocationItens(operationRuleLocationInsight);
        this.setState(StoreState.DONE);
      } else {
        this.setState(StoreState.ERROR);
        this.setMessageError(
          i18n.t(
            "oceanWeatherIntelligence.operationRuleLocationInsight.errorWhileGetDataForInsights"
          )
        );
      }
      if (!this.operationRuleLocationItens?.length) {
        this.setHasInsights(false);
      } else {
        this.setHasInsights(true);
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t(
          "oceanWeatherIntelligence.operationRuleLocationInsight.errorWhileGetDataForInsights"
        )
      );
    }
  }

  async getOperationRuleLocationInsightById(
    operationRuleLocationId: number,
    id: number
  ) {
    const { operationRuleLocationInsightService } = getEnv<RootEnv>();
    const operationRuleLocationInsight =
      await operationRuleLocationInsightService.getOperationRuleLocationInsightById(
        operationRuleLocationId,
        id
      );
    return this.setOperationRuleLocationItem(operationRuleLocationInsight);
  }

  async addOperationRuleLocationInsight() {
    const { operationRuleLocationInsightService } = getEnv<RootEnv>();
    try {
      this.setState(StoreState.PEDDING);
      const operationRuleLocationInsightInserted =
        await operationRuleLocationInsightService.createOperationRuleLocationInsight(
          this.operationRuleLocationItem.name,
          this.operationRuleLocationItem.priorityId,
          this.operationRuleLocationItem.operationRuleLocationId,
          this.operationRuleLocationItem.resume,
          this.operationRuleLocationItem.conditionGroup,
          this.operationRuleLocationItem.restrictionType
        );
      if (operationRuleLocationInsightInserted) {
        this.operationRuleLocationItens.push(
          operationRuleLocationInsightInserted
        );
        this.setState(StoreState.DONE);
      } else {
        this.setState(StoreState.ERROR);
        this.setMessageError(
          i18n.t(
            "oceanWeatherIntelligence.operationRuleLocationInsight.errorWhileAddInsight"
          )
        );
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t(
          "oceanWeatherIntelligence.operationRuleLocationInsight.errorWhileAddInsight"
        )
      );
    }
  }

  async updateOperationRuleLocationInsight() {
    const { operationRuleLocationInsightService } = getEnv<RootEnv>();
    try {
      this.setState(StoreState.PEDDING);
      const insightUpdated =
        await operationRuleLocationInsightService.updateOperationRuleLocationInsight(
          this.operationRuleLocationItem.id,
          this.operationRuleLocationItem.name,
          this.operationRuleLocationItem.priorityId,
          this.operationRuleLocationItem.operationRuleLocationId,
          this.operationRuleLocationItem.resume,
          this.operationRuleLocationItem.conditionGroup,
          this.operationRuleLocationItem.restrictionType
        );
      if (insightUpdated) {
        const operationRuleLocationIndex =
          this.operationRuleLocationItens.indexOf(
            this.getOperationRuleLocationInsight(
              this.operationRuleLocationItem.id
            )
          );
        this.operationRuleLocationItens[operationRuleLocationIndex] = {
          ...insightUpdated,
        };
        this.setState(StoreState.DONE);
      } else {
        this.setState(StoreState.ERROR);
        this.setMessageError(
          i18n.t(
            "oceanWeatherIntelligence.operationRuleLocationInsight.internalErrorToUpdateData"
          )
        );
      }
    } catch (error: any) {
      this.setState(StoreState.ERROR);
      if (error.response?.status === 403) {
        this.setMessageError(i18n.t("youCanOnlyChangeItems"));
      } else {
        this.setMessageError(
          i18n.t(
            "oceanWeatherIntelligence.operationRuleLocationInsight.internalErrorToUpdateData"
          )
        );
      }
    }
  }

  async deleteOperationRuleLocationInsight(id: number): Promise<Boolean> {
    const insightToRemove = this.getOperationRuleLocationInsight(id);

    if (insightToRemove) {
      const { operationRuleLocationInsightService } = getEnv<RootEnv>();
      try {
        this.setState(StoreState.PEDDING);
        const result =
          await operationRuleLocationInsightService.deleteOperationRuleLocationInsight(
            id
          );
        if (result) {
          const index =
            this.operationRuleLocationItens.indexOf(insightToRemove);
          this.operationRuleLocationItens.splice(index, 1);
          this.setState(StoreState.DONE);
          return true;
        } else {
          this.setState(StoreState.ERROR);
          this.setMessageError(
            i18n.t(
              "oceanWeatherIntelligence.operationRuleLocationInsight.errorDeleteInsight"
            )
          );
        }
      } catch (error: any) {
        this.setState(StoreState.ERROR);

        if (error.response?.status === 403) {
          this.setMessageError(i18n.t("youCanOnlyChangeItems"));
        } else {
          this.setMessageError(
            i18n.t(
              "oceanWeatherIntelligence.operationRuleLocationInsight.errorDeleteInsight"
            )
          );
        }
      }
    }
    return false;
  }

  getUnitsMeasurement = (environmentalVariable: number) => {
    switch (environmentalVariable) {
      case 7:
        return "Knots";

      case 8:
      case 5:
      case 3:
      case 63:
      case 85:
      case 86:
      case 90:
        return "Degrees";

      case 21:
      case 2:
      case 62:
      case 69:
      case 71:
      case 89:
      case 91:
        return "Knots";

      case 4:
      case 1:
      case 11:
      case 80:
      case 77:
      case 36:
        return "Meters";

      case 6:
      case 83:
      case 84:
        return "Seconds";

      case 9:
      case 94:
      case 95:
        return "mm/h";

      case 61:
      case 88:
      case 96:
        return "%";

      case 10:
        return "hPa";

      case 87:
        return "kW/m";

      case 60:
      case 23:
        return "°C";

      case 93:
        return "flash/h";

      default:
        return "";
    }
  };

  updateConditions = (
    fromConditions: IOperationRuleLocationItemCondition[],
    toConditions: IOperationRuleLocationItemCondition[]
  ): IOperationRuleLocationItemCondition[] => {
    const itemFrom = fromConditions.length;
    const itemTo = toConditions.length;
    for (let i = itemFrom; i < itemTo; i++) {
      toConditions[i].action = DBAction.CREATE;
    }
    for (let i = itemTo; i < itemFrom; i++) {
      fromConditions[i].action = DBAction.DELETE;
      toConditions.push(fromConditions[i]);
    }
    for (let i = 0; i < itemTo && i < itemFrom; i++) {
      toConditions[i].id = fromConditions[i].id;
    }
    return toConditions;
  };

  getUpdateOperationRuleLocationItemConditionGroup = (
    fromOperationRuleLocation: IOperationRuleLocationItemConditionGroup,
    toOperationRuleLocation: IOperationRuleLocationItemConditionGroup
  ): IOperationRuleLocationItemConditionGroup => {
    let itemFrom = fromOperationRuleLocation;
    let itemTo = toOperationRuleLocation;

    while (itemTo && itemFrom && itemTo.id !== undefined) {
      itemTo.conditions = this.updateConditions(
        itemFrom.conditions,
        itemTo.conditions
      );
      itemTo = itemTo.operationRuleLocationItemConditionGroupParent!;
      itemFrom = itemFrom.operationRuleLocationItemConditionGroupParent!;
    }

    if (itemTo && !itemFrom) {
      do {
        itemTo.action = DBAction.CREATE;
        for (let condition of itemTo.conditions) {
          condition.action = DBAction.CREATE;
        }
        itemTo = itemTo.operationRuleLocationItemConditionGroupParent!;
      } while (itemTo);
    }

    if (itemFrom && !itemTo) {
      const itemFromBegin = itemFrom;
      do {
        itemFrom.action = DBAction.DELETE;
        for (let condition of itemFrom.conditions) {
          condition.action = DBAction.DELETE;
        }
        itemFrom = itemFrom.operationRuleLocationItemConditionGroupParent!;
      } while (itemFrom);
      toOperationRuleLocation = this.addGroupConditionParent(
        toOperationRuleLocation,
        itemFromBegin
      );
    }

    if (itemTo && itemFrom) {
      while (itemTo) {
        if (itemFrom) {
          itemTo.id = itemFrom.id;
          itemTo.operationRuleLocationItemId =
            itemFrom.operationRuleLocationItemId;
          itemTo.conditions = this.updateConditions(
            itemFrom.conditions,
            itemTo.conditions
          );
        } else {
          itemTo.action = DBAction.CREATE;
          for (let condition of itemTo.conditions) {
            condition.action = DBAction.CREATE;
          }
        }

        itemTo = itemTo.operationRuleLocationItemConditionGroupParent!;
        if (itemFrom) {
          itemFrom = itemFrom.operationRuleLocationItemConditionGroupParent!;
        }
      }
      const itemFromBegin = itemFrom;
      while (itemFrom) {
        itemFrom.action = DBAction.DELETE;
        for (let condition of itemFrom.conditions) {
          condition.action = DBAction.DELETE;
        }
        itemFrom = itemFrom.operationRuleLocationItemConditionGroupParent!;
      }
      toOperationRuleLocation = this.addGroupConditionParent(
        toOperationRuleLocation,
        itemFromBegin
      );
    }
    return toOperationRuleLocation;
  };

  processGroupConditionForBackend = (
    groups: InsightFormModalValuesGroups[],
    action: DBAction
  ): IOperationRuleLocationItemConditionGroup => {
    let conditionsGroup;
    //@ts-ignore
    for (const [groupIndex, group] of groups.entries()) {
      const conditions = group.conditions;
      let conditionsForSave: IOperationRuleLocationItemCondition[] = [];
      let logicalOperatorConditions: string = "";

      for (const condition of conditions) {
        logicalOperatorConditions = condition.LogicalOperator || "and";
        conditionsForSave.push({
          id: action === DBAction.UPDATE ? condition.id! : 0,
          environmental_variable: condition.environmentalVariable.toString(),
          environmental_variable_id: condition.environmentalVariable,
          mathematicalOperator: condition.mathOperator,
          value: Number(condition.value ?? 0),
          action: action,
        });
      }
      let logicalOperator = "";
      if (groups[groupIndex + 1]) {
        logicalOperator = groups[groupIndex + 1].LogicalOperatorGroups || "";
      }
      if (!conditionsGroup) {
        conditionsGroup = {
          id: action === DBAction.UPDATE ? group.id! : 0,
          logicalOperator: logicalOperator,
          logicalOperatorConditions: logicalOperatorConditions,
          operationRuleLocationItemId:
            action === DBAction.UPDATE ? group.operationRuleLocationItemId! : 0,
          conditions: conditionsForSave,
          action: action,
        };
      } else {
        conditionsGroup = this.addGroupConditionParent(conditionsGroup, {
          id: action === DBAction.UPDATE ? group.id! : 0,
          logicalOperator: logicalOperator,
          logicalOperatorConditions: logicalOperatorConditions,
          operationRuleLocationItemId:
            action === DBAction.UPDATE ? group.operationRuleLocationItemId! : 0,
          conditions: conditionsForSave,
          action: action,
        });
      }
    }
    return conditionsGroup as IOperationRuleLocationItemConditionGroup;
  };

  addGroupConditionParent = (
    conditionsGroup: IOperationRuleLocationItemConditionGroup,
    value: IOperationRuleLocationItemConditionGroup
  ): IOperationRuleLocationItemConditionGroup => {
    if (
      !conditionsGroup.hasOwnProperty(
        "operationRuleLocationItemConditionGroupParent"
      )
    ) {
      conditionsGroup["operationRuleLocationItemConditionGroupParent"] = value;
    } else {
      this.addGroupConditionParent(
        conditionsGroup.operationRuleLocationItemConditionGroupParent!,
        value
      );
    }
    return conditionsGroup;
  };

  processGroupConditionForFormModal = (
    group: IOperationRuleLocationItemConditionGroup,
    groupsForModal: InsightFormModalValuesGroups[],
    groupOperadorChild?: string
  ): InsightFormModalValuesGroups[] => {
    const conditions = group.conditions;
    let conditionsTemp: InsightFormModalValuesConditions[] = [];
    for (const condition of conditions) {
      conditionsTemp.push({
        id: condition.id,
        environmentalVariable: condition.environmental_variable_id,
        units: this.getUnitsMeasurement(condition.environmental_variable_id),
        LogicalOperator: group.logicalOperatorConditions,
        mathOperator: condition.mathematicalOperator,
        value: condition.value.toString(),
      });
    }

    groupsForModal.push({
      id: group.id,
      LogicalOperatorGroups: groupOperadorChild,
      operationRuleLocationItemId: group.operationRuleLocationItemId,
      conditions: conditionsTemp,
    });
    if (group.operationRuleLocationItemConditionGroupParent) {
      return this.processGroupConditionForFormModal(
        group.operationRuleLocationItemConditionGroupParent,
        groupsForModal,
        group.logicalOperator
      );
    }
    return groupsForModal;
  };

  groupConditionsValidation(groups: InsightFormModalValuesGroups[]): boolean {
    for (const value of groups) {
      if (value.conditions.length === 0) {
        return false;
      }
    }
    return true;
  }
}
