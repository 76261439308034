import { makeAutoObservable } from "mobx";
import { getEnv } from "mobx-easy";
import { StoreState } from "../../../enum/StoreState";
import { RootEnv } from "../../setup/create-store";
import { EnvironmentalType } from "../../../enum/EnvironmentalType";
import { IStation } from "../oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import { IStationRegion } from "../../../types/IStationRegion";
import { IShareServicesParams } from "../../../services/ShareService";

export default class ShareStore {
  state: string = StoreState.DONE;
  stations: IStationRegion[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setStations(value: IStationRegion[]) {
    this.stations = value;
  }

  reset() {
    this.state = StoreState.DONE;
    this.stations = [];
  }

  async getStationsAPI(
    environmental_type: EnvironmentalType
  ): Promise<IStationRegion[]> {
    this.setState(StoreState.PEDDING);
    try {
      const { atmOceanService } = getEnv<RootEnv>();

      const stationsResult: IStation[] =
        await atmOceanService.getStationsForecastChart(environmental_type);

      const stationsRegion = stationsResult.map((item) => {
        const id = `${item.station_id}-${item.region_id}`;
        const name = `${item.station_name} - ${item.macro_region_name}`;

        return { id, name };
      });

      this.setStations(stationsRegion);

      this.setState(StoreState.DONE);
      return stationsRegion;
    } catch (error) {
      console.error(error);
      this.setState(StoreState.DONE);
      throw error;
    }
  }

  async shareForecastAPI(
    form: IShareServicesParams
  ): Promise<{ success: boolean }> {
    this.setState(StoreState.PEDDING);
    try {
      const { shareService } = getEnv<RootEnv>();
      const result = await shareService.shareForecast(form);

      this.setState(StoreState.DONE);
      return result;
    } catch (error) {
      console.error(error);
      this.setState(StoreState.DONE);
      throw error;
    }
  }
}
