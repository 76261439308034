import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React, { FC } from "react";
import NotificationsStore from "../../stores/data/notifications/notificationsStore";
import NotificationDropdown from "../NotificationDropdown";

import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import flagBrazil from "../../assets/flag-brazil.svg";
import flagUk from "../../assets/flag-uk.svg";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import OccurrenceStore from "../../stores/data/occurrence/occurrenceStore";
import { UserExperience } from "../../enum/UserExperience";
import AuthStore from "stores/data/Auth/AuthStore";
interface Props {
  handleLogout: () => void;
  notificationsStore: NotificationsStore;
  name: string;
  handleChangeLanguage: (language: string) => void;
  occurrenceStore: OccurrenceStore;
  authStore: AuthStore;
}

const Header: FC<Props> = ({
  notificationsStore,
  handleLogout,
  name,
  handleChangeLanguage,
  occurrenceStore,
  authStore,
}: Props) => {
  let history = useHistory();
  const { i18n } = useTranslation();

  return (
    <Flex
      as="header"
      alignItems="center"
      p={1}
      color="blue.900"
      bg="white"
      boxShadow={"0px 4px 10px rgba(30, 24, 83, 0.13)"}
    >
      {!authStore.readOnlyUser && (
        <Button
          onClick={() => {
            occurrenceStore.loadStations();
            occurrenceStore.addLogUserExperienceAPI(
              UserExperience.CLICK_IMPORT_OCCURRENCE_HEADER_BUTTON
            );
            history.push("/occurrences?open=true");
            occurrenceStore.setIsImportOccurrenceModalOpen(true);
          }}
          transition="all 0.2s ease-out"
          variant="ghost"
          size="sm"
          leftIcon={<FeedbackOutlinedIcon />}
          color="newBlue.500"
          fontWeight={"light"}
          _hover={{
            backgroundColor: "rgba(40, 103, 149, 0.1)",
          }}
        >
          {i18n.t("navBar.importOccurrence")}
        </Button>
      )}

      <Spacer />
      <Flex justifyContent="flex-end">
        <Center
          pl={2}
          borderRadius={8}
          _hover={{ bg: "gray.100" }}
          _focus={{ boxShadow: "outline" }}
          border="none"
        >
          <Box>
            {i18n.resolvedLanguage === "pt-BR" ? (
              <img src={flagBrazil} alt="Brazil" height="23px" width="23px" />
            ) : (
              <img src={flagUk} alt="Brazil" height="23px" width="23px" />
            )}
          </Box>

          <Select
            size="sm"
            value={i18n.resolvedLanguage}
            onChange={(e) => handleChangeLanguage(e.target.value)}
            _focus={{ boxShadow: "outline" }}
            border="none"
          >
            <option style={{ color: "blue.100" }} value="pt-BR">
              {i18n.t("navBar.portuguese")}
            </option>
            <option style={{ color: "black" }} value="en">
              {i18n.t("navBar.english")}
            </option>
          </Select>
        </Center>
        <Box>
          <NotificationDropdown
            notifications={notificationsStore.notifications}
            countNoReadNotifications={
              notificationsStore.countNoReadNotifications
            }
          />
        </Box>
        <Menu>
          <MenuButton
            _hover={{ bg: "gray.100" }}
            _focus={{ boxShadow: "outline" }}
            borderRadius={8}
          >
            <Center
              mr={8}
              _hover={{ bg: "gray.400" }}
              _expanded={{ bg: "blue.400" }}
              paddingX={2}
              paddingY={1}
            >
              <Avatar size="xs" />
              <Center ml={2} gap={2}>
                <Text>{name}</Text>
                <Box
                  w={0}
                  h={0}
                  borderLeft="5px solid transparent"
                  borderRight="5px solid transparent"
                  borderTop="5px solid"
                ></Box>
              </Center>
            </Center>
          </MenuButton>
          <MenuList zIndex={999999999999}>
            <Link to="/admin/my-data">
              <MenuItem as="div">
                <Text color="blue.100">{i18n.t("navBar.myData")}</Text>
              </MenuItem>
            </Link>
            <Link to="/admin/change-password">
              <MenuItem as="div">
                <Text color="blue.100">{i18n.t("navBar.changePassword")}</Text>
              </MenuItem>
            </Link>
            <MenuItem as="button" onClick={handleLogout}>
              <Text color="blue.100">{i18n.t("navBar.logout")}</Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default Header;
