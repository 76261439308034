import React from "react";
import DirectionPointer from "./DirectionPointer";

interface CompassProps {
  maxDegrees?: string | undefined;
  maxPointerText?: string | undefined;
  maxReverse?: boolean;
  averageDegrees?: string | undefined;
  averagePointerText?: string | undefined;
  averageReverse?: boolean;
}

const Compass: React.FC<CompassProps> = ({
  maxDegrees,
  maxPointerText,
  maxReverse,
  averageDegrees,
  averagePointerText,
  averageReverse,
}) => {
  const max = maxDegrees ? parseInt(maxDegrees) - 90 : 0;
  const average = averageDegrees ? parseInt(averageDegrees) - 90 : 0;

  const difference = Math.abs(max - average);

  return (
    <>
      {maxPointerText && (
        <DirectionPointer
          angle={max}
          pointerColor="#286795"
          pointerText={maxPointerText ? maxPointerText : ""}
          width={"10rem"}
          reverse={maxReverse}
        />
      )}
      {averageDegrees && (
        <DirectionPointer
          angle={average}
          pointerColor="#9D53F2"
          pointerText={averagePointerText ? averagePointerText : ""}
          width={difference > 9 ? "10rem" : "5rem"}
          reverse={averageReverse}
        />
      )}
    </>
  );
};

export default Compass;
