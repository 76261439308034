import React from "react";
import iconi4cast from "../../../assets/logo_login.svg";
import iconi4sea from "../../../assets/marca.svg";
import iconIlustration from "../../../assets/loginIlustration.svg";
import {
  SectionLoginHeader,
  AsideLogin,
  SectionLogin,
  WrapperLayoutLogin,
  SectionLoginFooter,
  SectionLoginMain,
  RegisterText,
  BrandText,
} from "../styles";

export interface LoginLayoutProps {
  children?: React.ReactNode;
}

export const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => {
  return (
    <>
      <WrapperLayoutLogin>
        <SectionLogin>
          <SectionLoginHeader>
            <img src={iconi4cast} alt="logo i4cast" height="90%" />
            {false && (
              <RegisterText>
                Você não tem uma conta?{" "}
                <a
                  href="https://i4sea.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cadastre-se
                </a>
              </RegisterText>
            )}
          </SectionLoginHeader>
          <SectionLoginMain>{children}</SectionLoginMain>
          <SectionLoginFooter>
            <img src={iconi4sea} alt="marca i4sea" height="24px" width="71px" />
            <BrandText>i4cast® is a registered trademark of i4sea.</BrandText>
          </SectionLoginFooter>
        </SectionLogin>
        <AsideLogin>
          <img
            src={iconIlustration}
            alt="ilustração login"
            height="60%"
            width="60%"
          />
        </AsideLogin>
      </WrapperLayoutLogin>
    </>
  );
};
