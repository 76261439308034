import styled from "styled-components";

export const WrapperLoginForm = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  min-width: 240px;
  width: 25vw;
  gap: 2.3vh;

  @media (max-width: 1024px) {
    width: 80vw;
  }
`;

export const ContainerLogin = styled.section`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.3vh;
  max-width: 500px;
  min-width: 240px;
`;

export const ContainerLoginTitle = styled.section`
  padding: 0;
  margin: 0;

  box-sizing: border-box;
  width: 100%;

  max-width: 300px;
  min-width: 240px;
`;

export const ContainerNewPasswordTitle = styled.section`
  padding: 0;
  margin: 0;

  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  min-width: 240px;
`;

export const TitleText = styled.text`
  font-family: "Akko Pro", "Sans-serif";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 64px;

  /* identical to box height */

  color: #1da0db;

  text-align: left;

  @media (max-width: 1024px) {
    font-size: 48px;
    line-height: 56px;
  }
`;

export const NewPasswordTitleText = styled.text`
  margin-top: 2.7vmin;
  font-family: "Akko Pro", "Sans-serif";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  /* identical to box height */

  color: #1da0db;

  text-align: center;

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 24px;
  }
`;

export const NewPasswordText = styled.text`
  margin-top: 2.7vh;
  margin-bottom: 0.3vh;
  font-family: "Akko Pro", "Sans-serif";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */

  text-align: center;

  color: rgba(30, 24, 83, 0.64);
`;

export const FormLogin = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  min-width: 240px;

  gap: 2.3vh;
`;

export const InputLogin = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 7.5vh;
  background: rgba(30, 24, 83, 0.04);
  margin-bottom: 1.5vh;
  border-radius: 8px;
  border: none;
  margin-top: 0.8vh;

  color: rgba(30, 24, 83, 1);
  font-family: "Akko Pro", "Sans-serif";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  max-width: 500px;

  min-height: 30px;
  max-height: 80px;
  padding-left: 1.5vmin;
  :focus {
    background: #ffffff;
    border: 1px solid #1da0db;
    box-shadow: 0px 0px 8px rgba(29, 160, 219, 0.32);
    outline: none;
  }

  ::placeholder {
    color: rgba(30, 24, 83, 0.24);

    font-family: "Akko Pro", "Sans-serif";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
`;

export const ButtonLogin = styled.button`
  width: 100%;
  height: 7.5vh;
  background: #1da0db;
  border-radius: 8px;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 1.2vh;
  max-width: 500px;
  min-width: 240px;

  min-height: 30px;
  max-height: 80px;
  border: none;
  cursor: pointer;

  font-family: "Akko Pro", "Sans-serif";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.8vh;

  :hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      #1da0db;
  }

  :disabled {
    opacity: 0.3;
  }
`;

export const InputLabelLogin = styled.label`
  display: flex;
  flex-direction: column;

  width: 100%;

  font-family: "Akko Pro", "Sans-serif";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6vh;
  /* identical to box height */
  color: #1e1853;
`;

export const InputLoginLabelCheckbox = styled.label`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.8vh;

  font-family: "Akko Pro", "Sans-serif";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.8vh;
  color: rgba(30, 24, 83, 0.64);
`;

export const InputLoginCheckbox = styled.input`
  color: #1da0db;
  mix-blend-mode: normal;
  max-width: 500px;
`;

export const DivErrorLogin = styled.div`
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 7.5vh;
  width: 100%;

  background: rgba(244, 50, 8, 0.16);
  border: 1px solid #f43208;
  border-radius: 8px;
  max-width: 500px;
  min-height: 30px;
  min-width: 240px;
  max-height: 80px;
`;

export const DivErrorLoginText = styled.text`
  hight: 90%;
  width: 90%;
  font-family: "Akko Pro", "Sans-serif";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  text-align: center;
  color: #f43208;
`;

export const WrapperLayoutLogin = styled.div`
  box-sizing: border-box;
  vertical-align: baseline;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: row nowrap;
  background: #ffffff;
  font-size: 10px;
`;

export const SectionLogin = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background: #ffffff;
`;

export const SectionLoginHeader = styled.header`
  display: flex;
  padding: 3vh 5vh 0 3vh;
  flex-flow: row nowrap;
  justify-content: space-between;
  height: 13vh;
  width: 100%;
  background: #ffffff;
`;

export const SectionLoginMain = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 82vh;
  width: 100%;
  background: #ffffff;
`;

export const SectionLoginFooter = styled.footer`
  display: flex;
  padding: 3vh;
  align-items: center;
  gap: 8px;
  flex-flow: row nowrap;
  height: 5vh;
  width: 100%;
  background: #ffffff;
`;

export const AsideLogin = styled.aside`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100vh;
  width: 40vw;
  background: #1da0db;
  min-width: 40vw;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const RegisterText = styled.text`
  font-family: "Akko Pro", "Sans-serif";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: rgba(30, 24, 83, 0.48);
`;

export const BrandText = styled.text`
  font-family: "Akko Pro", "Sans-serif";
  margin-bottom: -0.4%;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 0;
  letter-spacing: 0.01em;

  color: rgba(0, 0, 0, 0.64);

  mix-blend-mode: normal;
  opacity: 0.48;
`;

export const LinkArea = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 1.6vh;

  font-family: "Akko Pro", "sans-serif";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.8vh;
  max-width: 500px;
  min-width: 240px;
  max-height: 80px;
`;

export const NewPasswordInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;
  max-width: 500px;
  min-width: 240px;
`;

export const ShowHideButton = styled.button`
  position: absolute;
  right: 21px;
  top: 28px;
  background: transparent;
  border: none;

  img {
    width: 20px;
  }
`;
