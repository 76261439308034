import { EnvironmentalVariables } from "../../../../enum/EnvironmentalVariables";
import { VisualizationType } from "../../../../enum/VisualizationType";
import i18n from "../../../../i18n";
import { ISerieConfigMap } from "./types/ISerieConfigMap";

export const getWeatherConfigMap = (
  type: VisualizationType
): Record<string, ISerieConfigMap> => {
  const forecastColors: Partial<Record<EnvironmentalVariables, string>> = {
    [EnvironmentalVariables.WIND_VEL]: "#347ED5",
    [EnvironmentalVariables.WIND_VEL_80M]: "#184CD4",
    [EnvironmentalVariables.WIND_VEL_100M]: "#2F3A80",
    [EnvironmentalVariables.GUST]: "#D44C4C",
    [EnvironmentalVariables.GUST_80M]: "#E1842F",
    [EnvironmentalVariables.GUST_100M]: "#D7A905",
    [EnvironmentalVariables.PRECIP]: "rgba(0,128,0, 0.45)",
    [EnvironmentalVariables.WIND_DIR]: "#347ED5",
    [EnvironmentalVariables.WIND_DIR_80M]: "#184CD4",
    [EnvironmentalVariables.WIND_DIR_100M]: "#2F3A80",
  };

  const measurementColors: Partial<Record<EnvironmentalVariables, string>> = {
    [EnvironmentalVariables.WIND_VEL]: "#54D3C0",
    [EnvironmentalVariables.WIND_VEL_80M]: "#6fedb6",
    [EnvironmentalVariables.WIND_VEL_100M]: "#3e9aa8",
    [EnvironmentalVariables.GUST]: "#226373",
    [EnvironmentalVariables.GUST_80M]: "#3e7aa8",
    [EnvironmentalVariables.GUST_100M]: "#3ea86e",
    [EnvironmentalVariables.PRECIP]: "rgba(0,128,0, 0.45)",
    [EnvironmentalVariables.WIND_DIR]: "#54D3C0",
    [EnvironmentalVariables.WIND_DIR_80M]: "#6fedb6",
    [EnvironmentalVariables.WIND_DIR_100M]: "#3e9aa8",
  };

  const getColor = (
    variable: EnvironmentalVariables,
    type: VisualizationType
  ) => {
    let colorMap: Record<string, string>;
    switch (type) {
      case VisualizationType.FORECAST:
        colorMap = forecastColors;
        break;
      case VisualizationType.SENSOR:
        colorMap = measurementColors;
        break;
      default:
        colorMap = {};
        break;
    }
    return colorMap[variable.toLowerCase()] || "#347ED5";
  };

  const yAxisConfig = {
    title: {
      text: i18n.t("forecastChart.forecastChartPanelMeteogram.windsGusts"),
      style: {
        color: "#000000",
      },
    },
    min: 0,
    max: 10,
    labels: { format: "{value}", style: { color: "#000000" } },
    gridLineWidth: 1,
  };

  return {
    [EnvironmentalVariables.WIND_VEL]: {
      serieConfig: {
        color: getColor(EnvironmentalVariables.WIND_VEL, type),
        keys: ["x", "y", "direction"],
        updateYAxis: true,
        yAxis: 0,
        tooltip: {
          formatDirection: true,
          reverseDirection: false,
        },
      },
      yAxisConfig,
    },
    [EnvironmentalVariables.WIND_VEL_80M]: {
      serieConfig: {
        color: getColor(EnvironmentalVariables.WIND_VEL_80M, type),
        keys: ["x", "y", "direction"],
        updateYAxis: true,
        yAxis: 0,
        tooltip: {
          formatDirection: true,
          reverseDirection: false,
        },
      },
      yAxisConfig,
    },
    [EnvironmentalVariables.WIND_VEL_100M]: {
      serieConfig: {
        color: getColor(EnvironmentalVariables.WIND_VEL_100M, type),
        keys: ["x", "y", "direction"],
        updateYAxis: true,
        yAxis: 0,
        tooltip: {
          formatDirection: true,
          reverseDirection: false,
        },
      },
      yAxisConfig,
    },
    [EnvironmentalVariables.GUST]: {
      serieConfig: {
        color: getColor(EnvironmentalVariables.GUST, type),
        updateYAxis: true,
        yAxis: 0,
      },
      yAxisConfig,
    },
    [EnvironmentalVariables.GUST_80M]: {
      serieConfig: {
        color: getColor(EnvironmentalVariables.GUST_80M, type),
        updateYAxis: true,
        yAxis: 0,
      },
      yAxisConfig,
    },
    [EnvironmentalVariables.GUST_100M]: {
      serieConfig: {
        color: getColor(EnvironmentalVariables.GUST_100M, type),
        updateYAxis: true,
        yAxis: 0,
      },
      yAxisConfig,
    },
    [EnvironmentalVariables.PRECIP]: {
      serieConfig: {
        type: "column",
        color: getColor(EnvironmentalVariables.PRECIP, type),
        yAxis: 1,
        groupPadding: 0,
        pointPadding: 0,
        borderWidth: 0,
        shadow: false,
        dataLabels: { enabled: false },
        style: { fontSize: "12px" },
        updateYAxis: true,
      },
      yAxisConfig: {
        title: {
          text: i18n.t(
            "forecastChart.forecastChartPanelMeteogram.preciptation"
          ),
          style: {
            color: "#000000",
          },
        },

        min: 0,
        max: 0.6,
        labels: { format: "{value}", style: { color: "#000000" } },
        gridLineWidth: 0,
        tickLength: 0,
        opposite: true,
      },
    },
    [EnvironmentalVariables.WIND_DIR]: {
      serieConfig: {
        type: "windbarb",
        color: getColor(EnvironmentalVariables.WIND_DIR, type),
        enableMouseTracking: true,
        tooltip: {
          formatDirection: false,
        },
      },
    },
    [EnvironmentalVariables.WIND_DIR_80M]: {
      serieConfig: {
        type: "windbarb",
        color: getColor(EnvironmentalVariables.WIND_DIR_80M, type),
        tooltip: {
          formatDirection: false,
        },
      },
    },
    [EnvironmentalVariables.WIND_DIR_100M]: {
      serieConfig: {
        type: "windbarb",
        color: getColor(EnvironmentalVariables.WIND_DIR_100M, type),
        tooltip: {
          formatDirection: false,
        },
      },
    },
  };
};
