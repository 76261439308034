import { initializeApp } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { util } from "node-forge";
import { getConfig } from "../config/app.config";
import { ConfigKey } from "../enum/ConfigKey";
import {
  Firestore,
  getFirestore,
  Query,
  query,
  collection,
  where,
  CollectionReference,
  DocumentData,
  WhereFilterOp,
  onSnapshot,
  limit,
  connectFirestoreEmulator,
  orderBy,
} from "firebase/firestore";
import {
  RealtimeStorageEntityType,
  RealtimeStorageFilter,
} from "../types/realtimeStorageTypes";
import { checkLocalHost } from "../util/checkLocalHost/checkLocalHost";

export default class GoogleFirebase {
  firestore: Firestore;
  constructor() {
    const credentials = this.getCredentials();

    if (credentials) {
      const firebaseApp = initializeApp(credentials);
      this.firestore = getFirestore(firebaseApp);
      if (checkLocalHost()) {
        let emulatorHost: any = getConfig(
          ConfigKey.FIREBASE_FIRESTORE_EMULATOR_HOST
        );
        emulatorHost = emulatorHost.split(":");
        if (emulatorHost?.length === 2) {
          connectFirestoreEmulator(
            this.firestore,
            emulatorHost[0],
            emulatorHost[1]
          );
        }
      }
    }
  }

  private getCredentials() {
    try {
      return JSON.parse(
        util.decode64(getConfig(ConfigKey.REACT_APP_FIREBASE_CREDENTIAL))
      );
    } catch (error) {
      console.log(`Error while try get credentials notifications`);
    }
  }

  find<K extends keyof RealtimeStorageEntityType>(
    kind: K,
    filterObject: RealtimeStorageFilter
  ) {
    return this.getFilteredCollection(kind, filterObject);
  }

  /**
   * Find objects into the datastore according with a set of properties
   */
  private getFilteredCollection<K extends keyof RealtimeStorageEntityType>(
    kind: K,
    filterObject: RealtimeStorageFilter
  ): Query {
    let collectionInstance: CollectionReference<DocumentData> = collection(
      this.firestore,
      kind
    );

    let whereFilter: any[] = [];
    let limitOption: any;
    let orderOption: any[] = [];

    //Add filter properties
    filterObject.filter?.forEach((filter) =>
      whereFilter.push(
        where(filter.key, filter.operator as WhereFilterOp, filter.value)
      )
    );

    if (filterObject.limit) {
      limitOption = limit(filterObject.limit);
    }

    filterObject?.order?.forEach((order) =>
      orderOption.push(orderBy(order.item, order.type))
    );

    return query(
      collectionInstance,
      ...whereFilter,
      limitOption,
      ...orderOption
    );
  }

  /**
   * Find objects into the datastore according with a set of properties and listen for changes (snapshots)
   */
  onSnapshot<K extends keyof RealtimeStorageEntityType>(
    kind: K,
    filterObject: RealtimeStorageFilter,
    callback: (storage: RealtimeStorageEntityType[K][]) => void
  ): void {
    const query = this.getFilteredCollection(kind, filterObject);

    onSnapshot(query, (snapshot) => {
      if (!snapshot.docs || !snapshot.docs.length) {
        return;
      }

      callback(
        snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id } as RealtimeStorageEntityType[K];
        })
      );
    });
  }
}
