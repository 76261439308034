import {
  Divider,
  Drawer as DrawerWrapper,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { ReactNode, useRef } from "react";

type DrawerProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onOpen?: () => void;
  title?: string;
};

export default function Drawer({
  title,
  children,
  isOpen,
  onClose,
}: DrawerProps) {
  const btnRef = useRef(null);

  if (!isOpen) return null;

  return (
    <>
      <DrawerWrapper
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color="blackAlpha.700" />
          <DrawerHeader color="blackAlpha.700">{title}</DrawerHeader>

          <Divider />

          <DrawerBody>{children}</DrawerBody>
        </DrawerContent>
      </DrawerWrapper>
    </>
  );
}
