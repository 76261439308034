import React from "react";
import { Select } from "antd";
import { FormControl, FormLabel, Checkbox, Flex } from "@chakra-ui/react";
import { IStationRegion } from "types/IStationRegion";
import { FormikErrors } from "formik";

export interface FormVariableValues {
  weather: boolean;
  wave: boolean;
  waterFlow: boolean;
}

interface VariableControlProps {
  name: keyof FormVariableValues;
  label: string;
  values: FormVariableValues;
  stations: IStationRegion[];
  getFieldProps: (name: string) => {};
  setSelectedStation: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<FormVariableValues>>;
  selectedStationRegionId: string | null;
  filterOption: (input: string, option: any) => boolean;
}

export const VariableControl: React.FC<VariableControlProps> = ({
  name,
  label,
  values,
  stations,
  getFieldProps,
  setSelectedStation,
  selectedStationRegionId,
  filterOption,
}) => (
  <Flex>
    <FormControl display="flex" alignItems="center" color="newBlue.500">
      <Checkbox
        {...getFieldProps(name)}
        defaultChecked={stations.length > 0 ? values[name] : false}
        mr={3}
        borderColor="gray.400"
        isDisabled={!stations.length}
      />
      <FormLabel mt={2} cursor="pointer">
        {label}
      </FormLabel>
    </FormControl>
    <FormControl display="flex" alignItems="center" color="newBlue.500">
      <Select
        showSearch
        placeholder={`Select ${label}`}
        disabled={!values[name] || !stations.length}
        optionFilterProp="children"
        style={{ width: "100%" }}
        onChange={(value) => setSelectedStation(`${name}Location`, value)}
        filterOption={filterOption}
        defaultValue={selectedStationRegionId}
      >
        {stations.map((station) => (
          <Select.Option value={station.id} key={station.id}>
            {station.name}
          </Select.Option>
        ))}
      </Select>
    </FormControl>
  </Flex>
);
