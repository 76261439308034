import { IOperationRuleLocation } from "../stores/data/operationRuleLocations/OperationRuleLocationModel";
import api from "./api";

export default class OperationRuleLocationService {
  async getOperationRuleLocation(operationRuleId: number, id?: number) {
    let result;
    if (id) {
      result = await api.get(
        `/v1/OperationRule/${operationRuleId}/location/${id}`
      );
      return result.data;
    }
    result = await api.get(`/v1/OperationRule/${operationRuleId}/location`);
    return result.data;
  }

  async createOperationRuleLocation(
    name: string,
    initialsName: string,
    description: string,
    regionId: number,
    stationId: number,
    OperationRuleId: number,
    macroRegionId: number
  ): Promise<IOperationRuleLocation> {
    const result = await api.post(`/v1/OperationRule/location`, {
      name: name,
      initials_name: initialsName,
      description: description,
      macroRegionId: macroRegionId,
      regionId: regionId,
      stationId: stationId,
      operationRuleId: OperationRuleId,
    });
    if (result.status === 200) {
      return result.data;
    }
    throw new Error("Error while create new operation rule");
  }

  async updateOperationRuleLocation(
    id: number,
    name: string,
    initialsName: string,
    description: string,
    regionId: number,
    stationId: number,
    macroRegionId: number
  ): Promise<boolean> {
    if (!id) {
      throw new Error("Need to inform ID for update data");
    }
    const result = await api.put(`/v1/OperationRule/location/${id}`, {
      name: name,
      initials_name: initialsName,
      description: description,
      regionId: regionId,
      stationId: stationId,
      macroRegionId: macroRegionId,
    });
    if (result.status === 200 || result.status === 201) {
      return true;
    }
    throw new Error("Error while update operation rule");
  }

  async deleteOperationRuleLocation(id: number): Promise<boolean> {
    if (!id) {
      throw new Error("Need to inform ID for delete data");
    }
    const result = await api.delete(`/v1/OperationRule/location/${id}`);
    if (result.status === 200 || result.status === 201) {
      return true;
    }
    throw new Error("Error while delete operation rule");
  }
}
