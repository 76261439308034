import Highcharts from "highcharts";
import { DateTime } from "luxon";
import { makeAutoObservable } from "mobx";
import { getEnv } from "mobx-easy";
import { StoreState } from "../../../enum/StoreState";
import { UserExperience } from "../../../enum/UserExperience";
import { RootEnv } from "../../setup/create-store";
import {
  IEnvironmentalVariable,
  IGanttChartData,
  IGanttChartSerieEnvironmentalVariable,
  IInsightChangeForUpdate,
  IStation,
} from "./oceanWeatherIntelligenceModel";
import i18n from "../../../i18n";
import axios from "axios";
import { IGetGanttChartDataParams } from "../../../services/OceanWeatherIntelligenceService";

export default class OceanWeatherIntelligenceStore {
  ganttChartData: IGanttChartData[] = [
    { name: "", data: [], start: 0, end: 0, timezoneOffset: 0, errors: 0 },
  ];

  stations: IStation[] = [];

  state: string = StoreState.DONE;

  messageError: string = "";

  changeForUpdate: IInsightChangeForUpdate = {
    update: false,
    expiration: 0,
  };

  mapCenterLatitude: number = 0;

  mapCenterLongitude: number = 0;

  environmentalVariables: IEnvironmentalVariable[] = [];

  selectForecastDateAvailable: DateTime | undefined;

  actionCallGanttChart: boolean = false;

  activeTab: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setGanttChartData(values: IGanttChartData) {
    this.ganttChartData = [values];
  }

  setStations(values: IStation[]) {
    this.stations = values;
  }

  setChangeForUpdate(value: IInsightChangeForUpdate) {
    this.changeForUpdate = value;
  }

  getStationForLocation(stationId: number, regionId: number) {
    return this.stations.find(
      (station) =>
        station.station_id === stationId && station.region_id === regionId
    ) as IStation;
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setMessageError(value: string) {
    this.messageError = value;
  }

  setEnviromentalVariables(values: IEnvironmentalVariable[]) {
    this.environmentalVariables = values;
  }

  setMapCenter(): void {
    for (let i = 0; i < this.stations.length; i++) {
      if (
        this.stations[i].station_lat !== null &&
        this.stations[i].station_lon !== null
      ) {
        this.mapCenterLatitude = this.stations[i].station_lat;
        this.mapCenterLongitude = this.stations[i].station_lon;
        break;
      }
    }
  }

  setSelectForecastDateAvailable(values: DateTime) {
    this.selectForecastDateAvailable = values;
  }

  setActionCallGanttChart() {
    this.actionCallGanttChart = !this.actionCallGanttChart;
  }

  setActiveTab(value: number) {
    this.activeTab = value;
  }

  clearSelectForecastDateAvailable() {
    this.selectForecastDateAvailable = undefined;
  }

  reset() {
    this.setGanttChartData({
      name: "",
      data: [],
      start: 0,
      end: 0,
      timezoneOffset: 0,
      errors: 0,
    });
    this.setState(StoreState.DONE);
    this.setEnviromentalVariables([]);
  }

  async getGanttChartDataApi({
    operationRuleId,
    cancelToken,
    create_at,
  }: IGetGanttChartDataParams) {
    this.setMessageError("");
    const { oceanWeatherIntelligenceService } = getEnv<RootEnv>();
    this.setGanttChartData({
      name: "",
      data: [],
      start: 0,
      end: 0,
      timezoneOffset: 0,
      errors: 0,
    });
    this.setState(StoreState.PEDDING);
    try {
      const chartData: IGanttChartData =
        await oceanWeatherIntelligenceService.getGanttChartData({
          operationRuleId,
          create_at: create_at || this.selectForecastDateAvailable?.toISO(),
          cancelToken,
        });
      this.setGanttChartData(chartData);

      if (this.selectForecastDateAvailable) {
        this.addLogUserExperienceAPI(UserExperience.OWI_CHART_PAST_DATA);
      }
      if (chartData.errors > 0) {
        this.setMessageError(
          i18n.t("oceanWeatherIntelligence.errorGeneratingInsight")
        );
      }
      this.setState(StoreState.DONE);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("axios request cancelled");
      } else {
        this.setState(StoreState.ERROR);
        this.setMessageError(
          i18n.t("oceanWeatherIntelligence.errorGetChartData")
        );
      }
    }
  }

  async getStationForLocationAPI(macroRegionId: number) {
    this.setState(StoreState.PEDDING);
    const { oceanWeatherIntelligenceService } = getEnv<RootEnv>();
    this.setStations([]);
    const stationsResult =
      await oceanWeatherIntelligenceService.getStationForLocation(
        macroRegionId
      );
    this.setStations(stationsResult);
    this.setMapCenter();
    this.setState(StoreState.DONE);
    return stationsResult;
  }

  async addLogUserExperienceAPI(userExperience: UserExperience) {
    const { logServices } = getEnv<RootEnv>();
    logServices.addLogUserExperience(userExperience);
  }

  async getEnvironmentalVariableForStationAPI(
    stationId: number,
    macroRegionId: number
  ) {
    const { oceanWeatherIntelligenceService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);
    this.setEnviromentalVariables([]);
    this.setMessageError("");

    // list com nome interno das variaveis ambientais desejadas
    const mock = [
      "water_level",
      "current_vel",
      "current_dir",
      "wave_height",
      "wave_dir",
      "peak_period",
      "mean_period",
      "wave_power",
      "wave_height_max",
      "wave_height_sea",
      "wave_dir_sea",
      "peak_period_sea",
      "mean_period_sea",
      "wave_height_swell",
      "wave_dir_swell",
      "peak_period_swell",
      "mean_period_swell",
      "wind_vel",
      "wind_dir",
      "wind_vel_80m",
      "wind_dir_80m",
      "wind_vel_100m",
      "wind_dir_100m",
      "gust",
      "gust_80m",
      "gust_100m",
      "precip",
      "rel_humidity",
      "visibility",
      "slp",
      "dew_point",
      "temp_air",
      "total_cloud_cover",
      "lightning_density",
      "precip_min_20km",
      "precip_max_20km",
      "precip_probability"
    ];

    try {
      const variablesResult: IEnvironmentalVariable[] =
        await oceanWeatherIntelligenceService.getEnvironmentalVariableForStation(
          stationId,
          macroRegionId
        );

      const variableFilter: IEnvironmentalVariable[] = [];

      for (const value of mock) {
        const foundVariable = variablesResult.find(
          (environmentalVariable) =>
            environmentalVariable.internal_name === value
        );
        if (foundVariable) {
          if (foundVariable.internal_name.indexOf("_80m") !== -1) {
            // SLA for wind 50m acu region
            foundVariable.name = foundVariable.name.replace("80", "50");
          }
          variableFilter.push(foundVariable);
        }
      }
      this.setEnviromentalVariables(variableFilter);
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t("oceanWeatherIntelligence.environmentalVariablesNotFound")
      );
    }
    this.setState(StoreState.DONE);
  }

  renderMinMax(min: string, max: string, units: string) {
    return (
      "<td>" +
      "<div style='display:flex; align-items:center;justify-content:center;'>" +
      "<b>min: " +
      "</b>" +
      "<b>" +
      min +
      units +
      "</b>" +
      "</div>" +
      "</td>" +
      "<td>" +
      "</td>" +
      "<td>" +
      "<div style='display:flex; align-items:center;justify-content:center;margin-left:5px;'>" +
      "<b>max: " +
      "</b>" +
      "<b>" +
      max +
      units +
      "</b>" +
      "</div>" +
      "</td>"
    );
  }

  renderEnvironmentalVariable(
    environmentalVariables: IGanttChartSerieEnvironmentalVariable[]
  ) {
    let newTable = "";

    for (const item of environmentalVariables) {
      let { environmentalVariable, min, max, units } = item;

      // SLA for wind 50m acu region
      if (environmentalVariable.indexOf("80") !== -1) {
        environmentalVariable = environmentalVariable.replace("80", "50");
      }

      if (min !== undefined && max !== undefined && units) {
        const minFormatted = min.toFixed(1);
        const maxFormatted = max.toFixed(1);
        const isDegrees = units === "degrees";
        let _renderMinMax = "";

        if (units === "knots") {
          units = "kt";
        }

        if (units === "seconds") {
          units = "s";
        }

        if (!isDegrees) {
          _renderMinMax = this.renderMinMax(minFormatted, maxFormatted, units);
        }

        newTable +=
          "<tr>" +
          "<td>" +
          "<b style='color:#075fb1;'>" +
          environmentalVariable +
          "</b>" +
          "</td>" +
          _renderMinMax +
          "</tr>";
      }
    }

    return newTable;
  }

  ganttChartFormatTooltip(point: any) {
    let ret = "<table>";

    // Add all series
    ret +=
      "<tr><td>" +
      point.series.name +
      "</td></tr>" +
      "<tr><td>" +
      "<b>" +
      point.key +
      "</b>" +
      "</td></tr>" +
      `<tr><td>${i18n.t("oceanWeatherIntelligence.start")}: ` +
      Highcharts.dateFormat("%d/%m/%Y %H:%M", point.x) +
      "</td></tr>" +
      `<tr><td>${i18n.t("oceanWeatherIntelligence.end")}: ` +
      Highcharts.dateFormat("%d/%m/%Y %H:%M", point.x2) +
      "</td></tr>" +
      `<tr><td>${i18n.t("oceanWeatherIntelligence.summary")}:` +
      point.point.resume +
      "</td></tr>" +
      ` <tr><td> <b>${i18n.t(
        "oceanWeatherIntelligence.insightIsCausedBy"
      )}</b> </td></tr>` +
      this.renderEnvironmentalVariable(point.point.environmentalVariables);

    // Close
    ret += "</table>";

    return ret;
  }

  processDateCurrent(value: DateTime) {
    if (value.minute > 45) {
      return DateTime.local()
        .set({
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .plus({ hours: 1 })
        .toJSDate();
    } else if (value.minute > 30) {
      return DateTime.local()
        .set({
          minute: 45,
          second: 0,
          millisecond: 0,
        })
        .toJSDate();
    } else if (value.minute > 15) {
      return DateTime.local()
        .set({
          minute: 30,
          second: 0,
          millisecond: 0,
        })
        .toJSDate();
    } else {
      return DateTime.local()
        .set({
          minute: 15,
          second: 0,
          millisecond: 0,
        })
        .toJSDate();
    }
  }

  needUpdateGanttChart() {
    if (
      (this.ganttChartData?.length && this.ganttChartData[0].name === "") ||
      this.changeForUpdate.update ||
      this.changeForUpdate.expiration <= DateTime.local().toMillis()
    ) {
      return true;
    }
    return false;
  }
}
