import {
  Alert,
  Button,
  Form,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { StoreState } from "../../../enum/StoreState";
import { useStores } from "../../../stores/setup/use-store";
import { UserExperience } from "../../../enum/UserExperience";
import InsightAlertModal from "./InsightAlertModal";
import { PlusOutlined } from "@ant-design/icons";
import { IOperationRuleAlertForTable } from "../../../stores/data/insightAlerts/insightAlertModel";
import { DBAction } from "../../../enum/DBAction";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../../components/PageTitle/PageTitle";
import { Alert as AlertChakra, AlertIcon, Box } from "@chakra-ui/react";
import { SCREEN_HORIZONTAL_SPACING } from "../../../constants";
import i18n from "i18n";

const InsightAlertDetail: React.FC = () => {
  const {
    dataStores: { oceanWeatherIntelligenceStore, insightAlertStore, authStore },
  } = useStores();

  const [form] = Form.useForm();

  const { t } = useTranslation("translation", {
    keyPrefix: "oceanWeatherIntelligence.insightAlert",
  });

  useEffect(() => {
    if (insightAlertStore.operationRuleDetailSelected === 0) {
      form.resetFields();
      insightAlertStore.getOperationRuleAlertForTableAPI();

      insightAlertStore.getOperationRuleAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightAlertStore, insightAlertStore.operationRuleDetailSelected]);

  const handleOnCreateAlert = () => {
    insightAlertStore.setOperationRuleAlertAction(DBAction.CREATE);
    insightAlertStore.getOperationRuleModalAPI();
    insightAlertStore.getOperationRuleAlertUserAPI();
    insightAlertStore.setInsightAlertModalVisible(true);
  };

  const handleOnConfirmDelete = (record: IOperationRuleAlertForTable) => {
    if (record.id > 0) {
      insightAlertStore.deleteOperationRuleAlertAPI(record.id);
    } else {
      message.error(t("alertRemovalFailed"));
    }
  };

  const handleOnEdit = (value: IOperationRuleAlertForTable) => {
    insightAlertStore.getOperationRuleModalAPI();
    insightAlertStore.getOperationRuleAlertUserAPI(value.id);
    insightAlertStore.getOperationRuleLocationModalAPI(value.operationRuleId);
    insightAlertStore.getOperationRuleAlertAPI(value.id);

    insightAlertStore.getOperationRuleLocationInsightModalAPI(
      value.operationRuleLocationId
    );
    insightAlertStore.setOperationRuleAlertForTableForEdit(value);
    insightAlertStore.setOperationRuleAlertAction(DBAction.UPDATE);
    insightAlertStore.setInsightAlertModalVisible(true);
  };

  const handleSelectedDashboardChange = (value: number) => {
    if (value !== insightAlertStore.operationRuleDetailSelected) {
      insightAlertStore.setOperationRuleDetailSelected(value);
      form.setFieldsValue({
        selectLocation: 0,
      });
      insightAlertStore.setOperationRuleLocationDetailSelected(0);
    }
    if (value > 0) {
      insightAlertStore.getOperationRuleLocationAPI(value);
      insightAlertStore.getOperationRuleAlertForTableAPI(value);
    } else {
      insightAlertStore.getOperationRuleAlertForTableAPI();
    }
    oceanWeatherIntelligenceStore.addLogUserExperienceAPI(
      UserExperience.OWI_ALERT_DASHBOARD_FILTER
    );
  };

  const handleSelectedLocationChange = (value: number) => {
    if (insightAlertStore.operationRuleDetailSelected > 0) {
      if (value > 0) {
        insightAlertStore.setOperationRuleLocationDetailSelected(value);
        insightAlertStore.getOperationRuleAlertForTableAPI(
          insightAlertStore.operationRuleDetailSelected,
          value
        );
      } else {
        insightAlertStore.getOperationRuleAlertForTableAPI(
          insightAlertStore.operationRuleDetailSelected
        );
      }
    } else {
      message.warning(t("selectDashboardFilterLocation"));
    }
    oceanWeatherIntelligenceStore.addLogUserExperienceAPI(
      UserExperience.OWI_ALERT_LOCATION_FILTER
    );
  };

  const columns = [
    {
      title: t("alert"),
      key: "Alert",
      dataIndex: "Alert",
      width: "23%",
      render: (text: React.ReactNode, record: IOperationRuleAlertForTable) => (
        <>
          <div>
            <h3>{record.name}</h3>
          </div>
        </>
      ),
    },
    {
      title: t("insight"),
      key: "Insight",
      dataIndex: "Insight",
      width: "23%",
      render: (text: React.ReactNode, record: IOperationRuleAlertForTable) => (
        <>
          <div>
            <h3>{record.operationRuleLocationItemName}</h3>
          </div>
        </>
      ),
    },
    {
      title: t("location"),
      key: "Location",
      dataIndex: "Location",
      width: "23%",
      render: (text: React.ReactNode, record: IOperationRuleAlertForTable) => (
        <>
          <div>
            <h3>{record.operationRuleLocationName}</h3>
          </div>
        </>
      ),
    },
    {
      title: t("dashboard"),
      key: "Dashboard",
      dataIndex: "Dashboard",
      width: "23%",
      render: (text: React.ReactNode, record: IOperationRuleAlertForTable) => (
        <>
          <div>
            <h3>{record.operationRuleName}</h3>
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      width: "10%",
      render: (text: React.ReactNode, record: IOperationRuleAlertForTable) => (
        <Space>
          <>
            <Button
              type="primary"
              style={{
                margin: "0 8px",
                backgroundColor: "orange",
                borderColor: "orange",
              }}
              onClick={() => handleOnEdit(record)}
            >
              {t("edit")}
            </Button>
            {!authStore.readOnlyUser && (
              <Popconfirm
                placement="top"
                title={t("areYouSureWantDeleteThisAlert")}
                onConfirm={(e) => handleOnConfirmDelete(record)}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Button danger type="default" style={{ margin: "0 8px" }}>
                  {t("delete")}
                </Button>
              </Popconfirm>
            )}
          </>
        </Space>
      ),
    },
  ];

  return (
    <Box mt={8} mx={SCREEN_HORIZONTAL_SPACING}>
      <PageTitle text={t("insightAlerts")} />
      <Spin spinning={insightAlertStore.state === StoreState.PEDDING}>
        {insightAlertStore.state === StoreState.ERROR ? (
          <Alert
            message="Error"
            description={insightAlertStore.messageError}
            type="error"
            showIcon
            closable
          />
        ) : (
          ""
        )}

        {authStore.readOnlyUser && (
          <AlertChakra bg="#bee3f8" status="info" mb={4} color="gray.700">
            <AlertIcon />
            {i18n.t("readingModePleaseContact")}
          </AlertChakra>
        )}

        <InsightAlertModal
          onCancel={() => {
            insightAlertStore.setInsightAlertModalVisible(false);
          }}
        />

        <Row>
          <Form form={form} layout="vertical" name="panel_alert">
            <>
              <Form.Item
                key={`selectDashboard${insightAlertStore.operationRuleDetailSelected}`}
                name="selectDashboard"
                label={t("filterDashboard")}
                style={{ display: "inline-block" }}
              >
                <Select
                  key={`selectDashboard${insightAlertStore.operationRuleDetailSelected}`}
                  style={{ width: 300, fontWeight: "bold", fontSize: 14 }}
                  onChange={handleSelectedDashboardChange}
                >
                  <Select.Option value={0} key={0}>
                    {" "}
                  </Select.Option>
                  {insightAlertStore.operationRule.map((value) => (
                    <Select.Option value={value.id} key={value.id}>
                      {value.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {insightAlertStore.operationRuleDetailSelected > 0 ? (
                <Form.Item
                  key={`selectLocation${insightAlertStore.operationRuleLocationDetailSelected}`}
                  name="selectLocation"
                  label={t("filterLocation")}
                  style={{
                    display: "inline-block",
                    paddingLeft: 9,
                    marginBottom: 0,
                  }}
                >
                  <Select
                    key={`selectLocation${insightAlertStore.operationRuleLocationDetailSelected}`}
                    style={{ width: 300, fontWeight: "bold", fontSize: 14 }}
                    onChange={handleSelectedLocationChange}
                  >
                    <Select.Option value={0} key={0}>
                      {" "}
                    </Select.Option>
                    {insightAlertStore.operationRuleLocation.map((value) => (
                      <Select.Option value={value.id} key={value.id}>
                        {value.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ""
              )}
            </>
          </Form>
        </Row>
        {!authStore.readOnlyUser && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            style={{ margin: "0 25px", float: "right" }}
            onClick={handleOnCreateAlert}
          >
            {t("createNewAlert")}
          </Button>
        )}

        <Table
          columns={columns}
          dataSource={[...insightAlertStore.operationRuleAlertForTableDatail]}
          rowKey={(record) => record.id}
          key={`item${insightAlertStore.operationRuleAlertForTableDatail.length}`}
          style={{ paddingTop: 40 }}
        ></Table>
      </Spin>
    </Box>
  );
};

export default observer(InsightAlertDetail);
