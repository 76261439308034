import React, { FC } from "react";
import { Box, Divider, Text } from "@chakra-ui/react";

export interface PageTitleProps {
  text: string;
  divider?: boolean;
}

export const PageTitle: FC<PageTitleProps> = ({ text, divider = false }) => {
  return (
    <Box mb={4}>
      <Text fontSize="2xl" color="newBlue.500">
        {text}
      </Text>
      {divider && <Divider mb={1} />}
    </Box>
  );
};
