import { Button, Flex, Text } from "@chakra-ui/react";
import svg404 from "assets/404.svg";
import { LoginLayout } from "components/Login/LoginLayout/LoginLayout";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Page404() {
  const { t } = useTranslation("translation", {
    keyPrefix: "404",
  });
  return (
    <LoginLayout>
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={6}
      >
        <img width={300} src={svg404} alt="404" />
        <Text fontSize="20px" color="black">
          {t("oopsPageNotFound")}
        </Text>
        <Link to="/login">
          <Button
            color="white"
            bg="newBlue.500"
            border="1px solid #b0d2ec"
            _hover={{ backgroundColor: "rgba(49, 154, 221, 0.3)" }}
          >
            {t("goToLogin")}
          </Button>
        </Link>
      </Flex>
    </LoginLayout>
  );
}
