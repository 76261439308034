export enum UserExperience {
  OWI_WELCOME_VIDEO = "OWI WELCOME VIDEO",
  OWI_CUSTOMIZE_LOCATION = "OWI CUSTOMIZE LOCATION",
  OWI_DASHBOARD_VIDEO = "OWI DASHBOARD VIDEO",
  OWI_LOCATION_VIDEO = "OWI LOCATION VIDEO",
  OWI_INSIGHT_VIDEO = "OWI INSIGHT VIDEO",
  OWI_CHART_PAST_DATA = "OWI_CHART_PAST_DATA",
  FORECAST_CHART_PAST_DATA = "FORECAST_CHART_PAST_DATA",
  OWI_ALERT_LOCATION_FILTER = "OWI_ALERT_LOCATION_FILTER",
  OWI_ALERT_DASHBOARD_FILTER = "OWI_ALERT_DASHBOARD_FILTER",
  OWI_ALERT_NOTIFICATION_CONFIG_EVENT_START = "OWI_ALERT_NOTIFICATION_CONFIG_EVENT_START",
  OWI_ALERT_NOTIFICATION_CONFIG_EVENT_FINISH = "OWI_ALERT_NOTIFICATION_CONFIG_EVENT_FINISH",
  FORECAST_CHART_TRY_VERSION2_BUTTOM = "FORECAST_CHART_TRY_VERSION2_BUTTOM",
  FORECAST_CHART_BACK_TO_VERSION2_BUTTOM = "FORECAST_CHART_BACK_TO_VERSION1_BUTTOM",
  FORECAST_CHART_MAP_CLICKS = "FORECAST_CHART_MAP_CLICKS",
  FORECAST_CHART_MAP_CLICKS_OUTSIDE_GRID_AREA = "FORECAST_CHART_MAP_CLICKS_OUTSIDE_GRID_AREA",
  FORECAST_CHART_MAP_TOGGLE_TYPE_AND_OUTSIDE_GRID_AREA = "FORECAST_CHART_MAP_TOGGLE_TYPE_AND_OUTSIDE_GRID_AREA",
  FORECAST_CHART_MAP_SAVE = "FORECAST_CHART_MAP_SAVE",
  FORECAST_CHART_MAP_SAVE_OUTSIDE_GRID_AREA = "FORECAST_CHART_MAP_SAVE_OUTSIDE_GRID_AREA",
  FORECAST_CHART_REQUEST_SPECTRA_STATION_BUTTON = "FORECAST_CHART_REQUEST_SPECTRA_STATION_BUTTON",
  INSIGHT_MANAGER_TAB = "INSIGHT_MANAGER_TAB",
  CLICK_NEWS_BUTTON = "CLICK_NEWS_BUTTON",
  CLICK_OCCURRENCE_HEADER_BUTTON = "CLICK_OCCURRENCE_HEADER_BUTTON",
  CLICK_IMPORT_OCCURRENCE_HEADER_BUTTON = "CLICK_IMPORT_OCCURRENCE_HEADER_BUTTON",
  QUESTION_FEEDBACK_CLOSE_BUTTON = "QUESTION_FEEDBACK_CLOSE_BUTTON",
  CLICK_SHOW_INSIGHT_METEOGRAM = "CLICK_SHOW_INSIGHT_METEOGRAM",
  SELECT_INSIGHT_ON_FORECAST_CHARTS = "SELECT_INSIGHT_ON_FORECAST_CHARTS",
  CLEAR_SELECT_INSIGHT_ON_FORECAST_CHARTS = "CLEAR_SELECT_INSIGHT_ON_FORECAST_CHARTS",
  CLOSE_METEOGRAM = "CLOSE_METEOGRAM",
  CLICK_GEOESPATIAL_TIMELINE = "CLICK_GEOESPATIAL_TIMELINE",
  CLICK_LEGEND_EVENT_METEOGRAM = "CLICK_LEGEND_EVENT_METEOGRAM",
}
