import { makeAutoObservable } from "mobx";
import { getEnv } from "mobx-easy";
import { DBAction } from "../../../enum/DBAction";
import { StoreState } from "../../../enum/StoreState";
import { RootEnv } from "../../setup/create-store";
import { IOperationRuleLocation } from "./OperationRuleLocationModel";
import i18n from "../../../i18n";

export default class OperationRuleLocationStore {
  operationRuleLocation: IOperationRuleLocation | undefined;

  operationRuleLocations: IOperationRuleLocation[] = [];

  operationRuleLocationModalVisible: boolean = false;

  operationRuleLocationAction: DBAction = DBAction.CREATE;

  operationRuleLocationMainId: number = 0;

  state: string = StoreState.DONE;

  messageError: string = "";

  actionCallInsightsList: boolean = false;

  hasLocations: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  setOperationRuleLocation(value: IOperationRuleLocation | undefined) {
    this.operationRuleLocation = value;
  }

  setOperationRuleLocations(values: IOperationRuleLocation[]) {
    this.operationRuleLocations = values;
  }

  setOperationRuleLocationModalVisible(value: boolean) {
    this.operationRuleLocationModalVisible = value;
  }

  setOperationRuleLocationAction(value: DBAction) {
    this.operationRuleLocationAction = value;
  }

  setOperationRuleLocationMainId(value: number) {
    this.operationRuleLocationMainId = value;
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setMessageError(value: string) {
    this.messageError = value;
  }

  setActionCallInsightsList() {
    this.actionCallInsightsList = !this.actionCallInsightsList;
  }

  setHasLocations(value: boolean) {
    this.hasLocations = value;
  }

  getOperationRuleLocation(id: number) {
    return this.operationRuleLocations.find(
      (operationRuleLocation) => operationRuleLocation.id === id
    ) as IOperationRuleLocation;
  }

  reset() {
    this.setState(StoreState.DONE);
    this.setOperationRuleLocationMainId(0);
    this.setOperationRuleLocations([]);
  }

  async getOperationRuleLocationsApi(operationRuleId: number) {
    const { operationRuleLocationService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);
    this.setOperationRuleLocations([]);
    this.setOperationRuleLocationMainId(0);
    try {
      const operationRuleLocations: IOperationRuleLocation[] =
        await operationRuleLocationService.getOperationRuleLocation(
          operationRuleId
        );

      if (operationRuleLocations.length > 0) {
        this.setOperationRuleLocationMainId(operationRuleLocations[0].id);
        this.setOperationRuleLocations(operationRuleLocations);
        this.setHasLocations(true);
        this.setState(StoreState.DONE);
        return operationRuleLocations;
      } else {
        this.setOperationRuleLocationMainId(0);
        this.setOperationRuleLocations([]);
        this.setHasLocations(false);
      }
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t(
          "oceanWeatherIntelligence.operationRuleLocation.errorGetLocationData"
        )
      );
    }
  }

  async getOperationRuleLocationById(operationRuleId: number, id: number) {
    const { operationRuleLocationService } = getEnv<RootEnv>();
    let operationRuleLocation = undefined;
    try {
      operationRuleLocation =
        await operationRuleLocationService.getOperationRuleLocation(
          operationRuleId,
          id
        );
      if (operationRuleLocation) {
        this.setOperationRuleLocationMainId(id);
        this.setOperationRuleLocation(operationRuleLocation);
        this.setState(StoreState.DONE);
      } else {
        this.setState(StoreState.ERROR);
        this.setMessageError(
          i18n.t(
            "oceanWeatherIntelligence.operationRuleLocation.errorGetLocationData"
          )
        );
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t(
          "oceanWeatherIntelligence.operationRuleLocation.errorGetLocationData"
        )
      );
    }
  }

  async addOperationRuleLocation() {
    const { operationRuleLocationService } = getEnv<RootEnv>();
    if (
      !this.operationRuleLocation ||
      !this.operationRuleLocation.macroRegionId
    ) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t(
          "oceanWeatherIntelligence.operationRuleLocation.errorAddLocationData"
        )
      );
      return;
    }
    try {
      this.setState(StoreState.PEDDING);
      const operationRuleLocationInserted =
        await operationRuleLocationService.createOperationRuleLocation(
          this.operationRuleLocation.name,
          this.operationRuleLocation.initialName,
          this.operationRuleLocation.description,
          this.operationRuleLocation.regionId,
          this.operationRuleLocation.stationId,
          this.operationRuleLocation.operationRuleId,
          this.operationRuleLocation.macroRegionId
        );
      if (operationRuleLocationInserted) {
        this.operationRuleLocations.push(operationRuleLocationInserted);
        this.setOperationRuleLocationMainId(operationRuleLocationInserted.id);
        this.setState(StoreState.DONE);
      } else {
        this.setState(StoreState.ERROR);
        this.setMessageError(
          i18n.t(
            "oceanWeatherIntelligence.operationRuleLocation.errorAddLocationData"
          )
        );
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t(
          "oceanWeatherIntelligence.operationRuleLocation.errorAddLocationData"
        )
      );
    }
  }

  async updateOperationRuleLocation() {
    if (
      !this.operationRuleLocation ||
      !this.operationRuleLocation.macroRegionId
    ) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t(
          "oceanWeatherIntelligence.operationRuleLocation.errorUpdateLocationData"
        )
      );
      return;
    }
    const { operationRuleLocationService } = getEnv<RootEnv>();
    try {
      this.setState(StoreState.PEDDING);
      await operationRuleLocationService.updateOperationRuleLocation(
        this.operationRuleLocation.id,
        this.operationRuleLocation.name,
        this.operationRuleLocation.initialName,
        this.operationRuleLocation.description,
        this.operationRuleLocation.regionId,
        this.operationRuleLocation.stationId,
        this.operationRuleLocation.macroRegionId
      );

      const operationRuleLocationIndex = this.operationRuleLocations.indexOf(
        this.getOperationRuleLocation(this.operationRuleLocation.id)
      );
      this.operationRuleLocations[operationRuleLocationIndex] = {
        ...this.operationRuleLocation,
      };
      this.setState(StoreState.DONE);
    } catch (error: any) {
      this.setState(StoreState.ERROR);

      if (error.response?.status === 403) {
        this.setMessageError(i18n.t("youCanOnlyChangeItems"));
      } else {
        this.setMessageError(
          i18n.t(
            "oceanWeatherIntelligence.operationRuleLocation.errorUpdateLocationData"
          )
        );
      }
    }
  }

  async deleteOperationRuleLocation(id: number): Promise<Boolean> {
    if (!id) {
      throw new Error("Need to inform ID for delete data");
    }
    const operationRuleLocationToRemove = this.getOperationRuleLocation(id);

    if (operationRuleLocationToRemove) {
      const { operationRuleLocationService } = getEnv<RootEnv>();
      try {
        this.setState(StoreState.PEDDING);
        const result =
          await operationRuleLocationService.deleteOperationRuleLocation(id);
        this.setState(StoreState.DONE);
        if (result) {
          const operationRuleLocationToRemoveIndex =
            this.operationRuleLocations.indexOf(operationRuleLocationToRemove);
          this.operationRuleLocations.splice(
            operationRuleLocationToRemoveIndex,
            1
          );
          return true;
        }
      } catch (error: any) {
        this.setState(StoreState.ERROR);

        if (error.response?.status === 403) {
          this.setMessageError(i18n.t("youCanOnlyChangeItems"));
        } else {
          this.setMessageError(
            i18n.t(
              "oceanWeatherIntelligence.operationRuleLocation.errorDeleteLocationData"
            )
          );
        }
      }
    } else {
      this.setState(StoreState.DONE);
    }
    return false;
  }
}
