import React, { FC } from "react";
import { Input, InputProps } from "../Input/Input";

export type NumberInput = Omit<InputProps, "type">;

export const NumberInput: FC<NumberInput> = ({
  placeholder,
  icon,
  width,
  onChange,
  name,
  value,
  maxLength,
  inputStyle,
  disabled,
}) => {
  return (
    <Input
      step={"0.01"}
      type="number"
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      icon={icon}
      width={width}
      maxLength={maxLength}
      inputStyle={inputStyle}
      disabled={disabled}
    />
  );
};
