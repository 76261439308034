import { makeAutoObservable } from "mobx";
import { getEnv } from "mobx-easy";
import { StoreState } from "../../../enum/StoreState";
import { TableStationColumns } from "../../../enum/TableStationColumns";
import { RootEnv } from "../../setup/create-store";
import { IStation, IStationsFilter } from "../oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import i18n from "../../../i18n";
import { CancelToken } from "axios";

export default class ManageStationStore {
  state: string = StoreState.DONE;
  stations: IStation[] = [];
  filteredStations: IStation[] = [];
  errorMessage: string = "";
  stationCount: number = 0;
  searchFilterStations: IStation[] = [];
  searchByDescription: boolean = false;
  stationsHistory: IStation[] = [];
  searchFilterDescription: string = "";
  searchFilterRegion: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setStations(value: IStation[]) {
    this.stations = value;
  }

  setFilteredStations(value: IStation[]) {
    this.filteredStations = value;
  }

  setSearchFilterStations(value: IStation[]) {
    this.searchFilterStations = value;
  }

  setErrorMessage(value: string) {
    this.errorMessage = value;
  }

  setStationCount(value: number) {
    this.stationCount = value;
  }

  setSearchByDescription(value: boolean) {
    this.searchByDescription = value;
  }

  setStationsHistory(value: IStation[]) {
    this.stationsHistory = value;
  }

  setSearchFilterDescription(value: string) {
    this.searchFilterDescription = value;
  }

  setSearchFilterRegion(value: string) {
    this.searchFilterRegion = value;
  }

  reset() {
    this.setState(StoreState.DONE);
    this.setStations([]);
    this.setFilteredStations([]);
    this.setSearchFilterStations([]);
    this.setStationsHistory([]);
    this.setErrorMessage("");
    this.setStationCount(0);
    this.setSearchFilterDescription("");
    this.setSearchFilterRegion("");
  }

  async getStations(stationRequest: IStationsFilter, cancelToken?: CancelToken) {
    this.setState(StoreState.PEDDING);
    const { manageStationService } = getEnv<RootEnv>();

    try {
      const result = await manageStationService.getStations(stationRequest, cancelToken);

      if (!stationRequest.station_description && !stationRequest.macroregion_description) {
        this.setStations(result.stations);
        this.setFilteredStations(result.stations);
        this.setStationCount(result.stationCount);
        this.setSearchByDescription(false);
      } else {
        this.setSearchFilterStations(result.stations);
        this.setSearchByDescription(true);

        result.stations.map((station) => {
          const existingStation = this.stationsHistory.find((s) => s.station_id === station.station_id && s.region_id === station.region_id);
          if (!existingStation) {
            this.stationsHistory.push(station);
          }
        });

      }

      this.setState(StoreState.DONE);
      return result;
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  async updateStation(station: IStation) {
    this.setState(StoreState.PEDDING);
    this.setErrorMessage("");

    const { manageStationService } = getEnv<RootEnv>();

    try {
      const response = await manageStationService.updateStation(station);

      if (response.success) {
        const editStation = this.stations;
        const stationIndex = editStation.findIndex(s => s.station_id === station.station_id);
        editStation[stationIndex] = station;

        this.setStations(editStation);
      }

      this.setState(StoreState.DONE);
      return response;

    } catch (error: any) {
      this.setState(StoreState.ERROR);
      if (error?.response?.status === 404) {
        this.setErrorMessage(i18n.t("manageStation.errorNoEnvironmentalDataAtStation"))
      }
    }
  }

  filterStationByColumn(column: TableStationColumns, value: string) {
    if (!value) {
      return this.stations;
    }

    if (column === TableStationColumns.NAME) {
      return this.stations.filter((station) =>
        station.station_name.toLowerCase().includes(value.toLowerCase())
      );
    } else if (column === TableStationColumns.REGION) {
      return this.stations.filter((station) =>
        station.macro_region_name?.toLowerCase().includes(value.toLowerCase())
      );
    }
  }
}
