import { EnvironmentalVariables } from "../../../../enum/EnvironmentalVariables";
import i18n from "../../../../i18n";
import { ISerieConfigMap } from "./types/ISerieConfigMap";

export const getVisibilityConfigMap = (): Partial<
  Record<EnvironmentalVariables, ISerieConfigMap>
> => {
  const forecastColors: Partial<Record<EnvironmentalVariables, string>> = {
    [EnvironmentalVariables.VISIBILITY]: "#0DA12F",
    [EnvironmentalVariables.VISIBILITY_MAXIMUM_20KM]: "#0D57A1",
    [EnvironmentalVariables.VISIBILITY_MINIMUM_20KM]: "#0D8BA1",
    [EnvironmentalVariables.VISIBILITY_PROBABILITY_1000M]:
      "rgba(204, 176, 12, 0.55)",
    [EnvironmentalVariables.VISIBILITY_PROBABILITY_1500M]:
      "rgba(107, 23, 153, 0.55)",
    [EnvironmentalVariables.VISIBILITY_PROBABILITY_2000M]:
      "rgba(126, 148, 237, 0.55)",
  };

  return {
    [EnvironmentalVariables.VISIBILITY]: {
      serieConfig: {
        color: forecastColors[EnvironmentalVariables.VISIBILITY],
        lineWidth: 1,
        gridLineWidth: 1,
        marker: {
          enabled: false,
        },
        updateYAxis: true,
        yAxis: 1,
      },
      yAxisConfig: {
        opposite: true,
        title: {
          text: i18n.t("environmentalVariableEnum.visibility"),
          style: {
            color: "#000000",
          },
        },
        min: 0,
        max: 10000,
        labels: { format: "{value}", style: { color: "#000000" } },
        gridLineWidth: 0.5,
      },
    },
    [EnvironmentalVariables.VISIBILITY_MINIMUM_20KM]: {
      serieConfig: {
        type: "arearange",
        lineWidth: 0,
        fillOpacity: 0.1,
        zIndex: 2,
        marker: {
          enabled: false,
        },
        legend: {
          enabled: true,
        },
        color: forecastColors[EnvironmentalVariables.VISIBILITY_MINIMUM_20KM],
        yAxis: 1,
      },
      yAxisConfig: {
        opposite: true,
        title: {
          text: i18n.t("environmentalVariableEnum.visibility_minimum_20km"),
          style: {
            color: "#000000",
          },
        },
        min: 0,
        max: 10000,
        labels: { format: "{value}", style: { color: "#000000" } },
        gridLineWidth: 0.5,
      },
    },
    [EnvironmentalVariables.VISIBILITY_PROBABILITY_1000M]: {
      serieConfig: {
        type: "column",
        marker: {
          enabled: false,
        },
        groupPadding: 0,
        pointPadding: 0,
        borderWidth: 0,
        fillOpacity: 0.3,
        color:
          forecastColors[EnvironmentalVariables.VISIBILITY_PROBABILITY_1000M],
        yAxis: 0,
        updateYAxis: true,
      },
      yAxisConfig: {
        title: {
          text: i18n.t("environmentalVariableEnum.probability"),
          style: {
            color: "#000000",
          },
        },
        tickPixelInterval: 55,
        min: 0,
        max: 100,
        ceiling: 100,
        labels: { format: "{value}", style: { color: "#000000" } },
        gridLineWidth: 1,
      },
    },
    [EnvironmentalVariables.VISIBILITY_PROBABILITY_1500M]: {
      serieConfig: {
        type: "column",
        marker: {
          enabled: false,
        },
        groupPadding: 0,
        pointPadding: 0,
        borderWidth: 0,
        fillOpacity: 0.3,
        yAxis: 0,
        updateYAxis: true,
        color:
          forecastColors[EnvironmentalVariables.VISIBILITY_PROBABILITY_1500M],
      },
      yAxisConfig: {
        tickPixelInterval: 55,
        title: {
          text: i18n.t("environmentalVariableEnum.probability"),
          style: {
            color: "#000000",
          },
        },
        min: 0,
        max: 100,
        ceiling: 100,
        labels: { format: "{value}", style: { color: "#000000" } },
        gridLineWidth: 1,
      },
    },
    [EnvironmentalVariables.VISIBILITY_PROBABILITY_2000M]: {
      serieConfig: {
        type: "column",
        marker: {
          enabled: false,
        },
        groupPadding: 0,
        pointPadding: 0,
        borderWidth: 0,
        fillOpacity: 0.3,

        updateYAxis: true,
        yAxis: 0,
        color:
          forecastColors[EnvironmentalVariables.VISIBILITY_PROBABILITY_2000M],
      },
      yAxisConfig: {
        title: {
          text: i18n.t("environmentalVariableEnum.probability"),
          style: {
            color: "#000000",
          },
        },
        min: 0,
        max: 100,
        ceiling: 100,
        tickPixelInterval: 55,
        labels: { format: "{value}", style: { color: "#000000" } },
        gridLineWidth: 1,
      },
    },
  };
};
