import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "../../stores/setup/use-store";

import { EnvironmentalType } from "../../enum/EnvironmentalType";
import { StoreState } from "../../enum/StoreState";

import axios from "axios";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import PanelChart from "./PanelChart/PanelChart";
import PanelMap from "./PanelMap/PanelMap";
import { EnvironmentalVariables } from "../../enum/EnvironmentalVariables";
import { VisualizationType } from "enum/VisualizationType";
import { Spin } from "antd";

const RiverForecastChart: React.FC = () => {
  const {
    dataStores: {
      forecastChartPageStore,
      regionStore,
      unitMeasurementStore,
      riverForecastChartStore,
      analyticsChartStore,
    },
  } = useStores();

  const { i18n } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartPage",
  });

  const preventTranslationOnRender = useRef(true);

  useEffect(() => {
    unitMeasurementStore.getUserUnitMeasurementApi();

    riverForecastChartStore.setTabStateActive(EnvironmentalType.WATER_FLOW);

    if (riverForecastChartStore.stations.length === 0) {
      riverForecastChartStore.setClickMapLatLng(undefined);
      riverForecastChartStore.setSelectedStation(0);

      riverForecastChartStore.getRiverStations();
    }
  }, []);

  useEffect(() => {
    if (preventTranslationOnRender?.current) {
      preventTranslationOnRender.current = false;
      return;
    }

    riverForecastChartStore.setActionCallChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const controller = new AbortController();

    if (!riverForecastChartStore.clickMapLatLng) {
      riverForecastChartStore.setOpenMeteogram(false);
    }

    riverForecastChartStore.updateMacroRegionOrigin(
      riverForecastChartStore.clickMapLatLng,
      regionStore.macroRegions
    );

    riverForecastChartStore.setOpenMeteogram(true);

    openMeteogramChartMultipoints(controller);

    return () => {
      source.cancel("Request cancelled");
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riverForecastChartStore.actionCallChart, regionStore.macroRegions]);

  useEffect(() => {
    const controller = new AbortController();

    const coords = getCoordinatesIfValid();

    if (!coords) return;

    const { macroRegionOrigin } = coords;

    if (!macroRegionOrigin) {
      riverForecastChartStore.setState(StoreState.DONE);
      riverForecastChartStore.setIsChartDataEmpty(true);
      return;
    }

    riverForecastChartStore.setIsChartDataEmpty(false);
    analyticsChartStore.setIsChartDataEmpty(false);

    if (
      riverForecastChartStore.visualizationType === VisualizationType.FORECAST
    ) {
      getForecastChart(controller);
    } else if (
      riverForecastChartStore.visualizationType === VisualizationType.SENSOR
    ) {
      getMeasuredChart(controller);
    }

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riverForecastChartStore.actionCallMapClick]);

  const openMeteogramChartMultipoints = (controller: AbortController) => {
    const coords = getCoordinatesIfValid();
    if (!coords) return;

    const { macroRegionOrigin } = coords;

    if (!macroRegionOrigin) {
      riverForecastChartStore.setIsChartDataEmpty(true);
      analyticsChartStore.setIsChartDataEmpty(true);
      return;
    }

    riverForecastChartStore.setIsChartDataEmpty(false);
    analyticsChartStore.setIsChartDataEmpty(false);

    if (
      riverForecastChartStore.visualizationType === VisualizationType.FORECAST
    ) {
      getForecastChart(controller);
    } else if (
      riverForecastChartStore.visualizationType === VisualizationType.SENSOR
    ) {
      getMeasuredChart(controller);
    }
  };

  const getForecastChart = (controller?: AbortController) => {
    const latLng = riverForecastChartStore.clickMapLatLng;
    const macroRegionOrigin = riverForecastChartStore.macroRegionOrigin;

    riverForecastChartStore.getRiverForecastChart(
      {
        latitude: latLng.lat,
        longitude: latLng.lng,
        environmentalVariable: EnvironmentalVariables.WATER_LEVEL,
        macroregionOrigin: macroRegionOrigin!,
        stationId: riverForecastChartStore.selectedStation,
      },
      controller
    );
  };

  const getMeasuredChart = (controller?: AbortController) => {
    const stationId = riverForecastChartStore.selectedStation;
    const regionId = riverForecastChartStore.regionId;

    analyticsChartStore.getMeasuredDataChartApi({
      environmentalType: EnvironmentalType.WATER_FLOW,
      stationId,
      regionId,
      daysMeasured: 30,
      controller,
    });
  };

  const getCoordinatesIfValid = () => {
    const latLng = riverForecastChartStore.clickMapLatLng;

    if (!latLng) return false;

    if (!riverForecastChartStore.macroRegionOrigin) {
      riverForecastChartStore.setIsChartDataEmpty(true);
      analyticsChartStore.setIsChartDataEmpty(true);
      return false;
    }

    const isLatLonValidGrid = forecastChartPageStore.validateLatLonGrid(
      latLng.lat,
      latLng.lng,
      riverForecastChartStore.macroRegionOrigin,
      EnvironmentalType.WATER_FLOW
    );

    if (!isLatLonValidGrid) {
      riverForecastChartStore.setState(StoreState.DONE);
      riverForecastChartStore.setIsChartDataEmpty(true);
      analyticsChartStore.setIsChartDataEmpty(true);
      return false;
    }

    return {
      latLng,
      macroRegionOrigin: riverForecastChartStore.macroRegionOrigin,
    };
  };

  return (
    <Box h="92.2vh">
      <Box h={"88vh"}>
        <Box position="relative">
          <Box>
            <Spin
              spinning={riverForecastChartStore.mapState === StoreState.PEDDING}
            >
              <PanelMap />
            </Spin>
            <PanelChart />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default observer(RiverForecastChart);
