import React from "react";

interface DirectionPointerProps {
  angle: number;
  pointerColor: string;
  pointerText?: string;
  width: string;
  reverse?: boolean;
}

const DirectionPointer: React.FC<DirectionPointerProps> = ({
  angle = 0,
  pointerColor,
  pointerText,
  width,
  reverse,
}) => {
  return (
    <div
      style={{
        position: "absolute",
        transform: `rotate(${angle}deg) translateZ(0)`,
        transformOrigin: "left center",
        height: "1.5rem",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: reverse ? `1.5rem ${width}` : `2rem ${width}`,
          alignItems: "center",
          width: "8rem",
          height: "100%",
        }}
      >
        <div>
          {reverse ? (
            <div
              style={{
                position: "absolute",
                top: "-1px",
                left: "50%",
                transform:
                  "translateX(810%) translateY(-5%) perspective(1px) translateZ(0)",
                width: 0,
                height: 0,
                borderStyle: "solid",
                borderWidth: "14px 0px 14px 14px",
                borderColor: `transparent transparent transparent ${pointerColor}`,
                zIndex: "1",
                borderRadius: "8px",
              }}
            ></div>
          ) : (
            <div
              style={{
                position: "absolute",
                top: "-1px",
                left: "50%",
                transform:
                  "translateX(-303%) translateY(15%) perspective(1px) translateZ(0)",
                width: 0,
                height: 0,
                borderStyle: "solid",
                borderWidth: "10px 14px 10px 0",
                borderColor: `transparent ${pointerColor} transparent transparent`,
                zIndex: "1",
                borderRadius: "8px",
              }}
            ></div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            width: "100%",
            height: "100%",
            backgroundColor: pointerColor,
            borderRadius: "8px",
            transform: "perspective(1px) translateZ(0)",
          }}
        >
          <p
            style={{
              color: "white",
              width: 120,
              textAlign: angle > 90 ? "left" : "right",
              marginRight: "8px",
              scale: angle > 90 ? "-1" : undefined,
              transform: "perspective(1px) translateZ(0)",
            }}
          >
            {pointerText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DirectionPointer;
