import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Center, Flex, Grid, GridItem, Progress, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../stores/setup/use-store";
import windIcon from "../../../assets/event-analysis-card/wind.svg";
import arrowIcon from "../../../assets/event-analysis-card/arrow-up.svg";
import gustIcon from "../../../assets/event-analysis-card/gust.svg";
import dropIcon from "../../../assets/event-analysis-card/drop.svg";
import Card from "./components/Card";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { divIcon } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import Compass from "./components/Compass";
import i18n from "../../../i18n";
import { transformGeo } from "../../../util/meteogramCharts/transformGeo/transformGeo";
import { IStation } from "../../../stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import { IOccurrenceDescription } from "../../../types/IOccurrence";
import { StoreState } from "../../../enum/StoreState";
import { DateTime } from "luxon";
import { EnvironmentalType } from "../../../enum/EnvironmentalType";
import MapLegend from "./components/MapLegend";
import Meteogram from "./components/Meteogram";

interface WeatherTabProps {
  selectedOccurrenceDescription: IOccurrenceDescription | undefined;
  station: IStation | undefined;
}

const WeatherTab: React.FC<WeatherTabProps> = ({
  selectedOccurrenceDescription,
  station,
}) => {
  const {
    dataStores: { occurrenceStore },
  } = useStores();

  const { t } = useTranslation("translation", {
    keyPrefix: "eventAnalysis",
  });

  const [stationMarkerPosition, setStationMarkerPosition] =
    useState<
      | {
          lat: number;
          lng: number;
        }
      | undefined
    >();

  const statistics = useMemo(
    () => occurrenceStore.weatherData,
    [occurrenceStore.weatherData]
  );

  const precipitationDate = useMemo(() => {
    return statistics ? DateTime.fromISO(statistics.precipitationDate) : null;
  }, [statistics]);

  const formattedPrecipitationDate = useMemo(() => {
    return precipitationDate
      ? precipitationDate
          .setLocale(i18n.language)
          .toLocaleString(DateTime.DATETIME_SHORT)
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [precipitationDate, i18n.language]);

  useEffect(() => {
    if (occurrenceStore.occurrence) {
      const { initialDate, finalDate, stationId, regionId } =
        occurrenceStore.occurrence;

      occurrenceStore.getOccurrenceAnalysis({
        regionId,
        stationId,
        environmentalType: EnvironmentalType.WEATHER,
        initialDate,
        finalDate,
      });
    }

    if (station) {
      setStationMarkerPosition({
        lat: station?.station_lat,
        lng: station?.station_lon,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOccurrenceDescription]);

  return (
    <>
      <Grid templateColumns={"repeat(10, 1fr)"} gap={4}>
        <GridItem colSpan={3}>
          <Flex flexDir="column" gap={4}>
            <Card
              icon={windIcon}
              title={t("wind")}
              textLeft={t("averageValue")}
              textRight={t("maxValue")}
              valueLeft={
                statistics
                  ? statistics?.windData?.average.toFixed(1) + " " + t("knots")
                  : ""
              }
              valueRight={
                statistics
                  ? statistics?.windData?.max.toFixed(1) + " " + t("knots")
                  : ""
              }
              iconLeft={arrowIcon}
              iconLeftRotation={
                statistics ? statistics.directionAverage + 180 : undefined
              }
              bottomLeftText={
                statistics
                  ? transformGeo(statistics.directionAverage)
                  : undefined
              }
              iconRight={arrowIcon}
              iconRightRotation={
                statistics ? statistics.directionMax + 180 : undefined
              }
              bottomRightText={
                statistics ? transformGeo(statistics.directionMax) : undefined
              }
              isLoading={
                selectedOccurrenceDescription &&
                occurrenceStore.state === StoreState.PEDDING
              }
            />

            <Card
              icon={gustIcon}
              title={t("gust")}
              textLeft={t("averageValue")}
              textRight={t("maxValue")}
              valueLeft={
                statistics
                  ? statistics?.gustData?.average.toFixed(1) + " " + t("knots")
                  : ""
              }
              valueRight={
                statistics
                  ? statistics?.gustData?.max.toFixed(1) + " " + t("knots")
                  : ""
              }
              iconLeft={arrowIcon}
              iconLeftRotation={
                statistics ? statistics.directionAverage + 180 : undefined
              }
              bottomLeftText={
                statistics
                  ? transformGeo(statistics.directionAverage)
                  : undefined
              }
              iconRight={arrowIcon}
              iconRightRotation={
                statistics ? statistics.directionMax + 180 : undefined
              }
              bottomRightText={
                statistics ? transformGeo(statistics.directionMax) : undefined
              }
              isLoading={
                selectedOccurrenceDescription &&
                occurrenceStore.state === StoreState.PEDDING
              }
            />

            <Card
              icon={dropIcon}
              title={t("rain")}
              textLeft={t("accumulatedValue")}
              textRight={t("peakValue")}
              valueLeft={
                statistics
                  ? statistics?.precipitationAccumulated?.toFixed(0) +
                    " " +
                    "mm"
                  : ""
              }
              valueRight={formattedPrecipitationDate}
              hideBottomLeftText={true}
              hideBottomRightText={true}
              isLoading={
                selectedOccurrenceDescription &&
                occurrenceStore.state === StoreState.PEDDING
              }
            />
          </Flex>
        </GridItem>
        <GridItem colSpan={7}>
          {occurrenceStore.occurrence ? (
            occurrenceStore.weatherChart &&
            Object.keys(occurrenceStore.weatherChart as object).length ? (
              <>
                <MapLegend
                  averageColor="#9D53F2"
                  averageText={t("averageWindGust") as string}
                  maxColor="#286793"
                  maxText={t("maxWindGust") as string}
                />

                <MapContainer
                  key="mapStation"
                  center={stationMarkerPosition}
                  zoom={12}
                  scrollWheelZoom={false}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: 8,
                  }}
                >
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {stationMarkerPosition && statistics && (
                    <>
                      <Marker
                        position={stationMarkerPosition}
                        icon={divIcon({
                          html: renderToStaticMarkup(
                            <i
                              style={{
                                color: "#000",
                                marginLeft: "-8px",
                                marginTop: "-10px",
                              }}
                              className="fas fa-map-marker-alt fa-2x"
                            />
                          ),
                        })}
                      ></Marker>
                      <Marker
                        position={stationMarkerPosition}
                        icon={divIcon({
                          html: renderToStaticMarkup(
                            <Compass
                              maxDegrees={statistics?.directionMax.toString()}
                              maxPointerText={`${statistics?.windData?.max.toFixed(
                                0
                              )}kt | ${statistics?.gustData?.max.toFixed(0)}kt`}
                              averageDegrees={statistics?.directionAverage.toString()}
                              averagePointerText={`${statistics?.windData?.average.toFixed(
                                0
                              )}kt | ${statistics?.gustData?.average.toFixed(
                                0
                              )}kt`}
                            />
                          ),
                        })}
                      ></Marker>
                    </>
                  )}
                </MapContainer>
              </>
            ) : (
              <Center
                h="100%"
                w="100%"
                borderRadius={8}
                bg="white"
                color="newBlue.500"
              >
                {occurrenceStore.state === StoreState.PEDDING ? (
                  <Progress w="500px" size="xs" isIndeterminate />
                ) : (
                  <Text>{t("noi4castDataFound")}</Text>
                )}
              </Center>
            )
          ) : !selectedOccurrenceDescription ? (
            <Center
              h="100%"
              w="100%"
              borderRadius={8}
              bg="white"
              color="newBlue.500"
            >
              <Text>{t("selectAnOccurrenceToBegin")}</Text>
            </Center>
          ) : (
            <Center
              h="100%"
              w="100%"
              borderRadius={8}
              bg="white"
              color="newBlue.500"
            >
              {occurrenceStore.state === StoreState.PEDDING && (
                <Progress w="500px" size="xs" isIndeterminate />
              )}
            </Center>
          )}
        </GridItem>
      </Grid>

      <Meteogram
        description={selectedOccurrenceDescription}
        chart={occurrenceStore.weatherChart}
        timezone={
          occurrenceStore.weatherChart?.data?.plotOptions?.series.timezoneOffset
        }
        chartXAxis={occurrenceStore.i4castChartXAxis}
        chartYAxis={occurrenceStore.i4castChartYAxis}
      />
    </>
  );
};
export default observer(WeatherTab);
