import { JoyrideStorage } from "enum/JoyrideStorage";
import { metOceanReportJoySteps } from "../steps/MetOceanReportJoySteps";
import GuidedTutorial from "./GuidedTutorial";

export default function MetOceanReportTutorial({ shouldRunJoyride = false }) {
  return (
    <GuidedTutorial
      stepsProps={metOceanReportJoySteps}
      storage={[JoyrideStorage.METOCEAN_REPORT_RIDE_ON_LOCAL_STORAGE]}
      shouldRunJoyride={shouldRunJoyride}
    />
  );
}
