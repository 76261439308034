import { useEffect } from "react";
import { DateTime } from "luxon";
import ForecastChartInsightStore from "../stores/data/forecastChart/forecastChartInsight/forecastChartInsightStore";
import ForecastChartPageStore from "../stores/data/forecastChart/forecastChartPage/forecastChartPageStore";
import axios from "axios";
import { roundToNearestHour } from "../util/roundHours/roundHours";

function useFetchInsightMultiStation(
  forecastChartInsightStore: ForecastChartInsightStore,
  forecastChartPageStore: ForecastChartPageStore,
  insightId?: number
) {
  useEffect(() => {
    if (!insightId) return;

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const roundedNow = roundToNearestHour(DateTime.now().toMillis());
    const initialDate =
      forecastChartInsightStore.timelineDate?.start || roundedNow.toMillis();
    const endDate =
      forecastChartInsightStore.selectedTimelineDate?.end ||
      roundedNow.plus({ hours: 4 }).toMillis();

    const stations = forecastChartInsightStore.findStationsRegionsByCoordinates(
      forecastChartPageStore.stations
    );

    if (!stations?.length) return;

    forecastChartInsightStore.getInsightMultiStation(
      insightId,
      initialDate,
      endDate,
      stations,
      source.token
    );

    return () => {
      source.cancel("Request cancelled");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastChartInsightStore.selectedCoordinatesWithinRadius, insightId]);
}

export default useFetchInsightMultiStation;
