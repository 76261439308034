import { makeAutoObservable } from "mobx";
import { getEnv } from "mobx-easy";
import { ManeuverAction } from "../../../enum/ManeuverAction";
import { StoreState } from "../../../enum/StoreState";
import { RootEnv } from "../../setup/create-store";
import { IDynamicUKCLineChart } from "../../../types/IDynamicUKCLineChart";
import { IOperationWindowForDynamicDraft } from "./PortOfCallModel";
import i18n from "../../../i18n";
export default class PortOfCallStore {
  noDataLineChart: IDynamicUKCLineChart = {
    limits: { min: 0, max: 5 },
    serie: { name: "", units: "", data: [] },
    draft: 0,
  };

  operationWindowForDynamicDraft: IOperationWindowForDynamicDraft[] = [];

  maneuverUKCDocking: IDynamicUKCLineChart = this.noDataLineChart;

  maneuverUKCUndocking: IDynamicUKCLineChart = this.noDataLineChart;

  operationWindowSelected: number = 0;

  state: string = StoreState.DONE;

  messageError: string = "";

  stateDocking: string = StoreState.DONE;

  messageErrorDocking: string = "";

  stateUndocking: string = StoreState.DONE;

  messageErrorUndocking: string = "";

  ukcRestriction: string = "1.5";

  clientOperationWindowId: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setMessageError(value: string) {
    this.messageError = value;
  }

  setStateDocking(value: StoreState) {
    this.stateDocking = value;
  }

  setMessageErrorDocking(value: string) {
    this.messageError = value;
  }

  setStateUndocking(value: StoreState) {
    this.stateUndocking = value;
  }

  setMessageErrorUndocking(value: string) {
    this.messageError = value;
  }

  setOperationWindowForDynamicDraft(value: IOperationWindowForDynamicDraft[]) {
    this.operationWindowForDynamicDraft = value;
  }

  setManeuverUKCDocking(value: IDynamicUKCLineChart) {
    this.maneuverUKCDocking = value;
  }

  setManeuverUKCUndocking(value: IDynamicUKCLineChart) {
    this.maneuverUKCUndocking = value;
  }

  setOperationWindowSelected(value: number) {
    this.operationWindowSelected = value;
  }

  setUkcRestriction(value: string) {
    this.ukcRestriction = value;
  }

  setClientOperationWindowId(value: string) {
    this.clientOperationWindowId = value;
  }

  reset() {
    this.setState(StoreState.DONE);
    this.setStateDocking(StoreState.DONE);
    this.setStateUndocking(StoreState.DONE);
    this.setMessageError("");
    this.setMessageErrorDocking("");
    this.setMessageErrorUndocking("");
    this.setOperationWindowForDynamicDraft([]);
    this.setOperationWindowSelected(0);
    this.setManeuverUKCDocking(this.noDataLineChart);
    this.setManeuverUKCUndocking(this.noDataLineChart);
    this.setClientOperationWindowId("");
  }

  async getOperationWindowForDynamicDraftApi() {
    const { portOfCallService } = getEnv<RootEnv>();
    try {
      this.setOperationWindowForDynamicDraft([]);
      this.setState(StoreState.PEDDING);
      const operationWindowForDynamicDraft: IOperationWindowForDynamicDraft[] =
        await portOfCallService.getOperationWindowForDynamicDraft();
      if (operationWindowForDynamicDraft.length > 0) {
        const findMainOperationWindow = operationWindowForDynamicDraft.find(
          (value) => value.mainWindow
        );
        if (findMainOperationWindow) {
          this.setOperationWindowSelected(findMainOperationWindow.id);
          this.setClientOperationWindowId(
            findMainOperationWindow.clientOperationWindowId
          );
        } else {
          this.setOperationWindowSelected(
            operationWindowForDynamicDraft[
              operationWindowForDynamicDraft.length - 1
            ].id
          );

          this.setClientOperationWindowId(
            operationWindowForDynamicDraft[
              operationWindowForDynamicDraft.length - 1
            ].clientOperationWindowId
          );
        }
        this.setOperationWindowForDynamicDraft(operationWindowForDynamicDraft);
        if (this.operationWindowSelected > 0) {
          this.getManeuverUKCApi(
            this.operationWindowSelected,
            ManeuverAction.DOCKING
          );
          this.getManeuverUKCApi(
            this.operationWindowSelected,
            ManeuverAction.UNDOCKING
          );
        }
      }
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t("portOfCallStore.errorToGetOperationWindowData")
      );
    }
  }

  async getManeuverUKCApi(
    operationWindowId: number,
    maneuverActionId: ManeuverAction
  ) {
    const { portOfCallService } = getEnv<RootEnv>();
    if (maneuverActionId === ManeuverAction.DOCKING) {
      this.setStateDocking(StoreState.PEDDING);
      this.setManeuverUKCDocking(this.noDataLineChart);
    } else {
      this.setStateUndocking(StoreState.PEDDING);
      this.setManeuverUKCUndocking(this.noDataLineChart);
    }
    try {
      const dynamicUKC: IDynamicUKCLineChart =
        await portOfCallService.getManeuverUKC(
          operationWindowId,
          maneuverActionId
        );
      if (dynamicUKC.serie.data.length === 0) {
        this.setStateDocking(StoreState.DONE);
        this.setStateUndocking(StoreState.DONE);
        return;
      }
      if (maneuverActionId === ManeuverAction.DOCKING) {
        this.setManeuverUKCDocking(dynamicUKC);
        this.setStateDocking(StoreState.DONE);
      } else {
        this.setManeuverUKCUndocking(dynamicUKC);
        this.setStateUndocking(StoreState.DONE);
      }
    } catch (error) {
      if (maneuverActionId === ManeuverAction.DOCKING) {
        this.setMessageErrorDocking(
          i18n.t("portOfCallStore.errorToGetDockingDynamicUkcData")
        );
        this.setStateDocking(StoreState.ERROR);
      } else {
        this.setMessageErrorUndocking(
          i18n.t("portOfCallStore.errorToGetDockingDynamicUkcData")
        );
        this.setStateUndocking(StoreState.ERROR);
      }
    }
  }

  getUKCRestrictionLine(maneuverActionId: ManeuverAction) {
    const data =
      maneuverActionId === ManeuverAction.DOCKING
        ? this.maneuverUKCDocking.serie.data
        : this.maneuverUKCUndocking.serie.data;
    let restrictionLine: number[][] = [];

    data.map((item) => {
      const date = item[0];
      restrictionLine.push([date, parseFloat(this.ukcRestriction)]);
    });

    return restrictionLine;
  }

  getClientOperationalWindowId(operationWindowId: number) {
    if (operationWindowId > 0) {
      const index = this.operationWindowForDynamicDraft.findIndex(
        (x) => x.id === operationWindowId
      );
      const clientOperationWindowId =
        this.operationWindowForDynamicDraft[index]?.clientOperationWindowId;
      this.setClientOperationWindowId(clientOperationWindowId);
    }
  }
}
