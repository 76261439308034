import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStores } from "stores/setup/use-store";

const RadarLegend = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartPanelMeteogram",
  });

  const {
    dataStores: { forecastChartPageStore },
  } = useStores();

  return (
    <Box
      px={2}
      py={1}
      borderRadius={4}
      bg="rgba(0, 0, 0, 0.37)"
      color="white"
      zIndex={9999999}
      marginRight={5}
    >
      <Center fontWeight={"bold"}>{t("reflectivity")}</Center>

      <Flex>
        <Box>
          <Center fontSize="12px">{t("vweak")}</Center>
          <Flex justifyContent="space-between" fontSize="12px">
            <p>0</p>
            <p>20</p>
          </Flex>
          <Box
            h="12px"
            width="75px"
            bgGradient="linear(to-r, rgb(117, 255, 255), rgb(0, 0, 246))"
          ></Box>
          <Flex justifyContent="space-between" fontSize="12px">
            <p>0</p>
            <p>1</p>
          </Flex>
        </Box>
        <Box>
          <Box fontSize="12px" marginLeft="5px">
            {t("weak")}
          </Box>
          <Flex justifyContent="center" fontSize="12px">
            <p>30</p>
          </Flex>
          <Box
            h="12px"
            width="90px"
            bgGradient="linear(to-r, rgb(0, 255, 0), rgb(0, 153, 0))"
          ></Box>
          <Flex justifyContent="center" fontSize="12px">
            <p>3</p>
          </Flex>
        </Box>
        <Box>
          <Flex justifyContent="start" marginLeft="-20px" fontSize="12px">
            {t("moderate")}
          </Flex>
          <Flex justifyContent="end" fontSize="12px">
            <p>45</p>
          </Flex>
          <Box
            h="12px"
            width="65px"
            bgGradient="linear(to-r, rgb(255, 255, 0), rgb(255, 153, 0))"
          ></Box>
          <Flex justifyContent="end" fontSize="12px">
            <p>25</p>
          </Flex>
        </Box>
        <Box>
          <Center fontSize="12px">{t("strong")}</Center>
          <Flex justifyContent="end"></Flex>
          <Box
            h="12px"
            width="40px"
            marginTop="18px"
            bgGradient="linear(to-r, rgb(255, 0, 0), rgb(196, 0, 1))"
          ></Box>
        </Box>

        <Box>
          <Center fontSize="12px">{t("vstrong")}</Center>
          <Flex justifyContent="space-between" fontSize="12px">
            <p style={{ marginLeft: "15px" }}>63</p>
            <p>75</p>
          </Flex>
          <Box
            h="12px"
            width="90px"
            bgGradient="linear(to-r, rgb(255, 0, 255), rgb(151, 88, 201))"
          ></Box>
          <Flex justifyContent="space-between" fontSize="12px">
            <p style={{ marginLeft: "15px" }}>&gt;100</p>
          </Flex>
        </Box>
      </Flex>

      <Center fontWeight={"bold"}>{t("precipitationPotential")}</Center>
      <Text fontSize={"11px"} mt={"4px"}>
        {t("lastUpdatedAt")} {forecastChartPageStore.lastRadarUpdateDate}
      </Text>
    </Box>
  );
};

export default observer(RadarLegend);
