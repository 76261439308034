import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import React from "react";

interface LegendItemProps {
  color: string;
  label: string;
  tooltipText?: string;
}

export const LegendItem: React.FC<LegendItemProps> = ({
  color,
  label,
  tooltipText,
}) => {
  return (
    <Tooltip label={tooltipText} placement="top" hasArrow>
      <Flex alignItems={"baseline"} gap={2}>
        <Box bg={color} w="20px" h="7px" borderRadius="4px" />
        <Text fontSize="xs" lineHeight="1">
          {label}
        </Text>
      </Flex>
    </Tooltip>
  );
};
