import axios from "axios";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import { observer } from "mobx-react-lite";
import {
  Badge,
  Box,
  Center,
  Flex,
  Icon,
  IconButton,
  Switch,
  Td,
  Text,
  Th,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { PageTitle } from "../../../components/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../stores/setup/use-store";
import { StoreState } from "../../../enum/StoreState";
import ManageStationFilterDropdown from "./Components/ManageStationFilterDropdown";
import {
  IStation,
  IStationsFilter,
} from "../../../stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import { EnvironmentalType } from "../../../enum/EnvironmentalType";
import { EditStationModal } from "./Components/EditStationModal";
import TableComponent from "../../../components/Table/Table";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import filterIcon from "../../../assets/filter_icon.svg";
import { SCREEN_HORIZONTAL_SPACING } from "../../../constants";

const ManageStation: React.FC = () => {
  const {
    dataStores: { manageStationStore, forecastChartPageStore },
  } = useStores();

  const { t } = useTranslation("translation", {
    keyPrefix: "manageStation",
  });

  const [uniqueStationNames, setUniqueStationNames] = useState<string[]>([]);
  const [uniqueStationRegion, setUniqueStationRegion] = useState<
    (string | undefined)[]
  >([]);
  const [stationRequest, setStationRequest] =
    useState<IStationsFilter | undefined>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const searchIconNameRef = useRef<HTMLDivElement | null>(null);
  const searchIconRegionRef = useRef<HTMLDivElement | null>(null);

  const dropdownNameRef = useRef<HTMLDivElement | null>(null);
  const dropdownRegionRef = useRef<HTMLDivElement | null>(null);

  const [showFilterName, setShowFilterName] = useState(false);
  const [showFilterRegion, setShowFilterRegion] = useState(false);
  const [superUser, setSuperUser] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState<
    Record<string, string[]>
  >({});

  const [currentData, setCurrentData] = useState(manageStationStore.stations);
  const [editStation, setEditStation] = useState<IStation>();
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getStations({ limit: 10, offset: 0 });

    if (localStorage.getItem("@super_user") === "true") {
      setSuperUser(true);
    }

    const handleDocumentClick = (event: MouseEvent) => {
      if (
        dropdownNameRef.current &&
        !dropdownNameRef.current.contains(event.target as Node) &&
        searchIconNameRef.current &&
        !searchIconNameRef.current.contains(event.target as Node)
      ) {
        setShowFilterName(false);
        manageStationStore.setSearchFilterDescription("");
      }

      if (
        dropdownRegionRef.current &&
        !dropdownRegionRef.current.contains(event.target as Node) &&
        searchIconRegionRef.current &&
        !searchIconRegionRef.current.contains(event.target as Node)
      ) {
        setShowFilterRegion(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const uniqueStationNames = Array.from(
      new Set(
        manageStationStore.searchFilterStations.map(
          ({ station_name }) => station_name
        )
      )
    );

    setUniqueStationNames(uniqueStationNames);

    const uniqueStationRegion = Array.from(
      new Set(
        manageStationStore.searchFilterStations.map(
          ({ macro_region_name }) => macro_region_name
        )
      )
    );

    setUniqueStationRegion(uniqueStationRegion);
  }, [
    manageStationStore.searchByDescription,
    manageStationStore.searchFilterStations,
    manageStationStore.stations,
    manageStationStore.stationsHistory,
  ]);

  useEffect(() => {
    handleFilterStation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, manageStationStore.stations]);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    if (stationRequest) {
      manageStationStore.getStations(stationRequest, source.token);
    }

    return () => {
      source.cancel("Request cancelled");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationRequest]);

  const getStations = (stationRequest: IStationsFilter) => {
    setStationRequest(stationRequest);
  };

  const handleFilterChange = (column: string, filterValue: string) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [column]: prevFilters[column]?.includes(filterValue)
        ? prevFilters[column].filter((val) => val !== filterValue)
        : [...(prevFilters[column] || []), filterValue],
    }));
  };

  const filteredData = useCallback(() => {
    if (Object.keys(selectedFilters)?.length === 0) {
      setCurrentData(manageStationStore.stations);
    } else if (
      (selectedFilters["name"] && !selectedFilters["name"].length) ||
      (selectedFilters["region"] && !selectedFilters["region"].length)
    ) {
      setCurrentData([]);
    } else {
      return manageStationStore.stationsHistory.filter((station) => {
        for (const column in selectedFilters) {
          if (column === "name") {
            if (
              selectedFilters[column]?.length &&
              !selectedFilters[column].includes(station.station_name)
            ) {
              return false;
            }
          } else if (column === "region") {
            if (
              selectedFilters[column]?.length > 0 &&
              !selectedFilters[column].includes(station.macro_region_name!)
            ) {
              return false;
            }
          }
        }
        return true;
      });
    }
  }, [
    selectedFilters,
    manageStationStore.stations,
    manageStationStore.stationsHistory,
  ]);

  const handleFilterStation = () => {
    const data = filteredData();
    if (data) {
      setCurrentData(data);
    }
  };

  const formatEnvironmentalTypes = (
    environmentalTypes: string[] | undefined
  ) => {
    if (!environmentalTypes || environmentalTypes.length === 0) {
      return "";
    }

    const formattedTypes = environmentalTypes.map((type) => formatType(type));

    return formattedTypes;
  };

  const handleResetFilter = (filterType: string) => {
    getStations({
      limit: 10,
      offset: 0,
    });

    setSelectedFilters({});
    manageStationStore.setSearchFilterStations([]);
    manageStationStore.setStationsHistory([]);

    setCurrentPage(0);
    manageStationStore.setSearchFilterDescription("");
  };

  const formatType = (type: string) => {
    switch (type) {
      case EnvironmentalType.WEATHER:
        return (
          <Badge colorScheme="green" mr={2} mt={2}>
            {t("weather")}
          </Badge>
        );
      case EnvironmentalType.WAVE:
        return (
          <Badge colorScheme="purple" mr={2} mt={2}>
            {t("wave")}
          </Badge>
        );
      case EnvironmentalType.WAVE_SPECTRA:
        return (
          <Badge colorScheme="cyan" mr={2} mt={2}>
            {t("waveSpectra")}
          </Badge>
        );
      case EnvironmentalType.WATER_FLOW:
        return (
          <Badge colorScheme="messenger" mr={2} mt={2}>
            {t("waterFlow")}
          </Badge>
        );
      default:
        return type;
    }
  };

  const renderCell = (station: IStation) => {
    const {
      station_name,
      macro_region_name,
      station_lat,
      station_lon,
      data_history,
      environmentalTypes,
    } = station;

    return (
      <>
        <Td>{station_name}</Td>
        <Td>{macro_region_name}</Td>
        <Td>
          {station_lat}, {station_lon}
        </Td>
        <Td>
          <Switch
            isDisabled={!superUser}
            isChecked={data_history}
            onChange={() => handleSwitchDataHistory(station)}
          />
        </Td>
        <Td>{formatEnvironmentalTypes(environmentalTypes?.sort())}</Td>
        <Td textAlign={"end"}>
          <IconButton
            isDisabled={!superUser}
            icon={<EditIcon />}
            color={"#286795"}
            _hover={{ layerStyle: "lightOcean" }}
            size={"sm"}
            aria-label={""}
            onClick={() => handleOpenModal(station)}
            bg={"none"}
          />
        </Td>
      </>
    );
  };

  const renderHeader = () => (
    <Tr>
      <Th color="white" fontWeight="light" fontSize="md" w={250}>
        <Flex alignItems="center" ref={searchIconNameRef}>
          <Text mr={2}>{t("station")}</Text>
          <Icon
            cursor="pointer"
            color={selectedFilters["name"] ? "#f9d321" : "white"}
            as={SearchIcon}
            onClick={() => setShowFilterName((prevState: any) => !prevState)}
          />
          {selectedFilters["name"] ? (
            <Center
              border="1px solid"
              borderColor="#fff"
              borderRadius={8}
              cursor={"pointer"}
              onClick={() => handleResetFilter("name")}
              ml={2}
              p={1}
            >
              <Text fontSize={"xs"} fontWeight="light" ml={2} as="p">
                {t("clean")}
              </Text>
              <img src={filterIcon} alt="" />
            </Center>
          ) : (
            <></>
          )}
        </Flex>
      </Th>
      <Th color="white" fontWeight="light" fontSize="md" w={250}>
        <Flex alignItems="center" ref={searchIconRegionRef}>
          <Text mr={2}>{t("region")}</Text>
        </Flex>
      </Th>
      <Th color="white" fontWeight="light" fontSize="md" w={250}>
        {t("latLon")}
      </Th>
      <Th color="white" fontWeight="light" fontSize="md" w={150}>
        {t("historicalData")}
      </Th>
      <Th color="white" fontWeight="light" fontSize="md" w={250}>
        {t("forecastDataType")}
      </Th>
      <Th w={55}></Th>
    </Tr>
  );

  const handleOpenModal = (station: IStation) => {
    setEditStation(station);
    onOpen();
  };

  const handleSaveEditStation = async (station: IStation) => {
    const response = await manageStationStore.updateStation(station);

    if (response?.success) {
      toast({
        title: t("successEditStation"),
        status: "success",
        position: "bottom-right",
        isClosable: true,
      });
      onClose();
      forecastChartPageStore.updateStationDataHistory(
        station.station_id,
        station.region_id!,
        station.data_history || false
      );
      handleFilterStation();
    } else {
      toast({
        title: manageStationStore.errorMessage
          ? manageStationStore.errorMessage
          : t("errorEditStation"),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const hasEnvironmentalTypesOnStation = (station: IStation) => {
    return station?.environmentalTypes?.find(
      (type) =>
        type === "weather" ||
        type === "wave" ||
        type === "wave_spectra" ||
        type === "water_flow"
    );
  };

  const handleSwitchDataHistory = (station: IStation) => {
    if (!hasEnvironmentalTypesOnStation(station)) {
      const stationData = { ...station };
      stationData.data_history = !station.data_history;

      handleOpenModal(stationData);
    } else {
      const stationData = station;
      stationData.data_history = !station.data_history;

      handleSaveEditStation(stationData);
    }
  };

  const totalPages = useCallback(() => {
    if (Object.keys(selectedFilters)?.length === 0) {
      return Math.ceil(manageStationStore.stationCount / 10);
    } else {
      return Math.ceil(currentData.length / 10);
    }
  }, [currentData.length, manageStationStore.stationCount, selectedFilters]);

  function useFilteredStations<T>(propertySelector: (station: any) => T) {
    return useMemo(() => {
      return manageStationStore.stationsHistory
        .filter((station) => {
          if (selectedFilters.name?.length > 0) {
            return selectedFilters.name.includes(station.station_name);
          }
          if (selectedFilters.region?.length > 0) {
            return selectedFilters.region.includes(station.macro_region_name!);
          }
          return true;
        })
        .map(propertySelector);
    }, [propertySelector]);
  }

  const handleShowFilter = (value: boolean) => {
    setShowFilterName(value);
    manageStationStore.setSearchFilterDescription("");
  };

  return (
    <Box mt={8} mx={SCREEN_HORIZONTAL_SPACING}>
      <PageTitle text={t("manageStation")} />
      <Text mb="1rem" color="gray.500" fontSize={"md"}>
        {t("configureWhichStationViewHistoricalData")}
      </Text>

      <EditStationModal
        isOpen={isOpen}
        onClose={onClose}
        station={editStation}
        handleSaveEditStation={handleSaveEditStation}
        isLoading={manageStationStore.state === StoreState.PEDDING}
      />

      <TableComponent
        data={currentData}
        isLoading={manageStationStore.state === StoreState.PEDDING}
        renderCell={renderCell}
        renderHeader={renderHeader}
        pageSize={10}
        totalPages={totalPages()}
        getItems={(item) => getStations(item)}
        manualPagination={!!Object.keys(selectedFilters)?.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      <ManageStationFilterDropdown
        items={uniqueStationNames}
        initialItems={Array.from(
          new Set(useFilteredStations(({ station_name }) => station_name))
        )}
        selectedFilters={selectedFilters.name || []}
        onFilterChange={(item) => handleFilterChange("name", item)}
        showFilter={showFilterName}
        setShowFilter={handleShowFilter}
        searchIconRef={searchIconNameRef}
        dropdownRef={dropdownNameRef}
        column={t("name")}
        getItems={(item) => getStations(item)}
        handleResetFilter={(item) => handleResetFilter(item)}
        searchFilter={manageStationStore.searchFilterDescription}
        setSearchFilter={(filter) =>
          manageStationStore.setSearchFilterDescription(filter)
        }
      />

      <ManageStationFilterDropdown
        items={uniqueStationRegion}
        initialItems={Array.from(
          new Set(
            useFilteredStations(({ macro_region_name }) => macro_region_name)
          )
        )}
        selectedFilters={selectedFilters.region || []}
        onFilterChange={(item) => handleFilterChange("region", item)}
        showFilter={showFilterRegion}
        setShowFilter={setShowFilterRegion}
        searchIconRef={searchIconRegionRef}
        dropdownRef={dropdownRegionRef}
        column={t("region")}
        getItems={(item) => getStations(item)}
        handleResetFilter={(item) => handleResetFilter(item)}
        searchFilter={manageStationStore.searchFilterRegion}
        setSearchFilter={(filter) =>
          manageStationStore.setSearchFilterRegion(filter)
        }
      />
    </Box>
  );
};
export default observer(ManageStation);
