import { roundToNearestHour } from "../roundHours/roundHours";

export const generateFormattedTimelineDates = (
  timelineStartDate: number,
  timelineEndDate: number,
  timezoneOffset: number,
  dateFormat: string,
  intervalHours: number
): string[] => {
  const startDate = roundToNearestHour(timelineStartDate).toUTC(
    timezoneOffset * -1
  );

  const endDate = roundToNearestHour(timelineEndDate).toUTC(
    timezoneOffset * -1
  );

  const formattedDates: string[] = [];

  let currentDay: string | null = null;

  for (
    let currentDate = startDate;
    currentDate <= endDate;
    currentDate = currentDate.plus({ hours: intervalHours })
  ) {
    const dayString = currentDate.toFormat(dateFormat);
    const hourString = currentDate.toFormat("HH") + "h";

    if (currentDay !== dayString) {
      if (currentDay !== null) {
        const currentDateFormatted = currentDate.toFormat(dateFormat);
        formattedDates.push(`${currentDateFormatted} ${hourString}`);
      }
      if (currentDate.equals(startDate)) {
        formattedDates.push(`${dayString} ${hourString}`);
      }
      currentDay = dayString;
    } else {
      formattedDates.push(`${dayString} ${hourString}`);
    }
  }

  return formattedDates;
};
