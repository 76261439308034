import { ICompany } from "../types/ICompany";
import { IUser } from "../types/IUser";
import api from "./api";

export default class NotificationService {
  async getCompanies() {
    const result = await api.get("/v1/notification/companies");
    return result.data as ICompany[];
  }

  async getUsersForNotification(companyId: number) {
    const result = await api.get(`/v1/notification/${companyId}/users`);
    return result.data as IUser[];
  }

  async sendPushNotificationImmediately(
    recipients: number[],
    title: string,
    content: string
  ) {
    const result = await api.post(`/v1/notification/push`, {
      recipients,
      title,
      content,
    });
    return result.data;
  }

  async setNotificationRead(
    notificationId: string
  ): Promise<{ success: boolean }> {
    const result = await api.put(`/v1/notification/set-read/${notificationId}`);
    return result.data;
  }
}
