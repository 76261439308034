import { observer } from "mobx-react-lite";
import React from "react";
import MeteogramChart from "../../../../components/MeteogramChart/MeteogramChart";

import useWindowObserver from "../../../../hooks/useWindowObserver";

const VisibilityChartMock: React.FC = () => {
  const windowSize = useWindowObserver(".main-area");

  return (
    <MeteogramChart
      style={{ maxWidth: windowSize.width }}
      timezoneOffset={180}
      options={{
        plotOptions: {
          series: {
            label: {
              enabled: false,
            },
            marker: {
              radius: 3,
            },
          },
        },
        chart: {
          zoomType: "xy",
          height: "290px",
          backgroundColor: "#ffffff",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: [
          {
            title: {
              text: "Probability(%)",
            },
            labels: {
              format: "{value}%",
            },
          },
          {
            labels: {
              format: "{value}km",
            },
            title: {
              text: "Visibility",
            },
            opposite: true,
          },
        ],
        legend: {
          itemStyle: {
            fontSize: "1em",
            fontFamily: "'Hind Vadodara', 'Helvetica Neue', sans-serif",
            fontWeight: "normal",
            color: "#00000",
          },
        },
        series: [
          {
            name: "Visibilidade",
            lineWidth: 1,
            gridLineWidth: 1,
            marker: {
              enabled: false,
            },
            updateYAxis: true,
            yAxis: 1,
            data: [
              [1731326400000, 9401.6796875],
              [1731330000000, 10000],
              [1731333600000, 10000],
              [1731337200000, 10000],
              [1731340800000, 10000],
              [1731344400000, 10000],
              [1731348000000, 10000],
              [1731351600000, 10000],
              [1731355200000, 10000],
              [1731358800000, 10000],
              [1731362400000, 10000],
              [1731366000000, 10000],
              [1731369600000, 10000],
              [1731373200000, 10000],
              [1731376800000, 9261.671875],
              [1731380400000, 9510.484375],
              [1731384000000, 10000],
              [1731387600000, 10000],
              [1731391200000, 10000],
              [1731394800000, 10000],
              [1731398400000, 10000],
              [1731402000000, 10000],
              [1731405600000, 10000],
              [1731409200000, 10000],
              [1731412800000, 10000],
              [1731416400000, 5445.8671875],
              [1731420000000, 7164.65625],
              [1731423600000, 6726.65625],
              [1731427200000, 5874.4609375],
              [1731430800000, 6777.984375],
              [1731434400000, 8695.21875],
              [1731438000000, 10000],
              [1731441600000, 5979.421875],
              [1731445200000, 5350.84375],
              [1731448800000, 10000],
              [1731452400000, 10000],
              [1731456000000, 10000],
              [1731459600000, 8571.3828125],
              [1731463200000, 4525.890625],
              [1731466800000, 3960.5234375],
              [1731470400000, 1175.625],
              [1731474000000, 2711.640625],
              [1731477600000, 1871.625],
              [1731481200000, 835.21875],
              [1731484800000, 961.796875],
              [1731488400000, 1507.5234375],
              [1731492000000, 1519.453125],
              [1731495600000, 1738.71875],
              [1731499200000, 1477.5625],
              [1731502800000, 4361.1171875],
              [1731506400000, 4360.578125],
              [1731510000000, 4926.8671875],
              [1731513600000, 6036.609375],
              [1731517200000, 8114.65625],
              [1731520800000, 9047.421875],
            ],
            yAxisConfig: {
              opposite: true,
              title: {
                text: "Visibilidade",
                style: {
                  color: "#000000",
                },
              },
              min: 835.21875,
              max: 10000,
              labels: { format: "{value}", style: { color: "#000000" } },
              gridLineWidth: 0.5,
            },
          },
          {
            name: "Visibilidade minima",
            type: "arearange",
            lineWidth: 0,
            fillOpacity: 0.1,
            zIndex: 2,
            marker: {
              enabled: false,
            },
            legend: {
              enabled: true,
            },
            color: "#0D8BA1",
            yAxis: 1,
            data: [
              [1731326400000, 6561.6953125, 10000],
              [1731330000000, 10000, 10000],
              [1731333600000, 8104.2578125, 10000],
              [1731337200000, 6805.6171875, 10000],
              [1731340800000, 10000, 10000],
              [1731344400000, 10000, 10000],
              [1731348000000, 10000, 10000],
              [1731351600000, 10000, 10000],
              [1731355200000, 10000, 10000],
              [1731358800000, 10000, 10000],
              [1731362400000, 6551.078125, 10000],
              [1731366000000, 9349.0078125, 10000],
              [1731369600000, 10000, 10000],
              [1731373200000, 6484.3671875, 10000],
              [1731376800000, 5995.59375, 10000],
              [1731380400000, 5823.5859375, 10000],
              [1731384000000, 6886.7265625, 10000],
              [1731387600000, 7746.5078125, 10000],
              [1731391200000, 10000, 10000],
              [1731394800000, 10000, 10000],
              [1731398400000, 10000, 10000],
              [1731402000000, 10000, 10000],
              [1731405600000, 10000, 10000],
              [1731409200000, 10000, 10000],
              [1731412800000, 7468.8359375, 10000],
              [1731416400000, 3693.3203125, 10000],
              [1731420000000, 1230.375, 10000],
              [1731423600000, 733.625, 10000],
              [1731427200000, 674.6875, 10000],
              [1731430800000, 474.9921875, 10000],
              [1731434400000, 481.8359375, 10000],
              [1731438000000, 569.4609375, 10000],
              [1731441600000, 476.578125, 10000],
              [1731445200000, 445.234375, 10000],
              [1731448800000, 449.5390625, 10000],
              [1731452400000, 513.96875, 10000],
              [1731456000000, 502.6875, 10000],
              [1731459600000, 490.9140625, 10000],
              [1731463200000, 493.734375, 10000],
              [1731466800000, 501.6953125, 10000],
              [1731470400000, 1175.625, 10000],
              [1731474000000, 438.3046875, 10000],
              [1731477600000, 472.1640625, 8359.28125],
              [1731481200000, 385.375, 4717.609375],
              [1731484800000, 569.5703125, 5689.421875],
              [1731488400000, 462.359375, 5628.46875],
              [1731492000000, 474.1875, 5292.046875],
              [1731495600000, 501.1484375, 6020.3515625],
              [1731499200000, 483.890625, 4735.09375],
              [1731502800000, 3533.078125, 5192.671875],
              [1731506400000, 3438.6484375, 5358.7421875],
              [1731510000000, 3833.9296875, 6218.984375],
              [1731513600000, 4759.84375, 7550.6328125],
              [1731517200000, 6584.3125, 9789.296875],
              [1731520800000, 7473.171875, 10000],
            ],
            yAxisConfig: {
              opposite: true,
              title: {
                text: "Visibilidade mínima",
                style: {
                  color: "#000000",
                },
              },
              min: 385.375,
              max: 10000,
              labels: { format: "{value}", style: { color: "#000000" } },
              gridLineWidth: 0.5,
            },
          },
          {
            name: "Prob. Visibilidade menor que 1km (%)",
            type: "column",
            marker: {
              enabled: false,
            },
            groupPadding: 0,
            pointPadding: 0,
            borderWidth: 0,
            fillOpacity: 0.3,
            color: "rgba(204, 176, 12, 0.55)",
            yAxis: 0,
            updateYAxis: true,
            data: [
              [1731326400000, 0],
              [1731330000000, 0],
              [1731333600000, 0],
              [1731337200000, 0],
              [1731340800000, 0],
              [1731344400000, 0],
              [1731348000000, 0],
              [1731351600000, 0],
              [1731355200000, 0],
              [1731358800000, 0],
              [1731362400000, 0],
              [1731366000000, 0],
              [1731369600000, 0],
              [1731373200000, 0],
              [1731376800000, 0],
              [1731380400000, 0],
              [1731384000000, 0],
              [1731387600000, 0],
              [1731391200000, 0],
              [1731394800000, 0],
              [1731398400000, 0],
              [1731402000000, 0],
              [1731405600000, 0],
              [1731409200000, 0],
              [1731412800000, 0],
              [1731416400000, 0],
              [1731420000000, 0],
              [1731423600000, 0.828125],
              [1731427200000, 0.828125],
              [1731430800000, 3.3046875],
              [1731434400000, 3.3046875],
              [1731438000000, 2.4765625],
              [1731441600000, 2.4765625],
              [1731445200000, 2.4765625],
              [1731448800000, 3.3046875],
              [1731452400000, 2.4765625],
              [1731456000000, 0.828125],
              [1731459600000, 0.828125],
              [1731463200000, 1.65625],
              [1731466800000, 1.65625],
              [1731470400000, 0],
              [1731474000000, 2.4765625],
              [1731477600000, 17.3515625],
              [1731481200000, 25.6171875],
              [1731484800000, 10.7421875],
              [1731488400000, 4.1328125],
              [1731492000000, 7.4375],
              [1731495600000, 3.3046875],
              [1731499200000, 4.1328125],
              [1731502800000, 0],
              [1731506400000, 0],
              [1731510000000, 0],
              [1731513600000, 0],
              [1731517200000, 0],
              [1731520800000, 0],
            ],
            yAxisConfig: {
              title: {
                text: "Probabilidade",
                style: {
                  color: "#000000",
                },
              },
              tickPixelInterval: 55,
              min: 0,
              max: 100,
              ceiling: 100,
              labels: { format: "{value}", style: { color: "#000000" } },
              gridLineWidth: 1,
            },
          },
          {
            name: "Prob. Visibilidade menor que 1.5km (%)",
            type: "column",
            marker: {
              enabled: false,
            },
            groupPadding: 0,
            pointPadding: 0,
            borderWidth: 0,
            fillOpacity: 0.3,
            color: "rgba(107, 23, 153, 0.55)",
            yAxis: 0,
            updateYAxis: true,
            data: [
              [1731326400000, 0],
              [1731330000000, 0],
              [1731333600000, 0],
              [1731337200000, 0],
              [1731340800000, 0],
              [1731344400000, 0],
              [1731348000000, 0],
              [1731351600000, 0],
              [1731355200000, 0],
              [1731358800000, 0],
              [1731362400000, 0],
              [1731366000000, 0],
              [1731369600000, 0],
              [1731373200000, 0],
              [1731376800000, 0],
              [1731380400000, 0],
              [1731384000000, 0],
              [1731387600000, 0],
              [1731391200000, 0],
              [1731394800000, 0],
              [1731398400000, 0],
              [1731402000000, 0],
              [1731405600000, 0],
              [1731409200000, 0],
              [1731412800000, 0],
              [1731416400000, 0],
              [1731420000000, 0.828125],
              [1731423600000, 0.828125],
              [1731427200000, 0.828125],
              [1731430800000, 4.1328125],
              [1731434400000, 4.9609375],
              [1731438000000, 2.4765625],
              [1731441600000, 3.3046875],
              [1731445200000, 8.265625],
              [1731448800000, 4.9609375],
              [1731452400000, 4.1328125],
              [1731456000000, 3.3046875],
              [1731459600000, 3.3046875],
              [1731463200000, 2.4765625],
              [1731466800000, 4.1328125],
              [1731470400000, 4.9609375],
              [1731474000000, 7.4375],
              [1731477600000, 38.84375],
              [1731481200000, 47.109375],
              [1731484800000, 36.3671875],
              [1731488400000, 33.0546875],
              [1731492000000, 25.6171875],
              [1731495600000, 14.046875],
              [1731499200000, 25.6171875],
              [1731502800000, 0],
              [1731506400000, 0],
              [1731510000000, 0],
              [1731513600000, 0],
              [1731517200000, 0],
              [1731520800000, 0],
            ],
            yAxisConfig: {
              title: {
                text: "Probabilidade",
                style: {
                  color: "#000000",
                },
              },
              tickPixelInterval: 55,
              min: 0,
              max: 100,
              ceiling: 100,
              labels: { format: "{value}", style: { color: "#000000" } },
              gridLineWidth: 1,
            },
          },
          {
            name: "Prob. Visibilidade menor que 2km (%)",
            type: "column",
            marker: {
              enabled: false,
            },
            groupPadding: 0,
            pointPadding: 0,
            borderWidth: 0,
            fillOpacity: 0.3,
            color: "rgba(126, 148, 237, 0.55)",
            yAxis: 0,
            updateYAxis: true,
            data: [
              [1731326400000, 0],
              [1731330000000, 0],
              [1731333600000, 0],
              [1731337200000, 0],
              [1731340800000, 0],
              [1731344400000, 0],
              [1731348000000, 0],
              [1731351600000, 0],
              [1731355200000, 0],
              [1731358800000, 0],
              [1731362400000, 0],
              [1731366000000, 0],
              [1731369600000, 0],
              [1731373200000, 0],
              [1731376800000, 0],
              [1731380400000, 0],
              [1731384000000, 0],
              [1731387600000, 0],
              [1731391200000, 0],
              [1731394800000, 0],
              [1731398400000, 0],
              [1731402000000, 0],
              [1731405600000, 0],
              [1731409200000, 0],
              [1731412800000, 0],
              [1731416400000, 0],
              [1731420000000, 0.828125],
              [1731423600000, 1.65625],
              [1731427200000, 0.828125],
              [1731430800000, 5.78125],
              [1731434400000, 5.78125],
              [1731438000000, 2.4765625],
              [1731441600000, 5.78125],
              [1731445200000, 13.2265625],
              [1731448800000, 4.9609375],
              [1731452400000, 6.609375],
              [1731456000000, 5.78125],
              [1731459600000, 7.4375],
              [1731463200000, 2.4765625],
              [1731466800000, 11.5703125],
              [1731470400000, 11.5703125],
              [1731474000000, 17.3515625],
              [1731477600000, 52.890625],
              [1731481200000, 71.078125],
              [1731484800000, 63.6328125],
              [1731488400000, 66.1171875],
              [1731492000000, 56.1953125],
              [1731495600000, 47.109375],
              [1731499200000, 61.984375],
              [1731502800000, 0],
              [1731506400000, 0],
              [1731510000000, 0],
              [1731513600000, 0],
              [1731517200000, 0],
              [1731520800000, 0],
            ],
            yAxisConfig: {
              title: {
                text: "Probabilidade",
                style: {
                  color: "#000000",
                },
              },
              tickPixelInterval: 55,
              min: 0,
              max: 100,
              ceiling: 100,
              labels: { format: "{value}", style: { color: "#000000" } },
              gridLineWidth: 1,
            },
          },
        ],
      }}
    />
  );
};

export default observer(VisibilityChartMock);
