import { IDynamicUKCLineChart } from "../types/IDynamicUKCLineChart";
import { IOperationWindowForDynamicDraft } from "../stores/data/portOfCall/PortOfCallModel";
import api from "./api";

export default class PortofCallService {
  async getOperationWindowForDynamicDraft(): Promise<
    IOperationWindowForDynamicDraft[]
  > {
    const result = await api.post(
      "/v1/port-of-call/getOperationWindowForDynamicDraft"
    );
    return result.data;
  }

  async getManeuverUKC(
    operationWindowId: number,
    maneuverActionId: number
  ): Promise<IDynamicUKCLineChart> {
    const result = await api.post("/v1/port-of-call/getManeuverUKC", {
      operationWindowId: operationWindowId,
      maneuverActionId: maneuverActionId,
    });
    return result.data;
  }
}
