import { Box, Text } from "@chakra-ui/react";
import { CSSProperties } from "react";

type JoyrideModalProps = {
  image?: {
    src: string;
    alt: string;
  };
  imageStyle?: CSSProperties;
  text?: string;
  summary?: string;
};

export default function JoyrideContent({
  image,
  imageStyle,
  text,
  summary,
}: JoyrideModalProps) {
  return (
    <Box display="grid" gap={"14px"}>
      {image && (
        <img
          style={
            imageStyle ?? {
              width: "350px",
              objectFit: "cover",
              aspectRatio: "3/2",
            }
          }
          src={image.src}
          alt={image.alt}
        />
      )}
      <Text color="gray.700">{text}</Text>
      <Text fontSize="sm" color="gray.500">
        {summary}
      </Text>
    </Box>
  );
}
