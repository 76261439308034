import { observer } from "mobx-react-lite";
import React from "react";

export interface NavItemProps {
  event: (environmentalType: string) => void;
  isActive: boolean;
  environmentalType: string;
  text: string;
  icon: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
  isSubItem?: boolean;
  isOpen?: boolean;
}

const NavItem: React.FC<NavItemProps> = ({
  event,
  isActive,
  environmentalType,
  text,
  icon,
  isSubItem = false,
  isOpen = false,
}) => {
  return (
    <div
      style={{
        display: !isSubItem || (isSubItem && isOpen) ? "flex" : "none",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
      }}
      onClick={() => event(environmentalType)}
    >
      <span
        style={{
          display: isActive ? "flex" : "",
          alignItems: isActive ? "center" : "",
          position: "absolute",
          right: isActive ? "33px" : "40px",
          width: "auto",
          height: isActive ? "30.5px" : "",
          textAlign: "right",
          padding: isActive ? "4px 10px 4px" : "4px",
          background: isActive ? "#286795" : "",
          color: isActive ? "white" : "",
          borderRadius: isActive ? "4px 0 0 4px" : "",
          fontSize: isSubItem ? "12px" : "15px",
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </span>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: 3,
          height: "30px",
          color: "white",
          backdropFilter: "blur(2px)",
          width: "100%",
          borderRadius: 4,
          backgroundColor: isActive ? "#286795" : "",
        }}
      >
        {/* @ts-ignore */}
        {icon}
      </div>
    </div>
  );
};

export default observer(NavItem);
