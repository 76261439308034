import { getRoot } from "mobx-easy";
import { MacroRegion } from "../../enum/MacroRegion";
import { validatePointInsidePolygon } from "../validadePointInsidePolygon/validatePointInsidePolygon";
import { macroRegionLimits } from "./macroRegionLimits";
import RootStore from "../../stores/root-store";
import { IMacroRegion } from "types/IMacroRegion";

export const getMacroRegionOriginFromLatLon = (
  lat: number,
  lng: number,
  companyMacroRegions: IMacroRegion[]
): MacroRegion | undefined => {
  const {
    dataStores: { authStore },
  } = getRoot<RootStore>();
  const companyMacroRegionOrigins = companyMacroRegions?.map(
    (macroRegion) => macroRegion.origin
  );

  for (const macroRegionOrigin in macroRegionLimits) {
    if (!companyMacroRegionOrigins?.includes(macroRegionOrigin)) {
      continue;
    }

    if (
      authStore.userMarcroRegions?.length &&
      !authStore.userMarcroRegions.includes(macroRegionOrigin)
    ) {
      continue;
    }

    const macroRegionLimit = macroRegionLimits[macroRegionOrigin];
    const isLocationInMacroRegion = validatePointInsidePolygon(
      [lat, lng],
      macroRegionLimit
    );
    if (isLocationInMacroRegion) {
      return macroRegionOrigin as MacroRegion;
    }
  }
  return;
};
