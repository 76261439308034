import api from "./api";

export interface IAddUserUnitMeasurement {
  environmentalVariable: string;
  unitVariable: string;
}

export interface IUserUnitMeasurement {
  unit_variable: string;
  unit_symbol: string;
  environmentalVariable: string;
}

export default class UnitMeasurementService {

  async addUserUnitMeasurement(
    userUnitMeasurementParams: IAddUserUnitMeasurement[]
  ): Promise<IAddUserUnitMeasurement> {
    const result = await api.post(
      "/v1/user-unit-measurement", {user_unit_measurement_params:userUnitMeasurementParams},
    );
    return result.data;
  }

  async getUserUnitMeasurement(
  ): Promise<IUserUnitMeasurement[]> {
    const result = await api.get("/v1/user-unit-measurement");
    return result.data;
  }
}
