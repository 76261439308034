import { UserExperience } from "../enum/UserExperience";
import api from "./api";

export default class LogService {
  async addLogUserExperience(userExperience: UserExperience, details?: string) {
    const result = await api.post("/v1/log/addLogUserExperience", {
      userExperience: userExperience,
      details: details,
    });
    return result.data;
  }
}
