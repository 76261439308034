import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Text,
  Button,
  Radio,
  Stack,
  Select,
  Tooltip,
  FormControl,
  FormLabel,
  FormErrorMessage,
  RadioGroup,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import MeteogramChart from "../../components/MeteogramChart/MeteogramChart";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import TodayIcon from "@material-ui/icons/Today";
import pt from "date-fns/locale/pt-BR";
import en from "date-fns/locale/en-GB";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NumberInputWithElement } from "../../components/NumberInputWithElement/NumberInputWithElement";
import EmptyState from "../../components/EmptyState/EmptyState";
import emptyAnalytics from "../../assets/emptystate_analytics.svg";
import noAnalyticsData from "../../assets/noAnalyticsData.svg";
import { useTranslation } from "react-i18next";
import { useStores } from "../../stores/setup/use-store";
import { EnvironmentalType } from "../../enum/EnvironmentalType";
import { Formik } from "formik";
import { IMacroRegion } from "../../types/IMacroRegion";
import i18n from "../../i18n";
import { StoreState } from "../../enum/StoreState";
import { IStation } from "../../stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import hourglassIcon from "../../assets/hourglass.svg";

//@ts-ignore
const InitialDatePickerButton = React.forwardRef(({ value, onClick }, ref) => (
  <Button
    //@ts-ignore
    ref={ref}
    onClick={onClick}
    backgroundColor="white"
    color={value ? "rgba(30, 82, 151, 0.8)" : "#BCBCBC"}
    fontWeight="light"
    fontSize={"1rem"}
    width={"100%"}
    leftIcon={
      window.innerWidth < 1800 ? undefined : (
        <TodayIcon style={{ width: 18, height: 18 }} />
      )
    }
    p={2}
  >
    {value ? value : i18n.t("analyticsReporting.startDate")}
  </Button>
));

//@ts-ignore
const EndDatePickerButton = React.forwardRef(({ value, onClick }, ref) => (
  <Button
    //@ts-ignore
    ref={ref}
    onClick={onClick}
    backgroundColor="white"
    color={value ? "rgba(30, 82, 151, 0.8)" : "#BCBCBC"}
    fontWeight="light"
    fontSize={"1rem"}
    width={"100%"}
    leftIcon={
      window.innerWidth < 1800 ? undefined : (
        <TodayIcon style={{ width: 18, height: 18 }} />
      )
    }
    p={2}
  >
    {value ? value : i18n.t("analyticsReporting.endDate")}
  </Button>
));

export interface AnalyticsFormValues {
  startDate: Date;
  endDate: Date;
  regionId: number;
  stationRegion: string;
  environmentalVariableId: number;
  minValue?: number;
  maxValue?: number;
  periodForecast: number;
}

const AnalyticsReporting: React.FC = () => {
  const {
    dataStores: { analyticsStore, analyticsReportingStore, regionStore },
  } = useStores();
  const toast = useToast();

  const { t } = useTranslation("translation", {
    keyPrefix: "analyticsReporting",
  });

  const [initialValues] = useState<AnalyticsFormValues>({
    startDate: DateTime.local().minus({ years: 1 }).toJSDate(),
    endDate: new Date(),
    stationRegion: "",
    periodForecast: 24,
    environmentalVariableId: 7,
    regionId: regionStore.macroRegionMain?.id,
  });
  const [stations, setStations] = useState<IStation[]>([]);

  useEffect(() => {
    analyticsReportingStore.resetAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (regionStore.macroRegionMain) {
      analyticsStore.getMeasuredStationsAPI(
        EnvironmentalType.WEATHER,
        regionStore.macroRegionMain.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionStore.macroRegionMain]);

  useEffect(() => {
    setStations(analyticsStore.stations);
  }, [analyticsStore.stations]);

  const validateForm = (values: AnalyticsFormValues) => {
    let errors = {} as AnalyticsFormValues;
    const {
      environmentalVariableId,
      minValue,
      maxValue,
      stationRegion,
      regionId,
      startDate,
      endDate,
    } = values;

    if (!environmentalVariableId) {
      errors.environmentalVariableId = 1;
    }

    if (!stationRegion) {
      errors.stationRegion = "Station is required!";
    }

    if (!regionId) {
      errors.regionId = 1;
    }

    if (!minValue && !maxValue) {
      errors.minValue = 1;
    }

    if (maxValue && minValue) {
      if (parseInt(maxValue.toString()) < parseInt(minValue.toString())) {
        errors.maxValue = 1;
      }
    }

    if (!startDate) {
      errors.startDate = new Date();
    }

    if (!endDate) {
      errors.endDate = new Date();
    }

    return errors;
  };

  const renderExpression = (
    environmentalVariableId: number,
    minValue?: number,
    maxValue?: number
  ) => {
    const variableName =
      environmentalVariableId.toString() === "7"
        ? t("windSpeed")
        : t("direction");
    const unitMeasurement =
      environmentalVariableId.toString() === "7" ? t("knots") : t("degrees");

    if (minValue && !maxValue) {
      return (
        <Text color="newBlue.500">{`${variableName} ${t(
          "isGreaterOrEqualThan"
        )} ${minValue} ${unitMeasurement}`}</Text>
      );
    } else if (!minValue && maxValue) {
      return (
        <Text color="newBlue.500">{`${variableName} ${t(
          "isLessOrEqualThan"
        )} ${maxValue} ${unitMeasurement}`}</Text>
      );
    } else if (minValue && maxValue) {
      return (
        <Text color="newBlue.500">{`${variableName} ${t(
          "between"
        )} ${minValue} ${t("to")} ${maxValue} ${unitMeasurement}`}</Text>
      );
    }

    return null;
  };

  const handleChangeRegion = async (value: string) => {
    if (value) {
      analyticsStore.getMeasuredStationsAPI(
        EnvironmentalType.WEATHER,
        parseInt(value)
      );
    }
  };

  const renderPerformanceGainHours = () => {
    return analyticsReportingStore.performanceGainHours
      ? `${analyticsReportingStore.performanceGainHours}h`
      : "-";
  };

  const renderAverageEventOccurrencePercentage = () => {
    return analyticsReportingStore.averageEventOccurrencePercentage
      ? `${Math.floor(
          analyticsReportingStore.averageEventOccurrencePercentage
        )}%`
      : "-";
  };

  const renderMinValue = () => {
    return analyticsReportingStore.minValue
      ? ` ${analyticsReportingStore.minValue} `
      : " - ";
  };

  const renderMaxValue = () => {
    return analyticsReportingStore.maxValue
      ? `${analyticsReportingStore.maxValue}kt`
      : "-";
  };

  const resetChart = () => {
    if (analyticsReportingStore.chartCategories.length) {
      analyticsReportingStore.resetAnalytics();
    }
  };

  const renderMinMaxValue = () => {
    if (analyticsReportingStore.minValue && !analyticsReportingStore.maxValue) {
      return (
        <Text mt={-4} mb={2}>
          {t("ofTheTimeWithWindsGreaterOrEqual")}
          {` ${analyticsReportingStore.minValue}kt`}
        </Text>
      );
    } else if (
      !analyticsReportingStore.minValue &&
      analyticsReportingStore.maxValue
    ) {
      return (
        <Text mt={-4} mb={2}>
          {t("ofTheTimeWithWindsLessOrEqual")}
          {` ${analyticsReportingStore.maxValue}kt`}
        </Text>
      );
    } else {
      return (
        <Text mt={-4} mb={2}>
          {t("ofTheTimeWithWinds")}
          {renderMinValue()}
          {t("to")} {renderMaxValue()}
        </Text>
      );
    }
  };

  const onSubmitForm = (values: AnalyticsFormValues) => {
    analyticsReportingStore.getAnalytics(values).then(() => {
      if (analyticsReportingStore.state === StoreState.ERROR) {
        toast({
          title: t("errorGeneratingAnalytics"),
          status: "error",
          position: "top",
        });
      }
    });
  };

  return (
    <Box mt={8}>
      <PageTitle text={"Analytics"} />

      <Grid templateColumns="repeat(10, 1fr)" gap={4} h="100vh">
        <Formik<AnalyticsFormValues>
          initialValues={initialValues}
          onSubmit={onSubmitForm}
          validate={validateForm}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
        >
          {({ getFieldProps, submitForm, values, setFieldValue, errors }) => (
            <GridItem
              rowSpan={6}
              colSpan={window.innerWidth < 1800 ? 3 : 2}
              borderRight="1px solid #bcbcbc"
            >
              <Box color="#455154" px={4}>
                <FormLabel fontSize="sm" mb={2}>
                  {t("period")}
                </FormLabel>
                <Flex mb={4} gap={2}>
                  <FormControl display="grid" isInvalid={!!errors.startDate}>
                    <DatePicker
                      selected={values.startDate}
                      onChange={(date) => {
                        setFieldValue("startDate", date);
                        resetChart();
                      }}
                      popperPlacement={"bottom-start"}
                      maxDate={DateTime.local().toJSDate()}
                      timeIntervals={15}
                      customInput={<InitialDatePickerButton />}
                      dateFormat="MMM dd, yyyy"
                      locale={i18n.language === "en" ? en : pt}
                      showYearDropdown
                      scrollableYearDropdown
                    />
                    <FormErrorMessage>{t("requiredField")}</FormErrorMessage>
                  </FormControl>
                  <FormControl display="grid" isInvalid={!!errors.endDate}>
                    <DatePicker
                      selected={values.endDate}
                      onChange={(date) => {
                        setFieldValue("endDate", date);
                        resetChart();
                      }}
                      popperPlacement={"bottom-start"}
                      maxDate={DateTime.local().toJSDate()}
                      minDate={values.startDate}
                      timeIntervals={15}
                      customInput={<EndDatePickerButton />}
                      dateFormat="MMM dd, yyyy"
                      locale={i18n.language === "en" ? en : pt}
                      showYearDropdown
                      scrollableYearDropdown
                    />
                    <FormErrorMessage>{t("requiredField")}</FormErrorMessage>
                  </FormControl>
                </Flex>
                <Box mb={4}>
                  <FormLabel fontSize="sm" mb={2}>
                    {t("environmentalType")}
                  </FormLabel>
                  <Stack>
                    <Radio
                      size={window.innerWidth < 1800 ? "sm" : "md"}
                      name="1"
                      borderColor={"#455154"}
                      color="rgba(30, 82, 151, 0.8)"
                      defaultChecked
                    >
                      {t("weather")}
                    </Radio>
                  </Stack>
                </Box>
                <FormControl mb={4} isRequired isInvalid={!!errors.regionId}>
                  <FormLabel fontSize="sm" mb={2}>
                    {t("region")}
                  </FormLabel>
                  <Select
                    value={values.regionId}
                    onChange={(e) => {
                      setFieldValue("regionId", parseInt(e.target.value));
                      setFieldValue("stationRegion", undefined);
                      handleChangeRegion(e.target.value);
                      resetChart();
                    }}
                    size={window.innerWidth < 1800 ? "sm" : "md"}
                    placeholder={t("selectRegion") as string}
                    bg="white"
                    color="rgba(30, 82, 151, 0.8)"
                  >
                    {regionStore.macroRegions?.map((value: IMacroRegion) => (
                      <option
                        style={{ color: "rgba(30, 82, 151, 0.8)" }}
                        value={parseInt(value.id.toString())}
                        key={value.id}
                      >
                        {value.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{t("requiredField")}</FormErrorMessage>
                </FormControl>
                <FormControl
                  mb={4}
                  isInvalid={!!errors.stationRegion}
                  isRequired
                >
                  <FormLabel fontSize="sm" mb={2}>
                    {t("location")}
                  </FormLabel>
                  {values.regionId &&
                  analyticsStore.state === StoreState.PEDDING ? (
                    <Center>
                      <Spinner
                        speed="0.65s"
                        emptyColor="gray.300"
                        color="newBlue.500"
                        size="md"
                      />
                    </Center>
                  ) : (
                    <Select
                      id="location"
                      placeholder={t("selectLocation") as string}
                      {...getFieldProps("stationRegion")}
                      onChange={(e) => {
                        setFieldValue("stationRegion", e.target.value);
                        resetChart();
                      }}
                      bg="white"
                      color="rgba(30, 82, 151, 0.8)"
                      size={window.innerWidth < 1800 ? "sm" : "md"}
                    >
                      {stations?.map(
                        ({ station_id, region_id, station_name }) => (
                          <option
                            value={`${station_id}-${region_id}`}
                            key={`${station_id}-${region_id}`}
                          >
                            {station_name}
                          </option>
                        )
                      )}
                    </Select>
                  )}
                  <FormErrorMessage>{t("requiredField")}</FormErrorMessage>
                </FormControl>
                <Box mb={2}>
                  <FormControl
                    isInvalid={!!errors.environmentalVariableId}
                    isRequired
                    mb={4}
                  >
                    <FormLabel fontSize="sm" mb={2}>
                      {t("environmentalVariable")}
                    </FormLabel>
                    <Select
                      id="Location"
                      placeholder={t("selectEvent") as string}
                      {...getFieldProps("environmentalVariableId")}
                      disabled={false}
                      bg="white"
                      mb={2}
                      size={window.innerWidth < 1800 ? "sm" : "md"}
                      color="rgba(30, 82, 151, 0.8)"
                    >
                      <option value={7} key={1}>
                        {t("windSpeed")}
                      </option>
                    </Select>
                    <FormErrorMessage>{t("requiredField")}</FormErrorMessage>
                  </FormControl>
                  <Flex gap={2}>
                    <FormControl isInvalid={!!errors.minValue}>
                      <FormLabel fontSize="sm" mb={2}>
                        {t("isGreaterOrEqual")}
                      </FormLabel>
                      <NumberInputWithElement
                        placeholder="0.0"
                        value={values.minValue}
                        inputStyle={{
                          backgroundColor: "#fff",
                        }}
                        onChange={(e) => {
                          setFieldValue("minValue", e.target.value);
                          resetChart();
                        }}
                        suffix={
                          values.environmentalVariableId.toString() === "7"
                            ? (t("knots") as string)
                            : (t("degrees") as string)
                        }
                      />
                      <FormErrorMessage>
                        {t("fillAtLeastOneField")}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.maxValue}>
                      <FormLabel fontSize="sm" mb={2}>
                        {t("isLessOrEqual")}
                      </FormLabel>
                      <NumberInputWithElement
                        key={`id-${values.environmentalVariableId}`}
                        placeholder="0.0"
                        inputStyle={{
                          backgroundColor: "#fff",
                        }}
                        onChange={(e) => {
                          setFieldValue("maxValue", e.target.value);
                          resetChart();
                        }}
                        suffix={
                          values.environmentalVariableId.toString() === "7"
                            ? (t("knots") as string)
                            : (t("degrees") as string)
                        }
                      />
                      <FormErrorMessage>
                        {t("theMaximumValueMustBeGreater")}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <Center mt={2}>
                    {renderExpression(
                      values.environmentalVariableId,
                      values.minValue,
                      values.maxValue
                    )}
                  </Center>
                </Box>

                <FormLabel fontSize="sm" mb={2}>
                  {t("forecastAdvancePeriod")}
                </FormLabel>
                <RadioGroup {...getFieldProps("periodForecast")} mb={4}>
                  <Stack>
                    <Radio
                      value={24}
                      size={window.innerWidth < 1800 ? "sm" : "md"}
                      name="1"
                      borderColor={"#455154"}
                      color="rgba(30, 82, 151, 0.8)"
                      isChecked={values.periodForecast === 24}
                      onChange={(e) => {
                        setFieldValue(
                          "periodForecast",
                          parseInt(e.target.value)
                        );
                        resetChart();
                      }}
                    >
                      {t("first")} 24h
                    </Radio>
                  </Stack>
                </RadioGroup>
                <Button
                  onClick={submitForm}
                  type="submit"
                  layerStyle="ocean"
                  disabled={
                    analyticsReportingStore.state === StoreState.PEDDING
                  }
                  _hover={{ layerStyle: "ocean" }}
                  w="100%"
                >
                  {analyticsReportingStore.state === StoreState.PEDDING
                    ? t("generatingAnalysis")
                    : t("generateAnalysis")}
                </Button>
              </Box>
            </GridItem>
          )}
        </Formik>
        <GridItem
          h={window.innerWidth < 1800 ? 44 : 48}
          colSpan={window.innerWidth < 1800 ? 3 : 2}
          bg="#D9E6F3"
          borderRadius={8}
        >
          <Box p={4}>
            <Flex
              alignItems={"center"}
              justifyContent={"space-between"}
              color="#455154"
            >
              <Center gap={2}>
                <AccessTimeIcon />
                <Text fontSize={"lg"}>{t("hoursEarned")}</Text>
              </Center>
              <Tooltip
                label={t("hoursGainIsHowMuchMoreAccuratei4castWas")}
                fontSize="sm"
                placement="top-start"
                hasArrow
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </Flex>
            <Box color="#3290ED" mt={2}>
              <Text fontSize={window.innerWidth < 1800 ? "2.7rem" : "3.7rem"}>
                {renderPerformanceGainHours()}
              </Text>
              <Text mt={-4} mb={2}>
                {t("moreAssertiveForecastHours")}
              </Text>
            </Box>
          </Box>
        </GridItem>

        <GridItem
          h={window.innerWidth < 1800 ? 44 : 48}
          colSpan={window.innerWidth < 1800 ? 3 : 2}
          bg={"rgba(157, 83, 242, 0.12)"}
          borderRadius={8}
        >
          <Box p={4}>
            <Flex
              alignItems={"center"}
              justifyContent={"space-between"}
              color="#455154"
            >
              <Center gap={2}>
                <img src={hourglassIcon} alt={"hourIcon"} />
                <Text fontSize={"lg"}>{t("eventFrequency")}</Text>
              </Center>
              <Tooltip
                label={t("timeCalculationIsAPercentageOfTime")}
                fontSize="sm"
                placement="top-start"
                hasArrow
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </Flex>
            <Box
              color="#9D53F2"
              mt={2}
              key={`id-${analyticsReportingStore.minValue}-${analyticsReportingStore.maxValue}`}
            >
              <Text fontSize={window.innerWidth < 1800 ? "2.5rem" : "3.5rem"}>
                {renderAverageEventOccurrencePercentage()}
              </Text>

              {renderMinMaxValue()}

              {analyticsReportingStore.monthMostFrequently && (
                <Flex mt={-2} alignItems={"center"}>
                  <ArrowUpwardIcon fontSize="small" />

                  <Text fontSize={"0.8rem"}>
                    {t("mostFrequently")}{" "}
                    {t(analyticsReportingStore.monthMostFrequently)}
                  </Text>
                </Flex>
              )}
            </Box>
          </Box>
        </GridItem>

        <GridItem rowSpan={4} colSpan={window.innerWidth < 1800 ? 7 : 8} mb={2}>
          {analyticsReportingStore.state === StoreState.PEDDING ? (
            <Box mt={28}>
              <Center>
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.300"
                  color="newBlue.500"
                  size="xl"
                />
              </Center>
              <Center mt={4}>
                <Text color="gray.500">{t("weAreGeneratingAnalysis")}</Text>
              </Center>
            </Box>
          ) : !analyticsReportingStore.chartCategories?.length &&
            !analyticsReportingStore.noData ? (
            <Box mt={28}>
              <EmptyState
                description={t("fillINTheFieldsBelowViewData")}
                image={emptyAnalytics}
                imageSize="25vw"
              />
            </Box>
          ) : analyticsReportingStore.noData ? (
            <Box mt={8}>
              <EmptyState
                description={t("weDoNotHaveDataAvailable")}
                image={noAnalyticsData}
                imageSize="15vw"
              />
            </Box>
          ) : (
            <>
              <Box>
                <Text color="newBlue.500" fontSize={"2xl"}>
                  {t("events")}
                </Text>
                <Text color="newBlue.500" fontSize={"md"}>
                  {t("comparisonOfi4castForecastPerformance")}
                </Text>
              </Box>
              <MeteogramChart
                key={`charts-${analyticsReportingStore.chartCategories}`}
                options={{
                  chart: {
                    type: "column",
                    backgroundColor: "rgb(240, 241, 242)",
                  },
                  title: {
                    text: "",
                    align: "left",
                  },
                  subtitle: {
                    text: t("numberOfEvents"),
                    align: "left",
                  },
                  legend: {
                    verticalAlign: "Top",
                  },
                  xAxis: {
                    categories: analyticsReportingStore.chartCategories,
                  },
                  yAxis: {
                    min: 0,
                    title: {
                      text: "",
                    },
                  },
                  plotOptions: {
                    column: {
                      pointPadding: 0.2,
                      borderWidth: 0,
                    },
                  },
                  series: [
                    {
                      name: "i4cast",
                      data: analyticsReportingStore.i4castData,
                      color: "#286795",
                    },
                    {
                      name: t("globalModel"),
                      data: analyticsReportingStore.globalData,
                      color: "#9D53F2",
                    },
                  ],
                }}
                timezoneOffset={0}
              />
              <Box>
                <Text color="gray.500">
                  * {t("someMonthsMayNotBeDisplayed")}
                </Text>
              </Box>

              <Box mt={6}>
                <Text color="newBlue.500" fontSize={"2xl"}>
                  {t("monthlyAverageWindSpeed")}
                </Text>
              </Box>
              <MeteogramChart
                options={{
                  chart: {
                    backgroundColor: "rgb(240, 241, 242)",
                  },
                  title: {
                    text: "",
                    align: "left",
                  },
                  xAxis: {
                    categories: analyticsReportingStore.sensorMonths,
                  },
                  legend: {
                    verticalAlign: "Top",
                  },
                  yAxis: {
                    title: {
                      text: t("windSpeed"),
                    },
                  },
                  tooltip: {
                    shared: true,
                    valueDecimals: 2,
                    formatter: function () {
                      const { y, points } = this;

                      if (points) {
                        return `${t("average")}: ${points[0]?.y.toFixed(
                          1
                        )} (±${(points[1]?.y - points[0]?.y).toFixed(1)})kt `;
                      } else {
                        return `${t("maximum")}: ${y.toFixed(1)}kt`;
                      }
                    },
                  },
                  series: [
                    {
                      name: t("average"),
                      type: "spline",
                      color: "#286795",
                      lineWidth: 2,
                      data: analyticsReportingStore.average,
                    },
                    {
                      type: "scatter",
                      name: t("maximum"),
                      color: "#9F2933",
                      data: analyticsReportingStore.maximum,
                      marker: {
                        radius: 5,
                      },
                    },
                    {
                      name: t("standardDeviation"),
                      type: "errorbar",
                      color: "#286795",
                      lineWidth: 2,
                      data: analyticsReportingStore.standardDeviation,
                    },
                  ],
                }}
                timezoneOffset={0}
              />
            </>
          )}
        </GridItem>
      </Grid>
    </Box>
  );
};
export default observer(AnalyticsReporting);
