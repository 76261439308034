import { makeAutoObservable } from "mobx";
import { getEnv, getRoot } from "mobx-easy";
import { StoreState } from "../../../enum/StoreState";
import { RootEnv } from "../../setup/create-store";
import { IUserAreaPosition, IUserConfig, IUserShare } from "./userConfigModal";
import i18n from "../../../i18n";
import RootStore from "../../root-store";
import { getMenuListMock } from "../../ui/menuView/menuItem.mock";

export default class UserConfigStore {
  userConfig: IUserConfig = {
    name: "",
    middle_name: "",
    last_name: "",
    login: "",
    area_id: 0,
    position_id: 0,
    email: "",
    phone_code: "",
    phone_number: "",
  };

  userPosition: IUserAreaPosition[];

  userArea: IUserAreaPosition[];

  state: string = StoreState.DONE;

  messageError: string = "";

  users: IUserShare[];

  currentLanguage: string = i18n.language;

  constructor() {
    makeAutoObservable(this);
    this.getUserConfigApi();
    this.getUserAreaApi();
    this.getUserPositionApi();
  }

  setUserConfig(value: IUserConfig) {
    this.userConfig = value;
  }

  setUserPosition(value: IUserAreaPosition[]) {
    this.userPosition = value;
  }

  setUserArea(value: IUserAreaPosition[]) {
    this.userArea = value;
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setMessageError(value: string) {
    this.messageError = value;
  }

  setUsers(value: IUserShare[]) {
    this.users = value;
  }

  reset() {
    this.state = StoreState.DONE;
    this.userConfig = {
      name: "",
      middle_name: "",
      last_name: "",
      login: "",
      area_id: 0,
      position_id: 0,
      email: "",
      phone_code: "",
      phone_number: "",
    };
    this.userArea = [];
    this.userPosition = [];
  }

  async getUserConfigApi() {
    const { userConfigService } = getEnv<RootEnv>();
    try {
      this.setState(StoreState.PEDDING);
      const result: IUserConfig = await userConfigService.getUserConfig();

      this.setUserConfig(result);

      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(i18n.t("admin.myData.errorGetUserData"));
    }
  }

  async updateUserConfig(
    name: string,
    lastName: string,
    email: string,
    areaId: number,
    positionId: number,
    middleName?: string,
    phoneCode?: string,
    phoneNumber?: string
  ) {
    const { userConfigService } = getEnv<RootEnv>();
    try {
      this.setState(StoreState.PEDDING);
      await userConfigService.updateUserConfig(
        name,
        lastName,
        email,
        areaId,
        positionId,
        middleName,
        phoneCode,
        phoneNumber
      );

      this.setUserConfig({
        login: this.userConfig.login,
        name: name,
        last_name: lastName,
        email: email,
        area_id: areaId,
        position_id: positionId,
        middle_name: middleName,
        phone_code: phoneCode,
        phone_number: phoneNumber,
      });
      this.setState(StoreState.SUCCESS);
      setTimeout(() => {
        this.setState(StoreState.DONE);
      }, 3000);
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(i18n.t("admin.myData.errorUpdateUserData"));
    }
  }

  async getUserPositionApi() {
    const { userConfigService } = getEnv<RootEnv>();
    try {
      this.setState(StoreState.PEDDING);
      this.setUserPosition([]);
      const result: IUserAreaPosition[] =
        await userConfigService.getUserPosition();

      this.setUserPosition(result);

      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(i18n.t("admin.myData.errorGetUserPositionData"));
    }
  }

  async getUserAreaApi() {
    const { userConfigService } = getEnv<RootEnv>();
    try {
      this.setState(StoreState.PEDDING);
      this.setUserArea([]);
      const result: IUserAreaPosition[] = await userConfigService.getUserArea();

      this.setUserArea(result);
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(i18n.t("admin.myData.errorGetUserAreaData"));
    }
  }

  async updateUserLanguage(language: string) {
    const { userConfigService } = getEnv<RootEnv>();
    try {
      this.setState(StoreState.PEDDING);
      await userConfigService.updateUserLanguage(language);
      i18n.changeLanguage(language);
      this.currentLanguage = language;
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(i18n.t("admin.myData.errorUpdateUserLanguage"));
    }
  }

  async getUserLanguage() {
    const { userConfigService } = getEnv<RootEnv>();
    const {
      uiStores: { menuViewStore },
    } = getRoot<RootStore>();
    try {
      this.setState(StoreState.PEDDING);
      const preferedLanguage = await userConfigService.getUserLanguage();
      if (preferedLanguage.language) {
        i18n.changeLanguage(preferedLanguage.language);
        this.currentLanguage = preferedLanguage.language;
      } else {
        this.updateUserLanguage(this.currentLanguage);
      }
      menuViewStore.setMenuList(getMenuListMock());

      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(i18n.t("admin.myData.errorUpdateUserLanguage"));
    }
  }

  async getUsersApi() {
    const { userConfigService } = getEnv<RootEnv>();
    try {
      this.setState(StoreState.PEDDING);
      this.setUsers([]);
      const result = await userConfigService.getUsers();

      this.setUsers(result);
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(i18n.t("admin.myData.errorGetUserAreaData"));
    }
  }
}
