import { IMacroRegion } from "../types/IMacroRegion";
import api from "./api";

export default class RegionService {
  async getMacroRegion(): Promise<IMacroRegion[]> {
    const result = await api.get("/v1/macro-region");
    return result.data;
  }

  async getMainRegionTimezonOffset(): Promise<number> {
    const result = await api.get<{ timezoneOffset: number }>(
      "/v1/macro-region/timezone-offset"
    );
    return result.data.timezoneOffset;
  }
}
