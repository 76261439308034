import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Flex,
  Button,
} from "@chakra-ui/react";
import { PageTitle } from "../../../components/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import OperationRule from "../OperationRule/OperationRule";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import OperationRuleLocationDetail from "../OperationRuleLocation/OperationRuleLocationDetail";
import OperationRuleLocationInsightDetail from "../OperationRuleLocationInsight/OperationRuleLocationInsightDetail";
import { useStores } from "../../../stores/setup/use-store";
import LogService from "../../../services/LogService";
import { UserExperience } from "../../../enum/UserExperience";
import { SCREEN_HORIZONTAL_SPACING } from "../../../constants";

interface InsightManagerProps {}

const InsightManager: React.FC<InsightManagerProps> = () => {
  const {
    dataStores: {
      operationRuleStore,
      operationRuleLocationStore,
      operationRuleLocationInsightStore,
      oceanWeatherIntelligenceStore,
    },
  } = useStores();
  const { t } = useTranslation("translation", {
    keyPrefix: "oceanWeatherIntelligence.insightManager",
  });
  let history = useHistory();

  useEffect(() => {
    if (operationRuleStore.operationRules.length === 0) {
      operationRuleStore.getOperationRulesApi().then((dashboards) => {
        if (!dashboards?.length) {
          operationRuleLocationStore.setHasLocations(false);
          operationRuleLocationInsightStore.setHasInsights(false);
        } else {
          operationRuleLocationStore.setHasLocations(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uxMeasurement = async (tabIndex: number) => {
    const log = new LogService();
    let details = "";
    switch (tabIndex) {
      case 0:
        details = "dashboard_tab";
        break;

      case 1:
        details = "location_tab";
        break;

      case 2:
        details = "insight_tab";
        break;

      default:
        break;
    }
    log.addLogUserExperience(UserExperience.INSIGHT_MANAGER_TAB, details);
  };

  return (
    <Box mt={8} mx={SCREEN_HORIZONTAL_SPACING}>
      <Flex>
        <Button
          onClick={() => history.push("/operation-insights")}
          size="small"
          mb={4}
          mr={2}
          layerStyle="lightOcean"
          _hover={{ layerStyle: "lightOcean" }}
          leftIcon={<ArrowBackIcon style={{ marginLeft: "6px" }} />}
        ></Button>
        <PageTitle text={t("insightManager")} />
      </Flex>
      <Tabs
        isLazy
        defaultIndex={oceanWeatherIntelligenceStore.activeTab}
        onChange={uxMeasurement}
      >
        <TabList color="newBlue.300">
          <Tab _selected={{ color: "newBlue.500", borderColor: "newBlue.500" }}>
            {t("dashboards")}
          </Tab>
          <Tab _selected={{ color: "newBlue.500", borderColor: "newBlue.500" }}>
            {t("locations")}
          </Tab>
          <Tab _selected={{ color: "newBlue.500", borderColor: "newBlue.500" }}>
            {t("insights")}
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <OperationRule />
          </TabPanel>
          <TabPanel>
            <OperationRuleLocationDetail />
          </TabPanel>
          <TabPanel>
            <OperationRuleLocationInsightDetail />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default observer(InsightManager);
