import { makeAutoObservable } from "mobx";

export default class NavigatorAndWindowStore {
  isOnline: boolean = navigator?.onLine ?? true;

  constructor() {
    makeAutoObservable(this);
  }

  setIsOnline(value: boolean) {
    this.isOnline = value;
  }
}
