export const convertNumberIntervalToTimestamp = (
  hours: number[],
  startDate: number | null,
  endDate: number | null
): number[] => {
  if (!startDate || !endDate) return [];

  const startDt = new Date(startDate);
  const endDt = new Date(endDate);
  const timestamps: number[] = [];
  const intervalInMilliseconds = endDt.getTime() - startDt.getTime();

  hours.forEach((hour) => {
    const percentage = hour / 24;
    const milliseconds = intervalInMilliseconds * percentage;
    const newTimestamp = startDt.getTime() + milliseconds;
    timestamps.push(newTimestamp);
  });

  return timestamps;
};
