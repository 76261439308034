export const visibilityMock = {
  plotOptions: {
    series: {
      label: {
        enabled: true,
        onArea: false,
        style: {
          fontFamily: "monospace",
          fontSize: "0.95em",
          color: "#2F517A",
          fontWeight: "bold",
        },
      },
    },
  },
  series: [
    {
      name: "Visibility",
      yAxis: 0,
      zIndex: 3,
      label: {
        enabled: false,
      },
      marker: {
        enabled: false,
      },
      color: "#0DA12F",
      lineWidth: 3,
      gridLineWidth: 1,
      data: [
        [1715630400000, 1.34],
        [1715634000000, 1.35],
        [1715637600000, 1.36],
        [1715641200000, 1.2],
        [1715644800000, 1.24],
        [1715648400000, 1.31],
        [1715652000000, 1.27],
        [1715655600000, 1.42],
        [1715659200000, 1.41],
        [1715662800000, 1.42],
        [1715666400000, 1.43],
        [1715670000000, 1.48],
        [1715673600000, 1.42],
        [1715677200000, 1.41],
      ],
    },
    {
      name: "Visibility min",
      zIndex: 3,
      yAxis: 0,
      label: {
        enabled: false,
      },
      marker: {
        enabled: false,
      },
      color: "#0D57A1",
      lineWidth: 3,
      gridLineWidth: 1,
      data: [
        [1715630400000, 1.11],
        [1715634000000, 1.16],
        [1715637600000, 1.18],
        [1715641200000, 1.11],
        [1715644800000, 1.16],
        [1715648400000, 1.18],
        [1715652000000, 1.17],
        [1715655600000, 1.17],
        [1715659200000, 1.17],
        [1715662800000, 1.17],
        [1715666400000, 1.18],
        [1715670000000, 1.19],
        [1715673600000, 1.18],
        [1715677200000, 1.19],
      ],
    },
    {
      name: "Visibility max",
      zIndex: 3,
      label: {
        enabled: false,
      },
      marker: {
        enabled: false,
      },
      color: "#0D8BA1",
      lineWidth: 3,
      gridLineWidth: 1,
      data: [
        [1715630400000, 1.65],
        [1715634000000, 1.65],
        [1715637600000, 1.68],
        [1715641200000, 1.69],
        [1715644800000, 1.7],
        [1715648400000, 1.69],
        [1715652000000, 1.71],
        [1715655600000, 1.72],
        [1715659200000, 1.722],
        [1715662800000, 1.7],
        [1715666400000, 1.66],
        [1715670000000, 1.68],
        [1715673600000, 1.69],
        [1715677200000, 1.7],
      ],
    },
    {
      name: "Prob. Visibilidade <2km (%)",
      yAxis: 1,
      zIndex: 3,
      label: {
        enabled: false,
      },
      marker: {
        enabled: false,
      },
      dashStyle: "shortDot",
      color: "#4D0DA1",
      lineWidth: 3,
      gridLineWidth: 1,
      data: [
        [1715630400000, 74],
        [1715634000000, 74],
        [1715637600000, 74],
        [1715641200000, 73],
        [1715644800000, 73],
        [1715648400000, 72],
        [1715652000000, 71],
        [1715655600000, 69],
        [1715659200000, 69],
        [1715662800000, 69],
        [1715666400000, 70],
        [1715670000000, 70],
        [1715673600000, 70],
        [1715677200000, 71],
      ],
    },
    {
      name: "Prob. Visibilidade <1.5km (%)",
      yAxis: 1,
      zIndex: 3,
      label: {
        enabled: false,
      },
      marker: {
        enabled: false,
      },
      dashStyle: "shortDot",
      color: "#807EED",
      lineWidth: 3,
      gridLineWidth: 1,
      data: [
        [1715630400000, 81],
        [1715634000000, 82],
        [1715637600000, 83],
        [1715641200000, 84],
        [1715644800000, 82],
        [1715648400000, 83],
        [1715652000000, 83],
        [1715655600000, 83],
        [1715659200000, 79],
        [1715662800000, 79],
        [1715666400000, 79],
        [1715670000000, 80],
        [1715673600000, 80],
        [1715677200000, 80],
      ],
    },
    {
      name: "Prob. Visibilidade <1km (%)",
      yAxis: 1,
      zIndex: 3,
      label: {
        enabled: false,
      },
      marker: {
        enabled: false,
      },
      dashStyle: "shortDot",
      color: "#ED7EAE",
      lineWidth: 3,
      gridLineWidth: 1,
      data: [
        [1715630400000, 92],
        [1715634000000, 93],
        [1715637600000, 94],
        [1715641200000, 92],
        [1715644800000, 93],
        [1715648400000, 95],
        [1715652000000, 92],
        [1715655600000, 82],
        [1715659200000, 84],
        [1715662800000, 86],
        [1715666400000, 84],
        [1715670000000, 84],
        [1715673600000, 85],
        [1715677200000, 85],
      ],
    },
  ],
};

export const visibilitySeriesMock = [
  {
    name: "Visibility",
    environmental_variable: "visibility",
    data: [
      [1715630400000, 1.34],
      [1715634000000, 1.35],
      [1715637600000, 1.36],
      [1715641200000, 1.2],
      [1715644800000, 1.24],
      [1715648400000, 1.31],
      [1715652000000, 1.27],
      [1715655600000, 1.42],
      [1715659200000, 1.41],
      [1715662800000, 1.42],
      [1715666400000, 1.43],
      [1715670000000, 1.48],
      [1715673600000, 1.42],
      [1715677200000, 1.41],
    ],
    max: 1.48,
    min: 1.2,
    unit: "m",
    unit_symbol: "M",
  },
  {
    name: "Visibility min",
    environmental_variable: "visibility",
    data: [
      [1715630400000, 1.11],
      [1715634000000, 1.16],
      [1715637600000, 1.18],
      [1715641200000, 1.11],
      [1715644800000, 1.16],
      [1715648400000, 1.18],
      [1715652000000, 1.17],
      [1715655600000, 1.17],
      [1715659200000, 1.17],
      [1715662800000, 1.17],
      [1715666400000, 1.18],
      [1715670000000, 1.19],
      [1715673600000, 1.18],
      [1715677200000, 1.19],
    ],
    max: 1.19,
    min: 1.11,
    unit: "m",
    unit_symbol: "M",
  },
  {
    name: "Visibility max",
    environmental_variable: "visibility",
    data: [
      [1715630400000, 1.65],
      [1715634000000, 1.65],
      [1715637600000, 1.68],
      [1715641200000, 1.69],
      [1715644800000, 1.7],
      [1715648400000, 1.69],
      [1715652000000, 1.71],
      [1715655600000, 1.72],
      [1715659200000, 1.722],
      [1715662800000, 1.7],
      [1715666400000, 1.66],
      [1715670000000, 1.68],
      [1715673600000, 1.69],
      [1715677200000, 1.7],
    ],
    max: 1.722,
    min: 1.65,
    unit: "m",
    unit_symbol: "M",
  },
  {
    name: "Prob. Visibility <2km (%)",
    environmental_variable: "visibility",
    data: [
      [1715630400000, 74],
      [1715634000000, 74],
      [1715637600000, 74],
      [1715641200000, 73],
      [1715644800000, 73],
      [1715648400000, 72],
      [1715652000000, 71],
      [1715655600000, 69],
      [1715659200000, 69],
      [1715662800000, 69],
      [1715666400000, 70],
      [1715670000000, 70],
      [1715673600000, 70],
      [1715677200000, 71],
    ],
    max: 74,
    min: 69,
    unit: "%",
    unit_symbol: "%",
  },
  {
    name: "Prob. Visibility <1.5km (%)",
    environmental_variable: "visibility",
    data: [
      [1715630400000, 81],
      [1715634000000, 82],
      [1715637600000, 83],
      [1715641200000, 84],
      [1715644800000, 82],
      [1715648400000, 83],
      [1715652000000, 83],
      [1715655600000, 83],
      [1715659200000, 79],
      [1715662800000, 79],
      [1715666400000, 79],
      [1715670000000, 80],
      [1715673600000, 80],
      [1715677200000, 80],
    ],
    max: 84,
    min: 79,
    unit: "%",
    unit_symbol: "%",
  },
  {
    name: "Prob. Visibility <1km (%)",
    environmental_variable: "visibility",
    data: [
      [1715630400000, 92],
      [1715634000000, 93],
      [1715637600000, 94],
      [1715641200000, 92],
      [1715644800000, 93],
      [1715648400000, 95],
      [1715652000000, 92],
      [1715655600000, 82],
      [1715659200000, 84],
      [1715662800000, 86],
      [1715666400000, 84],
      [1715670000000, 84],
      [1715673600000, 85],
      [1715677200000, 85],
    ],
    max: 94,
    min: 82,
    unit: "%",
    unit_symbol: "%",
  },
];
