import { makeAutoObservable } from "mobx";
import { getEnv, getRoot } from "mobx-easy";
import { EnvironmentalType } from "../../../enum/EnvironmentalType";
import { StoreState } from "../../../enum/StoreState";
import { AnalyticsFormValues } from "../../../pages/AnalyticsReporting/AnalyticsReporting";
import {
  IClimatologicalItem,
  IEventsData,
  IGetEventsPerformance,
} from "../../../types/IAnalyticsReporting";
import RootStore from "../../root-store";
import { RootEnv } from "../../setup/create-store";
import {
  chartMeteogramXAxisMock,
  chartMeteogramYAxisMock,
} from "../forecastChart/forecastChartMeteogram/forecastChartMeteogram.mock";
import { IStation } from "../oceanWeatherIntelligences/oceanWeatherIntelligenceModel";

interface IMonths {
  [key: string]: string;
}

export interface IStationSensorForecast extends IStation {
  hasSensorData?: boolean;
}

export default class AnalyticsReportingStore {
  state: string = StoreState.DONE;
  stationState: string = StoreState.DONE;

  sensorData: number[] = [];
  i4castData: number[] = [];
  globalData: number[] = [];
  chartCategories: string[] = [];
  percentageOccurrence: number[] = [];
  performanceGainHours: number = 0;
  performanceGainPercentage: number = 0;
  averageEventOccurrencePercentage: number = 0;
  noData: boolean = false;
  monthMostFrequently: string | null = null;
  average: number[] = [];
  maximum: number[] = [];
  sensorMonths: string[] = [];
  standardDeviation: number[][] = [];

  stations: IStation[] = [];

  minValue: number | undefined = undefined;
  maxValue: number | undefined = undefined;

  sensorChart: IEventsData | null = null;
  i4castChart: IEventsData | null = null;
  globalChart: IEventsData | null = null;

  isEmptyStateCharts: boolean = true;

  sensorChartXAxis: any[] = chartMeteogramXAxisMock;
  sensorChartYAxis: any[] = chartMeteogramYAxisMock;

  i4castChartXAxis: any[] = chartMeteogramXAxisMock;
  i4castChartYAxis: any[] = chartMeteogramYAxisMock;

  globalChartXAxis: any[] = chartMeteogramXAxisMock;
  globalChartYAxis: any[] = chartMeteogramYAxisMock;

  constructor() {
    this.reset();
    makeAutoObservable(this);
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setStationState(value: StoreState) {
    this.stationState = value;
  }

  setSensorData(value: number[]) {
    this.sensorData = value;
  }

  setI4castData(value: number[]) {
    this.i4castData = value;
  }

  setGlobalData(value: number[]) {
    this.globalData = value;
  }

  setChartCategories(value: string[]) {
    this.chartCategories = value;
  }

  setPerformanceGainHours(value: number) {
    this.performanceGainHours = value;
  }

  setPerformanceGainPercentage(value: number) {
    this.performanceGainPercentage = value;
  }

  setAverageEventOccurrencePercentage(value: number) {
    this.averageEventOccurrencePercentage = value;
  }

  setNoData(value: boolean) {
    this.noData = value;
  }

  setMinValue(value: number | undefined) {
    this.minValue = value;
  }

  setMaxValue(value: number | undefined) {
    this.maxValue = value;
  }

  setMonthMostFrequently(value: string | null) {
    this.monthMostFrequently = value;
  }

  setAverage(value: number[]) {
    this.average = value;
  }

  setMaximum(value: number[]) {
    this.maximum = value;
  }

  setStandardDeviation(value: number[][]) {
    this.standardDeviation = value;
  }

  setSensorMonths(value: string[]) {
    this.sensorMonths = value;
  }

  setSensorChart(value: any) {
    this.sensorChart = value;
  }

  seti4castChart(value: any) {
    this.i4castChart = value;
  }

  setGlobalChart(value: any) {
    this.globalChart = value;
  }

  setSensorChartYAxis(value: any[]) {
    this.sensorChartYAxis = value;
  }

  seti4castChartYAxis(value: any[]) {
    this.i4castChartYAxis = value;
  }

  setGlobalChartYAxis(value: any[]) {
    this.globalChartYAxis = value;
  }

  setIsEmptyStateCharts(value: boolean) {
    this.isEmptyStateCharts = value;
  }

  setStations(values: IStation[]) {
    this.stations = values;
  }

  resetAnalytics() {
    this.setSensorData([]);
    this.setI4castData([]);
    this.setGlobalData([]);
    this.setChartCategories([]);
    this.setAverage([]);
    this.setMaximum([]);
    this.setSensorMonths([]);
    this.setStandardDeviation([]);
    this.setPerformanceGainHours(0);
    this.setPerformanceGainPercentage(0);
    this.setAverageEventOccurrencePercentage(0);
    this.setMonthMostFrequently(null);
    this.setMinValue(undefined);
    this.setMaxValue(undefined);
  }

  resetChart() {
    this.setSensorChart({});
    this.seti4castChart({});
    this.setGlobalChart({});
    this.setIsEmptyStateCharts(true);
  }

  reset() {
    this.setState(StoreState.DONE);
    this.setStationState(StoreState.DONE);
    this.resetAnalytics();
    this.setNoData(false);
    this.resetChart();
    this.setStations([]);
  }

  getMonthName(date: string) {
    const months: IMonths = {
      "01": "january",
      "02": "february",
      "03": "march",
      "04": "april",
      "05": "may",
      "06": "june",
      "07": "july",
      "08": "august",
      "09": "september",
      "10": "october",
      "11": "november",
      "12": "december",
    };

    const [monthName] = date.split("/");

    return months[monthName];
  }

  async getAnalytics({
    startDate,
    endDate,
    stationRegion,
    environmentalVariableId,
    minValue,
    maxValue,
    periodForecast,
  }: AnalyticsFormValues) {
    this.setState(StoreState.PEDDING);
    this.setNoData(false);
    const { analyticsReportingService } = getEnv<RootEnv>();

    try {
      const stationRegionArray = stationRegion.split("-");
      if (stationRegionArray?.length !== 2) {
        return;
      }
      const stationId = stationRegionArray[0];
      const regionId = stationRegionArray[1];

      const {
        performanceChart,
        climatologicalChart,
        performanceGainHours,
        performanceGainPercentage,
        averageEventOccurrencePercentage,
      } = await analyticsReportingService.getForecastPerformance({
        initialDate: this.formatDate(startDate),
        finalDate: this.formatDate(endDate),
        regionId: parseInt(regionId),
        stationId: parseInt(stationId),
        environmentalVariableId,
        minValue: minValue ? parseInt(minValue.toString()) : undefined,
        maxValue: maxValue ? parseInt(maxValue.toString()) : undefined,
        forecastPeriod: periodForecast,
      });

      const { sensor, i4cast, global, months, percentageOccurrence } =
        performanceChart;
      const {
        sensorAVGs,
        sensorMaxValues,
        sensorMonths,
        sensorStandardDeviations,
      } = this.transformToArrays(climatologicalChart);

      if (!sensor.length) {
        this.setNoData(true);
      } else {
        this.setSensorData(sensor);
        this.setI4castData(i4cast);
        this.setGlobalData(global);
        this.setChartCategories(months);
        this.setPerformanceGainHours(performanceGainHours);
        this.setPerformanceGainPercentage(performanceGainPercentage);
        this.setAverageEventOccurrencePercentage(
          averageEventOccurrencePercentage
        );

        this.setAverage(sensorAVGs);
        this.setMaximum(sensorMaxValues);
        this.setSensorMonths(sensorMonths);

        const formattedStandardDeviation = this.formatterStandardDeviation(
          sensorAVGs,
          sensorStandardDeviations
        );

        this.setStandardDeviation(formattedStandardDeviation);

        const biggerValueIndex = percentageOccurrence.reduce(
          (index, element, currentIndex) => {
            return element > percentageOccurrence[index] ? currentIndex : index;
          },
          0
        );

        this.setMonthMostFrequently(
          this.getMonthName(months[biggerValueIndex])
        );

        this.setMinValue(minValue);
        this.setMaxValue(maxValue);
      }

      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  combineStations(
    stationsForecast: IStationSensorForecast[],
    stationsSensor: IStationSensorForecast[]
  ): IStationSensorForecast[] {
    const resultMap = new Map<string, IStationSensorForecast>();

    for (const item of stationsForecast) {
      resultMap.set(`${item.station_id}-${item.region_id}`, item);
    }

    for (const item of stationsSensor) {
      const key = `${item.station_id}-${item.region_id}`;
      if (resultMap.has(key)) {
        resultMap.get(key)!.hasSensorData = true;
      } else {
        resultMap.set(key, item);
      }
    }

    return Array.from(resultMap.values());
  }

  convertToStationInfoWithSensorData(
    stations: IStation[]
  ): IStationSensorForecast[] {
    return stations.map((station) => ({
      ...station,
      hasSensorData: true,
    }));
  }

  sortByHasSensorDataFirst(stations: IStationSensorForecast[]) {
    stations.sort((a, b) => {
      if (a.hasSensorData && !b.hasSensorData) {
        return -1;
      } else if (!a.hasSensorData && b.hasSensorData) {
        return 1;
      }
      return 0;
    });
  }

  async getStations(environmental_type: EnvironmentalType) {
    const { atmOceanService, analyticsService } = getEnv<RootEnv>();
    this.resetChart();

    this.setStationState(StoreState.PEDDING);
    try {
      const stationsForecast: IStation[] =
        await atmOceanService.getStationsForecastChart(environmental_type);

      const stationsSensor: IStation[] =
        await analyticsService.getMeasuredStations(environmental_type);

      const stationsSensorConverted =
        this.convertToStationInfoWithSensorData(stationsSensor);

      const stationsForecastSensor = this.combineStations(
        stationsForecast,
        stationsSensorConverted
      );

      this.sortByHasSensorDataFirst(stationsForecastSensor);

      this.setStations(stationsForecastSensor);

      this.setStationState(StoreState.DONE);
    } catch (error) {
      this.setStationState(StoreState.ERROR);
    }
  }
  async getComparativeForecast({
    regionId,
    stationId,
    environmentalType,
    initialDate,
    finalDate,
  }: IGetEventsPerformance) {
    this.setState(StoreState.PEDDING);
    this.resetChart();

    const { analyticsReportingService } = getEnv<RootEnv>();
    const {
      dataStores: { forecastChartMeteogramStore },
    } = getRoot<RootStore>();

    const setChartFunctions: Record<string, (data: any) => void> = {
      sensor: (data) => {
        this.setSensorChart(data);
      },
      i4cast: (data) => {
        this.seti4castChart(data);
      },
      global: (data) => {
        this.setGlobalChart(data);
      },
    };

    const setChartYAxisFunctions: Record<string, (data: any) => void> = {
      sensor: (data) => {
        this.setSensorChartYAxis(data);
      },
      i4cast: (data) => {
        this.seti4castChartYAxis(data);
      },
      global: (data) => {
        this.setGlobalChartYAxis(data);
      },
    };

    try {
      const data = await analyticsReportingService.getEvents({
        initialDate,
        finalDate,
        stationId,
        regionId,
        environmentalType,
      });

      const i4cast = data?.find((item) => item.name === "i4cast");
      const sensor = data?.find((item) => item.name === "sensor");
      const global = data?.find((item) => item.name === "global");

      const processAndSetChart = (
        data: IEventsData | undefined,
        chartName: string
      ) => {
        if (data && data.data?.series?.length) {
          const { serieProcessed, yAxisProcessed } =
            forecastChartMeteogramStore.getProcessedSerieByEnvironmentalType(
              environmentalType,
              data.data.series
            );
          setChartFunctions[chartName](serieProcessed);
          setChartYAxisFunctions[chartName](yAxisProcessed);
        }
      };

      processAndSetChart(i4cast, "i4cast");
      processAndSetChart(sensor, "sensor");
      processAndSetChart(global, "global");

      this.setIsEmptyStateCharts(false);
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  private formatterStandardDeviation(
    average: number[],
    standardDeviation: number[]
  ) {
    const standardDeviationFormatted: number[][] = [];

    for (let i = 0; i < average.length; i++) {
      const upperValue = average[i] + standardDeviation[i];
      const lowerValue = average[i] - standardDeviation[i];
      standardDeviationFormatted.push([lowerValue, upperValue]);
    }

    return standardDeviationFormatted;
  }

  private formatDate(date: Date): number {
    const formattedDate = new Date(date);
    formattedDate.setHours(0, 0, 0, 0);
    return formattedDate.getTime();
  }

  private transformToArrays(data: IClimatologicalItem[]) {
    const sensorAVGArray: number[] = [];
    const sensorMaxValueArray: number[] = [];
    const sensorStandardDeviationArray: number[] = [];
    const monthArray: string[] = [];

    for (const item of data) {
      sensorAVGArray.push(item.sensorAVG);
      sensorMaxValueArray.push(item.sensorMaxValue);
      sensorStandardDeviationArray.push(item.sensorStandardDeviation);
      monthArray.push(item.month);
    }

    return {
      sensorAVGs: sensorAVGArray,
      sensorMaxValues: sensorMaxValueArray,
      sensorMonths: monthArray,
      sensorStandardDeviations: sensorStandardDeviationArray,
    };
  }
}
