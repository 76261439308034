import { EnvironmentalType } from "../../../../enum/EnvironmentalType";
import { EnvironmentalVariables } from "../../../../enum/EnvironmentalVariables";

export const getEnvironmentalVariablesDefault = (
  environmentalType: EnvironmentalType,
  isDefault100m?: boolean
): EnvironmentalVariables[] => {
  switch (environmentalType) {
    case EnvironmentalType.WEATHER:
      if (isDefault100m) {
        return [
          EnvironmentalVariables.WIND_VEL_100M,
          EnvironmentalVariables.GUST_100M,
          EnvironmentalVariables.PRECIP,
          EnvironmentalVariables.WIND_DIR_100M,
        ];
      }

      return [
        EnvironmentalVariables.WIND_VEL,
        EnvironmentalVariables.GUST,
        EnvironmentalVariables.PRECIP,
        EnvironmentalVariables.WIND_DIR,
      ];
    case EnvironmentalType.WAVE:
      return [
        EnvironmentalVariables.WAVE_HEIGHT,
        EnvironmentalVariables.WAVE_DIR,
        EnvironmentalVariables.PEAK_PERIOD,
      ];
    case EnvironmentalType.WAVE_SEA:
      return [
        EnvironmentalVariables.WAVE_HEIGHT_SEA,
        EnvironmentalVariables.WAVE_DIR_SEA,
        EnvironmentalVariables.PEAK_PERIOD_SEA,
      ];
    case EnvironmentalType.WAVE_SWELL:
      return [
        EnvironmentalVariables.WAVE_HEIGHT_SWELL,
        EnvironmentalVariables.WAVE_DIR_SWELL,
        EnvironmentalVariables.PEAK_PERIOD_SWELL,
      ];
    case EnvironmentalType.CURRENT:
      return [
        EnvironmentalVariables.CURRENT_VEL,
        EnvironmentalVariables.CURRENT_DIR,
        EnvironmentalVariables.WATER_LEVEL, // Fixing show only water level default serie
      ];

    case EnvironmentalType.WATER_FLOW:
      return [
        EnvironmentalVariables.WATER_LEVEL,
        EnvironmentalVariables.CURRENT_VEL,
        EnvironmentalVariables.CURRENT_DIR,
      ];
    case EnvironmentalType.WEATHER_TEMPERATURE:
      return [
        EnvironmentalVariables.TEMP_AIR,
        EnvironmentalVariables.DEW_POINT,
        EnvironmentalVariables.REL_HUMIDITY,
      ];
    case EnvironmentalType.PRECIPITATION:
      return [
        EnvironmentalVariables.PRECIP,
        EnvironmentalVariables.PRECIP_MIN_20KM,
        EnvironmentalVariables.PRECIP_PROBABILITY,
      ];
    case EnvironmentalType.VISIBILITY:
      return [
        EnvironmentalVariables.VISIBILITY,
        EnvironmentalVariables.VISIBILITY_MINIMUM_20KM,
        EnvironmentalVariables.VISIBILITY_MAXIMUM_20KM,
        EnvironmentalVariables.VISIBILITY_PROBABILITY_1000M,
      ];
    default:
      return [];
  }
};
