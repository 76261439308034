import { EnvironmentalType } from "../enum/EnvironmentalType";
import { IAtmOceanMeteogram } from "../stores/data/atmOcean/atmOceanModel";
import { IStation } from "../stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import api from "./api";

export interface IMeasuredDataChartParams {
  stationId: number;
  environmentalType: EnvironmentalType;
  createdAt?: string;
  daysMeasured?: number;
  regionId: number;
  controller?: AbortController;
}
export default class AnalyticsService {
  async getMeasuredDataChart(
    params: IMeasuredDataChartParams
  ): Promise<IAtmOceanMeteogram> {
    const result = await api.post(
      "/v1/analytics/measured-data-chart",
      {
        station_id: params.stationId,
        environmental_type: params.environmentalType,
        created_at: params.createdAt,
        days_measured: params.daysMeasured,
        region_id: params.regionId,
      },
      { signal: params.controller?.signal }
    );
    return result.data;
  }

  async getMeasuredStations(
    environmentalType: string,
    macroRegionId?: number
  ): Promise<IStation[]> {
    const result = await api.post("/v1/analytics/get-stations-measured-data", {
      environmental_type: environmentalType,
      macro_region_id: macroRegionId,
    });
    return result.data;
  }
}
