import { DBAction } from "../../enum/DBAction";

export const processObjectsForBackend = (
  itemsBefore: any[],
  itemsAfter: any[],
  keySort: string
): any[] => {
  const result: any[] = [];

  const itemsBeforeSorted = itemsBefore.sort((a, b) =>
    a[keySort] < b[keySort] ? -1 : a[keySort] > b[keySort] ? 1 : 0
  );

  const itemsAfterSorted: any[] = itemsAfter.sort((a, b) =>
    a[keySort] < b[keySort] ? -1 : a[keySort] > b[keySort] ? 1 : 0
  );

  let beforeCurrentCompare = 0;
  let afterCurrentCompare = 0;
  let deleteItemsIndex: Array<number> = [];

  while (beforeCurrentCompare < itemsBeforeSorted.length) {
    const beforeItem = itemsBeforeSorted[beforeCurrentCompare];
    if (!itemsAfterSorted[afterCurrentCompare]) {
      deleteItemsIndex.push(result.length);
      result.push({
        ...beforeItem,
        action: DBAction.DELETE,
      });
      beforeCurrentCompare++;
    } else {
      const afterItem = itemsAfterSorted[afterCurrentCompare];
      if (!itemsAfterSorted[afterCurrentCompare].hasOwnProperty(keySort)) {
        let newItem = {
          ...afterItem,
          action: DBAction.UPDATE,
        };
        newItem[keySort] = beforeItem[keySort];
        result.push(newItem);
        beforeCurrentCompare++;
        afterCurrentCompare++;
      } else if (
        beforeItem[keySort] < itemsAfterSorted[afterCurrentCompare][keySort]
      ) {
        deleteItemsIndex.push(result.length);
        result.push({
          ...beforeItem,
          action: DBAction.DELETE,
        });
        beforeCurrentCompare++;
      } else {
        const afterItem = itemsAfterSorted[afterCurrentCompare];
        if (beforeItem[keySort] === afterItem[keySort]) {
          let newItem = {
            ...afterItem,
            action: DBAction.UPDATE,
          };
          newItem[keySort] = beforeItem[keySort];
          result.push(newItem);
          beforeCurrentCompare++;
          afterCurrentCompare++;
        } else {
          const itemIndex = deleteItemsIndex.shift();
          let newItem = {
            ...afterItem,
          };
          if (itemIndex !== undefined) {
            newItem[keySort] = result[itemIndex][keySort];
            newItem["action"] = DBAction.UPDATE;
            result[itemIndex] = newItem;
          } else {
            newItem[keySort] = 0;
            newItem["action"] = DBAction.CREATE;
            result.push(newItem);
          }
          afterCurrentCompare++;
        }
      }
    }
  }

  while (afterCurrentCompare < itemsAfterSorted.length) {
    const afterItem = itemsAfterSorted[afterCurrentCompare];
    let newItem = {
      ...afterItem,
    };
    const itemIndex = deleteItemsIndex.shift();
    if (itemIndex !== undefined) {
      newItem[keySort] = result[itemIndex][keySort];
      newItem["action"] = DBAction.UPDATE;
      result[itemIndex] = newItem;
    } else {
      newItem[keySort] = 0;
      newItem["action"] = DBAction.CREATE;
      result.push(newItem);
    }
    afterCurrentCompare++;
  }

  return result;
};
