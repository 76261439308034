import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import I4castAlert from "../../../components/I4castAlert/I4castAlert";
import { StoreState } from "../../../enum/StoreState";
import { useStores } from "../../../stores/setup/use-store";

import {
  Text as TextChakra,
  Flex,
  Box,
  Center,
  Button,
  Tooltip,
  useToast,
  IconButton,
  ButtonGroup,
} from "@chakra-ui/react";
import Lottie from "lottie-react";
import noData from "../../../assets/animations/no-data.json";
import error from "../../../assets/animations/error.json";
import CachedIcon from "@material-ui/icons/Cached";
import { useTranslation } from "react-i18next";

import CloseIcon from "@material-ui/icons/Close";
import { SCREEN_HORIZONTAL_SPACING } from "../../../constants";
import useWindowObserver from "../../../hooks/useWindowObserver";
import { Spinner } from "@chakra-ui/react";
import { VisualizationTypeButton } from "pages/ForecastChart/ForecastChartPanelMeteogram/Components/VisualizationTypeButton";
import { VisualizationType } from "enum/VisualizationType";
import ForecastCharts from "./Charts/ForecastCharts";
import SensorChart from "pages/ForecastChart/ForecastChartPanelMeteogram/Charts/SensorChart";

interface PanelChartProps {}

const PanelChart: React.FC<PanelChartProps> = () => {
  const toast = useToast();
  const toastId = "toastInfoNoDataId";

  const windowSize = useWindowObserver(".main-area");
  const isSmallScreen = windowSize.width <= 1300;

  const {
    dataStores: { riverForecastChartStore, analyticsChartStore },
  } = useStores();

  const getChartHeight = () => {
    if (windowSize.width < 480) {
      return `${windowSize.height * 0.461}px`;
    } else if (windowSize.width < 768) {
      return `${windowSize.height * 0.399}px`;
    } else if (windowSize.width < 1200) {
      return `${windowSize.height * 0.393}px`;
    } else {
      return `${windowSize.height * 0.376}px`;
    }
  };

  const getContainerHeight = () => {
    if (windowSize.width < 480) {
      return "46.1vh";
    } else if (windowSize.width < 768) {
      return "39.9vh";
    } else if (windowSize.width < 1200) {
      return "39.33vh";
    } else {
      return "37.66vh";
    }
  };

  const chartHeight = getChartHeight();
  const containerHeight = getContainerHeight();

  const { i18n, t } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartPanelMeteogram",
  });

  useEffect(() => {
    riverForecastChartStore.setOpenMeteogram(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noDataAvailable = useMemo(() => {
    return (
      (riverForecastChartStore.state === StoreState.DONE &&
        riverForecastChartStore.isChartDataEmpty) ||
      analyticsChartStore.isChartDataEmpty
    );
  }, [
    analyticsChartStore.isChartDataEmpty,
    riverForecastChartStore.isChartDataEmpty,
    riverForecastChartStore.state,
  ]);

  const showErrorOnChart = useMemo(() => {
    return (
      riverForecastChartStore.state === StoreState.ERROR &&
      riverForecastChartStore.isChartDataEmpty
    );
  }, [riverForecastChartStore.isChartDataEmpty, riverForecastChartStore.state]);

  const showChart = useMemo(() => {
    return !noDataAvailable && !showErrorOnChart;
  }, [noDataAvailable, showErrorOnChart]);

  const isLoading =
    riverForecastChartStore.state === StoreState.PEDDING ||
    analyticsChartStore.state === StoreState.PEDDING;

  const handleCloseMeteogram = () => {
    riverForecastChartStore.setOpenMeteogram(false);

    const showMessage = localStorage.getItem("showMessageOnCloseMeteogram");

    if (showMessage) {
      return;
    }

    localStorage.setItem("showMessageOnCloseMeteogram", "true");
    toast({
      id: toastId,
      title: t("clickOnMapToOpen"),
      status: "info",
      position: "top",
      isClosable: true,
    });
  };

  const handleForecastClick = () => {
    riverForecastChartStore.setVisualizationType(VisualizationType.FORECAST);

    riverForecastChartStore.getRiverStations();
    analyticsChartStore.resetChartMeteogramData();
  };

  const handleSensorClick = async () => {
    riverForecastChartStore.setVisualizationType(VisualizationType.SENSOR);

    riverForecastChartStore.setRiverForecastData(null);
    riverForecastChartStore.getRiverStations();

    analyticsChartStore.setIsChartDataEmpty(false);
  };

  const renderChartByVisualizationType = () => {
    switch (riverForecastChartStore.visualizationType) {
      case VisualizationType.FORECAST:
        return <ForecastCharts />;
      case VisualizationType.SENSOR:
        return (
          <SensorChart
            chartHeight={chartHeight}
            style={{ maxWidth: windowSize.width }}
          />
        );

      default:
        return <ForecastCharts />;
    }
  };

  return (
    <>
      <Box
        position={
          riverForecastChartStore.openMeteogram ? "absolute" : undefined
        }
        w={"100%"}
        zIndex={99999999}
        bottom={0}
        right={0}
      >
        <Box>
          <Flex
            wrap={isSmallScreen ? undefined : "wrap"}
            justifyContent="space-between"
            alignItems="flex-end"
            gap={2}
            fontSize={isSmallScreen ? "12px" : undefined}
          >
            <Flex
              alignItems="center"
              gap={2}
              pl={SCREEN_HORIZONTAL_SPACING}
              hidden={!riverForecastChartStore.openMeteogram}
              position="absolute"
              left={0}
            >
              <Box>
                <Tooltip
                  label={t("refreshToseeNewData")}
                  fontSize="sm"
                  placement="top"
                  hasArrow
                >
                  <Button
                    disabled={riverForecastChartStore.isChartDataEmpty}
                    layerStyle="darkOcean"
                    _hover={{ layerStyle: "darkOcean" }}
                    _disabled={{ layerStyle: "darkOcean" }}
                    size="xs"
                    onClick={() => riverForecastChartStore.setActionCallChart()}
                    zIndex="999999"
                    borderRadius={4}
                  >
                    <CachedIcon style={{ width: "19px", height: "19px" }} />
                  </Button>
                </Tooltip>
              </Box>
            </Flex>
            <Box
              display={"grid"}
              zIndex={999999}
              gap={"7px"}
              position="fixed"
              right={0}
              mr={SCREEN_HORIZONTAL_SPACING}
            >
              <Flex display={"flex"} justifyContent={"end"} alignItems="center">
                <ButtonGroup
                  isAttached
                  color={"white"}
                  size="xs"
                  mr={"4px"}
                  mb={riverForecastChartStore.openMeteogram ? "0px" : "7px"}
                >
                  <VisualizationTypeButton
                    className="joyride-menu-types-forecast"
                    label={t("deterministic")}
                    isActive={
                      riverForecastChartStore.visualizationType ===
                      VisualizationType.FORECAST
                    }
                    onClick={handleForecastClick}
                  />
                  <VisualizationTypeButton
                    className="joyride-menu-types-sensor"
                    label="Sensor"
                    isActive={
                      riverForecastChartStore.visualizationType ===
                      VisualizationType.SENSOR
                    }
                    onClick={handleSensorClick}
                    style={{ borderRight: "none" }}
                  />
                </ButtonGroup>
              </Flex>
            </Box>
          </Flex>

          <Box bg="#ffffff" width="100%">
            <I4castAlert
              style={{ position: "absolute", zIndex: 9, width: "100%" }}
              visible={riverForecastChartStore.state === StoreState.ERROR}
              message={riverForecastChartStore.messageError}
              type="error"
            />

            <Box
              mt={"7px"}
              pt={"8px"}
              h={
                riverForecastChartStore.openMeteogram ? containerHeight : "0px"
              }
              transition="transform .2s ease-in-out"
              opacity={riverForecastChartStore.openMeteogram ? 1 : 0}
              display={riverForecastChartStore.openMeteogram ? "grid" : "none"}
              pointerEvents={
                riverForecastChartStore.openMeteogram ? "auto" : "none"
              }
              bg="#ffffff"
              p={"4px"}
            >
              <Flex
                zIndex={999999999999}
                position={"absolute"}
                right={0}
                mr={"5px"}
              >
                <Tooltip
                  label={t("closeChart")}
                  placement={"bottom-start"}
                  hasArrow
                >
                  <IconButton
                    colorScheme="red"
                    icon={
                      <CloseIcon
                        style={{ width: "0.65em", height: "0.85em" }}
                      />
                    }
                    p={"5px"}
                    size="xs"
                    onClick={() => handleCloseMeteogram()}
                    aria-label={"close button"}
                  />
                </Tooltip>
              </Flex>

              <Center>
                {!isLoading && noDataAvailable && (
                  <Box>
                    <Center>
                      <Box width={"115px"}>
                        <Lottie animationData={noData} />
                      </Box>
                    </Center>
                    <Center>
                      <TextChakra color={"blue.100"} fontSize="md">
                        {t("thereIsnotDataAvailable", {
                          empty: "",
                          location: i18n.t(
                            "environmentalVariableEnum.water_flow"
                          ),
                          empty2: "",
                        })}
                      </TextChakra>
                    </Center>
                  </Box>
                )}

                {!isLoading && showErrorOnChart && (
                  <Box>
                    <Center>
                      <Box width={"115px"}>
                        <Lottie animationData={error} />
                      </Box>
                    </Center>
                    <Center>
                      <TextChakra color={"blue.100"} fontSize="md">
                        {t("somethingWrong")}
                      </TextChakra>
                    </Center>
                  </Box>
                )}
              </Center>

              {isLoading && (
                <Center position="absolute" inset={0}>
                  <Spinner
                    thickness="4px"
                    color="newBlue.500"
                    size="xl"
                    zIndex={99999999999}
                  />
                </Center>
              )}
              <Box>
                {!isLoading && showChart ? (
                  <Box>{renderChartByVisualizationType()}</Box>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default observer(PanelChart);
