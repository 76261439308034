import { createBrowserHistory } from "history";
import React from "react";
import { Redirect, Route, RouteProps, Router, Switch } from "react-router-dom";
import I4castLayout from "./layouts/I4castLayout";
import MyData from "./pages/Admin/MyData/MyData";
import { UserManager } from "./pages/Admin/UserManager/UserManager";
import DynamicUKC from "./pages/DynamicUKC/DynamicUKC";
import ForecastChartPage from "./pages/ForecastChart/ForecastChartPage/ForecastChartPage";
import { ForecastImportChecker } from "./pages/ForecastImportChecker/ForecastImportChecker";
import Login from "./pages/Login/Login/Login";
import { NotificationManager } from "./pages/NotificationManager/NotificationManager";
import InsightAlertDetail from "./pages/OceanWeatherIntelligence/InsightAlert/InsightAlertDetail";
import OceanWeatherIntelligence from "./pages/OceanWeatherIntelligence/OceanWeatherIntelligence";
import OperationRule from "./pages/OceanWeatherIntelligence/OperationRule/OperationRule";
import OperationRuleLocationDetail from "./pages/OceanWeatherIntelligence/OperationRuleLocation/OperationRuleLocationDetail";
import OperationRuleLocationInsightDetail from "./pages/OceanWeatherIntelligence/OperationRuleLocationInsight/OperationRuleLocationInsightDetail";
import RegisterUser from "./pages/RegisterUser/RegisterUser";
import { ChangePassword } from "./pages/Admin/ChangePassword/ChangePassword";
import Settings from "./pages/Settings/Settings";
import InsightManager from "./pages/OceanWeatherIntelligence/InsightManager/InsightManager";
import { useStores } from "./stores/setup/use-store";
import Occurrences from "./pages/Occurrence/Occurrences";
import SedimentationMap from "./pages/SedimentationMap/SedimentationMap";
import ManageStation from "./pages/Admin/ManageStation/ManageStation";
import AnalyticsReporting from "./pages/AnalyticsReporting/AnalyticsReporting";
import EventHistory from "./pages/AnalyticsReporting/EventHistory";
import EventAnalysis from "./pages/AnalyticsReporting/EventAnalysis/EventAnalysis";
import Page404 from "pages/404";
import Page500 from "pages/500";
import RiverForecastChart from "pages/RiverForecastChart/RiverForecastChart";

interface MyRouteProps extends RouteProps {
  component: any;
  rest?: any;
}
const PrivateRoute: React.FunctionComponent<MyRouteProps> = ({
  component: Component,
  ...rest
}: any) => {
  const {
    dataStores: { securityStore },
  } = useStores();

  return (
    <I4castLayout>
      <Route
        {...rest}
        render={(props) =>
          securityStore.isAuthorized() ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    </I4castLayout>
  );
};

export const history = createBrowserHistory();

const Routes = () => (
  <Router history={history}>
    <Switch>
      <PrivateRoute
        exact
        path="/operation-insights"
        component={OceanWeatherIntelligence}
      />
      <PrivateRoute exact path="/operationRule" component={OperationRule} />
      <PrivateRoute
        exact
        path="/operationRuleLocation"
        component={OperationRuleLocationDetail}
      />
      <PrivateRoute
        exact
        path="/operationRuleLocationInsight"
        component={OperationRuleLocationInsightDetail}
      />
      <PrivateRoute exact path="/insight-manager" component={InsightManager} />
      <PrivateRoute
        exact
        path="/forecast-charts"
        component={ForecastChartPage}
      />

      <PrivateRoute
        exact
        path="/river-forecast-charts"
        component={RiverForecastChart}
      />

      <PrivateRoute exact path="/dynamic-ukc-chart" component={DynamicUKC} />

      <PrivateRoute
        exact
        path="/insight-alerts"
        component={InsightAlertDetail}
      />

      <PrivateRoute exact path="/occurrences" component={Occurrences} />

      <PrivateRoute
        exact
        path="/analytics-reporting"
        component={AnalyticsReporting}
      />
      <PrivateRoute exact path="/event-history" component={EventHistory} />
      <PrivateRoute exact path="/event-analysis" component={EventAnalysis} />

      <PrivateRoute exact path="/admin/my-data" component={MyData} />
      <PrivateRoute
        exact
        path="/notification-manager"
        component={NotificationManager}
      />

      <PrivateRoute
        exact
        path="/forecast-import-checker"
        component={ForecastImportChecker}
      />
      <PrivateRoute exact path="/admin/user-manager" component={UserManager} />
      <PrivateRoute
        exact
        path="/admin/change-password"
        component={ChangePassword}
      />
      <PrivateRoute
        exact
        path="/admin/manage-station"
        component={ManageStation}
      />
      <PrivateRoute exact path="/settings" component={Settings} />
      <PrivateRoute
        exact
        path="/sedimantetion-map"
        component={SedimentationMap}
      />
      <Route
        exact
        path="/invitation/:invitation_token"
        component={RegisterUser}
      />
      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />
      <Route path="/signup" component={() => <h1>SignUp</h1>} />
      <Route path="/404" component={Page404} />
      <Route path="/500" component={Page500} />
      <Redirect from="*" to="/404" />
    </Switch>
  </Router>
);

export default Routes;
