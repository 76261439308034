import { Step } from "react-joyride";
import Joyride from "../../../../components/Joyride/Joyride";
import { JoyrideStorage } from "../../../../enum/JoyrideStorage";
import i18n from "../../../../i18n";
import useGuidedTutorial from "../hooks/useGuidedTutorial";

interface GuidedTutorialProps {
  stepsProps: Step[];
  storage: JoyrideStorage[];
  shouldRunJoyride?: boolean;
}

export default function GuidedTutorial({
  stepsProps,
  storage,
  shouldRunJoyride = true,
}: GuidedTutorialProps) {
  const { run, stepIndex, handleJoyrideCallback } = useGuidedTutorial(
    stepsProps?.length,
    storage,
    shouldRunJoyride
  );

  if (!shouldRunJoyride) return null;

  return (
    <Joyride
      run={run}
      continuous
      showProgress
      showSkipButton
      disableOverlayClose
      steps={stepsProps}
      callback={handleJoyrideCallback}
      stepIndex={stepIndex}
      locale={{
        back: i18n.t("forecastChart.forecastChartPage.back"),
        next: i18n.t("forecastChart.forecastChartPage.next"),
        skip: i18n.t("forecastChart.forecastChartPage.skip"),
        last: i18n.t("forecastChart.forecastChartPage.last"),
      }}
    />
  );
}
