import api from "./api";

export default class FeedbackService {
  async sendQuestionFeedback(
    questionId: string,
    question: string,
    answer: string
  ): Promise<{ success: boolean }> {
    const result = await api.post("v1/feedback/question-answer", {
      questionId,
      question,
      answer,
    });
    return result.data;
  }

  async deleteQuestionFeedback(
    questionId: string
  ): Promise<{ success: boolean }> {
    const result = await api.delete(
      `v1/feedback/question-answer/${questionId}`
    );
    return result.data;
  }
}
