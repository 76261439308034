import { EnvironmentalVariables } from "../../../../enum/EnvironmentalVariables";
import { VisualizationType } from "../../../../enum/VisualizationType";
import i18n from "../../../../i18n";
import { ISerieConfigMap } from "./types/ISerieConfigMap";

export const getPrecipConfigMap = (
  type: VisualizationType
): Record<string, ISerieConfigMap> => {
  const precipColors: Partial<Record<EnvironmentalVariables, string>> = {
    [EnvironmentalVariables.PRECIP]: "rgba(0,128,0, 0.45)",
    [EnvironmentalVariables.PRECIP_MIN_20KM]: "rgba(30, 82, 151,1)",
    [EnvironmentalVariables.PRECIP_PROBABILITY]: "rgba(30, 82, 151,0.4)",
  };

  const getColor = (
    variable: EnvironmentalVariables,
    type: VisualizationType
  ) => {
    let colorMap: Record<string, string>;
    switch (type) {
      case VisualizationType.FORECAST:
        colorMap = precipColors;
        break;
      default:
        colorMap = {};
        break;
    }
    return colorMap[variable.toLowerCase()] || "#347ED5";
  };

  const yAxisConfig = {
    title: {
      text: i18n.t("forecastChart.forecastChartPanelMeteogram.preciptation"),
      style: {
        color: "#000000",
      },
    },
    min: 0,
    max: 0.6,
    labels: { format: "{value}", style: { color: "#000000" } },
    gridLineWidth: 0,
    tickLength: 0,
  };

  const yAxisConfig1 = {
    title: {
      text: i18n.t("forecastChart.forecastChartPanelMeteogram.probability"),
      style: {
        color: "#000000",
      },
    },
    min: 0,
    max: 100,
    labels: { format: "{value}%", style: { color: "#000000" } },
    opposite: true,
    tickPixelInterval: 55,
  };

  return {
    [EnvironmentalVariables.PRECIP]: {
      serieConfig: {
        type: "column",
        color: getColor(EnvironmentalVariables.PRECIP, type),
        yAxis: 0,
        groupPadding: 0,
        pointPadding: 0,
        borderWidth: 0,
        shadow: false,
        dataLabels: { enabled: false },
        style: { fontSize: "12px" },
      },
      yAxisConfig,
    },
    [EnvironmentalVariables.PRECIP_MIN_20KM]: {
      serieConfig: {
        type: "errorbar",
        color: getColor(EnvironmentalVariables.PRECIP_MIN_20KM, type),
        yAxis: 0,
        lineWidth: 2,
        showInLegend: true,
        dataLabels: { enabled: false },
        style: { fontSize: "12px" },
        updateYAxis: true,
        updateBiggestMax: true,
      },
      yAxisConfig,
    },
    [EnvironmentalVariables.PRECIP_PROBABILITY]: {
      serieConfig: {
        type: "spline",
        color: getColor(EnvironmentalVariables.PRECIP_PROBABILITY, type),
        yAxis: 1,
        dataLabels: { enabled: false },
        lineWidth: 0.85,
        style: { fontSize: "12px" },
        updateYAxis: true,
        updateBiggestMax: false,
      },
      yAxisConfig: yAxisConfig1,
    },
  };
};
