import computerScreen from "../../../assets/computer-screen.svg";
import pileStack from "../../../assets/pile-stack.svg";
import heartLine from "../../../assets/heart-line.svg";
import alarmBell from "../../../assets/alarm-bell.svg";
import userCircle from "../../../assets/user_circle.svg";
import sirene from "../../../assets/sirene.svg";
import vector from "../../../assets/vector.svg";
import occurenceIcon from "../../../assets/occurenceIcon.svg";
import anchor from "../../../assets/anchor-navigational.svg";
import mapIcon from "../../../assets/map.svg";
import analytics from "../../../assets/analytics.svg";
import waves from "../../../assets/waves.svg";

import { MenuItemProps } from "./menuViewStore";

import i18n from "../../../i18n";

export const getMenuListMock = (): MenuItemProps[] => [
  {
    id: 2,
    icon: pileStack,
    title: i18n.t("navBar.forecastCharts"),
    url: "/forecast-charts",
    homeScreen: true,
    hidden: false,
    isNew: true,
  },
  {
    id: 9,
    icon: waves,
    title: i18n.t("navBar.riverForecast"),
    url: "/river-forecast-charts",
    homeScreen: true,
    hidden: true,
  },
  {
    id: 1,
    icon: computerScreen,
    title: i18n.t("navBar.operationInsight"),
    url: "/operation-insights",
    homeScreen: false,
    hidden: false,
  },
  {
    id: 4,
    icon: heartLine,
    title: i18n.t("navBar.dynamicUkcCharts"),
    url: "/dynamic-ukc-chart",
    homeScreen: false,
    hidden: false,
  },
  {
    id: 5,
    icon: sirene,
    title: i18n.t("navBar.alerts"),
    url: "/insight-alerts",
    homeScreen: false,
    hidden: false,
  },
  {
    id: 6,
    title: i18n.t("navBar.mooringLines"),
    url: "",
    homeScreen: false,
    hidden: true,
    icon: anchor,
  },
  {
    id: 7,
    title: i18n.t("navBar.occurrences"),
    url: "/occurrences",
    homeScreen: false,
    hidden: false,
    icon: occurenceIcon,
  },
  {
    id: 17,
    icon: analytics,
    title: "Analytics",
    url: "",
    homeScreen: false,
    hidden: true,
    superUser: true,
    subMenuItem: [
      {
        id: 18,
        title: i18n.t("navBar.performance"),
        url: "/analytics-reporting",
        hidden: true,
        superUser: true,
      },
      {
        id: 19,
        title: i18n.t("navBar.eventHistory"),
        url: "/event-history",
        hidden: true,
        superUser: true,
      },
      {
        id: 20,
        title: i18n.t("navBar.eventAnalysis"),
        url: "/event-analysis",
        hidden: true,
        superUser: true,
      },
    ],
  },
  {
    id: 8,
    icon: userCircle,
    title: i18n.t("navBar.admin"),
    url: "",
    hidden: false,
    divider: true,
    subMenuItem: [
      {
        id: 10,
        title: i18n.t("navBar.userManager"),
        url: "/admin/user-manager",
        hidden: true,
        admin: true,
      },
      {
        id: 16,
        title: i18n.t("navBar.manageStation"),
        url: "/admin/manage-station",
        hidden: false,
      },
    ],
  },
  {
    id: 11,
    title: i18n.t("navBar.notificationManager"),
    url: "/notification-manager",
    icon: alarmBell,
    homeScreen: false,
    hidden: true,
    superUser: true,
  },
  {
    id: 12,
    title: i18n.t("navBar.forecastChecker"),
    url: "/forecast-import-checker",
    icon: alarmBell,
    homeScreen: false,
    hidden: true,
    superUser: true,
  },
  {
    id: 13,
    title: i18n.t("navBar.morphologyMap"),
    url: "/sedimantetion-map",
    icon: mapIcon,
    homeScreen: false,
    hidden: true,
    superUser: false,
  },
  {
    id: 14,
    title: i18n.t("navBar.settings"),
    url: "/Settings",
    icon: vector,
    homeScreen: false,
    hidden: false,
    superUser: true,
  },
];
