import { useEffect, useState } from "react";

export type WindowSize = {
  width: number;
  height: number;
};

export default function useWindowObserver(parentSelector: string) {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const parent = document.querySelector(parentSelector);

  useEffect(() => {
    if (!parent) return;

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      const width = entry.contentRect.width;
      const height = entry.contentRect.height;

      requestAnimationFrame(() => {
        setWindowSize({ width, height });
      });
    });

    observer.observe(parent);

    return () => {
      observer.unobserve(parent);
    };
  }, [parent]);

  return windowSize;
}
