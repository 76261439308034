import { Box, Center, Button, Text } from "@chakra-ui/react";
import Lock from "../../assets/lock.svg";

interface EmptyChartWithContactProps {
  ChartComponent: React.ReactNode;
  message: string;
  contactMessage: string;
  buttonMessage: string;
  onContactClick: () => void;
  bgColor?: string;
  fontSize?: string;
  padding?: string;
  width?: string;
}

export const EmptyChartWithContact: React.FC<EmptyChartWithContactProps> = ({
  ChartComponent,
  message,
  contactMessage,
  buttonMessage,
  onContactClick,
  bgColor = "#ffffff",
  fontSize = "sm",
  padding = "12px",
  width = "500px",
}) => {
  return (
    <Box className="joyride-probabilistic">
      <Box filter={"blur(2px)"} pointerEvents={"none"} mr={"14px"} mt={"16px"}>
        {ChartComponent}
      </Box>
      <Box
        bg={bgColor}
        position={"absolute"}
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        borderRadius="8px"
        p={padding}
        boxShadow={"0px 0px 18px rgba(0, 24, 83, 0.2)"}
        width={width}
      >
        <Center mb={"8px"}>
          <img loading="lazy" src={Lock} style={{ width: "32px" }} alt="Lock" />
        </Center>
        <Center>
          <Text textAlign={"center"} color={"newBlue.500"} fontSize={fontSize}>
            {message}
          </Text>
        </Center>
        <Center mb={"8px"}>
          <Text color={"gray.500"} fontSize={fontSize}>
            {contactMessage}
          </Text>
        </Center>
        <Center>
          <Button
            layerStyle="ocean"
            size={"sm"}
            _hover={{ layerStyle: "ocean" }}
            onClick={onContactClick}
          >
            {buttonMessage}
          </Button>
        </Center>
      </Box>
    </Box>
  );
};
