import React from "react";
import { FormControl, FormLabel, Flex } from "@chakra-ui/react";
import { IStationRegion } from "types/IStationRegion";
import { FormVariableValues, VariableControl } from "./VariableControl";
import { FormikErrors } from "formik";
import { useTranslation } from "react-i18next";

interface VariableStationControlProps {
  values: FormVariableValues;
  stations: IStationRegion[];
  getFieldProps: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<FormVariableValues>>;
  selectedStationId: number;
  selectedRegionId: number;
  filterOption: (
    input: string,
    option?: { label: string; value: string; children: any }
  ) => boolean;
}

export const VariableStationControl: React.FC<VariableStationControlProps> = ({
  values,
  stations,
  getFieldProps,
  setFieldValue,
  selectedStationId,
  selectedRegionId,
  filterOption,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.metOceanReport",
  });

  const selectedStationRegionId =
    selectedStationId > 0 && selectedRegionId > 0
      ? `${selectedStationId}-${selectedRegionId}`
      : null;

  return (
    <FormControl isRequired>
      <Flex>
        <FormLabel mt={4} color="newBlue.500">
          {t("selectTheVariablesAndStations")}
        </FormLabel>
      </Flex>

      <VariableControl
        name="weather"
        label={t("weather")}
        values={values}
        stations={stations}
        getFieldProps={getFieldProps}
        setSelectedStation={setFieldValue}
        selectedStationRegionId={selectedStationRegionId}
        filterOption={filterOption}
      />
      <VariableControl
        name="wave"
        label={t("wave")}
        values={values}
        stations={stations}
        getFieldProps={getFieldProps}
        setSelectedStation={setFieldValue}
        selectedStationRegionId={selectedStationRegionId}
        filterOption={filterOption}
      />
      <VariableControl
        name="waterFlow"
        label={t("waterFlow")}
        values={values}
        stations={stations}
        getFieldProps={getFieldProps}
        setSelectedStation={setFieldValue}
        selectedStationRegionId={selectedStationRegionId}
        filterOption={filterOption}
      />
    </FormControl>
  );
};
