import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { SCREEN_HORIZONTAL_SPACING } from "../../../constants";
import { LegendItem } from "./Components/LegendItem";

interface ForecastChartInsightLegendProps {
  legendItems: {
    color: string;
    label: string;
    description?: string;
  }[];
  outerTooltip?: string;
}

const ForecastChartInsightLegend: React.FC<ForecastChartInsightLegendProps> = ({
  legendItems,
  outerTooltip,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartInsightLegend",
  });

  return (
    <Box
      key={`id-${outerTooltip}`}
      zIndex={9999999}
      position={"unset"}
      bottom={2}
      right={SCREEN_HORIZONTAL_SPACING}
      mr={SCREEN_HORIZONTAL_SPACING}
      borderRadius={4}
      bg="rgba(0, 0, 0, 0.37)"
      color="white"
      backdropFilter="blur(2px)"
      p={2}
    >
      <Flex gap={2} justifyContent="space-between" mb={2}>
        <Tooltip label={t("innerCircle")} placement="top" hasArrow>
          <Flex alignItems={"center"}>
            <span>
              <i
                style={{ color: "#fff", position: "relative" }}
                className={`fa fa-circle fa-stack-1x fa-inverse`}
              />
            </span>
            <Box ml={2}>
              <Text fontSize="xs">{t("realTimeForecast")}</Text>
            </Box>
          </Flex>
        </Tooltip>

        <Tooltip
          label={
            <Box>
              {t("outerCircle")}
              <br />
              {outerTooltip}
            </Box>
          }
          placement="top-start"
          hasArrow
        >
          <Flex alignItems={"center"}>
            <span>
              <i
                style={{ color: "#fff", position: "relative" }}
                className={`far fa-circle fa-stack-1x`}
              />
            </span>
            <Box ml={2}>
              <Text fontSize="xs">{t("forecastTimeline")}</Text>
            </Box>
          </Flex>
        </Tooltip>
      </Flex>

      <Flex gap={2} alignItems="center" justifyContent="space-between">
        {legendItems.map((item, index) => (
          <LegendItem
            key={index}
            color={item.color}
            label={item.label}
            tooltipText={item.description}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default observer(ForecastChartInsightLegend);
