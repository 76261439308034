import { VisualizationType } from "enum/VisualizationType";
import i18n from "../../../../i18n";

export const getSerieDefaultConfig = (
  environmentalVariable: string,
  data: number[] | number[][] | number[][][],
  unitsUser: string,
  events: { legendItemClick: () => boolean },
  visualizationType: VisualizationType
) => {
  return {
    name: i18n.t(`environmentalVariableEnum.${environmentalVariable}`),
    type: "spline",
    data,
    environmentalVariable: environmentalVariable,
    visualizationType,
    events,
    color: "#347ED5",
    tooltip: {
      valueDecimals: 2,
      valueSuffix: unitsUser,
    },
  };
};
