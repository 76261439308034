import api from "./api";

export default class TechnomarAuthService {
  async getTechnomarAuthMooringLines() {
    const result = await api.get(`/v1/technomar-auth/mooringLines`);

    return result.data;
  }

  async getTechnomarAuthUKC() {
    const result = await api.get(`/v1/technomar-auth/ukc`);

    return result.data;
  }
}
