import { EnvironmentalVariables } from "../../../../enum/EnvironmentalVariables";
import i18n from "../../../../i18n";
import { ISerieConfigMap } from "./types/ISerieConfigMap";

export const getRiverWaterLevelConfigMap = (): Record<
  string,
  ISerieConfigMap
> => {
  const forecastColors: Partial<Record<EnvironmentalVariables, string>> = {
    [EnvironmentalVariables.RIVER_EXTREME_LOW]: "#f57105",
    [EnvironmentalVariables.RIVER_VERY_LOW]: "#f5ad05",
    [EnvironmentalVariables.RIVER_LOW]: "#f5da0c",
    [EnvironmentalVariables.RIVER_MOST_LIKELY]: "#0da12f",
    [EnvironmentalVariables.RIVER_HIGH]: "#0d8ba1",
    [EnvironmentalVariables.RIVER_VERY_HIGH]: "#0d57a1",
    [EnvironmentalVariables.RIVER_EXTREME_HIGH]: "#4d0da1",
    [EnvironmentalVariables.RIVER_SENSOR]: "#54D3C0",
  };

  return {
    [EnvironmentalVariables.RIVER_EXTREME_HIGH]: {
      serieConfig: {
        name: i18n.t("riverForecastChart.extremeHigh"),
        color: forecastColors[EnvironmentalVariables.RIVER_EXTREME_HIGH],
        zIndex: 3,
        label: {
          enabled: false,
        },
        marker: {
          enabled: false,
        },
        dashStyle: "dot",
        lineWidth: 4,
        gridLineWidth: 1,
      },
    },
    [EnvironmentalVariables.RIVER_VERY_HIGH]: {
      serieConfig: {
        name: i18n.t("riverForecastChart.veryHigh"),
        color: forecastColors[EnvironmentalVariables.RIVER_VERY_HIGH],
        zIndex: 3,
        label: {
          enabled: false,
        },
        marker: {
          enabled: false,
        },
        dashStyle: "shortDot",
        lineWidth: 3,
        gridLineWidth: 1,
      },
    },
    [EnvironmentalVariables.RIVER_HIGH]: {
      serieConfig: {
        name: i18n.t("riverForecastChart.high"),
        color: forecastColors[EnvironmentalVariables.RIVER_HIGH],
        zIndex: 3,
        label: {
          enabled: false,
        },
        marker: {
          enabled: false,
        },
        dashStyle: "shortDot",
        lineWidth: 3,
        gridLineWidth: 1,
      },
    },
    [EnvironmentalVariables.RIVER_MOST_LIKELY]: {
      serieConfig: {
        name: i18n.t("riverForecastChart.mostLikely"),
        color: forecastColors[EnvironmentalVariables.RIVER_MOST_LIKELY],
        zIndex: 3,
        label: {
          enabled: false,
        },
        marker: {
          enabled: false,
        },
        lineWidth: 3,
        gridLineWidth: 1,
      },
    },
    [EnvironmentalVariables.RIVER_LOW]: {
      serieConfig: {
        name: i18n.t("riverForecastChart.low"),
        color: forecastColors[EnvironmentalVariables.RIVER_LOW],
        zIndex: 3,
        label: {
          enabled: false,
        },
        marker: {
          enabled: false,
        },
        dashStyle: "shortDot",
        lineWidth: 3,
        gridLineWidth: 1,
      },
    },
    [EnvironmentalVariables.RIVER_VERY_LOW]: {
      serieConfig: {
        name: i18n.t("riverForecastChart.veryLow"),
        color: forecastColors[EnvironmentalVariables.RIVER_VERY_LOW],
        zIndex: 3,
        label: {
          enabled: false,
        },
        marker: {
          enabled: false,
        },
        dashStyle: "shortDot",
        lineWidth: 3,
        gridLineWidth: 1,
      },
    },
    [EnvironmentalVariables.RIVER_EXTREME_LOW]: {
      serieConfig: {
        name: i18n.t("riverForecastChart.extremeLow"),
        color: forecastColors[EnvironmentalVariables.RIVER_EXTREME_LOW],
        zIndex: 3,
        label: {
          enabled: false,
        },
        marker: {
          enabled: false,
        },
        dashStyle: "dot",
        lineWidth: 4,
        gridLineWidth: 1,
      },
    },
    [EnvironmentalVariables.RIVER_SENSOR]: {
      serieConfig: {
        name: i18n.t("riverForecastChart.sensor"),
        color: forecastColors[EnvironmentalVariables.RIVER_SENSOR],
        zIndex: 3,
        label: {
          enabled: false,
        },
        marker: {
          enabled: false,
        },
        gridLineWidth: 1,
      },
    },
  };
};
