import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Center,
} from "@chakra-ui/react";
import { message } from "antd";
import { useStores } from "../../../stores/setup/use-store";
import { Field, Form, Formik } from "formik";
import { StoreState } from "../../../enum/StoreState";
import { useTranslation } from "react-i18next";

interface FormChangePasswordValues {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const ChangePassword: React.FC = () => {
  const {
    dataStores: { authStore },
  } = useStores();
  const { t } = useTranslation("translation", {
    keyPrefix: "admin.changePassword",
  });

  const handleUpdatePassword = async (values: FormChangePasswordValues) => {
    if (values.newPassword !== values.confirmNewPassword) {
      message.info(t("passwordNotMatch"));
    } else {
      await authStore.changePasswordApi(
        values.currentPassword,
        values.newPassword
      );

      if (authStore.state === StoreState.ERROR) {
        message.info(authStore.messageError);
      } else if (authStore.state === StoreState.SUCCESS) {
        message.info(t("successChangedPassword"));
      }
    }
  };

  return (
    <Center p={4} mr={4}>
      <Formik
        initialValues={{
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
        onSubmit={(values: FormChangePasswordValues) => {
          handleUpdatePassword(values);
        }}
      >
        {(props) => (
          <Form>
            <Field name="currentPassword">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.name && form.touched.name}
                  mt={4}
                  w={350}
                >
                  <FormLabel color={"blue.100"}>
                    {t("currentPassword")}
                  </FormLabel>
                  <Input
                    {...field}
                    type="password"
                    color="black"
                    placeholder={t("currentPassword")}
                  />
                </FormControl>
              )}
            </Field>

            <Field name="newPassword">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.name && form.touched.name}
                  mt={4}
                >
                  <FormLabel color={"blue.100"}>{t("newPassword")}</FormLabel>
                  <Input
                    {...field}
                    color="black"
                    placeholder={t("newPassword")}
                    type="password"
                  />
                </FormControl>
              )}
            </Field>

            <Field name="confirmNewPassword">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.name && form.touched.name}
                  mt={4}
                >
                  <FormLabel color={"blue.100"}>
                    {t("confirmNewPassword")}
                  </FormLabel>
                  <Input
                    {...field}
                    color="black"
                    placeholder={t("confirmNewPassword")}
                    type="password"
                  />
                </FormControl>
              )}
            </Field>

            <Center mt={6}>
              <Button colorScheme="blue" ml={4} type="submit">
                {t("updatePassword")}
              </Button>
            </Center>
          </Form>
        )}
      </Formik>
    </Center>
  );
};
