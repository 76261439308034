import React, { useEffect } from "react";
import { Modal, Form, Input, message, Alert, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { StoreState } from "../../../enum/StoreState";
import { useStores } from "../../../stores/setup/use-store";
import { useTranslation } from "react-i18next";

interface ChangePasswordModalProps {
  onCancel: () => void;
}

interface FormChangePasswordValues {
  password: string;
  current_password: string;
  confirm_password: string;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  onCancel,
}) => {
  const {
    dataStores: { authStore },
  } = useStores();
  const [form] = Form.useForm();

  const { t } = useTranslation("translation", {
    keyPrefix: "login.changePassword",
  });

  useEffect(() => {
    form.resetFields();
  }, [authStore, form]);

  const handleUpdatePassword = (values: FormChangePasswordValues) => {
    if (values.password !== values.confirm_password) {
      message.info(t("passwordNotMatch"));
    } else {
      authStore.changePasswordApi(values.current_password, values.password);
    }
  };

  return (
    <Modal
      width={350}
      visible={authStore.modalChangePasswordVisible}
      onCancel={onCancel}
      title={t("changePassword")}
      okText={t("updatePassword")}
      cancelText={t("cancel")}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleUpdatePassword(values as FormChangePasswordValues);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Spin spinning={authStore.state === StoreState.PEDDING}></Spin>
      {authStore.state === StoreState.ERROR ? (
        <Alert
          message={t("error")}
          description={authStore.messageError}
          type="error"
          showIcon
          closable
        />
      ) : (
        ""
      )}
      {authStore.state === StoreState.SUCCESS ? (
        <Alert
          message={t("success")}
          description={t("dataEditedSuccess")}
          type="success"
          showIcon
          closable
        />
      ) : (
        ""
      )}
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal_child"
        initialValues={{ modifier: "public" }}
        style={{ marginTop: 10 }}
      >
        <Form.Item
          name="current_password"
          label={t("currentPassword")}
          rules={[
            {
              required: true,
              message: t("pleaseInputCurrentPassword") as string,
            },
          ]}
        >
          <Input.Password
            onChange={(e) => {
              form.setFieldsValue({
                current_password: e.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label={t("newPassword")}
          rules={[
            {
              required: true,
              message: t("pleaseInputYourNewPassword") as string,
            },
          ]}
        >
          <Input.Password
            onChange={(e) => {
              form.setFieldsValue({
                password: e.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          name="confirm_password"
          label={t("confirmNewPassword")}
          rules={[
            {
              required: true,
              message: t("pleaseInputYourNewPasswordAgain") as string,
            },
          ]}
        >
          <Input.Password
            onChange={(e) => {
              form.setFieldsValue({
                confirm_password: e.target.value,
              });
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(ChangePasswordModal);
