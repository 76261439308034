import Highcharts from "highcharts";

export const probabilisticFormatTooltip = (x: number, points: any) => {
  let ret =
    '<b style="font-size: 15px;">' +
    Highcharts.dateFormat("%d/%m/%Y %H:%M", x) +
    "</b>";

  ret += "<table>";

  // Add all series
  for (const point of points) {
    const serie = point.series;
    const rangeHigh = point.point.high
      ? " - " + point.point.high.toFixed(2)
      : "";
    ret +=
      '<tr><td><span style="font-size: 15px; color:' +
      serie.color +
      '">\u25CF</span> ' +
      serie.name +
      ': &nbsp;</td><td style="font-size: 15px; white-space:nowrap">' +
      point.y.toFixed(2) +
      rangeHigh +
      " " +
      serie.options.tooltip.valueSuffix +
      "</td></tr>";
  }

  // Close
  ret += "</table>";

  return ret;
};
